<!-- select and over all row -->
<div class="modal-header">
    <h1 class="modal-title">Direcciones Guardadas</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="overflow-auto w-100">
        <mat-form-field>
            <mat-label>
                <mat-icon>search</mat-icon> Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
        </mat-form-field>
    </div>
    <div class="mat-elevation-z8 table-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="w-100">

            <ng-container matColumnDef="addressName">
                <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header> Alias </th>
                <td mat-cell *matCellDef="let row"> {{row.addressName}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th class="col-4" mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
                <td mat-cell *matCellDef="let row"> {{row.street}} {{row.number}}, {{row.suburb}}, {{row.city}}, {{row.state}}, CP: {{row.postalCode}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th class="col-2" mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                <td mat-cell *matCellDef="let row" class="text-center" >
                    <button class="col-9" mat-stroked-button type="button" color="primary"  [mat-dialog-close]="selectAddress(row)">Seleccionar <mat-icon>navigate_next</mat-icon></button>
                    <button class="col-3" mat-icon-button matTooltip="Eliminar" [matTooltipPosition]="'above'" color="warn" (click)="deleteAddress(row.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td *ngIf="addressList.length == 0" class="mat-cell" colspan="6">No hay direcciones para mostrar</td>
                <td *ngIf="addressList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
            </tr>
        </table>

        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-bar color="primary" mode="indeterminate">
            </mat-progress-bar>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>

</div>