import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressModel } from 'src/app/models/address.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-address-add',
  templateUrl: './address-add.component.html',
  styleUrls: ['./address-add.component.scss']
})

export class AddressAddComponent implements OnInit {
  userObj!: UserModel;
  info! : AddressModel;
  addressName! : string;
  formSaveAddress! : FormGroup;

  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
    private userS: UsersService,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.info = this.data.info;
    this.info.city = this.data.city;
    this.info.state = this.data.state;
    this.formSaveAddress = this.fb.group({
      alias: ['', [ Validators.required, Validators.minLength(3)]],
    })
  }

  addAddressName(event:Event){
    this.info.addressName = ( event.target as HTMLInputElement ).value.trim();
  }
  
  saveAddressData() {
    Swal.fire({
      icon:  'info',
      title: 'Guardando...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.saveAddress( this.info ).subscribe(
      ( result:any ) => {
        if ( result.message == "Success" ) {
          Swal.fire({
            icon:  'success',
            title: 'Guardado',
            html:  'Domicilio guardado de manera correcta!'
          });
        }
        else if ( result.message == "isSaved" ) {
          Swal.fire({
            icon:  'info',
            title: 'Aviso',
            html:  'Ya tienes guardada esta direccion'
          })
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error al guardar',
          text:  'Ocurrió un error al guardar el domicilio, por favor vuelva a intentarlo.'
        });
      }
    );
  }
}
