<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Mis favoritos</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>favorite_outline</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Mis favoritos</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <ng-template mat-tab-label>Favoritos <mat-icon class="ms-1 ">favorite_outline</mat-icon>
                </ng-template>
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                        </mat-form-field>
                    </div>
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="fsDataSource" matSort class="w-100">
                            <ng-container matColumnDef="alias">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Alias </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> <b> {{ row.alias }}  </b></td>
                            </ng-container>

                            <ng-container matColumnDef="carrier">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Paquetería </th>
                                <td class="col-1" mat-cell *matCellDef="let row">
                                    <img class="w-50" *ngIf="row && row.rate_selected" src="../../../assets/images/carriers/{{imgFormat(row.rate_selected.carrier)}}.jpg" alt="{{imgFormat(row.rate_selected.carrier)}}">
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="origin">
                                <th class="ps-4" mat-header-cell *matHeaderCellDef mat-sort-header> Origen </th>
                                <td class="col-2 ps-4 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row">{{row.from_address.full_address}} </td>
                            </ng-container>

                            <ng-container matColumnDef="destination">
                                <th class="ps-4" mat-header-cell *matHeaderCellDef mat-sort-header> Destino </th>
                                <td class="col-2 ps-4 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row">{{row.to_address.full_address}} </td>
                            </ng-container>

                            <ng-container matColumnDef="shipment">
                                <th class="ps-4" mat-header-cell *matHeaderCellDef mat-sort-header> Envío </th>
                                <td class="col-3 text-justify pt-2 pb-2 pe-2 ps-4" mat-cell *matCellDef="let row"> 
                                    <div class="row" *ngFor="let package of row.packages; index as i"><p><span class="text-uppercase">{{package.content}}</span><br>{{package.length}}x{{package.width}}x{{package.height}}(cm) - {{package.weight}} KG.</p></div></td>
                                <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center" >
                                    <button mat-icon-button matTooltip="Reenviar favorito" [matTooltipPosition]="'above'" color="primary" (click)="addFavtoService(row)">
                                        <mat-icon>send</mat-icon>                                            </button>
                                    <button mat-icon-button [matMenuTriggerFor]="menufav" color="primary">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menufav="matMenu">
                                        <button mat-menu-item  matTooltip="Detalles" [matTooltipPosition]="'above'" color="primary" (click)="showFav(row)">
                                            <mat-icon class="me-2">info</mat-icon> Ver detalles
                                        </button>
                                        
                                        <button mat-menu-item matTooltip="Eliminar favorito" [matTooltipPosition]="'above'" color="primary" (click)="removeFavAddress(row)">
                                            <mat-icon class="me-2">delete</mat-icon>Eliminar
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="fsDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: fsDisplayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td *ngIf="favShipList.length == 0" class="mat-cell" colspan="6">Aún no cuentas con ningun envío favorito</td>
                                <td *ngIf="favShipList.length > 1" class="mat-cell" colspan="6">Aún no cuentas con ningun envío favorito</td>
                            </tr>
                        </table>
                
                        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-bar color="primary" mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>