import { NgModule } from '@angular/core';
import { RouterModule, Routes, Scroll  } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { ShipmentsAddComponent } from './components/shipments-add/shipments-add.component';
import { ShipmentsComponent } from './components/shipments/shipments.component';
import { UsersComponent } from './components/users/users.component';
import { BillingDataComponent } from './components/billing-data/billing-data.component';
import { AuthGuard } from './guards/auth.guard';
import { FavShipmentsComponent } from './components/fav-shipments/fav-shipments.component';
import { ShipmentOrderComponent } from './components/shipment-order/shipment-order.component';
import { ShipmentOrdersComponent } from './components/shipment-orders/shipment-orders.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { BulkUploadsComponent } from './components/bulk-uploads/bulk-uploads.component';
import { BulkUploadComponent } from './components/bulk-upload/bulk-upload.component';
import { BillingDataSfComponent } from './components/billing-data-sf/billing-data-sf.component';
import { PriceComparisonComponent } from './components/price-comparison/price-comparison.component';
import { BillingDataSiteflowComponent } from './components/billing-data-siteflow/billing-data-siteflow.component';
import { StpOrdersComponent } from './components/stp-orders/stp-orders.component';
import { CreditHistoryComponent } from './components/credit-history/credit-history.component';
import { ExecutiveReportComponent } from './components/executive-report/executive-report.component';
import { EcommerceConfigComponent } from './components/ecommerce-config/ecommerce-config.component';
import { EcommerceOrdersComponent } from './components/ecommerce-orders/ecommerce-orders.component';
import { PickupFormComponent } from './components/pickups/pickup-form/pickup-form.component';
import { PickupListComponent } from './components/pickups/pickup-list/pickup-list.component';
import { EcommerceComponent } from './components/ecommerce/ecommerce.component';
import { QuickQuoteComponent } from './components/quick-quote/quick-quote.component';
import { InhouseShipmentsComponent } from './components/inhouse-shipments/inhouse-shipments.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin', canActivate: [AuthGuard], component: LayoutComponent, data: {expectedRoles: ['Any']},
    children: [      
      { path: '', canActivate: [AuthGuard], component: DashboardComponent, data: {expectedRoles: ['Any']} },
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent, data: {expectedRoles: ['Any']} },
      { path: 'users', canActivate: [AuthGuard], component: UsersComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'myfavorites', canActivate: [AuthGuard], component: FavShipmentsComponent, data: {expectedRoles: ['Any']} },
      { path: 'shipments', canActivate: [AuthGuard], component: ShipmentsComponent, data: {expectedRoles: ['Any']} },
      { path: 'shipments/add', canActivate: [AuthGuard], component: ShipmentsAddComponent, data: {expectedRoles: ['Any']} },
      { path: 'billing-data', canActivate: [AuthGuard], component: BillingDataComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'billing-data-sf', canActivate: [AuthGuard], component: BillingDataSfComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'billing-data-siteflow', canActivate: [AuthGuard], component: BillingDataSiteflowComponent, data: {expectedRoles: ['estrategia','admin',]} },
      { path: 'shipment-orders', canActivate: [AuthGuard], component: ShipmentOrdersComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'shipment-order', canActivate: [AuthGuard], component: ShipmentOrderComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'companies', canActivate: [AuthGuard], component: CompaniesComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'bulk-uploads', canActivate: [AuthGuard], component: BulkUploadsComponent, data: {expectedRoles: ['estrategia','admin', 'operaciones']} },
      { path: 'bulk-upload', canActivate: [AuthGuard], component: BulkUploadComponent, data: {expectedRoles: ['estrategia','admin', 'operaciones']} },
      { path: 'price-comparison', canActivate: [AuthGuard], component: PriceComparisonComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'stp-orders', canActivate: [AuthGuard], component: StpOrdersComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'credit-history', canActivate: [AuthGuard], component: CreditHistoryComponent, data: {expectedRoles: ['Any']} },
      { path: 'executive-report', canActivate: [AuthGuard], component: ExecutiveReportComponent, data: {expectedRoles: ['estrategia','admin']} },
      { path: 'ecommerce-config', canActivate: [AuthGuard], component: EcommerceConfigComponent, data: {expectedRoles: ['estrategia','admin', 'operaciones']} },
      { path: 'ecommerce', canActivate: [AuthGuard], component: EcommerceComponent, data: {expectedRoles: ['estrategia','admin', 'operaciones']} },
      { path: 'ecommerce-orders', canActivate: [AuthGuard], component: EcommerceOrdersComponent, data: {expectedRoles: ['estrategia','admin', 'operaciones']} },
      { path: 'pickups', canActivate: [AuthGuard], component: PickupListComponent, data: {expectedRoles: ['admin', 'estrategia']} },
      { path: 'pickups/form', canActivate: [AuthGuard], component: PickupFormComponent, data: {expectedRoles: ['admin', 'estrategia']} },
      { path: 'quick-quote', canActivate: [AuthGuard], component: QuickQuoteComponent,  data: {expectedRoles: ['Any']} },
      { path: 'inhouse-shipments', canActivate: [AuthGuard], component: InhouseShipmentsComponent, data: {expectedRoles: ['Any']} }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
