export class UserLoginModel {
    email!: string;
    pass!: string;
}

export class UserModel {
    id!: number;
    id_rol!: number;
    id_company!: number;
    country!: string;
    iso_country!: string;
    currency!: string;
    role!: string;
    email!: string;
    name!: string;
    tel!: string;
    active!: number;
    id_shop!:string;
    exp!: number;
    permissions!: any;
    providers!:any;
    carriers!:any;
    ch_pass!: number;
    international_shipping!: string;
}

export class UserModelMin {
    id!: number;
    id_rol!: number;
    email!: string;
    name!: string;
}

export class UserSaveModel {
    id_user!: number;
    id_rol!: number;    
    id_company!: number;
    id_serv!: number;    
    email!: string;
    pass!: string;
    passConfirm!: string;    
    name!: string;
    active!: number;
    ch_pass!: number;
}

export class CurrencyModel {
    id!: number;
    currency!: string;
}