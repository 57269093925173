import { Component, OnInit } from '@angular/core';
import { ShopifyService } from 'src/app/services/shopify.service';
import Swal from 'sweetalert2';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-ecommerce-config',
  templateUrl: './ecommerce-config.component.html',
  styleUrls: ['./ecommerce-config.component.scss']
})
export class EcommerceConfigComponent implements OnInit {

  isLoading = true;
  storeList: any;
  shop: any;
  token: string | null = null;

  registerForm = this._formBuilder.group({
    id_shop: ['', Validators.required]
  });

  constructor(
    // private matDialog: MatDialog,
    private router: Router,
    private shopifyS: ShopifyService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService


  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const tokenParam = params.get('token');
      if (tokenParam) {
        this.token = tokenParam;
        const decoded: any = jwt_decode(tokenParam);
        this.shop = decoded.shop;
        this.setStore();
      } else {
        this.router.navigate(['/admin/ecommerce']);
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Error',
        //   text: 'Operación no autorizada'
        // });
        // Swal.fire({
        //   title: '<strong>Error</strong>',
        //   icon: 'error',
        //   showConfirmButton: false,
        //   html:
        //     'Realiza la instalacion desde la ' +
        //     '<a href="https://partners.shopify.com/3451349/apps/86297116673/test">tienda de aplicaciones</a> '
        // })
      }
    });
  }

  setStore() {
    this.registerForm.controls['id_shop'].setValue(this.shop)
  }

  ruSure() {
    if (this.registerForm.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas registrar esta tienda?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        this.saveStore();
      }
    });
  }

  saveStore() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    if (this.registerForm.valid) {
      const formValue = { 
        ...this.registerForm.value, 
        id_shop: this.registerForm.get('id_shop')?.value + '.myshopify.com' 
      };
      console.log(formValue)
      this.shopifyS.saveStore(formValue, this.token).subscribe(
        (result: any) => {
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              html: 'Tienda registrada de manera correcta, para terminar la configuracion inicía sesion nuevamente!'
            }).then(() => {            
                // this.router.navigate(['/admin/ecommerce']);
                this.auth.logout();
                this.router.navigateByUrl('/login');
            });
          }
        },
        (err: any) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
          });
        }
      );
    }
  }
}
