import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LogOrderNumberModel } from 'src/app/models/shipment.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipment-order',
  templateUrl: './shipment-order.component.html',
  styleUrls: ['./shipment-order.component.scss']
})
export class ShipmentOrderComponent implements OnInit {
  userObj!: UserModel;
  dataShipment : any;
  dataList =  new Array<LogOrderNumberModel>();
  isLoading = true;
  base64!:string;
  noDataReceived = false;
  //#region Filtros
  dateNow : Date = new Date();
  dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
  preLoadHidden = true;  
  //#endregion
  
  displayedColumns: string[] = ['created_at', 'info', 'warnings', 'errors'];
  
  dataSource!: MatTableDataSource<LogOrderNumberModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  get data():any { return this.userS.sharedOrderUnsuccessfully; } set data(value: any) { this.userS.sharedOrderUnsuccessfully = value; }
  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    //console.log(this.data);    
    if(this.data?.order_number){
      this.getDataList(this.data.order_number); 
    }else{
      this.noDataReceived = true;
      window.scrollTo(0, 0);
    }

    this.isLoading = true; 
    
  }

  

  getDataList(order_number : string) {
    this.userS.getLogShiptmentOrder(order_number).subscribe(
      (result : any) => {

        this.dataShipment = result.data;
        
        // if(result.data!=undefined)
        // {
        //   this.dataList = result.data.dataList;
        // }else{
        //   this.dataList = new Array<LogOrderNumberModel>();
        // }
        
        // this.dataSource = new MatTableDataSource(this.dataList);     
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.paginator.pageSize=this.dataList.length;
        // this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.preLoadHidden = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  
  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getPageSizeOptions(): number[]{
    if(this.dataList.length>10)
    return [10,50,100,this.dataList.length];
    else
    return [10,50,100];
  }

  formatItem(item:any){
    let resultado="";
    if (Array.isArray(item)) {
      item.forEach((element) => {
        if(resultado!=""){
          resultado+= `, `;
        }
        resultado+= `${element}`;
      });
    } else if (typeof item === 'object') {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const value = item[key];
          if(resultado!=""){
            resultado+= `, `;
          }
          resultado+= `${key}: ${value}`;
          //console.log(`Propiedad ${key}: ${value}`);
        }
      }
    }else {
      return item;
    }
    return resultado;
  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }
  
}
