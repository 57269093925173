import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FavShipModel } from 'src/app/models/shipment.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-fav-show',
  templateUrl: './fav-show.component.html',
  styleUrls: ['./fav-show.component.scss']
})
export class FavShowComponent implements OnInit {
  userObj!: UserModel;
  info!: FavShipModel;
  formSaveFav!: FormGroup;

  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
    private userS: UsersService,
    private fb: FormBuilder,
  ) { }
  
  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    //console.log(this.data);
    this.info = this.data;
    this.info.selected_rate = this.data.rate_selected;
  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }
  
}
