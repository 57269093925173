import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from './angular-material.module';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersComponent } from './components/users/users.component';
import { UsersAddComponent } from './components/shared/dialogs/users-add/users-add.component';
import { UsersEditComponent } from './components/shared/dialogs/users-edit/users-edit.component';
import { UsersEditPasswordComponent } from './components/shared/dialogs/users-edit-password/users-edit-password.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ShipmentsComponent } from './components/shipments/shipments.component';
import { ShipmentsAddComponent } from './components/shipments-add/shipments-add.component';
import { AddressSelectComponent } from './components/shared/dialogs/address-select/address-select.component';
import { AddressAddComponent } from './components/shared/dialogs/address-add/address-add.component';
import { ShipmentsShowComponent } from './components/shared/dialogs/shipments-show/shipments-show.component';
import { ShipmentsPrintComponent } from './components/shared/dialogs/shipments-print/shipments-print.component';
import { ShipmentsCheckoutComponent } from './components/shared/dialogs/shipments-checkout/shipments-checkout.component';
import { ShipmentsLabelActionsComponent } from './components/shared/dialogs/shipments-label-actions/shipments-label-actions.component';
import { UsersEditPasswordFirsttimeComponent } from './components/shared/dialogs/users-edit-password-firsttime/users-edit-password-firsttime.component';
import { BillingDataComponent } from './components/billing-data/billing-data.component';
import { ShipmentsHelpComponent } from './components/shared/dialogs/shipments-help/shipments-help.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FavAddComponent } from './components/shared/dialogs/fav-add/fav-add.component';
import { FavShowComponent } from './components/shared/dialogs/fav-show/fav-show.component';
import { FavShipmentsComponent } from './components/fav-shipments/fav-shipments.component';
import { ShipmentOrderComponent } from './components/shipment-order/shipment-order.component';
import { ShipmentOrdersComponent } from './components/shipment-orders/shipment-orders.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompaniesAddComponent } from './components/shared/dialogs/companies-add/companies-add.component';
import { CompaniesEditComponent } from './components/shared/dialogs/companies-edit/companies-edit.component';
import { CompaniesAddCreditComponent } from './components/shared/dialogs/companies-add-credit/companies-add-credit.component';
import { BulkUploadAddComponent } from './components/shared/dialogs/bulk-upload-add/bulk-upload-add.component';
import { BulkUploadComponent } from './components/bulk-upload/bulk-upload.component';
import { BulkUploadsComponent } from './components/bulk-uploads/bulk-uploads.component';
import { BulkUploadRowEditComponent } from './components/shared/dialogs/bulk-upload-row-edit/bulk-upload-row-edit.component';
import { BillingDataSfComponent } from './components/billing-data-sf/billing-data-sf.component';
import { PriceComparisonComponent } from './components/price-comparison/price-comparison.component';
import { PriceComparisonXlsComponent } from './components/shared/dialogs/price-comparison-xls/price-comparison-xls.component';
import { PriceComparisonNofoundComponent } from './components/shared/dialogs/price-comparison-nofound/price-comparison-nofound.component';
import { CostCatalogXlsComponent } from './components/shared/dialogs/cost-catalog-xls/cost-catalog-xls.component';
import { BillingDataSiteflowComponent } from './components/billing-data-siteflow/billing-data-siteflow.component';
import { CarrierPricingXlsComponent } from './components/shared/dialogs/carrier-pricing-xls/carrier-pricing-xls.component';
import { ClabeTransfersComponent } from './components/shared/dialogs/clabe-transfers/clabe-transfers.component';
import { StpOrdersComponent } from './components/stp-orders/stp-orders.component';
import { StpOrderDetailComponent } from './components/shared/dialogs/stp-order-detail/stp-order-detail.component';
import { CreditHistoryComponent } from './components/credit-history/credit-history.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { ExecutiveReportComponent } from './components/executive-report/executive-report.component';
import { EcommerceConfigComponent } from './components/ecommerce-config/ecommerce-config.component';
import { EcommerceOrdersComponent } from './components/ecommerce-orders/ecommerce-orders.component';
import { EcommerceShipmentCheckoutComponent } from './components/shared/dialogs/ecommerce-shipment-checkout/ecommerce-shipment-checkout.component';
import { BarChartComponent } from './components/shared/charts/bar-chart/bar-chart.component';
import { ProgressBarChartComponent } from './components/shared/charts/progress-bar-chart/progress-bar-chart.component';
import { DonutChartComponent } from './components/shared/charts/donut-chart/donut-chart.component';
import { SkeletonLoadingComponent } from './components/shared/skeleton-loading/skeleton-loading.component';
import { PickupFormComponent } from './components/pickups/pickup-form/pickup-form.component';
import { PickupListComponent } from './components/pickups/pickup-list/pickup-list.component';
import { PickupsManifestCreateComponent } from './components/shared/dialogs/pickups-manifest-create/pickups-manifest-create.component';
import { PickupsCheckoutComponent } from './components/shared/dialogs/pickups-checkout/pickups-checkout.component';
import { EcommerceComponent } from './components/ecommerce/ecommerce.component';
import { EcommerceLandingComponent } from './components/ecommerce-landing/ecommerce-landing.component';
import { EcommerceSetDimensionsComponent } from './components/shared/dialogs/ecommerce-set-dimensions/ecommerce-set-dimensions.component';
import { QuickQuoteComponent } from './components/quick-quote/quick-quote.component';
import { ViewPickupEvidenceComponent } from './components/shared/dialogs/view-pickup-evidence/view-pickup-evidence.component';
import { InhouseShipmentsComponent } from './components/inhouse-shipments/inhouse-shipments.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    LoginComponent,
    UsersComponent,
    UsersAddComponent,
    UsersEditComponent,
    UsersEditPasswordComponent,
    FooterComponent,
    ShipmentsComponent,
    ShipmentsAddComponent,
    AddressSelectComponent,
    AddressAddComponent,
    ShipmentsShowComponent,
    ShipmentsPrintComponent,
    ShipmentsCheckoutComponent,
    ShipmentsLabelActionsComponent,
    UsersEditPasswordFirsttimeComponent,
    BillingDataComponent,
    ShipmentsHelpComponent,
    FavAddComponent,
    FavShowComponent,
    FavShipmentsComponent,
    ShipmentOrderComponent,
    ShipmentOrdersComponent,
    CompaniesComponent,
    CompaniesAddComponent,
    CompaniesEditComponent,
    CompaniesAddCreditComponent,
    BulkUploadAddComponent,
    BulkUploadComponent,
    BulkUploadsComponent,
    BulkUploadRowEditComponent,
    BillingDataSfComponent,
    PriceComparisonComponent,
    PriceComparisonXlsComponent,
    PriceComparisonNofoundComponent,
    CostCatalogXlsComponent,
    BillingDataSiteflowComponent,
    CarrierPricingXlsComponent,
    ClabeTransfersComponent,
    StpOrdersComponent,
    StpOrderDetailComponent,
    CreditHistoryComponent,
    ExecutiveReportComponent,
    EcommerceConfigComponent,
    EcommerceOrdersComponent,
    EcommerceShipmentCheckoutComponent,
    EcommerceSetDimensionsComponent,
    BarChartComponent,
    ProgressBarChartComponent,
    DonutChartComponent,
    SkeletonLoadingComponent,
    PickupListComponent,
    PickupFormComponent,
    PickupsManifestCreateComponent,
    PickupsCheckoutComponent,
    EcommerceComponent,
    EcommerceLandingComponent,
    QuickQuoteComponent,
    ViewPickupEvidenceComponent,
    InhouseShipmentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableExporterModule,
    NgApexchartsModule,
    Ng2GoogleChartsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
