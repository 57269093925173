import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-companies-add-credit',
  templateUrl: './companies-add-credit.component.html',
  styleUrls: ['./companies-add-credit.component.scss']
})
export class CompaniesAddCreditComponent implements OnInit {
  formC!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<CompaniesAddCreditComponent>,
    private matDialog: MatDialog
  ) { }

  
  ngOnInit(): void {
    //console.log(this.data);
    this.createForm();
    this.setUserForm();
  }

  createForm() {
    this.formC = this.fb.group({
      id_company_credit: ['',[]],
      id_company: ['',[]],
      credit: ['',[]],
      observations: ['',[]],
      add_credit: ['', [ Validators.required]],
    });
  }
  setUserForm() {
    //const { active, email, id, role, rol_name, ...userFormInfo } = this.data.company;
    this.formC.setValue(this.data);
  }

  ruSure() {
    if(this.formC.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas añadir los créditos?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.save();
      }
    });
  }

  save() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.userS.addCreditCompany(this.formC.value.id_company_credit, this.formC.value.id_company, this.formC.value.add_credit, this.formC.value.observations).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Compañía guardada de manera correcta!'          
          }).then(() => {
            //console.log((this.formC.value.credit*1) + (this.formC.value.add_credit*1) );
            this.dialogRef.close({ reload: false, credit: (this.formC.value.credit*1) + (this.formC.value.add_credit*1) });
          });
        }
      },
      (err:any) => {        
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
      }
    );
  }



}
