import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexOptions, ApexPlotOptions, ApexResponsive, ApexStroke, ApexTheme, ApexTitleSubtitle, ApexXAxis, ApexYAxis } from "ng-apexcharts";

let primary_color = localStorage.getItem("primary_color") || "#7366ff";
let secondary_color = localStorage.getItem("secondary_color") || "#f73164";
let color_3 = '#51bb25';
let color_4 = getComputedStyle(document.documentElement).getPropertyValue('--bs-blue') || '#0d6efd';
let color_5 = getComputedStyle(document.documentElement).getPropertyValue('--bs-indigo') || '#6610f2';
let color_6 = getComputedStyle(document.documentElement).getPropertyValue('--bs-purple') || '#6f42c1';
let color_7 = getComputedStyle(document.documentElement).getPropertyValue('--bs-pink') || '#d63384';
let color_8 = getComputedStyle(document.documentElement).getPropertyValue('--bs-red') || '#dc3545';
let color_9 = getComputedStyle(document.documentElement).getPropertyValue('--bs-orange') || '#fd7e14';
let color_10 = getComputedStyle(document.documentElement).getPropertyValue('--bs-yellow') || '#ffc107';
let color_11 = getComputedStyle(document.documentElement).getPropertyValue('--bs-green') || '#198754';
let color_12 = getComputedStyle(document.documentElement).getPropertyValue('--bs-teal') || '#20c997';
let color_13 = getComputedStyle(document.documentElement).getPropertyValue('--bs-cyan') || '#0dcaf0';
let color_14 = getComputedStyle(document.documentElement).getPropertyValue('--bs-black') || '#000';
let color_15 = getComputedStyle(document.documentElement).getPropertyValue('--bs-gray') || '#6c757d';
let color_16 = getComputedStyle(document.documentElement).getPropertyValue('--bs-dark') || '#212529';
let color_17 = getComputedStyle(document.documentElement).getPropertyValue('--bs-light') || '#f8f9fa';

//#region google charts
// Pie Chart 1
export let pieChart1: any = {
  chartType: 'PieChart',
  dataTable: [],
  // dataTable: [
  //   ['Task', 'Hours per Day'],
  //   ['Work', 5],
  //   ['Eat', 10],
  //   ['Commute', 15],
  //   ['Watch TV', 20],
  //   ['Sleep', 25]
  // ],
  options: {
    title: '',
    width: '100%',
    height: 450,
    colors: [primary_color, secondary_color, color_3, color_4, color_5, color_6, color_7, color_8, color_9, color_10, color_11, color_12, color_13, color_14, color_15, color_16, color_17],//["#f8d62b", "#51bb25", "#a927f9", secondary_color, primary_color],
    backgroundColor: 'transparent',
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true
    }
  },
};

// Line Chart 
export var lineChart: any = {
  chartType: 'LineChart',
  dataTable: [
    // ['Month', 'Guardians of the Galaxy', 'The Avengers', 'Transformers: Age of Extinction'],
    // [1, 37.8, 80.8, 41.8],
    // [2, 30.9, 10.5, 32.4],
    // [3, 40.4, 57, 25.7],
    // [4, 11.7, 18.8, 10.5],
  ],
  options: {
    chart: {
      title: '', //'Box Office Earnings in First Two Weeks of Opening',
      subtitle: '' //'in millions of dollars (USD)'
    },
    colors: [primary_color, secondary_color, color_3, color_4, color_5, color_6, color_7, color_8, color_9, color_10, color_11, color_12, color_13, color_14, color_15, color_16, color_17],//["#f8d62b", "#51bb25", "#a927f9", secondary_color, primary_color],
    height: 700,
    width: '100%',
    backgroundColor: 'transparent'
  },
};

//#endregion



//51bb25
//

export let aColorBase: any = [ '#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0' ];

export type ChartOptions = {
    series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
    chart?: ApexChart;
    xaxis?: ApexXAxis | ApexXAxis[];
    stroke?: ApexStroke;
    tooltip?: any;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis | ApexYAxis[];
    legend?: ApexLegend;
    labels?: string[];
    plotOptions?: ApexPlotOptions;
    fill?: ApexFill;
    responsive?: ApexResponsive[];
    pieseries?: ApexNonAxisChartSeries;
    title?: ApexTitleSubtitle;
    theme?: ApexTheme;
    colors?: string[];
    markers?: ApexMarkers;
    annotations?: ApexAnnotations;
    grid?: ApexGrid;
    options?: ApexOptions;
    subtitle?: ApexTitleSubtitle;
};

export let oRadialBar: any = { //skillStatus
    chart: {
        type: "radialBar",
        height: 420,
        offsetY: -30,
        offsetX: 0,
    },
    plotOptions: {
        radialBar: {
        size: undefined,
        inverseOrder: false,
        hollow: {
            margin: 10,
            size: "30%",
            background: "transparent",
        },
        track: {
            show: true,
            background: "#f2f2f2",
            strokeWidth: "10%",
            opacity: 1,
            margin: 3,
        },
        },
    },
    series: [], //[70, 55, 25],
    labels: [], //["Skill 01", "Skill 02", "Skill 03"],
    legend: {
        show: true,
        fontSize: "12px",
        fontFamily: "Roboto, sans-serif",
        //fontWeight: 500,
        labels: {
            colors: "#2C323F",
            style: {
                margin: "0px 0px", // Espaciado entre leyendas
            },
        },
        markers: {
        width: 10,
        height: 10,
        radius: 5,
        },
        //floating: true,
        horizontalAlign: "center",
        // onItemClick: {
        //     toggleDataSeries: false,
        // },
        // position: "top",
        // offsetY: -33,
        offsetX: 0,
        offsetY: 10,
        position: "bottom",
    },
    //colors: [secondary_color, primary_color, "#51bb25"],
    //colors: [color_3, color_4, color_5, color_6, color_7, color_8, color_9, color_10, color_11, color_12, color_13, color_14, color_15, color_16, color_17],
    responsive: [
        {
            breakpoint: 767,
            options: {
                title: {
                    style: {
                        fontSize: "16px",
                    },
                },
            },
        },
    ],
};

export let oBar: any = { //monthlyHistory
    series: [
        // {
        // name: "Profit",
        // data: [100, 50, 25, 50, 30, 50, 70],
        // },
        // {
        // name: "Revenue",
        // data: [70, 20, 55, 45, 35, 110, 85],
        // },
        // {
        // name: "Cash Flow",
        // data: [85, 55, 100, 35, 90, 60, 80],
        // },
    ],
    chart: {
        type: "bar",
        height: 380,
        toolbar: {
        show: false,
        },
    },
    plotOptions: {
        bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
        curve: "smooth",
        lineCap: "butt",
    },
    xaxis: {
        categories: [], //["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        floating: false,
        axisTicks: {
        show: false,
        },
        axisBorder: {
        color: "#C4C4C4",
        },
    },
    yaxis: {
        title: {
        text: "Dollars in thounand",
        style: {
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 500,
        },
        },
    },
    //colors: [color_3, color_4, color_5, color_6, color_7, color_8, color_9, color_10, color_11, color_12, color_13, color_14, color_15, color_16, color_17],//colors: [secondary_color, "#51bb25", primary_color],
    fill: {
        type: "gradient",
        gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 100],
        },
    },
    tooltip: {
        y: {
        formatter: function (val:any) {
            return "$ " + val + " thousands";
        },
        },
    },
    responsive: [
        {
        breakpoint: 576,
        options: {
            chart: {
            height: 200,
            },
        },
        },
    ],
};

export let MesNombre=[ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Julio', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];


export let orderStatus2: any = {
    chart: {
      height: 350,
      type: "line",
      //stacked: true, //suma acumulada de los valores, si deseas ver los valores poner en false, si no se van a ir sumando en el gráfico
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      events: {
        // animationEnd: function (chartCtx) {
        //   const newData1 = chartCtx.w.config.series[0].data.slice();
        //   newData1.shift();
        //   const newData2 = chartCtx.w.config.series[1].data.slice();
        //   newData2.shift();
        //   window.setTimeout(function () {
        //     chartCtx.updateOptions(
        //       {
        //         series: [
        //           {
        //             data: newData1,
        //           },
        //           {
        //             data: newData2,
        //           },
        //         ],
        //       //   subtitle: {
        //       //     text: parseInt(getRandom() * Math.random()).toString(),
        //       //   },
        //       },
        //       false,
        //       false
        //     );
        //   }, 300);
        // },
      },
      toolbar: {
        show: false, 
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 5,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    fill: {
      opacity: 0.9,
    },
    // colors: [color_3, color_4, color_5, color_6, color_7, color_8, color_9, color_10, color_11, color_12, color_13, color_14, color_15, color_16, color_17],
    markers: {
      size: 0,
      hover: {
        size: 0,
      },
    },
    series:[
        // {
        //     name: "Load Average",
        //     data: [null,null,30,24,44,null, 28,15]
        // },
        // {
        //     name: "Social Media",
        //     data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
        // },
    ],
    xaxis: {
        categories: [], //["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
        axisBorder: {
        show: false,
        },
        axisTicks: {
        show: false,
        },
    },
    yaxis: {
    //   labels: {
    //     "formatter": function (value: number) {
    //         return value.toFixed(0); // Ajustar la precisión de los valores mostrados en el eje Y
    //       }
    //   }
      labels: {
        "formatter": function (val: number | bigint) {
            return new Intl.NumberFormat('es-MX').format(val);
        }
       }
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "12px",
      },
    },
    legend: {
      show: true,
      floating: true,
      horizontalAlign: "right",
      onItemClick: {
        toggleDataSeries: false,
      },
      position: "top",
      offsetY: -30,
      offsetX: 60,
    },
    responsive: [
      {
        breakpoint: 1366,
        options: {
          title: {
            style: {
              fontSize: "18px",
            },
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          title: {
            style: {
              fontSize: "16px",
            },
          },
        },
      },
    ],
  };
  

export let finance: any = {
    chart: {
      height: 350,
      type: "line",
  
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    series: [
    //   {
    //     name: "Load Average",
    //     type: "column",
    //     data: [30,24,44,]
    //   },
    //   {
    //     name: "Social Media",
    //     type: "line",
    //     data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
    //   },
    ],
    title: {
      text: "Average",
      align: "left",
      style: {
        fontSize: "12px",
      },
    },
    subtitle: {
      text: "17%",
      floating: true,
      align: "right",
      offsetY: 0,
      style: {
        fontSize: "20px",
        fontWeight: 500,
      },
    },
    fill: {
      colors: [primary_color],
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.8,
        stops: [0, 100],
      },
    },
    xaxis: {
      type: "datetime",
      range: 2700000,
    },
    yaxis: {
      decimalsInFloat: 1,
    },
    legend: {
      show: true,
    },
    responsive: [
      {
        breakpoint: 1366,
        options: {
          subtitle: {
            style: {
              fontSize: "18px",
            },
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          subtitle: {
            style: {
              fontSize: "16px",
            },
          },
        },
      },
    ],
  };
  
export let oAreaMoney: any = { //turnOver
    chart: {
        toolbar: {
        show: false,
        },
        height: 300,
        type: "area",
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: 3,
        curve: "smooth",
    },
    yaxis: {
        labels: {
            formatter: function (value: number | bigint) {
            const numberFormat = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            });
            return numberFormat.format(value);
            }
        }
    },
    xaxis: {
        categories: [], //["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
        axisBorder: {
        show: false,
        },
        axisTicks: {
        show: false,
        },
    },
    grid: {
        borderColor: "rgba(196,196,196, 0.3)",
        padding: {
            top: -20,
            right: -55,
            bottom: 0,
        },
    },
    fill: {
        opacity: 0.2,
    },
    //colors: [color_3, color_4, color_5, color_6, color_7, color_8, color_9, color_10, color_11, color_12, color_13, color_14, color_15, color_16, color_17],//colors: [primary_color],
    series: [
        {
        data: [], // [70, 60, 82, 80, 60, 90, 70, 120, 50, 60, 0],
        },
    ],
    tooltip: {
        x: {
            format: "dd/MM/yy HH:mm",
        },
        y: {
            "formatter": function (value: number | bigint) {
                return new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
                }).format(value);
            }
        }
    },
};

export let oArea: any = { //turnOver
    chart: {
        toolbar: {
        show: false,
        },
        height: 300,
        type: "area",
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: 3,
        curve: "smooth",
    },
    yaxis: {
        labels: {
            "formatter": function (val: number | bigint) {
                return new Intl.NumberFormat('es-MX').format(val);
            }
        }
    },
    xaxis: {
        categories: [], //["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
        axisBorder: {
        show: false,
        },
        axisTicks: {
        show: false,
        },
    },
    grid: {
        borderColor: "rgba(196,196,196, 0.3)",
        padding: {
        top: -20,
        right: -55,
        bottom: 0,
        },
    },
    fill: {
        opacity: 0.2,
    },
    //colors: [color_3, color_4, color_5, color_6, color_7, color_8, color_9, color_10, color_11, color_12, color_13, color_14, color_15, color_16, color_17],//colors: [primary_color],
    series: [
        {
        data: [], // [70, 60, 82, 80, 60, 90, 70, 120, 50, 60, 0],
        },
    ],
    tooltip: {
        x: {
        format: "dd/MM/yy HH:mm",
        },
        y: {
            "formatter": function (val: number | bigint) {
                return new Intl.NumberFormat('es-MX').format(val);
            }
        }
    },
};


export let oProgressBar: any = { //progress
    chart: {
        height: 70,
        type: "bar",
        stacked: true,
        sparkline: {
        enabled: true,
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: "15%",
            colors: {
                backgroundBarColors: [],
                backgroundBarOpacity: 0.2,
            },
        },
    },
    colors: [],
    stroke: {
        width: 0,
    },
    // fill: {
    //     colors: [primary_color],
    //     type: "gradient",
    //     gradient: {
    //     gradientToColors: [primary_color],
    //     },
    // },
    series: [
        {
        name: "", //"Process 1",
        data: []//[50],
        },
    ],
    title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: "", //"Packed title",
        style: {
            fontSize: "18px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: 500,
        },
    },
    subtitle: {
        floating: true,
        align: "right",
        offsetY: 0,
        text: "", //"44%",
        style: {
            fontSize: "14px",
        },
    },
    tooltip: {
        enabled: false,
    },
    xaxis: {
        categories: ["Packed xaxis"],
    },
    yaxis: {
        max: 100,
    },
    responsive: [
        {
        breakpoint: 767,
        options: {
            title: {
            style: {
                fontSize: "16px",
            },
            },
        },
        },
    ],
};



export let oGradient: any = { //monthlySales
    fill: {
        type: "gradient",
        gradient: {
        shade: "dark",
        type: "Reflected",
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.8,
        stops: [0, 100],
        },
    },
    colors: [primary_color, secondary_color],
    chart: {
        height: 300,
        type: "radar",
        dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
        },
    },
    series: [
        {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20],
        },
        {
        name: "Series 2",
        data: [20, 30, 40, 80, 20, 80],
        },
    ],
    title: {
        text: "Radar Chart - Multi Series",
    },
    stroke: {
        width: 0,
    },
    markers: {
        size: 0,
    },
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
};