import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as printJS from 'print-js';
import { CompanyModel } from 'src/app/models/company.model';
import { BillingDataModel, ServicesModeModel } from 'src/app/models/shipment.model';
import { CurrencyModel, UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-billing-data-sf',
  templateUrl: './billing-data-sf.component.html',
  styleUrls: ['./billing-data-sf.component.scss']
})
export class BillingDataSfComponent implements OnInit {
  groupCurrencyBillingData : any ;
  billingDataList =  new Array<BillingDataModel>();
  okCurrency: any;
  isLoading = true;
  base64!:string;
  servicesModeList =  new Array<ServicesModeModel>();

  //#region Filtros
  dateNow : Date = new Date();
  dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
    
  newDateTodayI : Date | null = null;//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDay());
  minDateI : Date =  new Date(2022, 0, 1);
  maxDateI : Date =  new Date();
  dateIDis=true;
  newDateTodayF : Date | null = null//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
  minDateF : Date =  new Date(2022, 0, 1);
  maxDateF : Date =  new Date();
  dateFDis=true;

  showFilter=false;
  selFechas = new FormControl('');  
  selCompanies = new FormControl('');  
  selCompaniesList:Array<CompanyModel>=[];
  selCurrency = new FormControl('');  
  selCurrencyList:Array<CurrencyModel>=[];
  selUsers = new FormControl('');  
  selUsersList:Array<UserModel>=[];

  preLoadHidden = true;
  
  //#endregion

  
  //displayedColumns: string[] = ['tracking_number', 'carrier', 'shipment_id', 'order_number', 'company_name', 'user_name', 'shipment', 'created_at', 'currency', 'service_price', 'total_price'];
  columnDefinitions = [
    { def:'created_at', label: 'Fecha de creación', hide: false},
    { def:'partner_id', label: 'PartnerId', hide: false},
    { def:'company_name', label: 'Cliente', hide: false},
    { def:'order_number', label: 'Order Id', hide: false},
    //{ def:'shipment_id', label: '# Orden', hide: true},
    { def:'provider', label: 'Proveedor', hide: false},
    { def:'carrier', label: 'Courier', hide: false},
    { def:'service', label: 'Método de envio', hide: false},
    { def:'extended_zone', label: 'Zona extendida', hide: false},
    { def:'shipment_type', label: 'Tipo', hide: false},
    { def:'reference', label: 'Referencia', hide: true},
    { def:'content', label: 'Contenido', hide: true},
    { def:'kg', label: 'Kg enviado', hide: false},
    { def:'dimension', label: 'Volumen enviado', hide: false},
    //{ def:'origin', label: 'Origen', hide: true},
    //{ def:'destination', label: 'Destino', hide: true},
    //{ def:'shipment', label: 'Envío', hide: true},
    { def:'service_price', label: 'Costo interno', hide: false},
    { def:'other_charge_price', label: 'Precio de venta otros cargos', hide: false},
    { def:'total_price', label: 'Precio de venta total', hide: false},
    { def:'currency', label: 'Moneda', hide: false},
    { def:'tracking_number', label: 'Guía', hide: false},
    { def:'origin', label: 'Origen', hide: true},
    { def:'destination', label: 'Destino', hide: true},

    
  ]
  showOptions = false;
  getDisplayedColumns():string[] {
    return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
  }

  getDisplayedLabel(def:string):string {
    let cLabel=this.columnDefinitions.find(item=> item.def==def);
    return cLabel?.label||''
  }
  
  dataSource!: MatTableDataSource<BillingDataModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.showFilter=true;
    this.selFechas.setValue('2');
    this.selCurrency.setValue('0');
    this.onchangeFechas(this.selFechas);
    //No cargar el listado de manera inicial
    //this.getBillingDataList(); 
    this.isLoading = false; //porque no se carga al principio los datos
    this.getServicesMode();

    //this.getCompaniesList();
    //this.getCurrencyList();
    //this.getUsersList();
    //this.newDateToday.setDate(this.newDateToday.getDate() + 1);
  }

  getBillingDataList(date_i : string|null = null, date_f : string|null = null) {
    this.userS.getBillingDataListSf(date_i, date_f).subscribe(
      (result : any) => {

        this.groupCurrency(false, result);
        //this.billingDataList = result;
        
        for ( let i = 0; i < this.billingDataList.length; i++ ) {
          this.billingDataList[i].package_dimensions = JSON.parse(this.billingDataList[i].package_dimensions);
        }
        this.dataSource = new MatTableDataSource(this.billingDataList);        
        this.dataSource.paginator = this.paginator;
        //this.dataSource.paginator.pageSize=this.billingDataList.length;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.preLoadHidden = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  groupCurrency(filter:boolean, data: Array<BillingDataModel> ){
    
    if(filter){
      //Si es tipo filtro significa que ya se trajeron los datos de la API y se agruparon anteriormente
      if(this.selCurrency.value == "0"){ //si son todas
        this.billingDataList = data;
      }else{
        //Ver si existe el agrupado de moneda, si no existe poner vacía la tabla
        if (!this.groupCurrencyBillingData[this.selCurrency.value]) {
          this.billingDataList = new Array<BillingDataModel>();
        }else{ //volver a llenar el mat-table
          const arrFil=this.groupCurrencyBillingData[this.selCurrency.value];
          this.billingDataList = arrFil;
          //console.log(this.billingDataList);
  
          // for ( let i = 0; i < this.billingDataList.length; i++ ) {
          //   this.billingDataList[i].package_dimensions = JSON.parse(this.billingDataList[i].package_dimensions);
          // }
          this.dataSource = new MatTableDataSource(this.billingDataList);        
          this.dataSource.paginator = this.paginator;
          this.dataSource.paginator.pageSize=this.billingDataList.length;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
          this.preLoadHidden = false;
        }
      }
    }else{
      //Es de la consulta a la API, el resultado esta en data, aquí no afecta el filtro por moneda
      this.groupCurrencyBillingData = data.reduce((groupCurrency:{[key: string]: any[]} , item:BillingDataModel) => {
        //Si la moneda no existe en agrupado, se crea como array vacío
        if (!groupCurrency[item.currency]) {
          groupCurrency[item.currency] = [];
        }
        // El objeto se agrega al array en la moneda que pertenezca.
        groupCurrency[item.currency].push(item);
        return groupCurrency;
      }, {});
      this.okCurrency = Object.keys(this.groupCurrencyBillingData);
      if(this.okCurrency.length>1){
        Swal.fire({
          icon:  'warning',
          title: 'Diferentes tipos de monedas',
          text:  'En el reporte hay diferentes tipos de monedas, la sumatoria solo se hará con monedas del mismo tipo (puedes usar el filtro de moneda).'
        });
      }
      //this.selCurrency.setValue("0");
      this.billingDataList = data;
      //console.log(this.groupCurrencyBillingData, this.okCurrency.length, this.selCurrency.value);
    }
  }
  showSumFooter() {
    let vShow = false;
    if(this.okCurrency!=undefined){ 
      if(this.okCurrency.length==1){
        vShow = true;
      }else{
        if(this.selCurrency.value == "0"){ //si son todas
          vShow = false;
        }else{ //Tiene selecciona 1 tipo de moneda
          vShow = true
        }
      }
    }else{
      vShow = false;
    }
    return vShow;
  }

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  searchFilter() {
    this.isLoading = true;
    let date_i:string|null, date_f:string|null, users:string|null, companies:string|null;
    let arrCom:Array<number>=this.selCompanies.value;
    let arrUse:Array<number>=this.selUsers.value;
    let currency:string;

    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);
    companies=arrCom.length>0?arrCom.join(','):null;
    users=arrUse.length>0?arrUse.join(','):null;
    currency=this.selCurrency.value;
    console.log(companies, users);
    this.getBillingDataList(date_i, date_f);
  }

  getServicesMode() {
    this.userS.getListServicesMode().subscribe(
      (result : any) => {
        this.servicesModeList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getCompaniesList() {
    this.userS.getCompanies().subscribe(
      (result : any) => {
        this.selCompaniesList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  getCurrencyList() {
    this.userS.getCurrencies().subscribe(
      (result : any) => {
        this.selCurrencyList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  getUsersList() {
    this.userS.getUserListMin().subscribe(
      (result : any) => {
        this.selUsersList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  printLabel(id:number) {
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.findLabel(id).subscribe(
      ( resp: any ) => {
        if ( resp.data.status.code == "success" ) {
          printJS({ printable: resp.data.data.payload, type: "pdf", base64: true })
          Swal.close();
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error',
          text:  'Ocurrió un error al descargar la guía, intenta de nuevo mas tarde.'
        });
      }
    );
  }

  searchFilterClean() {
    this.selCompanies.setValue([]);
    this.selUsers.setValue([]);
  }


  onchangedateI(e: Date): void {
    console.log('Inicio', e, 'Fin', this.newDateTodayF);
    if(this.newDateTodayF!=null && this.newDateTodayF!.getTime()<e.getTime()){
        this.newDateTodayF=null;
    }
  }
  onchangedateF(e: Date): void {
    console.log('Fin', e, 'Inicio', this.newDateTodayI);
    if(this.newDateTodayI!=null && this.newDateTodayI!.getTime()>e.getTime()){
      this.newDateTodayI=null;
    }
  }

  onchangeFechas(change:any) {
    //console.log(change);
    switch (change.value) {
      case '1'://Hoy
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '2'://Este mes
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1);
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '3'://Elegir fechas
        this.dateIDis=false;
        this.dateFDis=false;
        break;
      default:
        break;
    }
  }

  onchangeCurrency(change:any) {
    const auxArr= new Array<BillingDataModel>();
    this.groupCurrency(true,auxArr);
  }
  getPageSizeOptions(): number[]{
    if(this.billingDataList.length>10)
    return [10,50,100,this.billingDataList.length];
    else
    return [10,50,100];
  }
  
  getTotalService() {
    return (this.billingDataList.map(t => t.service_price).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
  }

  getTotalPrice() {
    return (this.billingDataList.map(t => t.total_price).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
  }
  
  toggleColumn(columnName: string, event: MatCheckboxChange) {
    //console.log(columnName);
    let columnHS=this.columnDefinitions.find( item=> item.def==columnName);
    if(columnHS){
      columnHS.hide=!event.checked;
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  getAddress(data:string) {
    try {
      const address = JSON.parse(data);
      return `${address.name}, ${address.street}, ${address.number}, ${address.suburb}, ${address.postalCode}, ${address.city}`;
    } catch (error) {
      return ''; 
    }
  }

  getServiceMode(service:string, carrier:string, provider:string ) {
    try {
      let sService=service;
      const indexFound = this.servicesModeList.findIndex(r=> r.provider.toUpperCase() == provider.toUpperCase() && r.carrier.toUpperCase() == carrier.toUpperCase() && service.toUpperCase().includes(r.service.toUpperCase()) );
      if(indexFound != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
        //sService=`${service}->${this.servicesModeList[indexFound].service_mode}`;
        sService=`${this.servicesModeList[indexFound].service_mode}`;
      }
      return sService;
    } catch (error) {
      return service; 
    }
  }

  getInitialExtendedZone(data:number) {
    try {
      let cadena='';
      switch (data) {
        case 0://No se ha consultado
          cadena='';
          break;
        case 1://ZOna extendida
          cadena='E';
          break;
        case 2://ZOna Normal
          cadena='N';
          break;
        case 3://No es extendida ni normal
          cadena='';
          break;
        case 4://Error
          cadena='';
          break;
      }
      
      return cadena;
    } catch (error) {
      return ''; 
    }
  }
  
}
