<div class="modal-header">
    <h1 class="modal-title">Carga masiva</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h4>Completa los siguientes campos y carga el archivo de importación para generar envío masivo.</h4>
    <ul>
        <li><a href="../../../assets/templates/bulk-upload.xlsx" download="bulk-upload.xlsx" class="text-primary">Descargar plantilla (bodegas)<mat-icon class="align-middle">download</mat-icon></a></li>
        <li><a href="../../../assets/templates/bulk-upload-origin.xlsx" download="bulk-upload.xlsx" class="text-primary">Descargar plantilla con origenes<mat-icon class="align-middle">download</mat-icon></a></li>
    </ul>
    
    
    <!-- <a href="../../../assets/templates/bulk-upload.xlsx" download="bulk-upload.xlsx" class="btn btn-outline-secondary"><mat-icon>download</mat-icon> Descargar la plantilla</a> -->

    <form autocomplete="off" [formGroup]="formAdd" (ngSubmit)="ruSure()" class="mt-3" id="formAdd">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="row">
                <div class="col-sm-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Nombre de envío</mat-label>
                        <input matInput formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Email de notificación</mat-label>
                        <mat-icon matSuffix>alternate_email</mat-icon>
                        <input matInput formControlName="email_notification">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <!-- <mat-label>Carga archivo de importación</mat-label> -->
                <div class="col-sm-8">
                    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload formControlName="file">
                    <div class="file-upload">                        
                        <button type="button" mat-flat-button color="primary" (click)="fileUpload.click()">
                            Carga archivo de importación <mat-icon>attach_file</mat-icon>
                        </button>
                        <span class="ms-3 h6">{{fileName || ""}}</span>
                    </div>
                </div>
                <div class="col-sm-4 d-flex justify-content-end">
                    <div>
                        <span *ngIf="jsonData.length>0" [ngClass]="{'badge': true, 'bg-info': jsonData.length==0, 'bg-primary': jsonData.length>0 }">{{jsonData.length }} en total</span>
                    </div>
                </div>
            </div>
        </div>       
    </form>


    <div *ngIf="dataAOA.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
        
        <table class="table table-sm">
            <thead>
                <tr>
                  <th *ngFor="let header of getKeys(jsonData[0])">{{ header }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of jsonData; let i = index">
                  <td *ngFor="let cell of getValues(row)">{{ cell }}</td>
                </tr>
              </tbody>
            <!-- <thead>
                <tr>
                  <th>#</th>
                  <th *ngFor="let header of dataAOA[0]">
                    {{ header }}
                  </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of dataAOA.slice(1); let i = index">
                  <td>{{ i + 1 }}</td>
                  <td *ngFor="let val of row">
                    {{ val }}
                  </td>
                </tr>
            </tbody> -->
        </table>
    </div>
	<!-- <button (click)="export()">Export!</button> -->



</div>
<div class="modal-footer">
    <button mat-flat-button class="btn bg-success text-white" form="formAdd" type="submit" [disabled]="!formAdd.valid">Importar <mat-icon>publish</mat-icon></button>
</div>