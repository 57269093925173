import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PickupsService } from 'src/app/services/pickups.service';


@Component({
  selector: 'app-pickups-checkout',
  templateUrl: './pickups-checkout.component.html',
  styleUrls: ['./pickups-checkout.component.scss']
})
export class PickupsCheckoutComponent implements OnInit {
  manifestLoading: boolean = false;
  manifestRoute: any = undefined;
  pickupID!: number;
  title!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dRef: MatDialogRef<PickupsCheckoutComponent>, private pickupsService: PickupsService) { }

  ngOnInit(): void {
    this.setCheckout();
  }

  /* void closeDialog(0):
     Closes... the dialog. */
  closeDialog(): void {
    this.dRef.close();
  }

  /* string toTime(1):
     Formats an integer to 12H time string for display.
     < x: The input number.
     > The formatted output. */
  toTime(x: number): string {
    return `${x % 12 === 0 ? 12 : x % 12}:00 ${x >= 12 ? 'PM' : 'AM'}`;
  }


  /* void getManifest(0):
     Calls the backend to get the manifest with the specified data. */
  async getManifest(): Promise<void> {
    // Start loading animation
    this.manifestLoading = true;

    console.log("MR: ", this.manifestRoute)

    try {
      if (this.manifestRoute === undefined) {
        // Set data
        const manifestData = this.data.manifest.map((x: any[]) => {
          if (x.length > 0) x[0] = this.pickupID;
          return x;
        });

        // Get manifest file's route
        this.manifestRoute = await this.pickupsService.generateManifest(manifestData);
      }

      // Open route and stop loading animation
      window.open(this.manifestRoute, '_blank');
    } catch (error) {
      console.error('Error generating manifest:', error);
    } finally {
      this.manifestLoading = false;
    }
    
  }

  /* async Promise<void> setCheckout(0):
     Registers pickup to the DB. */
  async setCheckout(): Promise<void> {
    if (this.data.status) {
      this.title = "Recolección creada exitosamente";
      const pickupData = {
        address: this.data.address,
        boxes: this.data.packages,
        carrier: this.data.carrier,
        confirmationID: this.data.confirmation,
        pickupDate: this.JSDateToTimestamp(this.data.date),
        pickupEndTime: this.data.finalTime,
        pickupFee: this.data.fee,
        pickupStartTime: this.data.initTime,
        userID: this.data.user,
        weight: this.data.weight
      }
      this.pickupID = await this.pickupsService.registerPickup(pickupData, this.data.manifest);
    } else {
      this.title = "No se pudo generar su recolección";
    }
  }


  /* string JSDateToTimestamp(1):
   Formats a JS Date object into an SQL timestamp string.
   < date: The JS Date object.
   > An SQL timestamp string. */
  private JSDateToTimestamp(date: Date): string {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} 00:00:00`;
  }
}
