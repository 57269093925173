import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-bulk-upload-row-edit',
  templateUrl: './bulk-upload-row-edit.component.html',
  styleUrls: ['./bulk-upload-row-edit.component.scss']
})
export class BulkUploadRowEditComponent implements OnInit {
  
  //Si se agrega una columna nueva en el excel se tiene que agregar aquí, para el formulario de edición de detalle
  prop_Data_Row=["ORDER", "WAREHOUSE_CODE", "ORIG_CITY", "ORIG_COMPANY", "ORIG_EMAIL", "ORIG_NAME", "ORIG_NUMBER", "ORIG_PHONE", "ORIG_POSTALCODE", "ORIG_REFERENCE", "ORIG_STATE", "ORIG_STREET", "ORIG_SUBURB", "DEST_NAME", "DEST_COMPANY", "DEST_EMAIL", "DEST_PHONE", "DEST_STREET", "DEST_NUMBER", "DEST_POSTALCODE", "DEST_SUBURB", "DEST_CITY", "DEST_STATE", "DEST_REFERENCE", "RATE_CARRIER", "RATE_SERVICE", "PKG_ORDER_NUMBER", "PKG_CONTENT", "PKG_TYPE", "PKG_WEIGHT", "PKG_LENGTH", "PKG_WIDTH", "PKG_HEIGHT", "RATE_PROVIDER", "STATUS"];
  
  formEdit!: FormGroup;
  dataStatus!:string;
  dataMessage!:string;
  isFormDisabled: boolean = false;
  isDifferentOrigin: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<BulkUploadRowEditComponent>,
    private matDialog: MatDialog
  ) { }


  ngOnInit(): void {
    console.log(this.data);
    this.dataStatus=this.data.detail.status;
    this.dataMessage=this.data.detail.message;
    this.isFormDisabled = this.dataStatus!='in_progress' && this.dataStatus!='done'?false:true;
    this.addDefaultProperties();
    this.createForm();
    this.setForm();
  }

  //Agrega las propiedades que hagan falta en el data_row como vacíos, para que no truene al asignar los valores del formulario
  addDefaultProperties() {
    for (const propiedad of this.prop_Data_Row) {
      if (!this.data.detail.hasOwnProperty(propiedad)) {
        if(propiedad=='WAREHOUSE_CODE')
          this.isDifferentOrigin=true;
        this.data.detail[propiedad] = "";
      }
    }
  }

  createForm() {
    this.formEdit = this.fb.group({
      id: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      id_bulk_upload: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      status_bud: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      //order
      
      ORIG_CITY: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_COMPANY: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_EMAIL: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_NAME: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_NUMBER: [{ value: '', disabled: this.isFormDisabled },[]],
      ORIG_PHONE: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_POSTALCODE: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_REFERENCE: [{ value: '', disabled: this.isFormDisabled },[]],
      ORIG_STATE: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_STREET: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],
      ORIG_SUBURB: [{ value: '', disabled: this.isFormDisabled }, this.isDifferentOrigin ? [Validators.required] : []],

      DEST_CITY: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_COMPANY: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_EMAIL: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_NAME: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_NUMBER: [{ value: '', disabled: this.isFormDisabled },[]],
      DEST_PHONE: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_POSTALCODE: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_REFERENCE: [{ value: '', disabled: this.isFormDisabled },[]],
      DEST_STATE: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_STREET: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      DEST_SUBURB: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      ORDER: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      PKG_TYPE: [{ value: '', disabled: this.isFormDisabled }, [ ]],
      PKG_CONTENT: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      PKG_HEIGHT: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      PKG_LENGTH: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      PKG_ORDER_NUMBER: [{ value: '', disabled: this.isFormDisabled },[]],
      PKG_WEIGHT: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      PKG_WIDTH: [{ value: '', disabled: this.isFormDisabled }, [ Validators.required]],
      RATE_PROVIDER: [{ value: '', disabled: this.isFormDisabled }, []],
      RATE_CARRIER: [{ value: '', disabled: this.isFormDisabled }, [ ]],
      RATE_SERVICE: [{ value: '', disabled: this.isFormDisabled }, [ ]],
      STATUS: [{ value: '', disabled: this.isFormDisabled },[]],
      WAREHOUSE_CODE: [{ value: '', disabled: this.isFormDisabled }, !this.isDifferentOrigin ? [Validators.required] : []],
    });
  }

  providerValidator(control: AbstractControl) {
    const carrierVal = control.parent?.get('RATE_CARRIER')?.value;
    const providerVal = control.value;

    if (carrierVal.toLowerCase() == 'afimex' || carrierVal.toLowerCase() == 'paquetexpress') {
      //Si es afimex el provider puede estar vacío
      if (!providerVal) {
        return null; // Válido
      } else {
        return { required: true }; // Inválido, se requiere un valor
      }
    } else {
      // Si no es afimex es requerido
      return Validators.required(control);
    }
  }

  setForm() {
    //const { active, email, id, role, rol_name, ...userFormInfo } = this.data.company;
    let dataRow:any = {};
    // this.data.detail.id = this.data.detail.id;
    // this.data.detail.id_bulk_upload = this.data.detail.id_bulk_upload;
    // this.data.detail.status_bud = this.data.detail.status;   
    
    dataRow["id"] = this.data.detail.id;
    dataRow["id_bulk_upload"] = this.data.detail.id_bulk_upload;
    dataRow["status_bud"] = this.data.detail.status;
    for (const propiedad of this.prop_Data_Row) {
      dataRow[propiedad] = this.data.detail[propiedad];
    }
    //console.log(dataRow);
    this.formEdit.setValue(dataRow);
    //this.tipoComSel = this.data.company.tipo_comision;
  }

  ruSure() {
    if(this.formEdit.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas actualizar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        
        //console.log(this.formEdit.value);
        this.save();
      }
    });
  }

  save() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.userS.editBulkUploadDetail(this.formEdit.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Registro guardado de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {        
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
      }
    );
  }

  statusChangeClass(status:string){
    let rClass="";
    switch (status) {
      case 'loaded':
        rClass='accent';
        break;
      case 'in_progress':
        rClass='basic';
        break;
      case 'done':
        rClass='primary';
        break;
      case 'cancel':
      case 'error':
        rClass='warn';
        break;
      default:
        break;
    }
    return rClass;
  }
  statusChangeIcon(status:string){
    let icon="";
    switch (status) {
      case 'loaded':
        icon='schedule_send';
        break;
      case 'in_progress':
        icon='sync';
        break;
      case 'done':
        icon='done';
        break;
      case 'cancel':
        icon='cancel';
        break;
      case 'error':
        icon='error';
        break;
      default:
        break;
    }
    return icon;
  }

  statusChangeTip(status:string, message:string){
    let icon="";
    if(message!='' && message!=null){
      icon=message;
    }
    else {
      switch (status) {
        case 'loaded':
          icon='Listo para comprar guía';
          break;
        case 'in_progress':
          icon='Comprando guía';
          break;
        case 'done':
          icon='Completado';
          break;
        case 'cancel':
          icon='Cancelado';
          break;
        case 'error':
          icon='Error al comprar';
          break;
        default:
          break;
      }
    }
        
    return icon;
  }



  resetForm() {
    this.formEdit.reset();
  }
}
