<div class="container-fluid">
  <div class="page-title">
      <div class="row">
          <div class="col-6">
              <h3>Hola {{userObj.name}} 👋</h3>
          </div>
          <div class="col-6">
              <ol class="breadcrumb me-3 mb-0">
                  <li class="breadcrumb-item">
                    <a href="index.html"
                      ><mat-icon>dashboard</mat-icon></a
                    >
                  </li>
                  <li class="breadcrumb-item">Dashboard</li>
                  <li class="breadcrumb-item font-primary dropdown drop-container">
                    <mat-spinner
                      class="ms-2"
                      diameter="20"
                      *ngIf="isLoading; else title"
                    ></mat-spinner>
                    <ng-template #title>
                      <span (click)="toggleDropContainer()">{{getPeriodLabel(period)}}</span>
                      <i (click)="toggleDropContainer()" class="ms-2 arrow down"></i>
                      <div class="drop bg-white" *ngIf="isDropContainerVisible">
                          <mat-list>                            
                              <mat-list-item (click)="setPeriod('7%20day')" class="list-item">
                                  <mat-icon class="me-3" matListItemIcon>today</mat-icon>
                                  <div matListItemLine>Últimos 7 días</div>
                              </mat-list-item>

                              <mat-list-item (click)="setPeriod('1%20month')" class="list-item">
                                  <mat-icon class="me-3" matListItemIcon>today</mat-icon>
                                  <div matListItemLine>Últimos 30 días</div>
                              </mat-list-item>
                              
                              <mat-list-item (click)="setPeriod('1%20year')" class="list-item">
                                  <mat-icon class="me-3" matListItemIcon>calendar_month</mat-icon>
                                  <div matListItemLine>Año actual</div>
                              </mat-list-item>
                          </mat-list>
                      </div>
                    </ng-template>
                  </li>
                </ol>
          </div>
      </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row size-column">
      <div class="col-md-12">

          <div class="row">            
            <div class="col-3">
              <div class="card small-widget">
                <div class="card-body primary"> <span class="f-light">Envíos Totales</span>
                  <div class="d-flex align-items-end gap-1">
                    <div *ngIf="isLoading; else cards_1_1" class="skeleton-cards skeleton-rect" style="width: 15%"></div>
                    <ng-template #cards_1_1>
                      <h1 class="mt-1 mb-0 fs-3">{{nDashboardData.shipments.length || 0 | number }}</h1>
                    </ng-template>
                  </div>
                  <div class="d-flex mt-1 mb-0">
                    <div *ngIf="isLoading; else cards_1_2" class="skeleton-cards skeleton-rect" style="width: 70%"></div>
                    <ng-template #cards_1_2>
                      <div *ngIf="nDashboardData.past_period.shipmentDifference > 0;else negative">
                        <p class="font-success">
                          <mat-icon class="text-muted fs-6 font-success">moving</mat-icon>
                          {{nDashboardData.past_period.shipmentDifference}}
                          <span class="divider-left ms-3 me-3"></span>
                          <span class="text-muted"> ></span>
                          <span class="text-muted"> {{periodLabels[period][1]}}</span>
                        </p>
                      </div>
                      <ng-template #negative>
                        <p class="font-danger">
                          <mat-icon class="text-muted fs-6 font-danger">trending_down</mat-icon>
                          {{nDashboardData.past_period.shipmentDifference}}
                          <span class="divider-left ms-3 me-3"></span>
                          <span class="text-muted">< </span>
                          <span class="text-muted"> {{periodLabels[period][1]}}</span>
                        </p>
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="bg-gradient">
                      <mat-icon class="text-muted font-primary">local_shipping</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card small-widget">
                <div class="card-body success">
                  <span class="f-light">Total Pagado</span>
                  <div class="d-flex align-items-end gap-1">
                    <div *ngIf="isLoading; else cards_2_1" class="skeleton-cards skeleton-rect" style="width: 15%"></div>
                    <ng-template #cards_2_1>
                      <h1 class="mt-1 mb-0 fs-3">{{ total_paid || 0 | currency }}</h1>
                    </ng-template>
                  </div>
                  <div class="d-flex mt-1 mb-0">
                    <div *ngIf="isLoading; else cards_2_2" class="skeleton-cards skeleton-rect" style="width: 70%"></div>
                    <ng-template #cards_2_2>
                      <div *ngIf="nDashboardData.past_period.pricePercentage > 0;else negativeD">
                        <p class="font-danger">
                          <mat-icon class="text-muted fs-6 font-danger">moving</mat-icon>
                          {{nDashboardData.past_period.pricePercentage}}%
                          <span class="divider-left ms-3 me-3"></span>
                          <span class="text-muted"> ></span>
                          <span class="text-muted"> {{periodLabels[period][1]}}</span>
                        </p>
                      </div>
                      <ng-template #negativeD>
                        <p class="font-success">
                          <mat-icon class="text-muted fs-6 font-success">trending_down</mat-icon>
                          {{nDashboardData.past_period.pricePercentage}}%
                          <span class="divider-left ms-3 me-3"></span>
                          <span class="text-muted">< </span>
                          <span class="text-muted"> {{periodLabels[period][1]}}</span>
                        </p>
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="bg-gradient">
                    <mat-icon class="text-muted font-success">payments</mat-icon>
                  </div>
                </div>
              </div>
            </div>   
            <div class="col-3">
              <div class="card small-widget">
                <div class="card-body warning">
                  <span class="f-light">Peso promedio</span>
                  <div class="d-flex align-items-end gap-1">
                    <div *ngIf="isLoading; else cards_3_1" class="skeleton-cards skeleton-rect" style="width: 15%"></div>
                    <ng-template #cards_3_1>
                      <h1 class="mt-1 fs-3 mb-0">{{avg_weight || 0 | number:'1.0-2' }} kg</h1>
                    </ng-template>
                  </div>
                  <div class="d-flex mt-1 mb-0">
                    <div *ngIf="isLoading; else cards_3_2" class="skeleton-cards skeleton-rect" style="width: 70%"></div>
                    <ng-template #cards_3_2>
                      <div *ngIf="nDashboardData.past_period.weightDifference > 0;else negativeC">
                        <p class="text-muted">
                          {{nDashboardData.past_period.weightDifference || 0 | number:'1.0-2'}} kg
                          <span class="divider-left ms-3 me-3"></span>
                          <span class="text-muted"> ></span>
                          <span class="text-muted"> {{periodLabels[period][1]}}</span>
                        </p>
                      </div>
                      <ng-template #negativeC>
                        <p class="text-muted">
                          {{nDashboardData.past_period.weightDifference || 0 | number:'1.0-2'}} kg
                          <span class="divider-left ms-3 me-3"></span>
                          <span class="text-muted">< </span>
                          <span class="text-muted"> {{periodLabels[period][1]}}</span>
                        </p>
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="bg-gradient">
                    <mat-icon class="text-muted font-warning">monitor_weight</mat-icon>
                  </div>
                </div>
              </div>
            </div>    
            <div class="col-3">
              <div class="card small-widget custom-height">
                <div class="card-body primary">
                  <span class="f-light">Tiempo de envío promedio</span>
                  <div class="d-flex align-items-end gap-1">
                    <div *ngIf="isLoading; else cards_4_1" class="skeleton-cards skeleton-rect" style="width: 15%"></div>
                    <ng-template #cards_4_1>
                      <h1 class="mt-1 fs-3 mb-0">{{ general_avg_delivery_time || 0 | number:'1.0-0' }}</h1>
                    </ng-template>
                  </div>
                  <!-- <div class="d-flex mt-1 mb-0">
                    <div *ngIf="isLoading; else cards_4_2" class="skeleton-cards skeleton-rect" style="width: 70%"></div>
                    <ng-template #cards_4_2>
                      <p class="text-muted">--
                        <span class="divider-left ms-3 me-3"></span>
                        <span class="text-muted"> hace 1 mes</span>
                      </p>
                    </ng-template>
                  </div> -->
                  <div class="bg-gradient">
                    <mat-icon class="text-muted font-primary">schedule</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="card summary-card">
                <div class="card-body">
                  <!-- <div class="row mb-2">
                    <div class="col">
                      <div class="d-flex justify-content-between">
                        <h5>Progreso:</h5>
                        <span>Total de paquetes: 1214</span>
                      </div>
                      <mat-progress-bar
                        mode="determinate"
                        value="86"
                      ></mat-progress-bar>
                      <p class="text-end mt-1">
                        86%
                      </p>
                    </div>
                  </div> -->
    
                  <div class="row">
                    <div class="col-md-2 col-6 resume-card">
                      <div class="">
                        <h4 class="align-middle fs-6">
                          <mat-icon class="font-success me-2 align-middle"
                            >done_all</mat-icon
                          >Entregado
                        </h4>
                        <div *ngIf="isLoading; else resume_successful" class="row justify-content-center">
                          <div class="skeleton-cards skeleton-rect-lg" style="width: 20%"></div>
                        </div>
                        <ng-template #resume_successful>
                          <h1 class=" text-center">
                            {{shipmentStatus.successful | number}}
                          </h1>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-md-2 col-6 resume-card-mobile">
                      <div class="">
                        <h4 class="align-middle fs-6">
                          <mat-icon class="font-warning me-2 align-middle"
                            >local_shipping</mat-icon
                          >En ruta
                        </h4>
                        <div *ngIf="isLoading; else resume_started" class="row justify-content-center">
                          <div class="skeleton-cards skeleton-rect-lg" style="width: 20%"></div>
                        </div>
                        <ng-template #resume_started>
                          <h1 class="mt-2 text-center">
                            {{shipmentStatus.started | number}}
                          </h1>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-md-2 col-6 resume-card">
                      <div class="">
                        <h4 class="align-middle fs-6">
                          <mat-icon color="accent" class="me-2 align-middle"
                            >forklift</mat-icon
                          >Recolectado
                        </h4>
                        <div *ngIf="isLoading; else resume_assigned" class="row justify-content-center">
                          <div class="skeleton-cards skeleton-rect-lg" style="width: 20%"></div>
                        </div>
                        <ng-template #resume_assigned>
                          <h1 class="mt-2 text-center">
                            {{shipmentStatus.assigned | number}}
                          </h1>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-md-2 col-6 resume-card-mobile">
                      <div class="">
                        <h4 class="align-middle fs-6">
                          <mat-icon class="font-purple me-2 align-middle"
                            >assignment</mat-icon
                          >Orden recibida
                        </h4>
                        <div *ngIf="isLoading; else resume_order_created" class="row justify-content-center">
                          <div class="skeleton-cards skeleton-rect-lg" style="width: 20%"></div>
                        </div>
                        <ng-template #resume_order_created>
                          <h1 class="mt-2 text-center">
                            {{shipmentStatus.order_created | number}}
                          </h1>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-md-2 col-6 resume-card">
                      <div class="">
                        <h4 class="align-middle fs-6">
                          <mat-icon class="font-light-blue me-2 align-middle"
                            >assignment_turned_in</mat-icon
                          >Orden surtida
                        </h4>
                        <div *ngIf="isLoading; else resume_order_completed" class="row justify-content-center">
                          <div class="skeleton-cards skeleton-rect-lg" style="width: 20%"></div>
                        </div>
                        <ng-template #resume_order_completed>
                          <h1 class="mt-2 text-center">
                            {{shipmentStatus.order_completed | number}}
                          </h1>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-md-2 col-6">
                      <div class="">
                        <h4 class="align-middle fs-6">
                          <mat-icon color="warn" class="me-2 align-middle"
                            >warning</mat-icon
                          >Incidencias
                        </h4>
                        <div *ngIf="isLoading; else resume_error" class="row justify-content-center">
                          <div class="skeleton-cards skeleton-rect-lg" style="width: 20%"></div>
                        </div>
                        <ng-template #resume_error>
                          <h1 class="mt-2 text-center">
                            {{shipmentStatus.error | number}}
                          </h1>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="appointment">
                <div class="card card-height">
                  <div class="card-header card-no-border">
                    <div class="header-top">
                      <h5 class="m-0">Envíos pendientes</h5>
                      <a href="/admin/shipments">Ver todo</a>
                    </div>
                  </div>
                  <div class="card-body pt-0 custom-table">
                    <div class="row justify-content-center" *ngIf="nDashboardData.shipments?.length == 0 && !isLoading  ; else shipmentsTable">
                      <img id="logistic-image" src="../../../assets/images/logistic.svg" alt="" srcset="">
                      <p class="text-muted text-center mt-5">Sin envíos</p>
                    </div>
            
                    <ng-template #shipmentsTable>
                      <div class="appointment-table table-responsive">
                        <table class="table table-bordernone">
                          <thead>
                            <tr>
                              <th class="text-start ps-0">Paqueteria</th>
                              <th class="text-start ps-2">Guia - Servicio</th>
                              <th class="text-start ps-2">Creación</th>
                              <th class="text-start ps-5">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngIf="isLoading; else shipmentsData">
                              <tr *ngFor="let i of [1,2,3,4,5,6]">
                                <td><div class="skeleton-loader skeleton-circle"></div></td>
                                <td class="ps-2">
                                  <div class="skeleton-loader skeleton-text"></div>
                                  <div class="skeleton-loader skeleton-text" style="width: 60%;"></div>
                                </td>
                                <td class="text-start ps-0"><div class="skeleton-loader skeleton-text" style="width: 50%;"></div></td>
                                <td class="ps-1"><div class="skeleton-loader skeleton-text" style="width: 70%;"></div></td>
                              </tr>
                            </ng-container>
                            <ng-template #shipmentsData>
                              <tr *ngFor="let service of pendingShipments | slice:0:6">
                                <td>
                                  <div *ngIf="isLoading; else image" class="skeleton-loader skeleton-circle"></div>
                                  <ng-template #image>
                                    <img class="img-fluid img-50 margin-c" src="../../../assets/images/carriers/{{imgFormat(service.carrier == 'DHL MEXICO' ? 'dhl' : service.carrier)}}.jpg" alt="{{imgFormat(service.carrier)}}">
                                  </ng-template>
                                </td>
                                <td class="img-content-box ps-2" matTooltip="Click para rastrear envío" [matTooltipShowDelay]="1000" [matTooltipPosition]="'above'">
                                  <span class="f-w-500 d-block">
                                    <a class="a-ship fw-bold" target="_blank" href="https://tracking.buhologistics.com/?id={{service.tracking_number}}">{{service.tracking_number}}</a>
                                  </span>
                                  <span class="f-light">{{getServiceMode(service.service, service.carrier)}}</span>
                                </td>
                                <td class="text-start ps-2">
                                  <span>{{service.created_at | date:'dd/MM/yyyy'}}</span>
                                </td>
                                <td class="img-content-box ps-5">
                                  <span [class]="'badge custom-badge ' + (estatusMapping[service.status ?? 'order_created']?.color)">
                                    <mat-icon [class]="'badge-icon ' + (estatusMapping[service.status ?? 'order_created']?.icon_color)">
                                      {{ estatusMapping[service.status ?? 'order_created']?.icon }}
                                    </mat-icon>
                                    <span class="badge-text">{{ estatusMapping[service.status ?? 'order_created']?.label }}</span>
                                  </span>
                                </td>
                              </tr>
                            </ng-template>
                          </tbody>
                        </table>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="appointment">
                <div class="card card-height">
                  <div class="card-header card-no-border">
                    <div class="header-top">
                      <h5 class="m-0">Envíos generados</h5>
                    </div>
                  </div>
                  <div class="card-body pt-0 custom-table">
                    <div *ngIf="isLoading; else barChart" class="skeleton-chart-container">
                      <div class="skeleton-chart">
                        <div class="skeleton-column" style="height: 60%;"></div>
                        <div class="skeleton-column" style="height: 80%;"></div>
                        <div class="skeleton-column" style="height: 70%;"></div>
                        <div class="skeleton-column" style="height: 90%;"></div>
                        <div class="skeleton-column" style="height: 50%;"></div>
                        <div class="skeleton-column" style="height: 25%;"></div>
                        <div class="skeleton-column" style="height: 30%;"></div>
                        <div class="skeleton-column" style="height: 100%;"></div>
                        <div class="skeleton-column" style="height: 45%;"></div>
                      </div>
                    </div>
                    <ng-template #barChart>
                      <app-bar-chart [data]="barChartData" [totalShipments]="nDashboardData.totalShipments" [actualMonth]="nDashboardData.monthlyShipments"></app-bar-chart>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="appointment">
                  <div class="card card-height">
                      <div class="card-header card-no-border">
                          <div class="header-top">
                              <h5 class="m-0">Tiempo promedio de entrega</h5>
                          </div>
                      </div>
                      <div class="card-body pt-0 table-lg">
                        <div class="row justify-content-center" *ngIf="avg_delivery_time?.length == 0 && !isLoading; else avgDeliveryTime">
                          <img id="on-the-way-image" src="../../../assets/images/on_the_way.svg" alt="" >
                          <p class="text-muted text-center mt-5 ms-4">Aún no se ha entregado ningún paquete</p>
                        </div>
                        <ng-template #avgDeliveryTime>
                          <div class="appointment-table table-responsive">
                              <table class="table table-bordernone">
                                  <thead>
                                    <tr>
                                        <th class="text-start ps-0">Paqueteria</th>
                                        <th class="text-start ps-2">Servicio</th>
                                        <th class="text-end ps-1">Tiempo</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <ng-container *ngIf="isLoading; else avgDeliveryTimeData">
                                      <tr *ngFor="let i of [1,2,3,4,5,6]">
                                        <td><div class="skeleton-loader skeleton-circle"></div></td>
                                        <td class="ps-2">
                                          <div class="skeleton-loader skeleton-rect-lg" style="width: 100%;"></div>
                                        </td>
                                        <td class="text-end"><div class="skeleton-loader skeleton-rect-lg-mr" style="width: 20%;"></div></td>
                                      </tr>
                                    </ng-container>
                                    <ng-template #avgDeliveryTimeData>
                                      <tr *ngFor="let service of avg_delivery_time | slice:0:7">
                                          <td>
                                              <img class="img-fluid img-50"
                                                   src="../../../assets/images/carriers/{{ imgFormat(service.carrier) }}-sm.jpg" 
                                                   alt="{{ imgFormat(service.carrier) }}">
                                          </td>
                                          <td class="img-content-box">
                                              <span>{{ service.service_mode }}</span>
                                          </td>
                                          <td class="text-end">
                                              <h2>{{ service.average_delivery_time + 1 || 0 | number:'1.0-0'  }} {{ service.count === 1 ? 'Día' : 'Días' }}</h2>
                                          </td>
                                      </tr>
                                  </ng-template>                                  
                                  </tbody>
                              </table>
                          </div>
                        </ng-template>
                      </div>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="appointment">
                  <div class="card card-height">
                      <div class="card-header card-no-border">
                          <div class="header-top">
                              <h5 class="m-0">Incidencias</h5>
                          </div>
                      </div>
                      <div class="card-body pt-0 table-lg">
                          <div class="row justify-content-center" *ngIf="incidentShipments?.length == 0 && !isLoading; else incidentsTable">
                              <img id="no-data-image" src="../../../assets/images/no_data.svg" alt="" srcset="">
                              <p class="text-muted text-center mt-5 ms-4">Sin incidencias</p>
                          </div>
                          <ng-template #incidentsTable>
                              <div class="appointment-table table-responsive">
                                  <table class="table table-bordernone">
                                      <thead>
                                          <tr>
                                              <th class="text-start ps-0">Paqueteria</th>
                                              <th class="text-start ps-2">Guia - Servicio</th>
                                              <th class="text-start ps-0">Creación</th>
                                              <th class="text-start ps-4">Status</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <ng-container *ngIf="isLoading; else incidentsData">
                                              <tr *ngFor="let i of [1,2,3,4,5,6]">
                                                  <td><div class="skeleton-loader skeleton-circle"></div></td>
                                                  <td class="ps-2">
                                                      <div class="skeleton-loader skeleton-text"></div>
                                                      <div class="skeleton-loader skeleton-text" style="width: 60%;"></div>
                                                  </td>
                                                  <td class="text-start ps-0">
                                                      <div class="skeleton-loader skeleton-text" style="width: 50%;"></div>
                                                  </td>
                                                  <td class="ps-1">
                                                      <div class="skeleton-loader skeleton-text" style="width: 70%;"></div>
                                                  </td>
                                              </tr>
                                          </ng-container>
                                          <ng-template #incidentsData>
                                              <tr *ngFor="let service of incidentShipments | slice:0:6">
                                                  <td>
                                                      <img class="img-fluid img-50 margin-c"
                                                          src="../../../assets/images/carriers/{{imgFormat(service.carrier)}}.jpg" 
                                                          alt="{{imgFormat(service.carrier)}}">
                                                  </td>
                                                  <td class="img-content-box ps-2" matTooltip="Click para rastrear envío" [matTooltipShowDelay]="1000" [matTooltipPosition]="'above'">
                                                      <span class="f-w-500 d-block">
                                                        <a class="a-ship fw-bold" target="_blank" href="https://tracking.buhologistics.com/?id={{service.tracking_number}}">{{service.tracking_number}}</a>
                                                      </span>
                                                      <span class="f-light">{{getServiceMode(service.service, service.carrier)}}</span>
                                                  </td>
                                                  <td class="text-start ps-0">
                                                      <span>{{ service.created_at | date:'dd/MM/yyyy' }}</span>
                                                  </td>
                                                  <td class="img-content-box ps-4">
                                                      <span [class]="'badge custom-badge ' + (estatusMapping[service.status ?? 'order_created']?.color)">
                                                          <mat-icon [class]="'badge-icon ' + (estatusMapping[service.status ?? 'order_created']?.icon_color)">
                                                              {{ estatusMapping[service.status ?? 'order_created']?.icon }}
                                                          </mat-icon>
                                                          <span class="badge-text">{{ estatusMapping[service.status ?? 'order_created']?.label }}</span>
                                                      </span>
                                                  </td>
                                              </tr>
                                          </ng-template>
                                      </tbody>
                                  </table>
                              </div>
                          </ng-template>
                      </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="appointment">
                <div class="card card-height">
                  <div class="card-header card-no-border">
                    <div class="header-top">
                      <h5 class="m-0">Porcentaje de entregas exitosas</h5>
                    </div>
                  </div>
                  <div class="card-body mt-1 custom-card-height">
                    <div class="row justify-content-center" *ngIf="success_rate?.length == 0 && !isLoading; else successRate">
                      <img id="no-data-image" src="../../../assets/images/success_rate.svg" alt="" srcset="">
                      <p class="text-muted text-center mt-5 ms-4">Aún no se ha entregado ningún paquete</p>
                    </div>
                    <ng-template #successRate>
                      <ng-container *ngIf="isLoading; else successfulDeliveryData">
                        <div class="skeleton-bar-container">
                          <div class="row justify-content-between">
                            <div class="col text-start"><div class="skeleton-loader skeleton-text" style="width: 30%;"></div></div>
                          </div>
                          <div style="width: 80%;" class="skeleton-bar"></div>
                          
                          <div class="row justify-content-between mt-4">
                            <div class="col text-start"><div class="skeleton-loader skeleton-text" style="width: 30%;"></div></div>
                          </div>
                          <div style="width: 50%;" class="skeleton-bar"></div>
                          
                          <div class="row justify-content-between mt-4">
                            <div class="col text-start"><div class="skeleton-loader skeleton-text" style="width: 30%;"></div></div>
                          </div>
                          <div style="width: 70%;" class="skeleton-bar"></div>

                          <div class="row justify-content-between mt-4">
                            <div class="col text-start"><div class="skeleton-loader skeleton-text" style="width: 30%;"></div></div>
                          </div>
                          <div style="width: 90%;" class="skeleton-bar"></div>
                        </div>
                      </ng-container>
                    </ng-template>
                    <ng-template #successfulDeliveryData>
                      <div *ngIf="success_rate[0]" class="row justify-content-between">
                        <div class="col">
                          <img class="img-fluid img-60 progress-chart-image" src="../../../assets/images/carriers/{{imgFormat(success_rate[0]?.carrier)}}-sm.jpg" alt="{{imgFormat(success_rate[0]?.carrier)}}">
                        </div>
                        <div class="col text-end">
                          <span class="text-muted">{{success_rate[0]?.success_rate | number:'1.0-2'}}%</span>
                        </div>
                        <app-progress-bar-chart [data]="success_rate[0]"></app-progress-bar-chart>
                      </div>
                      <div *ngIf="success_rate[1]" class="row justify-content-between mt-3">
                        <div class="col">
                          <img class="img-fluid img-60 progress-chart-image" src="../../../assets/images/carriers/{{imgFormat(success_rate[1]?.carrier)}}-sm.jpg" alt="{{imgFormat(success_rate[1]?.carrier)}}">
                        </div>
                        <div class="col text-end">
                          <span class="text-muted">{{success_rate[1]?.success_rate | number:'1.0-2' }}%</span>
                        </div>
                        <app-progress-bar-chart [data]="success_rate[1]"></app-progress-bar-chart>
                      </div>
                      <div *ngIf="success_rate[2]" class="row justify-content-between mt-3">
                        <div class="col">
                          <img class="img-fluid img-60 progress-chart-image" src="../../../assets/images/carriers/{{imgFormat(success_rate[2]?.carrier)}}-sm.jpg" alt="{{imgFormat(success_rate[2]?.carrier)}}">
                        </div>
                        <div class="col text-end">
                          <span class="text-muted">{{success_rate[2]?.success_rate | number:'1.0-2' }}%</span>
                        </div>
                        <app-progress-bar-chart [data]="success_rate[2]"></app-progress-bar-chart>
                      </div>
                      <div *ngIf="success_rate[3]" class="row justify-content-between mt-3">
                        <div class="col">
                          <img class="img-fluid img-60 progress-chart-image" src="../../../assets/images/carriers/{{imgFormat(success_rate[3]?.carrier)}}-sm.jpg" alt="{{imgFormat(success_rate[3]?.carrier)}}">
                        </div>
                        <div class="col text-end">
                          <span class="text-muted">{{success_rate[3]?.success_rate | number:'1.0-2' }}%</span>
                        </div>
                        <app-progress-bar-chart [data]="success_rate[3]"></app-progress-bar-chart>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="appointment">
                <div class="card card-height">
                  <div class="card-header card-no-border">
                    <div class="header-top">
                      <h5 class="m-0">Uso de paqueterías</h5>
                    </div>
                  </div>
                  <div class="card-body carriers-usage-chart">
                    <div class="row justify-content-center" *ngIf="carrier_usage?.length == 0 && !isLoading; else carrierUsage">
                      <img id="no-data-image" src="../../../assets/images/usage.svg" alt="" srcset="">
                      <p class="text-muted text-center mt-5 ms-4">Aún no se ha utilizado ninguna paquetería</p>
                    </div>
                    <ng-template #carrierUsage>
                      <ng-container *ngIf="isLoading; else donutChartData">
                        <div class="skeleton-donut-container">
                          <div class="skeleton-donut"></div>
                        </div>
                      </ng-container>
                      <ng-template #donutChartData>
                        <div class="row justify-content-center">
                          <app-donut-chart [data]="carrier_usage"></app-donut-chart>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
