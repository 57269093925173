<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Mis envíos</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>local_shipping</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Mis envíos</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <ng-template mat-tab-label> Lista de envíos <mat-icon class="ms-1">list</mat-icon>
                </ng-template>
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <div class="float-end">
                            <button mat-stroked-button class="btn btn-outline-secondary" matTooltip="Genera un nuevo envío masivo" [matTooltipShowDelay]="1000" (click)="openBulkUpload()" [hidden]="isClientUsr" style="margin-right: 10px;"><mat-icon>upload</mat-icon><span class="btn-no-text"> Carga masiva</span></button>
                            <button mat-stroked-button class="btn btn-primary" matTooltip="Genera un nuevo envío" [matTooltipShowDelay]="1000" (click)="cleanGoToShipmentAdd()"><mat-icon>local_shipping</mat-icon><span class="btn-no-text"> Crear envío</span></button>
                        </div>
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                        </mat-form-field>
                    </div>
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear class="w-100">
                
                            <ng-container matColumnDef="tracking_number">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tracking </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row" matTooltip="Click para rastrear envío" [matTooltipShowDelay]="1000" [matTooltipPosition]="'above'"> <a class="a-ship fw-bold" target="_blank" href="https://tracking.buhologistics.com/?id={{row.tracking_number}}">{{row.tracking_number}}</a></td>
                            </ng-container>
                
                            <ng-container matColumnDef="carrier">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Paquetería </th>
                                <td class="col-1" mat-cell *matCellDef="let row">
                                    <img class="w-50" src="../../../assets/images/carriers/{{imgFormat(row.carrier)}}.jpg">
                                </td>
                            </ng-container>
                
                            <ng-container matColumnDef="destination">
                                <th class="ps-4" mat-header-cell *matHeaderCellDef mat-sort-header> Destino </th>
                                <td class="col-3 ps-4 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.to_address}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="shipment">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Envío </th>
                                <td class="col-2" mat-cell matTooltip="Medidas: {{row.packages[0].length}}cm. x {{row.packages[0].width}}cm. x {{row.packages[0].height}}cm. , Peso: {{row.packages[0].weight}}kg" *matCellDef="let row"> {{row.packages[0].content}} <div class="row"><p><strong>Dimensiones: </strong> {{row.packages[0].length}}x{{row.packages[0].width}}x{{row.packages[0].height}}</p></div></td>
                            </ng-container>

                            <ng-container matColumnDef="order_price" >
                                <th class="" mat-header-cell *matHeaderCellDef mat-sort-header>Precio </th>
                                <td class="col-1" mat-cell *matCellDef="let row">{{row.total_price | currency}} </td>
                            </ng-container>

                            <ng-container matColumnDef="user_name" >
                                <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> Usuario</th>
                                <td mat-cell *matCellDef="let row"> <span [ngClass]="{'badge': true, 'bg-info': !row.id_usuario, 'bg-primary': row.id_usuario}">{{row.user_name}}</span> </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th class="ps-2" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td class="col-1 ps-2 pe-2" mat-cell *matCellDef="let row">
                                    <span [class]="'badge custom-badge ' + (estatusMapping[row.status ?? 'order_created']?.color)">
                                        <mat-icon [class]="'badge-icon ' + (estatusMapping[row.status ?? 'order_created']?.icon_color)">
                                          {{ estatusMapping[row.status ?? 'order_created']?.icon }}
                                        </mat-icon>
                                        <span class="badge-text">{{ estatusMapping[row.status ?? 'order_created']?.label }}</span>
                                    </span>
                                </td>
                            </ng-container>
                
                            <ng-container matColumnDef="created_at">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                                <td mat-cell *matCellDef="let row" class="col-1 ps-1"> {{row.created_at.slice(0,16).replace('T','  ')}}</td>
                            </ng-container>
                
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center col-1">                                            
                                    <button mat-icon-button matTooltip="Imprimir guía" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="primary" (click)="printLabel(row.tracking_number)">
                                        <mat-icon>print</mat-icon>
                                    </button>
                                    <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="viewLabel(row.tracking_number)"><mat-icon class="me-2">preview</mat-icon>Ver Guía</button>
                                        <button *ngIf="row.shipment_type=='int'" mat-menu-item (click)="viewLabel(row.tracking_number,true)"><mat-icon class="me-2">preview</mat-icon>Ver Factura</button>
                                        <button mat-menu-item (click)="printLabel(row.tracking_number)"><mat-icon class="me-2">print</mat-icon>Imprimir Guía</button>
                                        <button *ngIf="row.shipment_type=='int'" mat-menu-item (click)="printLabel(row.tracking_number,true)"><mat-icon class="me-2">print</mat-icon>Imprimir Factura</button>
                                        <button mat-menu-item (click)="goToTracking(row.tracking_number)"><mat-icon class="me-2">local_shipping</mat-icon>Rastrear Envío</button>
                                        <button mat-menu-item (click)="openDialogSaveFav(row.id)"><mat-icon class="me-2">favorite_outline</mat-icon>Agregar a Favoritos</button>
                                        <button mat-menu-item (click)="showPickupEvidence(row.id)" [hidden]="locallyDelivered(row.carrier, row.status)"><mat-icon class="me-2">aspect_ratio</mat-icon>Ver Evidencia</button>
                                        <button mat-menu-item (click)="requestPickup(row)" [hidden]="validForPickup(row.carrier)"><mat-icon class="me-2">trolley</mat-icon>Solicitar recolección</button>
                                        <button mat-menu-item (click)="helpShipment(row)"><mat-icon class="me-2">contact_support</mat-icon>Ayuda con Envío</button>
                                        <!-- <button mat-menu-item><mat-icon class="me-2">dangerous</mat-icon>Cancelar Envío</button> -->
                                    </mat-menu>
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td *ngIf="shipmentList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                <td *ngIf="shipmentList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                            </tr>
                        </table>
                
                        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-bar color="primary" mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" pageSize="25"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>