<div class="modal-header">
    <h1 class="modal-title">Agregar créditos</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="alert alert-warning">
        Esta acción agregará créditos a los ya exitentes.
    </div>
    <h4>Ingresa la cantidad de créditos que deseas añadir</h4>
    <form autocomplete="off" [formGroup]="formC" (ngSubmit)="ruSure()" class="px-3 mt-3" id="formC">
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Créditos</mat-label>
                    <input matInput formControlName="credit" readonly>
                    <mat-icon matSuffix>attach_money</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Créditos para añadir</mat-label>
                    <input matInput formControlName="add_credit">
                    <mat-icon matSuffix>attach_money</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Observación</mat-label>
                    <input matInput formControlName="observations">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" form="formC" type="submit" [disabled]="!formC.valid">Guardar <mat-icon>save</mat-icon></button>
</div>