<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Pedidos</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon>dashboard</mat-icon></a></li>
                    <li class="breadcrumb-item">Ordenes</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. #555555555555" #input>
                        </mat-form-field>
                    </div>

                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container class="col-2" matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Orden</th>
                                <td mat-cell *matCellDef="let row" class="custom-cell">
                                  <div >
                                    <!-- <img class="logo" src="assets/images/shopify.png" alt="Shopify Logo"> -->
                                    <div >
                                        <div class="order-number">
                                           <a matTooltip="Ver orden" matTooltipPosition="above" class="a-ship" target="_blank" href="https://admin.shopify.com/store/{{row.shop.split('.myshopify.com')[0]}}/orders/{{row.id}}"> {{row.orderName}} </a>
                                        </div>
                                        <!-- <div class="order-name">#{{row.id}}</div> -->
                                      <!-- <div class="order-date">{{formatDate(row.createdAt)}}</div> -->
                                      <!-- <div class="order-source">Shopify</div> -->
                                    </div>
                                  </div>
                                </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="createdAt">
                                <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                                <td class="col-2 pt-1 pb-1" mat-cell *matCellDef="let row"> 
                                    <div *ngIf="row.destination;else noData">
                                        <p><b>{{row.to_address.name }}</b></p>
                                        {{ row.to_address.state }},
                                        {{ row.to_address.postalCode }}
                                    </div>
                                    <ng-template #noData>
                                        <p><b>No definido</b></p>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-container  matColumnDef="items">
                                <th class="col-1" mat-header-cell *matHeaderCellDef mat-sort-header> Productos </th>
                                <td class="col-1" mat-cell *matCellDef="let row">
                                    <span [matTooltip]="row.packages[0].content">{{row.items.length}}{{row.items.length === 1 ? ' producto' : ' productos' }}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="financialStatus">
                                <th class="col-1 ps-3" mat-header-cell *matHeaderCellDef mat-sort-header> Estado de pago </th>
                                <td class="col-1 ps-3" mat-cell *matCellDef="let row">
                                    <span [class]="'badge custom-badge ' + (statusCatalog[row.financialStatus]?.color)">
                                        <span class="badge-text">{{ statusCatalog[row.financialStatus]?.label }}</span>
                                    </span>
                                    <!-- <span [class]="statusCatalog[row.financialStatus].colorClass">{{ statusCatalog[row.financialStatus].name }}</span> -->
                                </td>
                            </ng-container>

                            <ng-container class="col-2" matColumnDef="fulfillmentStatus">
                                <th class="col-2 ps-4" mat-header-cell *matHeaderCellDef mat-sort-header> Estado de cumplimiento </th>
                                <td class="col-2 ps-4" mat-cell *matCellDef="let row">
                                    <span [class]="'badge custom-badge ' + (fulfillmentStatusCatalog[row.fulfillmentStatus]?.color)">
                                        <span class="badge-text">{{ fulfillmentStatusCatalog[row.fulfillmentStatus]?.label }}</span>
                                    </span>
                                    <!-- <span [class]="fulfillmentStatusCatalog[row.fulfillmentStatus].colorClass">{{ fulfillmentStatusCatalog[row.fulfillmentStatus].name }}</span> -->
                                </td>
                            </ng-container>

                            <ng-container class="col-2" matColumnDef="tracking">
                                <th class="ps-2" mat-header-cell *matHeaderCellDef mat-sort-header> 
                                    <span> Numero de rastreo </span>
                                </th>
                                <td class="text-center" mat-cell *matCellDef="let row">
                                    <a matTooltip="Asignar fulfillment" matTooltipPosition="above" *ngIf="row.tracking_number && row.fulfillmentStatus != 'fulfilled' else fulfilled" class="a-ship" target="_blank" href="https://admin.shopify.com/store/quickstart-057cd8c8/orders/{{row.id}}/fulfillment_orders/{{row.orderFullfillmentId}}/fulfill">{{row.tracking_number}}</a>
                                    <ng-template #fulfilled>
                                        <span *ngIf="row.tracking_number && row.fulfillmentStatus == 'fulfilled' else noTracking">{{row.tracking_number}}</span>
                                    </ng-template>
                                    <!-- <a matTooltip="Asignar fulfillment" *ngIf="row.tracking_number;else noTracking" class="a-ship" target="_blank" href="https://tracking.buhologistics.com/?id={{row.tracking_number}}">{{row.tracking_number}}</a> -->
                                   <!-- <a href="" *ngIf="row.tracking_number;else noTracking">{{row.tracking_number }}</a> -->
                                   <ng-template #noTracking>
                                    <!-- <mat-icon>minimize</mat-icon> -->
                                       <span>—</span>
                                   </ng-template>
                                </td>
                            </ng-container>

                            <ng-container class="col-2" matColumnDef="carrier">
                                <th class="ps-4 text-center" mat-header-cell *matHeaderCellDef mat-sort-header> 
                                    <span> Paqueteria </span>
                                </th>
                                <td class="text-center" mat-cell *matCellDef="let row">
                                   <img *ngIf="row.tracking_number;else noTracking" class="w-custom" src="../../../assets/images/carriers/{{imgFormat(row.carrier)}}-sm.jpg">
                                   <ng-template #noTracking>
                                    <!-- <mat-icon>minimize</mat-icon> -->
                                       <span>—</span>
                                   </ng-template>
                                </td>
                            </ng-container>

                            <ng-container class="col-2" matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center">

                                    <!-- <ng-template #getQuote> -->
                                        <!-- <button  [matTooltip]="hasGiftCard(row.items) > -1 ? 'Tiene gift card': 'No tiene gift card'" matTooltipPosition="above" [disabled]="!row.destination" *ngIf="!row.tracking_number;else trackingInfo" mat-button (click)="openDialogCheckout(row)" color="primary"> -->
                                        <button  [matTooltip]="!row.destination ? 'Revisa los datos de envío' : ''" matTooltipPosition="above" [disabled]="!row.destination" *ngIf="!row.tracking_number;else trackingInfo" mat-button (click)="openDialogCheckout(row)" color="primary">
                                            Cotizar guía
                                            <mat-icon class="ms-1">request_quote</mat-icon>
                                        </button>
                                        <ng-template #trackingInfo>
                                            <div>
                                                <!-- <b>Guía: </b> {{row.tracking_number }}<br/> -->
                                                <!-- <img class="w-custom" src="../../../assets/images/carriers/{{imgFormat(row.carrier)}}-sm.jpg"> -->
    
                                            </div>
                                            <button mat-icon-button matTooltip="Imprimir guía" [matTooltipPosition]="'above'" [matTooltipShowDelay]="100" color="primary" (click)="printLabel(row.tracking_number)">
                                                <mat-icon>print</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Ver guía" [matTooltipPosition]="'above'" [matTooltipShowDelay]="100" color="primary" (click)="viewLabel(row.tracking_number)">
                                                <mat-icon>preview</mat-icon>
                                            </button>
                                        
                                            <a target="_blank" href="https://tracking.buhologistics.com/?id={{row.tracking_number}}">
                                                <button mat-icon-button matTooltip="Rastrear guía" [matTooltipPosition]="'above'" [matTooltipShowDelay]="100" color="primary">
                                                    <mat-icon>local_shipping</mat-icon>
                                                </button>
                                            </a>
                                             <!-- <a target="_blank" href="https://tracking.buhologistics.com/?id={{row.tracking_number}}"> -->
                                                 <!-- <button (click)="viewLabel(row.tracking_number)" class="bg-success" mat-raised-button>
                                                     <mat-icon class="me-1">local_shipping</mat-icon>
                                                     Rastrear guia
                                                 </button> -->
                                            <!-- </a> -->
                                        </ng-template>
                                    <!-- </ng-template> -->

                                </td>
                            </ng-container>
            
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td *ngIf="orderList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                <td *ngIf="orderList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                            </tr>
                        </table>
                
                        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner color="primary" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>