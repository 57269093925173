<!-- select and over all row -->
<div class="modal-header pb-3">
    <h1 class="modal-title">Guardar favorito</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col">   
                <div class="card card-absolute shadow shadow-showcase">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">Dirección de origen <mat-icon class="align-middle">pin_drop</mat-icon>
                        </h5>
                    </div>
                    <div class="card-body">
                        <mat-list role="list">
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Nombre:</strong>{{info.from_address.name}}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Empresa:</strong>{{info.from_address.company}}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Email:</strong>{{info.from_address.email}}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Teléfono:</strong>{{info.from_address.phone}}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Dirección:</strong>{{ info.from_address.street }} {{ info.from_address.number }}, {{ info.from_address.suburb }}, {{ info.from_address.city }}, {{ info.from_address.state }}, {{info.from_address.country}}, CP: {{ info.from_address.postalCode }}</mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card card-absolute shadow shadow-showcase">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">Dirección de destino <mat-icon class="align-middle">pin_drop</mat-icon>
                        </h5>
                    </div>
                    <div class="card-body">
                        <mat-list role="list">
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Nombre:</strong>{{ info.to_address.name }}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Empresa:</strong>{{ info.to_address.company }}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Email:</strong>{{ info.to_address.email }}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Teléfono:</strong>{{ info.to_address.phone }}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info"><strong class="pe-2">Dirección:</strong>{{ info.to_address.street }} {{ info.to_address.number }}, {{ info.to_address.suburb }}, {{ info.to_address.city }}, {{ info.to_address.state }}, {{info.to_address.country}}, CP: {{ info.to_address.postalCode }}</mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card card-absolute shadow shadow-showcase col">
                    <div class="card-header bg-primary">
                    <h5 class="text-white">Paqueteria  <mat-icon class="align-middle">view_in_ar</mat-icon>
                    </h5>
                    </div>
                    <div class="card-body">
                        <mat-list role="list">
                            <mat-list-item role="listitem" *ngIf="info && info.selected_rate"><strong class="pe-2">Servicio:</strong>{{info.selected_rate.service}}</mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info && info.selected_rate">
                                <img src="../../../assets/images/carriers/{{imgFormat(info.selected_rate.carrier)}}-sm.jpg" alt="{{imgFormat(info.selected_rate.carrier)}}">
                            </mat-list-item>
                            <mat-list-item role="listitem" *ngIf="info && info.selected_rate"><strong class="pe-2">Precio:</strong>{{info.selected_rate.total_price | currency}}</mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card card-absolute shadow shadow-showcase col">
                    <div class="card-header bg-primary">
                      <h5 class="text-white">Paquetes  <mat-icon class="align-middle">view_in_ar</mat-icon>
                      </h5>
                    </div>
                    <div class="card-body" *ngIf="info && info.packages">
                        <div *ngFor="let package of info.packages; index as i"  class="row align-items-center justify-content-center">
                            <mat-list role="list">
                                <b class="fs-6">Paquete {{i+1}}</b>
                                <mat-list-item role="listitem"><strong class="pe-2">Tipo de paquete:</strong>{{ package.shipmentType  == 'box' ? 'Caja' : package.shipmentType == 'envelope' ? 'Sobre' : 'Tarima' }}</mat-list-item>
                                <mat-list-item role="listitem"><strong class="pe-2">Medidas:</strong>{{ package.lenght}}x{{ package.width}}x{{ package.heigth}}, {{ package.weight}} kg</mat-list-item>
                                        <mat-list-item role="listitem"><strong class="pe-2">Contenido:</strong>{{package.content}}</mat-list-item>
                                <mat-divider *ngIf="info.packages.length > 1" class="mt-3"></mat-divider>
                            </mat-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>
<div class="modal-footer">
    <form [formGroup]="formSaveFav">
        <mat-form-field *ngIf="canSave" class="w-100" appearance="outline">
            <mat-label>Alias de favorito</mat-label>
            <mat-icon matSuffix>edit</mat-icon>
            <input formControlName="alias" (keyup)="addFavAlias($event)" matInput>
        </mat-form-field>
    </form> 
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button *ngIf="canSave" [disabled]="!formSaveFav.valid" (click)="saveFavData()" mat-flat-button class="btn bg-success text-white" form="formSaveFav" mat-dialog-close>Guardar <mat-icon>save</mat-icon></button>
</div>