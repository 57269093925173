import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FavShipModel } from 'src/app/models/shipment.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-fav-add',
  templateUrl: './fav-add.component.html',
  styleUrls: ['./fav-add.component.scss']
})
export class FavAddComponent implements OnInit {
  userObj!: UserModel;
  info!: FavShipModel;
  formSaveFav!: FormGroup;
  canSave=true;


  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
    private userS: UsersService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FavAddComponent>
  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.getShipment();
    this.formSaveFav = this.fb.group({
      alias: ['', [ Validators.required, Validators.minLength(3)]],
    });
  }

  getShipment() {
    this.userS.getShipment(this.data.shipmentIdSelected).subscribe(
      (result : any) => {
        if(result.message == "Success") {
          this.info = result.data;
          if(result.data.from_address!=null && result.data.packages!=null && result.data.to_address!=null && result.data.selected_rate!=null){
            this.canSave=true;
          }else{
            this.canSave=false;
            Swal.fire({
              icon:  'error',
              title: 'Incompleto para favorito',
              text:  'No es posible utilizar este envío como un favorito, por favor elige otro.',
            }).then((result) => {
              if(result){
                this.dialogRef.close();
              }
            });
          }
        }
      },
      (err : any) => {
        
      }
    );
  }
  
  addFavAlias(event:Event){
    this.info.alias = ( event.target as HTMLInputElement ).value.trim();
  }
  
  saveFavData() {
    Swal.fire({
      icon:  'info',
      title: 'Guardando...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.addFavAddress( this.info ).subscribe(
      ( result:any ) => {
        if ( result.message == "Success" ) {
          Swal.fire({
            icon:  'success',
            title: 'Guardado',
            html:  'Favorito guardado de manera correcta!'
          });
        }
        else if ( result.message == "isSaved" ) {
          Swal.fire({
            icon:  'info',
            title: 'Aviso',
            html:  'Ya lo tienes guardado como favorito'
          })
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error al guardar',
          text:  'Ocurrió un error al guardarlo como favorito, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }
}
