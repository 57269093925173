import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { BulkUploadAddComponent } from '../bulk-upload-add/bulk-upload-add.component';
type AOA = any[][];

@Component({
  selector: 'app-price-comparison-xls',
  templateUrl: './price-comparison-xls.component.html',
  styleUrls: ['./price-comparison-xls.component.scss']
})
export class PriceComparisonXlsComponent implements OnInit {
  wb:XLSX.WorkBook = XLSX.utils.book_new();
  sheetNames: string[] = [];
  selectedSheet: string='';

  dataAOA: AOA = [];
  jsonData : any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  //fileName: string = 'SheetJS.xlsx';
  
  //id_user!: number;
  formAdd!: FormGroup;
  fileName : string = '';

  isLoading=false;

  get bulkUploadData():any { return this.userS.sharedBulkUpload; } set bulkUploadData(value: any) { this.userS.sharedBulkUpload = value; }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<BulkUploadAddComponent>,
    private matDialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    //this.id_user = this.data.userSel.id;
    this.createFormUser();
  }

  createFormUser() {
    this.formAdd = this.fb.group({
      //name: ['', [ Validators.required, Validators.minLength(3)]],
      //email_notification: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],
      formato: ['', [ Validators.required]],
      pestania: ['', []],
      file: ['', []],
      file_name: ['', []],
      rows: ['', []]
    });
  }


  ruSure() {
    if(this.formAdd.invalid) { return; }
    if(this.jsonData.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Error al importar',
        text: 'No se encontró ningún dato para hacer la comparación, por favor sube una plantilla con datos o selecciona la pestaña correcta.'
      });
    }else{
      Swal.fire({
        title: '¿Estás seguro que deseas realizar la comparación con los datos del archivo seleccionado?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.isLoading=true;
          this.saveForm();
        }
      });
    }

  }

  saveForm() {
    let aResponseJson: any=[];

    switch (this.formAdd.value.formato) {
      case 'pgo':        
        //aResponseJson = this.mapPak2Go(this.jsonData);
        aResponseJson = this.mapPak2Go(this.jsonData);
        break;
      case 'afimex':
        aResponseJson = this.mapAfimex(this.jsonData);
        break;
      case 'eme':
        aResponseJson = this.mapEnviame(this.jsonData);
        break;
      case 'paquetexpress':
        aResponseJson = this.mapPaquetexpress(this.jsonData);
        break;
      case 'estafeta':
        aResponseJson = this.mapEstafeta(this.jsonData);
        break;
      default:
        break;
    }
    this.isLoading=true;
    this.dialogRef.close({ reload: false, id: 1, data: aResponseJson, formato: this.formAdd.value.formato });
    // this.formAdd.get('rows')?.setValue(this.jsonData);
    // this.formAdd.get('file_name')?.setValue(this.fileName);

    //console.log(this.formAdd.value);
    // Swal.fire({
    //   icon: 'info',
    //   title: 'Guardando...',
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   showConfirmButton: false,
    //   didOpen: () => {
    //     Swal.showLoading(null);
    //   }
    // });
    // this.userS.saveBulkUpload(this.formAdd.value).subscribe(
    //   (result:any) => {
    //     if(result.message == "Success") {
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Guardado',
    //         text: 'Se han importado de manera correcta los datos del excel!'
    //       }).then(() => {            
    //         this.dialogRef.close({ reload: false, id: result.id });
    //         //this.dialogRef.close({ reload: false, credit: (this.formC.value.credit*1) + (this.formC.value.add_credit*1) });
    //       });
    //     }
    //   },
    //   (err:any) => {        
    //       console.log(err);
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Error al guardar',
    //         text: 'Ocurrió un error al importar, por favor vuelve a intentarlo.'
    //       });
    //   }
    // );
  }



  onFileSelected(event: any) {
    const archivo = event.target.files[0];
    
    const tipoPermitido = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            ,'application/vnd.ms-excel' ]; // MIME para Excel
    const tamanioKb = 5120; // 1024=1 mb
    const tamanioMaximo = tamanioKb * 1024;
  
    if (!tipoPermitido.includes(archivo.type)) {
      // error: Tipo de archivo no válido
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El tipo de archivo no es válido.'
      });
      return;
    }
  
    if (archivo.size > tamanioMaximo) {
      // error: Tamaño de archivo excedido
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `El tamaño del archivo excede el límite de ${((tamanioMaximo/1024)/1024).toFixed(2)} Mb.`
      });
      return;
    }
  
    const file:File = event.target.files[0];
    if (file) {
      console.log(this.fileName = file.name);
      this.onFileChange(event);
      // const formData = new FormData();
      // formData.append("thumbnail", file);
      // const upload$ = this.http.post("/api/thumbnail-upload", formData);
      // upload$.subscribe();
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      this.wb = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      this.sheetNames = this.wb.SheetNames; //para llenar el mat-select
      const wsname: string = this.wb.SheetNames[0];
      this.selectedSheet=wsname; //selecciona el primer elemento en el mat-select
      const ws: XLSX.WorkSheet = this.wb.Sheets[wsname];

      /* save data */
      this.dataAOA = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      
      //console.log(this.dataAOA);


      const headers: string[] = this.dataAOA[0];
      this.jsonData = [];
      this.jsonData = this.dataAOA.slice(1).map((row: any[], index: number) => {
        const obj: any = {};
        obj["ORDER"] = index + 1; //Columna "order" incremental
        headers.forEach((header: string, columnIndex: number) => {
          obj[header] = row[columnIndex] || "";
        });
    
        return obj;
      });
      //console.log('factura',this.jsonData);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  loadSheetData() {
    /* grab sheet select */
    this.sheetNames = this.wb.SheetNames; //para llenar el mat-select
    const ws: XLSX.WorkSheet = this.wb.Sheets[this.selectedSheet];

    /* save data */
    this.dataAOA = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

    const headers: string[] = this.dataAOA[0];
    this.jsonData = [];
    this.jsonData = this.dataAOA.slice(1).map((row: any[], index: number) => {
      const obj: any = {};
      obj["ORDER"] = index + 1; //Columna "order" incremental
      headers.forEach((header: string, columnIndex: number) => {
        obj[header] = row[columnIndex] || "";
      });

      return obj;
    });
  }

  abrirSeleccionArchivo() {
    const archivoInput = document.querySelector('.hidden-input') as HTMLInputElement;
    archivoInput.click();
  }
  
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  
  getValues(obj: any): any[] {
    return Object.values(obj);
  }

  //#region mapeos
  mapPak2Go(received: any): any[] {
    const obj = received.map((item: { [x: string]: any; }) => {
      const xTrackingNumber = (item && item['Número de guía'] ?  this.cleanString(item['Número de guía'].toString()) : '' ).toString();
      if (xTrackingNumber !== '') {
        return {
          //: item?.['Usuario'] || '',
          //: item?.['Nombre o Razón social'] || '',
          xSource : (item?.['fuente'] || '').toString(),
          xCarrier : (item?.['Paquetera'] || '').toString(),
          xService : (item?.['Servicio'] || '').toString(),
          xWeight : item?.['Peso declarado'] || '',
          //: item?.['KG enviados'] || '',
          //: item?.['KG Extra'] || '',
          //: item?.['Zona extendida'] || '',
          //: item?.['Costo de Zona Extendida'] || '',
          //: item?.['Valor declarado'] || '',
          //: item?.['Costo'] || '',
          //: item?.['Costo por kilos extra'] || '',
          xPrice : (item?.['Costo de Zona Extendida'] || 0)+(item?.['Costo por kilos extra'] || 0),
          xTotal_price: item?.['TOTAL CON IVA'] || '', //item?.['TOTAL CON IVA'] || '',
          xDestination_address: (item?.['Dirección'] || '').toString(),
          xDestination_name: (item?.['Destinatario'] || '').toString(),
          //: item?.['RFC Destino'] || '',
          xCreated_at: (item && item['Fecha de creación'] ?  this.serialDateToJSDate(item['Fecha de creación']) : '' ),
          //: item?.['Fecha real de recoleccion'] || '',
          //: item?.['# de paquetes'] || '',
          xTracking_number: xTrackingNumber,
          //: item?.['Estado de la guía'] || '',
          //: item?.['Estado de entrega'] || '',
          //: item?.['Fecha de entrega'] || '',
          //: item?.['Recibió'] || '',
          //: item?.['Origen'] || '',
          //: item?.['Enviado por'] || '',
          //: item?.['RFC Origen'] || '',
          xContent: (item?.['Contenido'] || '').toString(),
          //: item?.['ID Cliente SAP'] || '',
          //: item?.['Asesor Comercial'] || '',
          //: item?.['Usuario'] || '',
          //: item?.['Referencia de envío'] || '',
          //: item?.['Compañía'] || '',
          //: item?.['PROYECTO'] || '',
          //: item?.['OC'] || '',
          
        };
      } else {
        return null; // Excluir elementos con xTracking_number vacío
      }
    }).filter((item: any) => item !== null); // Filtrar elementos nulos (elementos con xTracking_number vacío)
    return obj;
  }

  mapPak2Go2(received: any): any[] {
    const obj = received.map((item: { [x: string]: any; }) => ({
      xSource : (item?.['fuente'] || '').toString(),
      xCarrier : (item?.['Paquetera'] || '').toString(),
      xService : (item?.['Servicio'] || '').toString(),
      xWeight : item?.['Peso declarado'] || '',
      xPrice : item?.['Costo'] || '',
      xTotal_price: item?.['SUBTOTAL A FACTURAR'] || '',
      xDestination_address: (item?.['Dirección'] || '').toString(),
      xDestination_name: (item?.['Destinatario'] || '').toString(),
      xCreated_at: (item && item['Fecha de creación'] ?  this.serialDateToJSDate(item['Fecha de creación']) : '' ),
      xTracking_number: (item && item['# Guia a Text'] ?  this.cleanString(item['# Guia a Text'].toString()) : '' ).toString(),
      xContent: (item?.['Contenido'] || '').toString(),
    }));
    return obj;
  }

  mapPak2Go3(received: any): any[] {
    const obj = received.map((item: { [x: string]: any; }) => ({
      xSource : (item?.['fuente'] || '').toString(),
      xCarrier : (item?.['Paquetera'] || '').toString(),
      xService : (item?.['Servicio'] || '').toString(),
      xWeight : item?.['Peso declarado'] || '',
      xPrice : item?.['PARA FACTURAR'] || '',
      xTotal_price: item?.['TOTAL'] || '',
      xDestination_address: (item?.['Dirección'] || '').toString(),
      xDestination_name: (item?.['Destinatario'] || '').toString(),
      xCreated_at: (item && item['Fecha de creación'] ?  this.serialDateToJSDate(item['Fecha de creación']) : '' ),
      xTracking_number: (item && item['guía'] ?  this.cleanString(item['guía'].toString()) : '' ).toString(),
      xContent: (item?.['Proyecto'] || '').toString(),
    }));
    return obj;
  }


  mapAfimex(received: any): any[] {
    const obj = received.map((item: { [x: string]: any; }) => {
      const xTrackingNumber = (item && item['Guía'] ?  this.cleanString(item['Guía'].toString()) : '' ).toString();
      if (xTrackingNumber !== '') {
        return {
          //item?.['Cliente paga'] || '',
          //item?.['Cliente paga'] || '',
          xCreated_at: (item && item['F. documentación'] ?  this.serialDateToJSDate(item['F. documentación']) : '' ),
          //item?.['Oficina origen'] || '',
          //xTracking_number: item?.['Guia'] || '',
          xTracking_number: xTrackingNumber,
          //item?.['Tipo envío'] || '',
          //item?.['Tipo entrega'] || '',
          //item?.['Estado guía'] || '',
          //item?.['No. usuario'] || '',
          //item?.['Usuario'] || '',
          //item?.['Oficina factura'] || '',
          //item?.['Serie'] || '',
          //item?.['Factura'] || '',
          //item?.['Cliente remitente'] || '',
          //item?.['Nombre remitente'] || '',
          //item?.['Remitente'] || '',
          //item?.['Pais remitente'] || '',
          //item?.['Estado remitente'] || '',
          //item?.['Ciudad remitente'] || '',
          //item?.['Colonia remitente'] || '',
          //item?.['Calle remitente'] || '',
          //item?.['CP remitente'] || '',
          //item?.['Teléfono remitente'] || '',
          //item?.['Oficina destino'] || '',
          //item?.['Cliente destinatario'] || '',
          xDestination_name: item?.['Destinatario'] || '',
          // item?.['Destinatario'] || '',
          // item?.['Pais destinatario'] || '',
          // item?.['Estado destinatario'] || '',
          // item?.['Ciudad destinatario'] || '',
          // item?.['Colonia destinatario'] || '',
          // item?.['Calle destinatario'] || '',
          xDestination_address : `${item?.['Calle destinatario'] || ''}, ${item?.['Colonia destinatario'] || ''}, ${item?.['CP destinatario'] || ''}`,
          //item?.['CP destinatario'] || '',
          // item?.['Teléfono destinatario'] || '',
          // item?.['Mensajero'] || '',
          xContent: item?.['Descripcion'] || '',
          // item?.['F. historia'] || '',
          // item?.['Forzada'] || '',
          // item?.['No. usuario forzó'] || '',
          // item?.['No. usuario canceló'] || '',
          // item?.['F. cancela'] || '',
          xPrice: item?.['Subtotal'] || '',
          // item?.['Total IVA'] || '',
          xTotal_price: item?.['Total guía'] || '',
          // item?.['Facturada'] || '',
          // item?.['Entregada'] || '',
          // item?.['Id Zona Vendida'] || '',
          // item?.['Zona'] || '',
          // item?.['Peso vendido'] || '',
          xWeight: item?.['Peso'] || '',
          // item?.['Peso cobrar'] || '',
          // item?.['Largo'] || '',
          // item?.['Ancho'] || '',
          // item?.['Alto'] || '',
          // item?.['Volumen total'] || '',
          // item?.['Peso volumétrico'] || '',
          // item?.['Peso excedente'] || '',
          // item?.['Importe excedente'] || '',
          // item?.['Paquetes'] || '',
          // item?.['Id Ciudad entrega'] || '',
          // item?.['Ciudad entrega'] || '',
          // item?.['Nombre recibió'] || '',
          // item?.['F. entrega'] || '',
          // item?.['Id Oficina paga'] || '',
          // item?.['Oficina paga'] || '',
          // item?.['Referencia 1'] || '',
          // item?.['Referencia 2'] || '',
          // item?.['Referencia 3'] || '',
          // item?.['Excepcion 1'] || '',
          // item?.['F. excepción 1'] || '',
          // item?.['Excepcion 2'] || '',
          // item?.['F. excepción 2'] || '',
          // item?.['Excepcion 3'] || '',
          // item?.['F. excepción 3'] || '',
          // item?.['Excepcion 4'] || '',
          // item?.['F. excepción 4'] || '',
          // item?.['Excepcion 5'] || '',
          // item?.['F. excepción 5'] || '',
          // item?.['Origen'] || '',
          // item?.['Estado destino'] || '',
          // item?.['Ciudad destino'] || '',
          // item?.['Dirección'] || '',
          // item?.['CP origen'] || '',
          // item?.['CP destino'] || '',
          // item?.['F. confirmación'] || '',
          // item?.['Calificación'] || '',
        };
      } else {
        return null; // Excluir elementos con xTracking_number vacío
      }
    }).filter((item: any) => item !== null); // Filtrar elementos nulos (elementos con xTracking_number vacío)
    return obj;
  }

  mapEnviame(received: any): any[] {
    const obj = received.map((item: { [x: string]: any; }) => {
      const xTrackingNumber = (item && item['OT'] ? this.cleanString(item['OT'].toString()) : '').toString();
      if (xTrackingNumber !== '') {
        return {
          //: item?.['Delivery_ID'] || '',
          //xTracking_number: item?.['OT'] || '',
          xTracking_number: xTrackingNumber,
          xTracking_number_a: (item && item['OT_A'] ?  this.cleanString(item['OT_A'].toString()) : '' ).toString(),
          //: item?.['OT_A'] || '',
          // : item?.['Referencia'] || '',
          xStatus : item?.['Estado(Tracking)'] || '',
          // : item?.['Seller'] || '',
          // : item?.['Seller_ID'] || '',
          // : item?.['Marketplace'] || '',
          xCarrier: item?.['Carrier'] || '',
          xDestination_name: item?.['Cliente_Final'] || '',
          xCreated_at: (item && item['Fecha_Creación'] ?  this.serialDateToJSDate(item['Fecha_Creación']) : '' ),
          // : item?.['Fecha_Entrega'] || '',
          // : item?.['Direccion_Origen'] || '',
          // : item?.['Colonia_Origen'] || '',
          // : item?.['Municipio_Origen'] || '',
          // : item?.['Estado_Origen'] || '',
          // : item?.['Codigo_postal_Origen'] || '',
          xDestination_address: item?.['Direccion_Destino'] || '',
          // : item?.['Colonia_Destino'] || '',
          // : item?.['Municipio_Destino'] || '',
          // : item?.['Estado_Destino'] || '',
          // : item?.['Codigo_postal_Destino'] || '',
          xWeight: item?.['Peso'] || '',
          // : item?.['Volumen'] || '',
          // : item?.['Peso Volumetrico/ Operador'] || '',
          // : item?.['Ancho'] || '',
          // : item?.['Alto'] || '',
          // : item?.['Largo'] || '',
          // : item?.['Bultos'] || '',
          xContent: item?.['Producto'] || '',
          // : item?.['Valor_Producto'] || '',
          // : item?.['Tipo_Contrato'] || '',
          xService: item?.['Servicio'] || '',
          // : item?.['ContraEntrega'] || '',
          // : item?.['Valor Recaudado'] || '',
          // : item?.['Flete'] || '',
          // : item?.['Cargo combustible'] || '',
          // : item?.['Cargos adicionales'] || '',
          xTotal_price: item?.['Total'] || '',
        };
      } else {
        return null; // Excluir elementos con xTracking_number vacío
      }
    }).filter((item: any) => item !== null); // Filtrar elementos nulos (elementos con xTracking_number vacío)
    return obj;

  }

  mapPaquetexpress(received: any): any[] {
    const obj = received.map((item: { [x: string]: any; }) => {
      const xTrackingNumber = (item && item['Rastreo'] ?  this.cleanString(item['Rastreo'].toString()) : '' ).toString();
      if (xTrackingNumber !== '') {
        return {
          // : item?.['Factura'] || '',
          // : item?.['Guía'] || '',
          //xTracking_number: item?.['Rastreo'] || '',
          xTracking_number: xTrackingNumber,
          xCreated_at: (item && item['Fecha de emisión'] ?  this.serialDateToJSDate(item['Fecha de emisión']) : '' ),
          // : item?.['Argumento'] || '',
          // : item?.['Manifiesto'] || '',
          // : item?.['Suc. Origen'] || '',
          // : item?.['Destino'] || '',
          // : item?.['Fecha Doc en línea'] || '',
          // : item?.['Fecha envio'] || '',
          // : item?.['Fecha Entrega'] || '',
          // : item?.['Referencia'] || '',
          // : item?.['Cte. org.'] || '',
          // : item?.['Nom. org.'] || '',
          // : item?.['Cte. dest.'] || '',
          xDestination_name: item?.['Nom. dest.'] || '',
          // : item?.['Estado'] || '',
          // : item?.['Código Postal'] || '',
          // : item?.['Localidad'] || '',
          // : item?.['Colonia'] || '',
          // : item?.['Calle'] || '',
          // : item?.['Número'] || '',
          xDestination_address : `${item?.['Calle'] || ''}, ${item?.['Colonia'] || ''}, ${item?.['Código Postal'] || ''}`,
          // : item?.['Importe'] || '',
          // : item?.['Modo de pago'] || '',
          // : item?.['Tipo de pago'] || '',
          // : item?.['Tipo entrega'] || '',
          // : item?.['Estatus'] || '',
          // : item?.['Cantidad'] || '',
          xWeight: item?.['Peso'] || '',
          // : item?.['Volumen'] || '',
          // : item?.['Tarifa'] || '',
          xContent: item?.['Contenido'] || '',
          // : item?.['Descripción'] || '',
          // : item?.['Flete'] || '',
          // : item?.['RAD'] || '',
          // : item?.['EAD'] || '',
          // : item?.['Seguro'] || '',
          // : item?.['Acuse'] || '',
          // : item?.['COD'] || '',
          // : item?.['Otros'] || '',
          xPrice: item?.['Subtotal'] || '',
          // : item?.['IVA'] || '',
          // : item?.['IVA Ret.'] || '',
          xTotal_price: item?.['Total'] || '',
          // : item?.['Observaciones'] || '',
          // : item?.['Persona recibe'] || '',
          // : item?.['Distancia KM'] || '',
          // : item?.['Persona envía'] || '',
          // : item?.['UUID'] || '',
        };
      } else {
        return null; // Excluir elementos con xTracking_number vacío
      }
    }).filter((item: any) => item !== null); // Filtrar elementos nulos (elementos con xTracking_number vacío)
    return obj;
  }

  mapEstafeta(received: any): any[] {
    //console.log('Estafeta XLS',received);
    const obj = received.map((item: { [x: string]: any; }) => {
      // Indices de las columnas en Excel, empezando en 1, el valor final del slice no se incluye
      const colPeso = Object.keys(item).slice(12, 14);       
      const sumPesoEstafeta = colPeso.reduce((total, columna) => (total*1) + (item[columna]*1), 0);

      const colCosto = Object.keys(item).slice(20, 53);       
      const sumCostoCobrado = colCosto.reduce((total, columna) => (total*1) + (item[columna]*1), 0);


      const xTrackingNumber = (item && item['Número de Guía'] ?  this.cleanString(item['Número de Guía'].toString()) : '' ).toString();
      const peso = Math.ceil(((item?.['Peso amparado'] || 0)*1)+((item?.['Sobrepeso'] || 0)*1));
      const volumetrico = Math.ceil((((item?.['Largo'] || 0)*1)+((item?.['Ancho'] || 0)*1)+((item?.['Alto'] || 0)*1))/5000);
      if (xTrackingNumber !== '') {
        return {
          xcPesoRegistrado:sumPesoEstafeta,
          xcCostoCobrado:sumCostoCobrado,
          // : item?.['Factura'] || '',
          // : item?.['Guía'] || '',
          //xTracking_number: item?.['Rastreo'] || '',
          xTracking_number: xTrackingNumber,
          xCreated_at: (item && item['Fecha'] ?  this.serialDateToJSDate(item['Fecha']) : '' ),
          // : item?.['Argumento'] || '',
          // : item?.['Manifiesto'] || '',
          // : item?.['Suc. Origen'] || '',
          // : item?.['Destino'] || '',
          // : item?.['Fecha Doc en línea'] || '',
          // : item?.['Fecha envio'] || '',
          // : item?.['Fecha Entrega'] || '',
          // : item?.['Referencia'] || '',
          // : item?.['Cte. org.'] || '',
          // : item?.['Nom. org.'] || '',
          // : item?.['Cte. dest.'] || '',
          xDestination_name: item?.['Destino'] || '',
          // : item?.['Estado'] || '',
          // : item?.['Código Postal'] || '',
          // : item?.['Localidad'] || '',
          // : item?.['Colonia'] || '',
          // : item?.['Calle'] || '',
          // : item?.['Número'] || '',
          xDestination_address : `${item?.['Destino'] || ''}`,
          // : item?.['Importe'] || '',
          // : item?.['Modo de pago'] || '',
          // : item?.['Tipo de pago'] || '',
          // : item?.['Tipo entrega'] || '',
          // : item?.['Estatus'] || '',
          // : item?.['Cantidad'] || '',
          xWeight: peso>volumetrico?peso:volumetrico,
          // : item?.['Volumen'] || '',
          // : item?.['Tarifa'] || '',
          xContent: '',
          // : item?.['Descripción'] || '',
          // : item?.['Flete'] || '',
          // : item?.['RAD'] || '',
          // : item?.['EAD'] || '',
          // : item?.['Seguro'] || '',
          // : item?.['Acuse'] || '',
          // : item?.['COD'] || '',
          // : item?.['Otros'] || '',
          xPrice: item?.['Importe'] || '',
          // : item?.['IVA'] || '',
          // : item?.['IVA Ret.'] || '',
          xTotal_price: ((item?.['Mto. Serv.'] || 0)*1)+((item?.['Monto Cam dom/F. plaza'] || 0)*1)+((item?.['Otros'] || 0)*1)+((item?.['Cargo Sobredemanda'] || 0)*1),
          // : item?.['Observaciones'] || '',
          // : item?.['Persona recibe'] || '',
          // : item?.['Distancia KM'] || '',
          // : item?.['Persona envía'] || '',
          // : item?.['UUID'] || '',
        };
      } else {
        return null; // Excluir elementos con xTracking_number vacío
      }
    }).filter((item: any) => item !== null); // Filtrar elementos nulos (elementos con xTracking_number vacío)
    return obj;
  }
  //#endregion

  serialDateToJSDate(serialDate: number): string {
    let formattedDate = '';
    if (!isNaN(serialDate)){ //Si es un número
      const adjustedSerialDate = (serialDate - 25569) * 86400 * 1000;
      let dateObj = new Date(adjustedSerialDate);

      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // +1 porque enero es 0
      const day = dateObj.getDate().toString().padStart(2, '0');
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');

      formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    }else{
      formattedDate = serialDate.toString();
    }
    return formattedDate;
  }

  cleanString(cadena:string) {
    //expresión regular para eliminar todos los caracteres que no sean letras o números
    return cadena.replace(/[^a-zA-Z0-9\-]/g, '');
  }
  

}
