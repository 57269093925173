<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Carga masiva: {{sTitulo}}  <small class="d-inline">{{sFileName!=''?'('+sFileName+')':''}}</small></h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'bulk-uploads'">
                            <mat-icon>pallet</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Envíos masivos </li>
                    <li class="breadcrumb-item">Carga masiva </li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <ng-template mat-tab-label>Carga masiva <mat-icon class="ms-1 ">upload</mat-icon>
                </ng-template>
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                        </mat-form-field>
                        <!-- <div class="d-inline">
                            <span *ngIf="dataList.length>0" [ngClass]="{'badge': true, 'bg-info': false, 'bg-primary': true }">{{dataList.length }} en total</span>
                            <span *ngIf="countDone.length>0" [ngClass]="{'badge': true, 'bg-info': false, 'bg-primary': true }">{{ countDone.length }} eaan total</span>
                        </div> -->
                        <div class="float-end">
                            <!-- <button mat-stroked-button class="btn btn-outline-secondary" matTooltip="Genera un envío masivo" [matTooltipShowDelay]="1000" (click)="openBulkUpload()" style="margin-right: 10px;"><mat-icon>upload</mat-icon><span class="btn-no-text"> Carga masiva</span></button> -->
                            <button *ngIf="countDone.length>0" mat-stroked-button class="btn btn-outline-secondary" matTooltip="Imprimir todas las guías compradas" [matTooltipShowDelay]="1000" style="margin-right: 10px;" (click)="viewAllPdf()"><mat-icon>print</mat-icon><span class="btn-no-text"> Imprimir</span></button>
                            <button *ngIf="countDone.length>0" mat-stroked-button class="btn btn-outline-secondary" matTooltip="Descargar todas las guías compradas" [matTooltipShowDelay]="1000" style="margin-right: 10px;" (click)="downloadAllPdf()"><mat-icon>picture_as_pdf</mat-icon><span class="btn-no-text"> Descargar</span></button>
                            <button (click)="exporter.exportTable('xlsx', {fileName:sTitulo })" mat-stroked-button class="btn btn-outline-secondary" matTooltip="Exportar a Excel" [matTooltipShowDelay]="1000" [hidden]="isLoading" style="margin-right: 10px;"> <mat-icon>download</mat-icon><span class="btn-no-text"> Descargar Excel </span></button>
                            <button *ngIf="dataList.length != countDone.length" disabled="{{isSendAll}}" mat-raised-button class="mt-2 mb-2 bg-success text-white" (click)="ruSure()">
                                <mat-icon>price_change</mat-icon> {{ sBtnMsjTotal }}
                            </button>
                        </div>
                    </div>
                    <div class="" *ngIf="bulk_upload_status=='in_progress'">
                        <b>Guías procesadas {{countDone.length}} de {{dataList.length}}</b>
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: {{percentProgress}}%">{{percentProgress}}%</div>
                        </div>
                    </div>
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="order" class="w-100" >
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> {{getDisplayedLabel('actions')}} </th>
                                <td mat-cell *matCellDef="let row" class="text-center col-2 pe-4" >
                                    <button mat-icon-button (click)="openDialogEdit(row)" matTooltip="{{ statusChangeTip(row.status, row.message) }}" [matTooltipPosition]="'above'" color="{{statusChangeClass(row.status)}}" [ngClass]="{'processing-icon': row.status=='in_progress' }">
                                        <mat-icon>{{ statusChangeIcon(row.status) }}</mat-icon>
                                    </button>
                                    <!-- <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" (click)="openDialogEdit(row)" color="primary">
                                        <mat-icon [hidden]="row.rol_name == 'admin'" >edit</mat-icon>
                                    </button> -->
                                </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="ORDER">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORDER')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORDER }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="tracking_number" *ngIf="showTrackingNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('tracking_number')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> <a class="a-ship fw-bold" target="_blank" href="https://tracking.buhologistics.com/?id={{row.tracking_number}}">{{row.tracking_number}}</a> </td>
                            </ng-container>
                            <ng-container matColumnDef="WAREHOUSE_CODE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('WAREHOUSE_CODE')}}  </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.WAREHOUSE_CODE }}  </td>
                            </ng-container>

                            <ng-container matColumnDef="ORIG_NAME">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_NAME')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_NAME }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_COMPANY">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_COMPANY')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_COMPANY }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_EMAIL">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_EMAIL')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_EMAIL }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_PHONE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_PHONE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_PHONE }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_STREET">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_STREET')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_STREET }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_NUMBER">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_NUMBER')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_NUMBER }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_POSTALCODE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_POSTALCODE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_POSTALCODE }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_SUBURB">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_SUBURB')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_SUBURB }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_CITY">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_CITY')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_CITY || '' }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_STATE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_STATE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_STATE || '' }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="ORIG_REFERENCE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ORIG_REFERENCE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.ORIG_REFERENCE }}  </td>
                            </ng-container>



                            <ng-container matColumnDef="DEST_NAME">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_NAME')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_NAME }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_COMPANY">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_COMPANY')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_COMPANY }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_EMAIL">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_EMAIL')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_EMAIL }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_PHONE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_PHONE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_PHONE }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_STREET">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_STREET')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_STREET }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_NUMBER">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_NUMBER')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_NUMBER }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_POSTALCODE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_POSTALCODE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_POSTALCODE }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_SUBURB">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_SUBURB')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_SUBURB }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_CITY">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_CITY')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_CITY || '' }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_STATE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_STATE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_STATE || '' }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="DEST_REFERENCE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('DEST_REFERENCE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.DEST_REFERENCE }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="RATE_PROVIDER">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('RATE_PROVIDER')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.RATE_PROVIDER || '' }}  <small class="d-inline text-muted">{{boughtCheapest(row,'provider')}}</small></td>
                            </ng-container>
                            <ng-container matColumnDef="RATE_CARRIER">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('RATE_CARRIER')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.RATE_CARRIER }}  <small class="d-inline text-muted">{{boughtCheapest(row,'carrier')}}</small></td>
                            </ng-container>
                            <ng-container matColumnDef="RATE_SERVICE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('RATE_SERVICE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.RATE_SERVICE }} <small class="d-inline text-muted">{{boughtCheapest(row,'service')}}</small></td>
                            </ng-container>
                            <ng-container matColumnDef="PKG_ORDER_NUMBER">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('PKG_ORDER_NUMBER')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.PKG_ORDER_NUMBER }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="PKG_CONTENT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('PKG_CONTENT')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.PKG_CONTENT }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="PKG_TYPE">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('PKG_TYPE')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.PKG_TYPE }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="PKG_WEIGHT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('PKG_WEIGHT')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.PKG_WEIGHT }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="PKG_LENGTH">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('PKG_LENGTH')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.PKG_LENGTH }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="PKG_WIDTH">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('PKG_WIDTH')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.PKG_WIDTH }}  </td>
                            </ng-container>
                            <ng-container matColumnDef="PKG_HEIGHT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('PKG_HEIGHT')}} </th>
                                <td class="col-2 pe-4" mat-cell *matCellDef="let row"> {{ row.PKG_HEIGHT }}  </td>
                            </ng-container>


                            
                            <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                            <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (dblclick)="onRowDoubleClick(row)"></tr>
                            
                            <tr class="mat-row" *matNoDataRow>
                                <td *ngIf="dataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                <td *ngIf="dataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                            </tr>
                                
                        </table>                                    
                        <!-- <mat-paginator [pageSizeOptions]="getPageSizeOptions()" ></mat-paginator> -->
                        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" pageSize="25"></mat-paginator>
                    
                        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-bar color="primary" mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>