import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ClabeTransfersComponent } from '../dialogs/clabe-transfers/clabe-transfers.component';
import { StpPendingOrder } from 'src/app/models/Stp.model';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [CurrencyPipe]
})
export class HeaderComponent implements OnInit {

  @Output() menuEvent = new EventEmitter<boolean>();

  userObj!: UserModel;
  roleName!: any;
  userCredit!: string;
  currency!: string;
  pendingsOrders: Array<StpPendingOrder>=[];
  clabe!:any;
  clickEventsubscription!: Subscription;

  get favData():any { return this.userS.sharedData; } set favData(value: any) { this.userS.sharedData = value; }
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private userS: UsersService,
    private matDialog: MatDialog,
    private currencyPipe: CurrencyPipe
  ) {
    this.clickEventsubscription = this.userS.getCreditEvent().subscribe(() => {
      this.getClabe();
      this.getUserCredit();
      this.getPendingsOrders();
    })
  }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.roleName = environment.roles.find(el => el.id === this.userObj.id_rol)?.rol;
    this.getClabe();
    this.getUserCredit();
    this.getPendingsOrders();
  }

  menuToggle() {
    this.menuEvent.emit(true);
  }

  logOut() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }

  goToShipmentAdd() {
    this.favData = null;
    this.router.navigateByUrl('/admin/shipments/add');
  }

  openDialogAddCredit() {
    //if(this.clabe &&  this.clabe.length>0){
      this.matDialog.open(ClabeTransfersComponent, {
        panelClass: 'dialogs-main',
        data: {
          company: {id_company: this.userObj.id_company},
          userCredit: this.userCredit,
          currency: this.currency,
          pendingsOrders: this.pendingsOrders,
        }
      }).afterClosed().subscribe(
        resp => {        
          this.getUserCredit();
          this.getPendingsOrders();
        }
      );
    // }else{
    //   Swal.fire({
    //     icon: 'warning',
    //     title: '¿Desea agregar más crédito a su cuenta?',
    //     text: 'Si desea agregar más créditos a su cuenta para poder seguir utilizando la plataforma Wing, por favor, contáctenos vía correo electrónico a "hola@buhologistics.com" para asignar su cuenta CLABE de transferencias.',
    //     heightAuto: false
    //   });
    // }
  }

  getUserCredit() {
    this.userS.getCreditCompany().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.userCredit = result.data.creditos;
          this.currency = result.data.currency;
        }
      },
      (err:any) => {
        console.log(err);
      }
    )
  }
  getPendingsOrders() {
    this.userS.getPendingsOrders(this.userObj.id_company).subscribe(
      (result:any) => {
        this.pendingsOrders = result;
      },
      (err:any) => {
        console.log(err);
      }
    )
  }
  
  getClabe() {
    this.userS.getCuentaClabe(this.userObj.id_company).subscribe(
      (result:any) => {
        this.clabe = result.data;
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  tooltipPendings(){
    let sTooltip='';
    if(this.pendingsOrders && this.pendingsOrders.length>0)
    {
      if(this.pendingsOrders.length==1)
        sTooltip=`Abono pendiente: ${this.currencyPipe.transform(this.pendingsOrders[0].amount, 'USD', 'symbol', '1.2-2')}`;
      else
        sTooltip='Abonos pendientes';
    }
    return sTooltip;
  }
}
