import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {

  @Input() data: any;

  chartData: any;

  colorsMapping: any = {
    'paquetexpress': { color: '#223e88' },
    'dhl': { color: '#ffcc01' },
    'afimex': { color: '#9108b2' },
    'fedex': { color: '#ff6200' },
    'dhl mexico': { color: '#00a8e8' },
    'mx99': { color: '#85c440' },
    '99 minutos mexico': { color: '#85c440' },
    '99minutos': { color: '#85c440' },
    'dhlm': { color: '#3498db' },
    'estafeta mexico': { color: '#2ecc71' },
    'est': { color: '#e74c3c' }
  };

  constructor() { }

  ngOnInit(): void {
    this.chartData = this.donutChart();
  }

  donutChart() {
    const series = this.data.map((item: any) => item.percentage_of_total);
    const labels = this.data.map((item: any) => item.carrier);
    const colors = this.data.map((item: any) => this.getColorForCarrier(item.carrier));

    return {
      series: series,
      chart: {
        type: 'donut',
      },
      tooltip: {
        y: {
          formatter: function(val:any) {
            return val.toFixed(2) + "%"
          },
          title: {
            formatter: function (seriesName:any) {
              return seriesName
            }
          }
        }
      },
      labels: labels,
      colors: colors,
      legend: {
        position: 'left',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };
  }

  getColorForCarrier(carrier: string): string {
    carrier = carrier.toLowerCase();
    for (let key in this.colorsMapping) {
      if (carrier.includes(key)) {
        return this.colorsMapping[key].color;
      }
    }
    return '#000000';
  }

}
