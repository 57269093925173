import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyModel } from 'src/app/models/company.model';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.scss']
})
export class UsersAddComponent implements OnInit {

  hideEmail = true;
  hideEmailConfirm = true;
  selected : any = [];
  companiesList! : Array<CompanyModel>;
  companyEmpty : CompanyModel = new CompanyModel;
  providersList: any = [];
  carriersList: any = [];
  carriersListFiltered: any = [];
  carriersListTemp: any = [];

  rolesList!: {
    id: number,
    rol: string
  }[];

  tipoCom!: {
    id: string,
    nombre: string
  }[];
  tipoComSel: string = "";

  formUser!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private customVal: CustomValidatorsService,
    private userS: UsersService,
    private dialogRef: MatDialogRef<UsersAddComponent>
  ) {  }

  ngOnInit(): void {
    this.createFormUser();
    this.rolesList = environment.roles;
    this.tipoCom = [{"id": "porc", "nombre": "Porcentaje"},{"id": "cant", "nombre": "Cantidad"}];
    this.getConfigList();
  }

  createFormUser() {
    this.formUser = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/)]],
      email: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],      
      pass: ['', [Validators.required, Validators.minLength(8)]],
      passConfirm: ['', [Validators.required, Validators.minLength(8)]],
      id_rol: ['', [Validators.required]],
      providers:['',[]],
      carriers:['',[]],
      tipo_comision:['',[]],
      cant_comision:['',[]],
      id_company:[''],
      phone:[0],
      email2fa:[0],
      sms2fa:[0]
    },{
      validators: this.customVal.samePasswords("pass", "passConfirm")
    });
  }

  // setBuhoConfig(id:number){
  //   this.getCompaniesList(environment.role_buho_id != id);
  // }

  // getCompaniesList(isClient : boolean) {
  //   this.companyEmpty.name = "Sin compañia";
  //   this.companiesList = [this.companyEmpty];
  //   if(isClient) {
  //     this.userS.getCompanies().subscribe(
  //       (result:any) => {
  //         if(result.length > 0) {
  //           this.companiesList = this.companiesList.concat(result);
  //         }
  //       },
  //       (err:any) => {
  //         console.log(err);
  //       }
  //     );
  //   }
  // }

  getCompaniesList() {
    this.companyEmpty.name = "Sin compañia";
    this.companiesList = [this.companyEmpty];
    this.userS.getCompanies().subscribe(
      (result: any) => {
        if (result.length > 0) {
          this.companiesList = this.companiesList.concat(result);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  unselect(obj : any): void {
    obj = undefined;
 }

  getConfigList() {
    this.userS.getConfigList().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.providersList = result.data.providers;
          this.carriersList = result.data.carriers;        
        }
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getCarriers() {
    this.carriersListFiltered = [];
    if(this.formUser.value.providers.length > 0){
      this.carriersListTemp = this.carriersList.filter((c:any) => this.formUser.value.providers.includes(c.id_provider)).map((c:any)=> ({ id : c.id , carrier: c.carrier}));
      this.carriersListTemp.forEach( (element:any) => {
        if(!this.carriersListFiltered.find((e:any)=>  e.carrier == element.carrier )){
          this.carriersListFiltered.push(element);
        }
      });
    }
  }

  setTipoCom() {
    this.tipoComSel = this.formUser.value.tipo_comision;
  }
  
  ruSure() {
    if(this.formUser.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.formUser.value.comision = {tipo: this.formUser.value.tipo_comision, cantidad: this.formUser.value.cant_comision};
    if( !this.formUser.get('email2fa')?.value ){ this.formUser.get('sms2fa')?.setValue(0) };
    this.userS.saveUser(this.formUser.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Usuario guardado de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        if(err.error.status.message.indexOf("ER_DUP_ENTRY") > -1)
        {
          Swal.fire({
            icon: 'error',
            title: 'Usuario duplicado',
            text: 'Ya existe un usuario con ese email.'
          });
        }
        else {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      }
    );
  }

  resetForm() {
    this.selected = [];
    this.formUser.reset();
  }

}
