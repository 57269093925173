<div class="modal-header">
    <h1 class="modal-title">Edita usuario</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="alert alert-warning" *ngIf="isUserLogged">
        Para ver reflejados los cambios cierra la sesión y vuelve a ingresar al sistema.
    </div>
    <h4>Edita la información del usuario: {{data.userSel.name}}</h4>
    <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>account_circle</mat-icon>
                    <mat-error *ngIf="formUser.get('name')?.errors">
                        <div *ngIf="formUser.get('name')?.errors?.required">El nombre es requerido.</div>
                        <div *ngIf="formUser.get('name')?.errors?.minlength">El nombre debe tener al menos 3 caracteres.</div>
                        <div *ngIf="formUser.get('name')?.errors?.pattern">El nombre solo puede contener letras y espacios.</div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Selecciona perfil</mat-label>
                    <!-- <mat-select formControlName="id_rol" (selectionChange)="setBuhoConfig($event.value);"> -->
                    <mat-select formControlName="id_rol" (selectionChange)="getCompaniesList();">
                        <mat-option *ngFor="let rol of rolesList" [value]="rol.id">{{rol.rol}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="formUser.get('id_rol')?.errors">
                        <div *ngIf="formUser.get('id_rol')?.errors?.required">El perfil es requerido.</div>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Selecciona compañia</mat-label>
                    <mat-select formControlName="id_company">
                        <mat-option *ngFor="let c of companiesList" [value]="c.id">{{c.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <div>Verificación en dos pasos</div>
                <div class="row mt-2">
                    <div class="col-sm-6  d-flex justify-content-start align-items-center">
                        <mat-slide-toggle formControlName="email2fa" matTooltip="¿Activar/Desactivar verificación en dos pasos?" [matTooltipPosition]="'above'" color="primary" labelPosition="before" style="margin-right: 10px;">Email</mat-slide-toggle>
                        <mat-slide-toggle *ngIf="formUser.get('email2fa')?.value" formControlName="sms2fa" matTooltip="¿Activar verificación en dos pasos por sms?" [matTooltipPosition]="'above'" color="primary" labelPosition="before">SMS</mat-slide-toggle>
                        <mat-form-field *ngIf="formUser.get('email2fa')?.value" class="ms-3 w-100" appearance="outline">
                            <mat-label>Telefono</mat-label>
                            <input matInput formControlName="phone" [maxlength]="10">
                            <mat-icon matSuffix>call</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>          
        <div *ngIf="false" class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Proveedores</mat-label>
                    <mat-select (selectionChange)="getCarriers();" formControlName="providers" multiple>
                        <mat-option *ngFor="let p of providersList" [value]="p.id">{{p.provider}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Paqueterías</mat-label>
                    <mat-select formControlName="carriers" multiple>
                        <mat-option *ngFor="let c of carriersListFiltered" [value]="c.id">{{c.carrier}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="false" class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Tipo de comisión</mat-label>
                    <mat-select (selectionChange)="setTipoCom();" formControlName="tipo_comision">
                        <mat-option *ngFor="let com of tipoCom" [value]="com.id">{{com.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Comisión</mat-label>
                    <input matInput formControlName="cant_comision">
                    <mat-icon matSuffix [hidden]="tipoComSel!='porc'">percent</mat-icon>
                    <mat-icon matSuffix [hidden]="tipoComSel!='cant'">attach_money</mat-icon>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-stroked-button color="primary" class="me-3 mb-1" type="button" (click)="openDialogPassword()">
        Cambiar contraseña <mat-icon>password</mat-icon>
    </button>
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>