import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { last } from 'rxjs';
import { CountryModel } from 'src/app/models/company.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-companies-add',
  templateUrl: './companies-add.component.html',
  styleUrls: ['./companies-add.component.scss']
})
export class CompaniesAddComponent implements OnInit {

  formCreate!: FormGroup;
  countriesList: any = [];
  selectedPais: any;
  commissionTypesList: any = [];
  tipoCom!: {
    id: string,
    nombre: string
  }[];
  tipoComSel: string = "";
  selected : any = [];

  providersList: any = [];
  carriersList: any = [];
  carriersListFiltered: any = [];
  carriersListTemp: any = [];
  
  constructor(
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<CompaniesAddComponent>
  ) {  }

  ngOnInit(): void {
    this.tipoCom = [{"id": "porc", "nombre": "Porcentaje"},{"id": "cant", "nombre": "Cantidad"}];
    this.getCommissionType();
    this.getCountries();
    this.createForm();
    this.selectISOCountry();
    this.getConfigList();
  }

  createForm() {
    this.formCreate = this.fb.group({
      key: ['', [ Validators.required, Validators.maxLength(15)]],
      name: ['', [ Validators.required, Validators.minLength(3), Validators.maxLength(45)]],
      commission_type: ['',[Validators.required]],
      country: ['', [ Validators.maxLength(100)]],
      iso_country: ['', [ Validators.required, Validators.maxLength(3)]],
      currency: ['', [ Validators.maxLength(3)]],
      active: ['',[]],
      commission_tipo: ['', []],
      commission_cantidad: ['',[]],
      credit: ['',[]],
      providers:['',[]],
      carriers:['',[]],
      opt_api_cre: [false,[]],
      opt_api_com: [false,[]],
      opt_pla_cre: [false,[]],
      opt_pla_com: [false,[]],
      international_shipping: [false,[]],
      commission_int_cantidad: ['',[]]
    });
  }

  onNameBlur() {
    let name = this.formCreate.get('name')?.value;
    console.log('name: ',name);
    if (name && name.length >= 3) {
      const randomPart = this.randomString(5); // Genera el string aleatorio
      const newKey = name.substr(0, 3) +'-'+ randomPart; // Concatena los primeros 3 caracteres con el string aleatorio
      this.formCreate.get('key')?.patchValue(newKey.toUpperCase()); // Actualiza el valor del segundo input
    }
  }

  onCreditBlur() {
    let val = this.formCreate.get('credit')?.value;
    console.log('val onCreditBlur: ',val);
    if (val!= '' && val >= 0) {
      this.formCreate.get('opt_api_cre')?.patchValue(true);
      this.formCreate.get('opt_pla_cre')?.patchValue(true);
    }
  }

  onCommissionTypeBlur() {
    let val = this.formCreate.get('commission_type')?.value;
    console.log('val onCommissionTypeBlur: ',val);
    if (val!= '' && val >= 0) {
      this.formCreate.get('opt_api_com')?.patchValue(true);
      this.formCreate.get('opt_pla_com')?.patchValue(true);
    }
  }

  randomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  selectISOCountry(){
    this.formCreate.get('iso_country')?.valueChanges.subscribe(iso => {
      if (iso) {
        // Encuentra el país seleccionado en la lista de países
        this.selectedPais = this.countriesList.find((pais: CountryModel) => pais.iso_country === iso);
        console.log(this.selectedPais);
        // Actualiza el valor del campo currency en el formulario
        this.formCreate.get('currency')?.setValue(this.selectedPais.currency);
        this.formCreate.get('country')?.setValue(this.selectedPais.country);
      }
    });
  }

  getCommissionType() {
    this.userS.getCommissionTypes().subscribe(
      (result:any) => {
        this.commissionTypesList = result;
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getLastWord(words:string):string  {
    let aWords=words.split(' ');
    let lastWord=aWords[aWords.length-1];
    return `${lastWord.charAt(0).toUpperCase()}${lastWord.slice(1)}`;
  }

  getCountries() {
    this.userS.getCountries().subscribe(
      (result:any) => {
        this.countriesList = result;
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getConfigList() {
    this.userS.getConfigList().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.providersList = result.data.providers;
          this.carriersList = result.data.carriers;        
        }
      },
      (err:any) => {
        console.log(err);
      }
    )
  }


  getCarriers() {
    this.carriersListFiltered = [];
    if(this.formCreate.value.providers.length > 0){
      this.carriersListTemp = this.carriersList.filter((c:any) => this.formCreate.value.providers.includes(c.id_provider)).map((c:any)=> ({ id : c.id , carrier: c.carrier}));
      this.carriersListTemp.forEach( (element:any) => {
        if(!this.carriersListFiltered.find((e:any)=>  e.carrier == element.carrier )){
          this.carriersListFiltered.push(element);
        }
      });
    }
  }

  setTipoCom(e:any) {
    //console.log(e);
    this.tipoComSel = e.value;//this.formCreate.value.tipo_comision;
  }
  
  ruSure() {
    if(this.formCreate.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        //console.log(this.formCreate.value);
        this.save();
      }
    });
  }

  save() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.userS.saveCompany(this.formCreate.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Compañía guardada de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {        
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
      }
    );
  }

  resetForm() {
    this.selected = [];
    this.formCreate.reset();
  }

}
