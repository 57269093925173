import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { CompanyCompleteModel } from 'src/app/models/company.model';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CompaniesAddComponent } from '../shared/dialogs/companies-add/companies-add.component';
import { CompaniesEditComponent } from '../shared/dialogs/companies-edit/companies-edit.component';
import { ClabeTransfersComponent } from '../shared/dialogs/clabe-transfers/clabe-transfers.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  private idRoleAdmin = environment.role_admin_id;
  dataList = new Array<CompanyCompleteModel>();
  isLoading = true;

  displayedColumns: string[] = ['id', 'name', 'commission', 'country', 'currency', 'credit', 'international_shipping', 'actions'];
  dataSource!: MatTableDataSource<CompanyCompleteModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private matDialog: MatDialog,
    private _titleService: Title
  ) { }

  ngOnInit(): void {
    this.getList();
  }
  getList() {
    this.userS.getCompaniesList().subscribe(
      (result : any) => {
        this.dataList = result;
        this.dataSource = new MatTableDataSource(this.dataList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id:any): void {
    // if (this.userS.getUser().id_rol != this.idRoleAdmin) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: '¡No tienes permitido realizar esta acción!',        
    //   })
    // }
    // else {
    //   Swal.fire({
    //     title: '¿Está seguro que desea eliminar el registro?',
    //     icon: 'warning',
    //     showDenyButton: false,
    //     showCancelButton: true,
    //     confirmButtonText: `Confirmar`,
    //     cancelButtonText: `Cancelar`
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       Swal.fire({
    //         icon: 'info',
    //         title: 'Eliminando...',
    //         allowEscapeKey: false,
    //         allowOutsideClick: false,
    //         showConfirmButton: false,
    //         didOpen: () => {
    //           Swal.showLoading(null);
    //         }
    //       });
    //       this.userS.deleteUser(id).subscribe(
    //         (result:any) => {
    //           if (result.message == "Success")                
    //           Swal.fire({
    //             icon: 'success',
    //             title: 'Eliminado',
    //             text: 'Usuario eliminado de manera correcta!'
    //           }).then(() => {
    //             this.getList();
    //           });
    //         },
    //         (err:any) => {
    //           Swal.fire({
    //             icon: 'error',
    //             title: 'Error al eliminar',
    //             text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
    //           });
    //         }
    //       );
    //     }
    //   });
    // }
  }

  isAdmin() {
    return (this.userS.getUser().id_rol == this.idRoleAdmin);
  }

  activate(id: number, active: number, fullname: string) {
    var action = active == 1 ? "desactivado" : "activado";
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.userS.changeStatusCompany(id, active == 1 ? 0 : 1).subscribe(
      (result:any) => {
        if (result.message == "Success")
          Swal.fire({
            icon: 'success',
            title: 'Actualizado',
            text: 'Se ha ' + action + ' a ' + fullname + ' de manera correcta!'
          }).then(() => {
            this.getList();
          });
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar',
          text: 'Ocurrió un error al actualizar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  

  openDialogCreate() {
    this.matDialog.open(CompaniesAddComponent, {panelClass: 'dialogs-main'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getList();        
      }
    );
  }

  openDialogEdit(company: CompanyCompleteModel) {
    this.matDialog.open(CompaniesEditComponent, {
      panelClass: 'dialogs-main',
      data: {
        company: company
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getList();        
      }
    );
  }

  openDialogClabe(company: CompanyCompleteModel) {
    this.matDialog.open(ClabeTransfersComponent, {
      panelClass: 'dialogs-main',
      data: {
        company: company,
        userCredit: company.credit,
        currency: company.currency,
        pendingsOrders: [],
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getList();        
      }
    );
  }

}