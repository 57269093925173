import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { map, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopifyService {

  private apiURL = environment.API_URL;

  constructor(
    private main: MainService,
    private http: HttpClient

  ) { }

  auth(shop:string) {
    return this.main.getRequest(`/shopify/auth?shop=${shop}`).pipe(
    // return this.main.ecomGetRequest(`/shopify/auth?shop=${shop}`).pipe(
      map((resp: any) => {
        if(resp.message == "Success") {
          return resp.data
        }     
      })
    );
  }

  generateToken(registerForm: any) {
    return this.main.postRequest(registerForm ,"/shopify/generate-token").pipe(
      map((resp: any) => {
        if(resp.message == "Success") {
          return resp.data.token
        }     
      })
    );
  }

  saveStore(shop: any, token: string | null): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', token ? `Bearer ${token}` : '');
    return this.http.post(`${this.apiURL}/shopify/save-store`, shop, { headers }).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  getStoreList() {
    return this.main.getRequest("/shopify/get-stores").pipe(
      map((resp: any) => {
        if(resp.message == "Success") {
          return resp.data
        }     
      })
    );
  }

  getOrdersList() {
    return this.main.getRequest("/shopify/get-orders").pipe(
      map((resp: any) => {
        if(resp.message == "Success") {
          return resp.data
        }     
      })
    );
  }

  deleteStore(id: string) {
    return this.main.deleteRequest(`/shopify/delete-store/${ id }`);
  }

}
