import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ShipmentUnsuccessfully } from 'src/app/models/shipment.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipment-orders',
  templateUrl: './shipment-orders.component.html',
  styleUrls: ['./shipment-orders.component.scss']
})
export class ShipmentOrdersComponent implements OnInit {
  dataList =  new Array<ShipmentUnsuccessfully>();
  isLoading = true;
  base64!:string;

  //#region Filtros
  dateNow : Date = new Date();
  dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
  
  newDateTodayI : Date | null = null;//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDay());
  minDateI : Date =  new Date(2022, 0, 1);
  maxDateI : Date =  new Date();
  dateIDis=true;
  newDateTodayF : Date | null = null//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
  minDateF : Date =  new Date(2022, 0, 1);
  maxDateF : Date =  new Date();
  dateFDis=true;

  showFilter=false;
  selFechas = new FormControl('');  
    
  preLoadHidden = true;  
  //#endregion
  
  formSearch!: FormGroup;
  txtOrderNumber = new FormControl('');  

  displayedColumns: string[] = ['last_created_at', 'order_number', 'company_name', 'records', 'actions'];
  
  dataSource!: MatTableDataSource<ShipmentUnsuccessfully>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  get shipmentUnsucData():any { return this.userS.sharedOrderUnsuccessfully; } set shipmentUnsucData(value: any) { this.userS.sharedOrderUnsuccessfully = value; }
  
  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    //No cargar el listado de manera inicial
    //this.getBillingDataList(); 
    this.createForms();
    this.formSearch.get('selFechas')?.setValue('2');
    //console.log(this.formSearch.get('selFechas')?.value);
    this.onchangeFechas(this.formSearch.get('selFechas'));

    this.isLoading = false; //porque no se carga al principio los datos
    //this.newDateToday.setDate(this.newDateToday.getDate() + 1);
  }

  
  createForms() {
    this.formSearch = this.fb.group({
      txtOrderNumber: ['', [ ]],
      selFechas: ['', [ Validators.required]],
      selFechaI: [{value: '', disabled: true }],
      selFechaF: [{value: '', disabled: true}],
    });

    this.formSearch.get('txtOrderNumber')?.valueChanges.subscribe((value) => {
      if (value) {
        this.formSearch.get('selFechas')?.disable();
      } else {
        this.formSearch.get('selFechas')?.enable();
      }
    });
  }

  getDataList(date_i : string|null = null, date_f : string|null = null, order_number : string) {
    this.userS.getOrdersUnsuccessfully(date_i, date_f, order_number).subscribe(
      (result : any) => {

        if(result.data!=undefined)
        {
          this.dataList = result.data;
        }else{
          this.dataList = new Array<ShipmentUnsuccessfully>();
        }
        
        this.dataSource = new MatTableDataSource(this.dataList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.pageSize=this.dataList.length;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.preLoadHidden = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }


  

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  searchFilter() {
    this.isLoading = true;
    let date_i:string|null, date_f:string|null, users:string|null, companies:string|null;
    let ordNum:string;

    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);

    ordNum = this.formSearch.get('txtOrderNumber')?.value;
    this.getDataList(date_i, date_f, ordNum);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onchangedateI(e: Date): void {
    this.newDateTodayI = e;
    console.log('Inicio', e, 'Fin', this.newDateTodayF);
    if(this.newDateTodayF!=null && this.newDateTodayF!.getTime()<e.getTime()){
      this.newDateTodayF=null;
      this.formSearch.get('selFechaF')?.setValue(this.newDateTodayF);
    }
  }
  onchangedateF(e: Date): void {
    this.newDateTodayF = e;
    console.log('Fin', e, 'Inicio', this.newDateTodayI);
    if(this.newDateTodayI!=null && this.newDateTodayI!.getTime()>e.getTime()){
      this.newDateTodayI=null;
      this.formSearch.get('selFechaI')?.setValue(this.newDateTodayI);
    }
  }


  onchangeFechas(change:any) {
    //console.log(change);
    switch (change.value) {
      case '1'://Hoy
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '2'://Este mes
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1);
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '3'://Elegir fechas
        this.dateIDis=false;
        this.dateFDis=false;
        break;
      default:
        break;
    }
    this.formSearch.get('txtOrderNumber')?.setValue('');
    this.formSearch.get('selFechaI')?.setValue(this.newDateTodayI);
    this.formSearch.get('selFechaF')?.setValue(this.newDateTodayF);
  }

  getPageSizeOptions(): number[]{
    if(this.dataList.length>10)
    return [10,50,100,this.dataList.length];
    else
    return [10,50,100];
  }

  formatItem(item:any){
    let resultado="";
    if (Array.isArray(item)) {
      item.forEach((element) => {
        if(resultado!=""){
          resultado+= `, `;
        }
        resultado+= `${element}`;
      });
    } else if (typeof item === 'object') {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const value = item[key];
          if(resultado!=""){
            resultado+= `, `;
          }
          resultado+= `${key}: ${value}`;
          //console.log(`Propiedad ${key}: ${value}`);
        }
      }
    }else {
      return item;
    }
    return resultado;
  }

  borrarOrderNumber() {
    if (this.formSearch.get('selFechas')?.disabled) {
      this.formSearch.get('txtOrderNumber')?.setValue('');
    }
  }

  getDetails(obj: ShipmentUnsuccessfully) {
    this.shipmentUnsucData = obj;
    this.goToDetail();
  }
  goToDetail() {
    this.router.navigateByUrl('/admin/shipment-order');
    window.scrollTo(0, 0);
  }
}
