import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map } from 'rxjs'

import { MainService } from './main.service';
import { Pickup, CarrierData, CollectableShipment, WarehousePickupReceipt } from '../models/shipment.model'


@Injectable({
  providedIn: 'root'
})
export class PickupsService {
  private shipmentPipe: CollectableShipment | null = null;

  constructor(private main: MainService) { }

  /* Observable<Array<CarrierData>> getCarrierData(0):
     GET-retrieves carriers pickup schedules' data from the DB
     > carrierData: an Observable array with data from each carrier. */
  getCarrierData(): Observable<Array<CarrierData>> {
    return this.main.getRequest('/pickup/getCarrierData').pipe(
      map((response: any) => {
        let carrierData: Array<CarrierData> = [];
        if (response.message == "Success") {
          response.data.forEach((x: any) => {
            carrierData.push(x);
          });
        }
        return carrierData;
      })
    );
  }

  /* Observable<Array<CollectableShipment>> getCollectableShipments(0):
     GETs from the DB the shipments that can be selected to arrange a pickup.
     > shipmentList: The list of collectable shipments according to the DB. */
  getCollectableShipments(): Observable<Array<CollectableShipment>> {
    return this.main.getRequest('/pickup/getCollectableShipments').pipe(
      map((response: any) => {
        let shipmentList: Array<CollectableShipment> = [];
        if (response.message == "Success") {
          shipmentList = response.data.map((x: any) => {
            // Parse addresses and packages JSON
            const temp = JSON.parse(x.from_address),
                  temp2 = JSON.parse(x.destination),
                  temp3 = JSON.parse(x.packages);

            const y: CollectableShipment = {
              address: {
                city: temp.city,
                country: "MX",
                email: temp.email,
                neighborhood: temp.suburb,
                number: temp.number,
                phone: temp.phone,
                postCode: temp.postalCode,
                reference: temp.reference,
                rfc: temp.rfc,
                state: temp.state,
                street: temp.street
              },
              carrier: x.carrier,
              company: temp.company,
              creationDate: this.timestampToJSDate(x.created_at),
              destination: {
                city: temp2.city,
                country: "MX",
                neighborhood: temp2.suburb,
                number: temp2.number,
                phone: temp2.phone,
                postCode: temp2.postalCode,
                state: temp2.state,
                street: temp2.street
              },
              id: x.id,
              name: temp.name,
              package: {
                height: temp3[0].height,
                length: temp3[0].length,
                width: temp3[0].width
              },
              shipmentID: x.order_id,
              trackingNumber: x.tracking_number,
              weight: temp3[0].weight
            };

            return y;
          });
        }

        return shipmentList;
    }));
  }

  /* Observable<Array<any>> getManifestElements(1):
     GETs the elements included in the manifest of a given pickup ID.
     TODO: Change to get box values.
     < x: The ID of the pickup appointment. */
  getManifestElements(x: number): Observable<Array<any>> {
    return this.main.getRequest(`/pickup/getPMElementsFromID/${x}`).pipe(
      map((response: any) => {
        let manifestData: Array<any> = [];
        if (response.message == "Success") {
          manifestData = response.data.map((x: any) => ([
            x.id_pickup,
            x.id_shipment,
            x.carrier,
            x.tracking_number
          ]));
        }
        return manifestData;
    }));
  }

  /* Observable<Array<Pickup>> fetchPickups(0):
     GET-retrieves pickup appointments from the DB that have not happened yet
     and helps filter information to confirm times.
     > pickupData: an Observable array with data from each pickup appointment. */
  getPickups(): Observable<Array<Pickup>> {
    return this.main.getRequest('/pickup/getPendingPickups').pipe(
      map((response: any) => {
        let pickupData: Array<Pickup> = [];
        if (response.message == "Success") {
          pickupData = response.data.map((x: any) => ({
            address: x.address,
            boxes: x.boxes,
            carrier: x.carrier,
            confirmationID: x.confirmation_id,
            creationDate: this.timestampToJSDate(x.created_at),
            date: this.timestampToJSDate(x.pickup_date),
            endTime: x.pickup_end_time,
            fee: x.pickup_fee,
            id: x.id,
            manifestURL: x.manifest_url,
            startTime: x.pickup_start_time,
            textCDate: this.format(x.created_at),
            textDate: this.format(x.pickup_date),
            user: x.name,
            weight: x.weight
          })) as Array<Pickup>;
        }
        return pickupData;
    }));
  }

  /* any|null receiveShipment(1):
     Receives shipment's data from the pipe and clears it.
     > data: The data to be recieved. */
  receiveShipment(): CollectableShipment | null {
    const data: CollectableShipment | null = this.shipmentPipe;
    this.shipmentPipe = null;
    return data;
  }

  /* void sendShipment(1):
     Adds shipment's data to the pipe to be received by the Pickups Form component.
     < data: The data to be sent. */
  sendShipment(data: any): void {
    // Parse destination address that comes as string.
    const dest: any = this.parseAddress(data.destination);

    this.shipmentPipe = {
      address: {
        city: data.city,
        country: "MX",
        email: data.email,
        neighborhood: data.neighborhood,
        number: data.number,
        phone: data.phone,
        postCode: data.postCode,
        reference: data.reference,
        rfc: data.rfc,
        state: data.state,
        street: data.street
      },
      carrier: data.carrier,
      company: data.company,
      creationDate: this.timestampToJSDate(data.createdAt),
      destination: {
        city: dest.city,
        country: dest.country,
        neighborhood: dest.neighborhood,
        number: dest.number,
        phone: dest.phone,
        postCode: dest.postCode,
        state: dest.state,
        street: dest.street
      },
      id: data.id,
      name: data.name,
      shipmentID: data.shipmentID,
      package: {
        height: data.package.height,
        length: data.package.length,
        width: data.package.width
      },
      trackingNumber: data.trackingNumber,
      weight: data.weight
    } as CollectableShipment;
  }


  /* async Promise<any> generateManifest(1):
     POSTs request to create a manifest or bill of lading with the given data.
     < data: An array of shipments' data to be picked up.
     > response.filepath: The filepath of the newly generated manifest. */
  async generateManifest(data: any[]): Promise<any> {
    try {
      const response: any = await firstValueFrom(this.main.postRequest(data, '/pickup/generateManifest'));
  
      if (response?.filepath) return response.filepath;
      else throw new Error("No filepath received.");
    } catch (error) {
      console.error('Error generating manifest:\n', error);
      throw error;
    }
  }

  /* async Promise<WarehousePickupReceipt | null> getWarehousePickupReceipt(1):
     GETs request to obtain pickup confirmation data for pickups from the local warehouse.
     < sid: The shipment's ID.
     > data: The gotten data (may be null). */
  async getWarehousePickupReceipt(sid: number): Promise<WarehousePickupReceipt | null> {
    let data: WarehousePickupReceipt | null = null;

    try {
      const response: any = await firstValueFrom(this.main.getRequest('/shipment/get-pickup-receipt/' + sid.toString()));

      if (response?.message && response.message === 'Success') {
        data = {
          email: response.data.email,
          ID: response.data.id,
          name: response.data.name,
          phone: response.data.phone,
          photoURL: response.data.photo_url,
          registryDate: this.timestampToJSDate(response.data.created_at),
          shipmentID: response.data.id_shipment,
          textRegDate: this.format(response.data.created_at),
          trackingNumber: response.data.tracking_number
        };
      } else throw new Error(response.code.toString() + ' ' + response.message);
    } catch (error) {
      console.error('Error getting pickup receipt:\n', error);
    }

    return data;
  }

  /* async Promise<void> registerPickup(2):
     POSTs a request to the server with the pickup details to register them
     in the database.
     < data: The pickup data going to be pushed into the DB.
     < shipData: The shipment data going to be pushed into the respective table in the DB.
     > True if register was successful. */
  async registerPickup(data: any, shipData: any): Promise<number> {
    try {
      const payload: any = { data: data, shipData: shipData };
      const response: any = await firstValueFrom(this.main.postRequest(payload, '/pickup/register'));

      return response.id;
    } catch (error) {
      console.error('Error registering pickup: ', error);
      return -1;
    }
  }

  /* async Promise<any> requestPickup(2):
     POSTs a request to the server with the pickup details to create a request for the carrier.
     < data: The data required to generate the request.
     < carrier: The name of the receiving carrier.
     > an object with the data or excuse, the server's message, and HTTP status. */
  async requestPickup(data: any, carrier: string): Promise<any> {
    let reqBody;
    
    switch (carrier) {
      case "Estafeta":
        let packageList: Array<any> = [];

        // Create packages array with specified data by the company
        for (let i = 0; i < data.shipments.length; i++) {
          packageList.push({
            "PackageType": "PKG",
            "Length": data.packages[i].length,
            "Width": data.packages[i].width,
            "Height": data.packages[i].height,
            "Weight": data.packages[i].weight,
            "Quantity": 1,
            "Description": "",
            "Waybills": [{
              "WaybillNumber": data.shipments[i],
              "DestinationAddress": {
                "Country": "Mexico",
                "PostalCode": data.destination[i].postCode,
                "State": data.destination[i].state,
                "City": data.destination[i].city,
                "Municipality": "",
                "Neighborhood": data.destination[i].neighborhood,
                "Address1": data.destination[i].street,
                "Address2": "",
                "SocialReason": "",
                "Contact": "",
                "EmailAddress": "",
                "PhoneNumber": "",
                "MobileNumber": "",
                "ExternalNumber": data.destination[i].number,
                "InternalNumber": "",
                "BetweenStreet1": "",
                "BetweenStreet2": "",
                "Latitude": "",
                "Longitude": ""
          }}]});
        }

        // Create company-specific request object
        reqBody = JSON.stringify({
          "AccountNumber": null,
          "RequesterName": data.name,
          "RequesterEmail": data.email,
          "PickupType": "MP",
          "PickupDayPart": "PM",
          "PickupDate": `${data.date.getFullYear()}-${(data.date.getMonth() + 1).toString().padStart(2, '0')}-${data.date.getDate().toString().padStart(2, '0')}`,
          "PickupAddress": {
            "ShortName": data.address.street,
            "Country": "Mexico",
            "PostalCode": data.address.postCode,
            "State": data.address.state,
            "City": data.address.city,
            "Neighborhood": data.address.neighborhood,
            "Address1": data.address.street,
            "Address2": "",
            "ExternalNumber": data.address.number,
            "InternalNumber": "",
            "Platform": "",
            "Block": "",
            "BetweenStreet1": "",
            "BetweenStreet2": "",
            "ReferenceData": "",
          },
          "PickupPackageList": packageList,
          "PickupAlert_Primary": {
            "Name": data.name,
            "EmailAddress": data.email,
            "PhoneNumber": data.phone
          },
          "PickupAlert_Secondary": {
            "Name": "",
            "EmailAdress": "",
            "PhoneNumber": ""
          }
        });
      break;
      case "Paquetexpress":
        data.date.setHours(15, 0, 0, 0); // Set date's time to 3:00 PM

        // Create company-specific request object
        reqBody = JSON.stringify({
          request: {
            data: [{
              numbPack: data.packages.toString(),
              planCollDate: data.date.getTime().toString(),
              hourFrom: data.initTime * 3600000,
              hourTo: data.finalTime * 3600000,
              breakIni: data.initTime * 3600000,
              breakEnd: data.finalTime * 3600000,
              guiaNo: data.shipments.join(','),
              radGuiaAddrDTOList: [{}]
            }],
            objectDTO: null
          },
          response: null
        });
      break;
      default:
        console.log("No hay procesos disponibles para pedir recolecciones a ", carrier, ".");
        
        return {
          data: "Tuvimos problemas para enviar la información. Por favor, revisa la información ingresada e intenta de nuevo.",
          message: "Not available processes for selected carrier.",
          status: 400
        }
    }

    // Send request to backend
    try {
      const response: any = await firstValueFrom(this.main.postRequest({ carrier: carrier, data: reqBody }, '/pickup/requestPickup'));

      if (response.status === 200)
        return response
      else {
        console.log(response.message);

        return {
          data: response.excuse,
          message: response.message,
          status: 400
        }
      }
    } catch (error: any) {
      console.log(error.message);
        
      return {
        data: "Tuvimos problemas para enviar la información. Por favor, revisa la información ingresada e intenta de nuevo.",
        message: error.message,
        status: 400
      }
    }
  }


  /* Date format(1):
     Converts an SQL timestamp string into a string formatted for display.
     < timestamp: The SQL timestamp string.
     > The formatted string. */
  private format(timestamp: string): string {
    const date = new Date(timestamp);
    date.setHours(date.getHours() + 12); // Fix DB-to-Backend format delay

    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
  }

  /* any parseAddress(1):
     Converts a string with an address in it into an address-like object if possible.
     < input: The address to be converted.
     > address: The formatted address. */
  private parseAddress(input: string): any {
    // First splitting by commas.
    const pieces = input.split(',').map(x => x.trim());

    // Further splitting section 0 into street and number.
    let subpiece = pieces[1].match(/(.+)\s+(\d+)/);
    const street = subpiece ? subpiece[1] : null;
    const number = subpiece ? subpiece[2] : null;

    // Object building.
    const address = {
      city: pieces[4],
      country: pieces.length === 7 ? pieces[6] : pieces[5],
      neighborhood: pieces[2],
      number: number,
      phone: "",
      postCode: pieces[3],
      state: pieces.length === 7 ? pieces[5] : null,
      street: street
    }

    return address;
  }

  /* Date timestampToJSDate(1):
     Converts an SQL timestamp string into a JS Date object.
     < timestamp: The SQL timestamp string.
     > jsDate: The converted Date object. */
  private timestampToJSDate(timestamp: string): Date {
    const jsDate: Date = new Date(timestamp);
    jsDate.setHours(jsDate.getHours() + 12); // Fix DB-to-Backend format delay
    
    return jsDate;
  }
}
