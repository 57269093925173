import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as printJS from 'print-js';
import { StpCreditHistory, StpPendingOrder } from 'src/app/models/Stp.model';
import { CompanyModel } from 'src/app/models/company.model';
import { CurrencyModel, UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.scss']
})
export class CreditHistoryComponent implements OnInit {
   userObj!: UserModel;
   ordersDataList =  new Array<StpCreditHistory>();
   okCurrency: any;
   isLoading = true;
   base64!:string;
   pendingsOrders: Array<StpPendingOrder>=[];
   p_id_company:any;
 
   //#region Filtros
   dateNow : Date = new Date();
   dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
     
   newDateTodayI : Date | null = null;//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDay());
   minDateI : Date =  new Date(2022, 0, 1);
   maxDateI : Date =  new Date();
   dateIDis=true;
   newDateTodayF : Date | null = null//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
   minDateF : Date =  new Date(2022, 0, 1);
   maxDateF : Date =  new Date();
   dateFDis=true;
 
   showFilter=false;
   selFechas = new FormControl('');  
   selCompanies = new FormControl('');  
   selCompaniesList:Array<CompanyModel>=[];
   selUsers = new FormControl('');  
   selUsersList:Array<UserModel>=[];
 
   preLoadHidden = true;

   
   //#endregion
 
   
   columnDefinitions = [
    { def:'id', label: 'id', hide: true},
    { def:'id_company', label: 'id_company', hide: true},
    { def:'amount', label: 'Importe', hide: false},
    { def:'reference_number', label: 'Referencia', hide: false},
    { def:'tracking_key', label: 'No. rastreo', hide: false},
    { def:'previous_credit', label: 'previous_credit', hide: true},
    { def:'observations', label: 'Información', hide: true},
    { def:'id_user_creator', label: 'id_user_creator', hide: true},
    { def:'id_stp_collection', label: 'id_stp_collection', hide: true},
    { def:'created_at', label: 'Creado', hide: false},
    { def:'company_name', label: 'company_name', hide: true},
    { def:'transaction_date', label: 'transaction_date', hide: true},
    { def:'beneficiary_account', label: 'beneficiary_account', hide: true},
    { def:'concept_payment', label: 'concept_payment', hide: true},
    { def:'added_credit', label: 'Agregado', hide: true},
    { def:'conciliation', label: 'Conciliado', hide: true},
    { def:'url_cep', label: 'url_cep', hide: true},
    { def:'conciliation_date', label: 'conciliation_date', hide: true},
    { def:'status', label: 'Estatus', hide: true},
    { def:'status_group', label: 'Estado', hide: false},
    { def:'email', label: 'email', hide: true},
    { def:'nombre', label: 'nombre', hide: true},
    { def:'actions', label: '', hide: true}
  ]
   showOptions = false;
   getDisplayedColumns():string[] {
     return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
   }
 
   getDisplayedLabel(def:string):string {
     let cLabel=this.columnDefinitions.find(item=> item.def==def);
     return cLabel?.label||''
   }
   
   dataSource!: MatTableDataSource<StpCreditHistory>;
 
   @ViewChild(MatPaginator) paginator!: MatPaginator;
   @ViewChild(MatSort) sort!: MatSort;
 
   get data():any { return this.userS.sharedId; } set data(value: any) { this.userS.sharedId = value; }
  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.showFilter=true;
    this.selFechas.setValue('2');
    this.onchangeFechas(this.selFechas);
    //No cargar el listado de manera inicial
    //this.getBillingDataList(); 
    this.isLoading = false; //porque no se carga al principio los datos
    
    console.log(this.pendingsOrders);
    
    this.p_id_company=this.data;
    if(this.p_id_company && this.p_id_company>0){
      this.getPendingsOrders(this.p_id_company);
      this.getDataList(null, null, this.p_id_company);
    }else{
      this.getDataList(null, null, this.userObj.id_company);
      this.getPendingsOrders(this.userObj.id_company);
    }
  }

  getDataList(date_i : string|null = null, date_f : string|null = null, id_company : number|null = null) {
    //this.userS.getStpListOrders(date_i, date_f, id_company).subscribe(
    id_company=id_company||0;
    this.userS.getCreditHistory(id_company).subscribe(
      (result : any) => {
        this.ordersDataList = result;
        this.mergePendingsAndOrders();
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getPendingsOrders(id_company:number) {
    this.userS.getPendingsOrders(id_company).subscribe(
      (result:any) => {
        this.pendingsOrders = result;
        this.mergePendingsAndOrders();
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  mergePendingsAndOrders(){
    let arrAux: Array<StpCreditHistory>=[];
    if(this.pendingsOrders && this.pendingsOrders.length>0){
      for (let index = 0; index < this.pendingsOrders.length; index++) {
        const element = this.pendingsOrders[index];
        let aux={
          id:0,
          id_company:element.id_company,
          amount: element.amount,
          previous_credit:0,
          observations: `Autorización pendiente`,
          id_user_creator:0,
          id_stp_collection:-1,
          created_at: element.created_at,
          company_name: '',
          transaction_date: element.transaction_date,
          tracking_key: element.tracking_key,
          beneficiary_account: element.beneficiary_account,
          concept_payment: element.concept_payment,
          reference_number: element.reference_number,
          added_credit: 0,
          conciliation: 0,
          url_cep: '',
          conciliation_date: element.created_at,
          status: 'AUTORIZACIÓN PENDIENTE',
          status_group: element.status_group,
          email: '',
          nombre: ''
        };
        arrAux.push(aux);
      }
      //this.ordersDataList=arrAux;
      this.ordersDataList=this.ordersDataList.concat(arrAux);
    }

    this.dataSource = new MatTableDataSource(this.ordersDataList);        
    this.dataSource.paginator = this.paginator;
    //this.dataSource.paginator.pageSize=this.billingDataList.length;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
    this.preLoadHidden = false;

    console.log(this.dataSource);
  }

  showSumFooter() {
    let vShow = false;
    if(this.okCurrency!=undefined){ 
      if(this.okCurrency.length==1){
        vShow = true;
      }else{
        //if(this.selCurrency.value == "0"){ //si son todas
        //  vShow = false;
        // }else{ //Tiene selecciona 1 tipo de moneda
           vShow = true
        // }
      }
    }else{
      vShow = false;
    }
    return vShow;
  }

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  searchFilter() {
    this.isLoading = true;
    let date_i:string|null, date_f:string|null, users:string|null, companies:string|null;
    let arrCom:Array<number>=this.selCompanies.value;
    let arrUse:Array<number>=this.selUsers.value;
    let currency:string;

    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);
    companies=arrCom.length>0?arrCom.join(','):null;
    users=arrUse.length>0?arrUse.join(','):null;
    //currency=this.selCurrency.value;
    console.log(companies, users);
    //this.getDataList(date_i, date_f);
    this.ordersDataList=[];
    this.pendingsOrders=[];
    if(this.p_id_company && this.p_id_company>0){
      this.getPendingsOrders(this.p_id_company);
      this.getDataList(null, null, this.p_id_company);
    }else{
      this.getDataList(null, null, this.userObj.id_company);
      this.getPendingsOrders(this.userObj.id_company);
    }
  }


  getCompaniesList() {
    this.userS.getCompanies().subscribe(
      (result : any) => {
        this.selCompaniesList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  
  getUsersList() {
    this.userS.getUserListMin().subscribe(
      (result : any) => {
        this.selUsersList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  searchFilterClean() {
    this.selCompanies.setValue([]);
    this.selUsers.setValue([]);
  }


  onchangedateI(e: Date): void {
    console.log('Inicio', e, 'Fin', this.newDateTodayF);
    if(this.newDateTodayF!=null && this.newDateTodayF!.getTime()<e.getTime()){
        this.newDateTodayF=null;
    }
  }
  onchangedateF(e: Date): void {
    console.log('Fin', e, 'Inicio', this.newDateTodayI);
    if(this.newDateTodayI!=null && this.newDateTodayI!.getTime()>e.getTime()){
      this.newDateTodayI=null;
    }
  }

  onchangeFechas(change:any) {
    //console.log(change);
    switch (change.value) {
      case '1'://Hoy
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '2'://Este mes
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1);
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '3'://Elegir fechas
        this.dateIDis=false;
        this.dateFDis=false;
        break;
      default:
        break;
    }
  }

  
  getPageSizeOptions(): number[]{
    if(this.ordersDataList.length>10)
    return [10,50,100,this.ordersDataList.length];
    else
    return [10,50,100];
  }
  
  getTotalService() {
    return (this.ordersDataList.map(t => t.amount).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
  }

  getTotalPrice() {
    return (this.ordersDataList.map(t => t.amount).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
  }
  
  toggleColumn(columnName: string, event: MatCheckboxChange) {
    //console.log(columnName);
    let columnHS=this.columnDefinitions.find( item=> item.def==columnName);
    if(columnHS){
      columnHS.hide=!event.checked;
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  getAddress(data:string) {
    try {
      const address = JSON.parse(data);
      return `${address.name}, ${address.street}, ${address.number}, ${address.suburb}, ${address.postalCode}, ${address.city}`;
    } catch (error) {
      return ''; 
    }
  }

  icon(value: string, attribute: string = "icon"): string {
    if (attribute == 'icon') {
      if (value == 'credited') return 'check_circle';
      else if (value == 'not_credited') return 'cancel';
      else if (value == 'attention') return 'error';
    } else if (attribute == 'color') {
      if (value == 'credited') return '#09AE0C';
      else if (value == 'not_credited') return '#E00031';
      else if (value == 'attention') return '#CABD2B';
    } else if (attribute == 'tooltip') {
      if (value == 'credited') return 'Acreditado';
      else if (value == 'not_credited') return 'No acreditado';
      else if (value == 'attention') return 'Contáctanos para corroborar el estado de tu pago';
    }
    return '';
  }
  
  setNameXls() {
    let monthShort=this.dateNow.toLocaleString('default', { month: 'short' });
    let year=` ${this.dateNow.getFullYear()}`;
    let sCli='';//this.selClient.value == "0"?'':' '+this.selClient.value;
    let sCur='';//this.selCurrency.value == "0"?'':' '+this.selCurrency.value;
    
    let res=`Billing Data Site flow ${monthShort}${year}${sCli}${sCur}`;

    return res;
  }

  statusName(status:string){
    let sStatus="";
    if(status){
      switch (status.toUpperCase()) {
        case 'D':
          sStatus='Devuelta';
          break;
        case 'LQ':
          sStatus='Liquidada';
          break;
        case 'CCE':
          sStatus='Confirmación enviada';
          break;
        case 'CXO':
          sStatus='Por enviar confirma';
          break;
        case 'CCO':
          sStatus='Confirmada';
          break;
        case 'CCR':
          sStatus='Confirmación rech';
          break;
        default:
          break;
      } 
    }
    return sStatus;
  }
  
}