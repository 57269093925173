<div class="modal-header">
    <h1 class="modal-title">{{ data.detail.id }} - {{ data.detail.transaction_date }} - {{ data.detail.amount | currency}} - {{ data.detail.originator_name }}  
        <!-- <a mat-icon-button style="vertical-align: middle; margin-right: 5px;">
            <span *ngIf="data.detail.status!=''" [ngClass]="{'badge': true , 'bg-dark': data.detail.status!='LQ', 'bg-primary': data.detail.status=='LQ' }" mat-icon-button matTooltip="{{statusName(data.detail.status)}}" [matTooltipPosition]="'above'">{{data.detail.status}}</span>
            <mat-icon *ngIf="data.detail.conciliation==1" class="text-primary" mat-icon-button matTooltip="Conciliado" [matTooltipPosition]="'above'">receipt_long</mat-icon>
            <mat-icon *ngIf="data.detail.added_credit==1" class="text-primary" mat-icon-button matTooltip="Crédito abonado a la compañía" [matTooltipPosition]="'above'">paid</mat-icon>
        </a> -->
    </h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <!-- <h4>Edita la información del registro: <b>{{data.detail.id}}</b>  </h4> -->
    <p >
        <!-- <button mat-icon-button matTooltip="{{ statusChangeTip(dataStatus, dataMessage) }}" [matTooltipPosition]="'above'" color="{{statusChangeClass(dataStatus)}}" [ngClass]="{'processing-icon': dataStatus=='in_progress' }">
            <mat-icon>{{ statusChangeIcon(dataStatus) }}</mat-icon>
        </button> -->
        <!-- <span *ngIf="dataStatus=='error'"> {{dataMessage}}</span>
        <span *ngIf="dataStatus!='error'"> {{statusChangeTip(dataStatus, dataMessage)}}</span> -->
    </p>
    
        <!-- <small>Todos los campos son obligatorios *</small> -->
        <div class="row">

            <div class="form-group col-sm-3">
                <label for="statictransaction_date" class="col-form-label text-muted">Fecha operación</label>
                <p>
                    {{data.detail.transaction_date}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticamount" class="col-form-label text-muted">Monto</label>
                <p>
                    {{ data.detail.amount | currency}} 
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="statictracking_key" class="col-form-label text-muted">Clave rastreo</label>
                <p>
                    {{data.detail.tracking_key}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticconcept_payment" class="col-form-label text-muted">Concepto pago</label>
                <p>
                    {{data.detail.concept_payment}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticreference_number" class="col-form-label text-muted">Referencia numérica</label>
                <p>
                    {{data.detail.reference_number}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticrecord_id" class="col-form-label text-muted">idEF</label>
                <p>
                    {{data.detail.record_id}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticoriginator_institution" class="col-form-label text-muted">Clave rastreo devolución</label>
                <p>
                    {{data.detail.originator_institution}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticbeneficiary_institution" class="col-form-label text-muted">Institución beneficiaria</label>
                <p>
                    {{data.detail.beneficiary_institution}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticoriginator_name" class="col-form-label text-muted">Nombre ordenante</label>
                <p>
                    {{data.detail.originator_name}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="statictype_originator_account" class="col-form-label text-muted">Tipo cuenta ordenante</label>
                <p>
                    {{data.detail.type_originator_account}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticpayer_account" class="col-form-label text-muted">Cuenta ordenante</label>
                <p>
                    {{data.detail.payer_account}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticrfc_curp_originator" class="col-form-label text-muted">RFC CURP ordenante</label>
                <p>
                    {{data.detail.rfc_curp_originator}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticbeneficiary_name" class="col-form-label text-muted">Nombre beneficiario</label>
                <p>
                    {{data.detail.beneficiary_name}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="statictype_beneficiary_account" class="col-form-label text-muted">Tipo cuenta beneficiario</label>
                <p>
                    {{data.detail.type_beneficiary_account}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticbeneficiary_account" class="col-form-label text-muted">Cuenta beneficiario</label>
                <p>
                    {{data.detail.beneficiary_account}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticbeneficiary_name2" class="col-form-label text-muted">Nombre beneficiario 2</label>
                <p>
                    {{data.detail.beneficiary_name2}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="statictype_beneficiary_account2" class="col-form-label text-muted">Tipo cuenta beneficiario 2</label>
                <p>
                    {{data.detail.type_beneficiary_account2}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticbeneficiary_account2" class="col-form-label text-muted">Cuenta beneficiario 2</label>
                <p>
                    {{data.detail.beneficiary_account2}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticrfc_curp_beneficiary" class="col-form-label text-muted">RFC CURP beneficiario</label>
                <p>
                    {{data.detail.rfc_curp_beneficiary}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticcompany" class="col-form-label text-muted">Empresa</label>
                <p>
                    {{data.detail.company}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticpayment_type" class="col-form-label text-muted">Tipo pago</label>
                <p>
                    {{tipoPago(data.detail.payment_type)}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticts_settlement" class="col-form-label text-muted">tsLiquidación</label>
                <p>
                    {{data.detail.ts_settlement}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticfolio_codi" class="col-form-label text-muted">Folio Codi</label>
                <p>
                    {{data.detail.folio_codi}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticcreated_at" class="col-form-label text-muted">Creado</label>
                <p>
                    {{data.detail.created_at?data.detail.created_at.slice(0,16).replace('T','  '):''}}
                </p>
            </div>

            <!-- <div class="form-group col-sm-3">
                <label for="staticstatus" class="col-form-label text-muted">Estado</label>
                <p>
                    {{data.detail.status}}
                </p>
            </div> 

            <div class="form-group col-sm-3">
                <label for="staticconciliation" class="col-form-label text-muted">Conciliación</label>
                <p>
                    {{data.detail.conciliation}}
                </p>
            </div>

            <div class="form-group col-sm-3">
                <label for="staticconciliation_date" class="col-form-label text-muted">Fecha Conciliación</label>
                <p>
                    {{data.detail.conciliation_date?data.detail.conciliation_date.slice(0,16).replace('T','  '):''}}
                </p>
            </div> 

            <div class="form-group col-sm-3">
                <label for="staticonly_conciliation" class="col-form-label text-muted">Solo conciliación</label>
                <p>
                    {{data.detail.only_conciliation}}
                </p>
            </div>  -->

            <!-- <div class="form-group col-sm-3">
                <label for="staticid_stp_clabe_company" class="col-form-label text-muted">id_stp_clabe_company</label>
                <p>
                    {{data.detail.id_stp_clabe_company}}
                </p>
            </div> -->

            

            <!-- <div class="form-group col-sm-3">
                <label for="staticadded_credit" class="col-form-label text-muted">Crédito Agregado</label>
                <p>
                    {{data.detail.added_credit}}
                </p>
            </div> -->

            
        </div>
        <hr>          
        <div class="row mt-2">
            <div class="col-sm-3">
                <label for="staticadded_credit" class="col-form-label text-muted">Estado: {{statusName(data.detail.status)}} </label>
                <p>
                    <span *ngIf="data.detail.status!=''" [ngClass]="{'badge': true , 'bg-dark': data.detail.status!='LQ', 'bg-primary': data.detail.status=='LQ' }" mat-icon-button matTooltip="{{statusName(data.detail.status)}}" [matTooltipPosition]="'above'">{{data.detail.status}}</span>
                </p>
            </div>
            <div class="col-sm-3" >
                <label for="staticadded_credit" class="col-form-label text-muted">Conciliado: {{data.detail.conciliation_date?data.detail.conciliation_date.slice(0,16).replace('T','  '):'En espera'}}</label>
                <p>
                    <mat-icon *ngIf="data.detail.conciliation==1" style="vertical-align: middle; margin-right: 5px;" class="text-primary" mat-icon-button matTooltip="Conciliado" [matTooltipPosition]="'above'">receipt_long</mat-icon>
                    {{data.detail.only_conciliation==1?'Solo conciliación':''}}  
                </p>
            </div>
            <div class="col-sm-3">
                <label for="staticadded_credit" class="col-form-label text-muted">Crédito para {{data.detail.name_company}}: {{data.detail.added_credit==1?'Abonado':'En espera'}}</label>
                <p>
                    <mat-icon *ngIf="data.detail.added_credit==1" style="vertical-align: middle; margin-right: 5px;" class="text-primary" mat-icon-button matTooltip="Crédito abonado a la compañía" [matTooltipPosition]="'above'">paid</mat-icon> 
                    {{ data.detail.amount | currency}} 
                </p>
            </div>
            <div class="col-sm-3">
                <label for="staticurl_cep" class="col-form-label text-muted">CEP Banxico</label>
                <p>
                    <a href="{{ data.detail.url_cep }}"  *ngIf="data.detail.url_cep && data.detail.url_cep!=''"  target="_blank" mat-icon-button matTooltip="Ir a Banxico" [matTooltipPosition]="'above'" class="text-info" style="margin-top: -10px;">
                        <mat-icon>link</mat-icon>
                        Ir a Banxico
                    </a>
                    <span *ngIf="data.detail.url_cep==''" ></span>
                </p>
            </div>
        </div>
        
        <p class="mb-4"></p>
    
</div>
<div class="modal-footer">
    <!-- <button *ngIf="dataStatus!='in_progress' && dataStatus!='done'" mat-flat-button class="bg-success text-white" form="formEdit" type="submit">Guardar <mat-icon>save</mat-icon></button> -->
</div>