import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { BulkUploadAddComponent } from '../shared/dialogs/bulk-upload-add/bulk-upload-add.component';
import Swal from 'sweetalert2';
import * as printJS from 'print-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bulk-uploads',
  templateUrl: './bulk-uploads.component.html',
  styleUrls: ['./bulk-uploads.component.scss']
})
export class BulkUploadsComponent implements OnInit {
  dataList: any = [];
  isLoading = true;
  base64!:string;
  intervaloActualizacion: any;

  displayedColumns: string[] = ['id', 'name', 'file_name', 'email_notification', 'status', 'created_at', 'actions'];
  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  get bulkUploadData():any { return this.userS.sharedBulkUpload; } set bulkUploadData(value: any) { this.userS.sharedBulkUpload = value; }

  constructor(private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.getDataList();

    this.intervaloActualizacion = setInterval(() => {
      this.dataList=this.getDataList();
    }, (environment.update_interval*1000 * 2)); //lo multiplico por 2 para no actualizar tan seguido
  }

  ngOnDestroy() {
    clearInterval(this.intervaloActualizacion); // Limpia el intervalo al destruir el componente
  }

  getDataList() {
    
    this.userS.getBulkUploads().subscribe(
      (result : any) => {
        this.dataList=result.data;
        this.dataSource = new MatTableDataSource(this.dataList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
    
  }

  openBulkUpload() {
    const dialogRef = this.matDialog.open( BulkUploadAddComponent, {
      data: { 
        //shipmentIdSelected: id
      },
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        //console.log(resp);
        idBU = resp.id
      }
      if(idBU>0)
        this.goToBulkUploads(idBU);
    });
  }

  goToBulkUploads(id:number) {
    this.bulkUploadData = {"id":id};    
    this.router.navigateByUrl('/admin/bulk-upload');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  

  viewDetail(id:number) {
    this.bulkUploadData = {"id":id};    
    this.router.navigateByUrl('/admin/bulk-upload');
  }

  downloadAllPdf(id:number){
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.getBulkUploadPdf(id).subscribe({
      next: (data: any) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const urlFile = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlFile;
        link.download = `bu-${id}.pdf`;
        Swal.close();
        link.click();
      },
      error:  (err : any) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error al descargar',
          text:  'Ocurrió un error al descargar el PDF, por favor vuelva a intentarlo.'
        });
      }
    });

  }

  viewAllPdf(id:number){
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.getBulkUploadPdf(id).subscribe({
      next: (data: any) => {
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
        URL.revokeObjectURL(blobUrl);

        Swal.close();
      },
      error:  (err : any) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error al descargar',
          text:  'Ocurrió un error al descargar el PDF, por favor vuelva a intentarlo.'
        });
      }
    });

  }

}
