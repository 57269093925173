<div class="modal-header">
    <h1 class="modal-title">¡Gracias por tu compra!</h1>
    <button type="button" class="btn-close mb-2" mat-dialog-close></button>
</div>
<div class="row mt-4 justify-content-center">
    <div class="col-sm-12">
        <h3 class="text-center">
            <span>{{labelName}}.pdf</span>
        </h3>
    </div>
</div>
<div class="row mt-1 justify-content-center">
    <div class="col-sm-12 d-flex justify-content-center">
            <button [disabled]="!base64" (click)="onClickDownloadPdf(base64)"  mat-flat-button class="ms-3 text-white" color="accent">Descarga tu guía <mat-icon>download</mat-icon></button>
            <button [disabled]="!base64" (click)="print(base64)" mat-stroked-button class="ms-3" color="accent">Imprime tu guía <mat-icon>print</mat-icon></button>
    </div>
</div>
<div class="row mt-1 justify-content-center">
    <div *ngIf="base64Bol" class="col-sm-12 d-flex justify-content-center">
        <button [disabled]="!base64Bol" (click)="onClickDownloadPdf(base64Bol,true)"  mat-flat-button class="ms-3 text-white" color="accent">Descarga tu factura <mat-icon>download</mat-icon></button>
        <button [disabled]="!base64Bol" (click)="print(base64Bol)" mat-stroked-button class="ms-3" color="accent">Imprime tu factura <mat-icon>print</mat-icon></button>
    </div>
</div>
