// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: "http://localhost:4000",
  API_URL: "https://qa.api.buho-shipping.buhologistics.com",
  role_admin_id: 1,
  role_buho_id: 2,
  role_client_id: 3,
  role_operations_id: 4,
  roles: [
    {
      "id": 2,
      "rol": "Estrategia"
    },{
      "id":3,
      "rol": "Cliente"
    },{
      "id":4,
      "rol": "Operaciones"
    },
  ],
  update_interval: 10, //segundos
  bu_item_pro_time: 15 //segundos
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
