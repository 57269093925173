import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FavShipModel } from 'src/app/models/shipment.model';
import { ShopifyService } from 'src/app/services/shopify.service';
import { UsersService } from 'src/app/services/users.service';
import { EcommerceShipmentCheckoutComponent } from '../shared/dialogs/ecommerce-shipment-checkout/ecommerce-shipment-checkout.component';
import Swal from 'sweetalert2';
import { ShipmentsPrintComponent } from '../shared/dialogs/shipments-print/shipments-print.component';
import * as printJS from 'print-js';

@Component({
  selector: 'app-ecommerce-orders',
  templateUrl: './ecommerce-orders.component.html',
  styleUrls: ['./ecommerce-orders.component.scss']
})
export class EcommerceOrdersComponent implements OnInit {
  isLoading = false;
  displayedColumns: string[] = ['id', 'createdAt', 'items','financialStatus', 'fulfillmentStatus', 'tracking', 'carrier','actions'];
  // displayedColumns: string[] = ['id', 'items', 'createdAt', 'financialStatus', 'fulfillmentStatus', 'tracking','actions'];
  dataSource!: MatTableDataSource<any>;
  orderList:any;
  // statusCatalog: any = {
  //   authorized: { name: 'Autorizado', colorClass: 'badge text-bg-info' },
  //   expired: { name: 'Expirado', colorClass: 'badge text-bg-danger' },
  //   paid: { name: 'Pagado', colorClass: 'badge text-bg-success' },
  //   partially_paid: { name: 'Parcialmente pagado', colorClass: 'badge text-bg-warning' },
  //   partially_refunded: { name: 'Parcialmente reembolsado', colorClass: 'badge text-bg-warning' },
  //   pending: { name: 'Pendiente', colorClass: 'badge text-bg-warning' },
  //   refunded: { name: 'Reembolsado', colorClass: 'badge text-bg-secondary' },
  //   voided: { name: 'Anulado', colorClass: 'badge text-bg-dark' },
  //   null: { name: 'No definido', colorClass: 'badge text-bg-secondary' },
  // };
  // fulfillmentStatusCatalog: any = {
  //   fulfilled: { name: 'Cumplido', colorClass: 'badge text-bg-success' },
  //   partial: { name: 'Parcial', colorClass: 'badge text-bg-warning' },
  //   restocked: { name: 'Reabastecido', colorClass: 'badge text-bg-warning' },
  //   null: { name: 'No cumplido', colorClass: 'badge text-bg-warning' }
  // };

  statusCatalog:any = {
    'authorized': { label:'Autorizado', color: 'badge-warning' },
    'expired': {label:'Expirado', color: 'badge-warning'},
    'paid': {label: 'Pagado', color: 'badge-success'},
    'partially_paid': {label:'Parcialmente pagado', color: 'badge-warning'},
    'partially_refunded': {label:'Parcialmente reembolsado', color: 'badge-warning'},
    'pending': {label:'Pendiente', color: 'badge-warning'},
    'refunded': {label:'Reembolsado', color: 'badge-warning'},
    'voided': {label:'Anulado', color: 'badge-warning'},
    'null': {label:'No definido', color: 'badge-warning'},
  };

  fulfillmentStatusCatalog:any = {
    'fulfilled': { label:'Cumplido', color: 'badge-success' },
    'partial': {label:'Parcial', color: 'badge-warning'},
    'restocked': {label: 'Reabastecido', color: 'badge-warning'},
    'null': {label:'No cumplido', color: 'badge-warning'}
  };

  globalObject!:any;
  orderNumber!:string;
  emailDefault="hola@buhologistics.com";
  base64!:string;
  base64Bol!:string;
  labelName!:string;
  clicked = false;





  
  get favData():any { return this.userS.sharedData; } set favData(value: any) { this.userS.sharedData = value; }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private shopifyS: ShopifyService,
    private userS: UsersService,
    private matDialog: MatDialog,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.getOrdersList();
  }

  getOrdersList() {
    this.shopifyS.getOrdersList().subscribe(
      (result : any) => {
        this.orderList = result;
        console.log(this.orderList)
        this.dataSource = new MatTableDataSource(this.orderList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }


  generateTooltip(items: any[]){
    const itemNames = items.map(item => item.itemName);
    return itemNames.join(' | ');
  }

  // test(data:any) {
  //   console.log(data)
  // }

  sendToForm(obj: FavShipModel) {
    this.favData = obj;
    this.favData.isEcommerce = true;
    this.favData.ecommerceOrderId = obj.id;
    this.goToShipmentAdd();
  }

  goToShipmentAdd() {
    this.router.navigateByUrl('/admin/shipments/add');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  formatDate(data:any){
    const date = new Date(data);
    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const anio = date.getFullYear();
    return `${dia}/${mes}/${anio}`;
  }

  openDialogCheckout(row:any){
    if(row.selected_rate){
      this.matDialog.open( EcommerceShipmentCheckoutComponent, {
        height: 'auto',
        maxHeight:'50rem',
        width:'80rem',
        data: { 
          data: row,
        },
        panelClass: 'custom-dialog-container-scroll'
      });
    }else{
      this.sendToForm(row);
    }
  }

  viewLabel(id:number, isBol:boolean = false) {
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.findLabel(id).subscribe(
      ( resp: any ) => {
        if ( resp.data.status.code == "success" ) {
          const dialogRef = this.matDialog.open( ShipmentsPrintComponent, {
            height: '40rem',
            width:'80rem',
            data: { 
              data: isBol?resp.data.data.commercial_invoice : resp.data.data.payload
            },
            // panelClass: 'dialogs-main'
            panelClass: 'custom-dialog-container'
          });
          Swal.close();
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error',
          text:  'Ocurrió un error al descargar la guía, intenta de nuevo mas tarde.'
        });
      }
    );
  }

  printLabel(id:number, isBol:boolean = false) {
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.findLabel(id).subscribe(
      ( resp: any ) => {
        if ( resp.data.status.code == "success" ) {
          printJS({ printable: isBol? resp.data.data.commercial_invoice : resp.data.data.payload, type: "pdf", base64: true })
          Swal.close();
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error',
          text:  'Ocurrió un error al descargar la guía, intenta de nuevo mas tarde.'
        });
      }
    );
  }
  
  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }

  hasGiftCard(items:any){
    console.log(items.findIndex((e:any)=> e.giftCard === true))
    return items.findIndex((e:any)=> e.giftCard === true)
  }
}
