import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-pickups-manifest-create',
  templateUrl: './pickups-manifest-create.component.html',
  styleUrls: ['./pickups-manifest-create.component.scss']
})
export class PickupsManifestCreateComponent implements OnInit {
  bill: string[];
  billForm: FormGroup;
  orders: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dref: MatDialogRef<PickupsManifestCreateComponent>, private formBuilder: FormBuilder) {
    this.bill = [];
    this.orders = data.orders;

    this.billForm = this.formBuilder.group({
      selection: ['']
    });
  }

  ngOnInit(): void {
    this.toBill(this.data.manifest);
  }

  /* void addToManifest(1?):
     Adds the selected item to the manifest to be generated and
     resets the dropdown.
     < input: An optional forced input. */
  addToManifest(input: number = -1): void {
    const x: any = (input !== -1) ? this.orders[input] : this.billForm.controls.selection?.value;

    if (x && !this.bill.includes(x)) {
      this.bill.push(x);
      this.billForm.controls.selection?.reset();
    }
  }

  /* void cancel(0):
     Closes the dialog without returning any data. */
  cancel(): void {
    this.dref.close();
  }

  /* void onSubmit(0):
     Closes the dialog and returns the manifest items. */
  onSubmit(): void {
    const info: string[] = [];

    // Get only shipments' numbers
    for (let i = 0; i < this.bill.length; i++)
      info.push(this.bill[i][0]);

    this.dref.close(info);
  }

  /* void removeItem(1):
     Removes the item from the manifest to be generated.
     < item: The item to be removed. */
  removeItem(item: string): void {
    this.bill = this.bill.filter(x => x !== item);
  }

  /* void toBill(1):
     Compares imported shipments and add them to the bill for display.
     < data: The imported list. */
  toBill(data: string[]): void {
    this.bill = this.data.orders.filter((x: string[]) => data.includes(x[0]));
  }
}
