<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Reporte STP Transacciones </h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>request_quote</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Billing Data - STP</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <h4>Selecciona fechas y da click en buscar para mostrar la información</h4>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fechas</mat-label>
                                <mat-select [formControl]="selFechas" (selectionChange)="onchangeFechas($event)">
                                    <mat-option value="1">Hoy</mat-option>
                                    <mat-option value="2">Este mes</mat-option>
                                    <mat-option value="3">Elegir fechas</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha inicio</mat-label>
                                <input name="pickerI" (ngModelChange)="onchangedateI($event)" matInput [matDatepicker]="picker" [min]="minDateI" [max]="maxDateI" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayI">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha fin</mat-label>
                                <input name="pickerF" (ngModelChange)="onchangedateF($event)" matInput [matDatepicker]="pickerf" [min]="minDateF" [max]="maxDateF" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayF">
                                <mat-datepicker-toggle matSuffix [for]="pickerf"></mat-datepicker-toggle>
                                <mat-datepicker #pickerf [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <button mat-stroked-button class="btn btn-primary mt-2 me-2 mb-1" matTooltip="Filtrar resultados" [matTooltipShowDelay]="1000" (click)="searchFilter()"><mat-icon>search</mat-icon><span class="btn-no-text"> Buscar</span></button>

                            <!-- <button  mat-stroked-button [disabled]="formatoXls!=''" class="btn mt-2 me-2 mb-1"  id="uploadXls" (click)="openUploadExcel()"  matTooltip="Columnas" [matTooltipPosition]="'above'" color="link" [hidden]="preLoadHidden" >
                                <mat-icon>cloud_upload</mat-icon> {{formatoXls!=''?formatoXls:'Subir Excel'}} 
                            </button> -->
                            <!-- <button  mat-stroked-button [disabled]="formatoXls!=''" class="btn mt-2 me-2 mb-1"  id="uploadXlsCost" (click)="openUploadCost()"  matTooltip="Columnas" [matTooltipPosition]="'above'" color="link" [hidden]="preLoadHidden" >
                                <mat-icon>cloud_upload</mat-icon> Subir Costos 
                            </button>
                            
                            <button mat-stroked-button (click)="filterXTracking()" class="btn mt-2 me-2 mb-1"  color="link" [hidden]="preUploadXlsdHidden">
                                <mat-icon>{{ activeFilterXls ? 'filter_list_off'  : 'filter_list' }}</mat-icon> {{ activeFilterXls ? 'Mostrar Todo ('+ordersDataList.length +')'  : 'Mostrar coincidencias ('+  countCoincidencia +')' }}
                            </button>
                            <button mat-stroked-button (click)="trackingsNoFound()" class="btn mt-2 me-2 mb-1"   color="link" [hidden]="preUploadXlsdHidden && xlsDataNotFound.length==0">
                                <mat-icon>search_off</mat-icon> Ver no encontrados ({{ xlsDataNotFound.length }})
                            </button> -->


                            <button (click)="exporter.exportTable('xlsx', {fileName: setNameXls() })" mat-stroked-button class="btn btn-outline-success mt-2 me-2 mb-1" matTooltip="Exportar a Excel" [matTooltipShowDelay]="1000" [hidden]="preLoadHidden">
                                <mat-icon>download</mat-icon><span class="btn-no-text"> Descargar Excel </span>
                            </button>
                            <button  mat-stroked-button class="btn btn-outline-success mt-2 me-2 mb-1" id="customDropdownButton" (click)="toggleOptions()"  matTooltip="Columnas" [matTooltipPosition]="'above'" color="link" class="btn mt-2 me-2 mb-1" [hidden]="preLoadHidden">
                                <mat-icon>view_week</mat-icon> Columnas
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': showOptions}"  aria-labelledby="customDropdownButton"> 
                                <div class="dropdown-content" style=" max-height: 300px; overflow-y: auto;">
                                    <h5 class="dropdown-header d-flex justify-content-between align-items-center" style="margin: 0px;">Columnas  
                                        <button  mat-icon-button (click)="toggleOptions()"  matTooltip="Cerrar" [matTooltipPosition]="'above'" color="link" class="">
                                            <mat-icon>close</mat-icon>
                                        </button> 
                                    </h5>
                                    <div class="dropdown-item">
                                      <p class="m-0" style="color: #ABABAB">Seleccione las columnas para mostrar:</p>
                                      <ul>
                                        <li class="master-select my-2">
                                            <mat-checkbox [(ngModel)]="allTrue" [ngModelOptions]="{standalone: true}" (change)="toggleAll($event)">
                                                Seleccionar todas
                                            </mat-checkbox>
                                            <hr class="m-0"/> 
                                        </li>
                                        <li *ngFor="let column of columnDefinitions">
                                            <mat-checkbox [checked]="!column.hide" (change)="toggleColumn(column.def, $event)" class="d-flex align-items-center mb-2">
                                                {{ column.label }}
                                            </mat-checkbox>
                                        </li>
                                      </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-sm-2"> -->
                                    <!-- <mat-form-field class="mt-2 me-2 mb-1" appearance="outline">
                                        <mat-label>Cliente</mat-label>
                                        <mat-select [formControl]="selClient" >
                                            <mat-option value="0">Todos</mat-option>
                                            <mat-option *ngFor="let item of selClientXC" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                                <!-- </div>
                                <div class="col-sm-2"> -->
                                    <!-- <mat-form-field class="mt-2 me-2 mb-1" appearance="outline">
                                        <mat-label>Moneda</mat-label>
                                        <mat-select [formControl]="selCurrency" >
                                            <mat-option value="0">Todos</mat-option>
                                            <mat-option *ngFor="let item of selCurrencyXC" [value]="item">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                                <!-- </div>
                                <div class="col-sm-2"> -->
                                    <!-- <button mat-stroked-button (click)="filterClientCurrency()" class="btn mt-2 me-2 mb-1"  color="link" >
                                        <mat-icon>filter_list</mat-icon> Filtrar cliente y moneda
                                    </button> -->
                                <!-- </div>
                            </div> -->
                            
                        </div>
                        <!-- <div class="col-sm-6">
                            
                        </div> -->
                    </div>
                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="primary" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    
                    <div [hidden]="preLoadHidden">
                        <div class="overflow-auto w-100">
                            <mat-form-field>
                                <mat-label>
                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                            </mat-form-field>
                            <div class="float-end" >
                                
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive">
                            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear class="w-100">
                                <ng-container matColumnDef="created_at">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('created_at')}} </th>
                                    <td class="col-2 pe-2" mat-cell *matCellDef="let row"> {{row.created_at?row.created_at.slice(0,16).replace('T','  '):''}}</td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="id">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="record_id">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('record_id')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.record_id}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="transaction_date">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('transaction_date')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.transaction_date}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="originator_institution">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('originator_institution')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.originator_institution}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="beneficiary_institution">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('beneficiary_institution')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.beneficiary_institution}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="tracking_key">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('tracking_key')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.tracking_key}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="amount">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('amount')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.amount | currency }} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="originator_name">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('originator_name')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.originator_name}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="type_originator_account">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('type_originator_account')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.type_originator_account}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="payer_account">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('payer_account')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.payer_account}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="rfc_curp_originator">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('rfc_curp_originator')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.rfc_curp_originator}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="beneficiary_name">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('beneficiary_name')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.beneficiary_name}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="type_beneficiary_account">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('type_beneficiary_account')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.type_beneficiary_account}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="beneficiary_account">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('beneficiary_account')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.beneficiary_account}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="beneficiary_name2">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('beneficiary_name2')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.beneficiary_name2}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="type_beneficiary_account2">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('type_beneficiary_account2')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.type_beneficiary_account2}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="beneficiary_account2">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('beneficiary_account2')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.beneficiary_account2}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="rfc_curp_beneficiary">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('rfc_curp_beneficiary')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.rfc_curp_beneficiary}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="concept_payment">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('concept_payment')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.concept_payment}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="reference_number">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('reference_number')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.reference_number}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="company">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('company')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.company}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="payment_type">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('payment_type')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.payment_type}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="ts_settlement">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('ts_settlement')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.ts_settlement}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="folio_codi">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('folio_codi')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.folio_codi}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="added_credit">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('added_credit')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.added_credit}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('status')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.status}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="conciliation">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('conciliation')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.conciliation}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="conciliation_date">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('conciliation_date')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.conciliation_date?row.conciliation_date.slice(0,16).replace('T','  '):''}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="only_conciliation">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('only_conciliation')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.only_conciliation}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="id_stp_clabe_company">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_stp_clabe_company')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_stp_clabe_company}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="url_cep">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('url_cep')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> 
                                        <a href="{{row.url_cep}}"  *ngIf="row.url_cep!=''"  target="_blank" mat-icon-button matTooltip="Ir a Banxico" [matTooltipPosition]="'above'" class="text-info">
                                            <mat-icon>link</mat-icon>
                                        </a>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="clabe">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('clabe')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.clabe}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="clabe_active">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('clabe_active')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.clabe_active}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="id_company">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_company')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_company}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="name_company">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('name_company')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.name_company}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                
                                
                                
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> {{getDisplayedLabel('actions')}} </th>
                                    <td class="col-sm-3 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row">
                                        <a href="{{row.url_cep}}"  *ngIf="row.url_cep && row.url_cep!=''"  target="_blank" mat-icon-button matTooltip="Ir a Banxico" [matTooltipPosition]="'above'" class="text-info">
                                            <mat-icon>link</mat-icon>
                                        </a>
                                        <a mat-icon-button >
                                            <span *ngIf="row.status!=''" [ngClass]="{'badge': true , 'bg-dark': row.status!='LQ', 'bg-primary': row.status=='LQ' }" mat-icon-button matTooltip="{{statusName(row.status)}}" [matTooltipPosition]="'above'">{{row.status}}</span>
                                            <mat-icon *ngIf="row.conciliation==1" class="text-primary" mat-icon-button matTooltip="Conciliado" [matTooltipPosition]="'above'">receipt_long</mat-icon>
                                            <mat-icon *ngIf="row.added_credit==1" class="text-primary" mat-icon-button matTooltip="Crédito abonado a la compañía" [matTooltipPosition]="'above'">paid</mat-icon>
                                        </a>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                    
                                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (dblclick)="onRowDoubleClick(row)"></tr>
                                <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()"></tr>
                    
                                <tr class="mat-row" *matNoDataRow>
                                    <td *ngIf="ordersDataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                    <td *ngIf="ordersDataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="getPageSizeOptions()" pageSize="100" ></mat-paginator>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>