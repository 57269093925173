import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UsersEditPasswordFirsttimeComponent } from '../components/shared/dialogs/users-edit-password-firsttime/users-edit-password-firsttime.component';
import { AuthService } from '../services/auth.service';
import { UsersService } from '../services/users.service';
import { ShopifyService } from '../services/shopify.service';
import { catchError, map, switchMap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
    private shopifyS: ShopifyService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var expectedRoles = route.data.expectedRoles;
    if (this.auth.isAuthenticated()) {
      if (this.userS.getUser().ch_pass === 1) {
        this.openDialogChangePasswordFirstTime();
      }
      if (expectedRoles.includes(this.userS.getUser().role) || expectedRoles.includes("Any")) {
        if (route.routeConfig && route.routeConfig.path === 'ecommerce-config') {
          return this.shopifyS.getStoreList().pipe(
            map((result: any) => {
              if (result.length > 0) {
                this.router.navigate(['/admin/ecommerce']);
                return false;
              } else {
                return true;
              }
            }),
            catchError((err: any) => {
              console.error(err);
              return of(false);
            })
          );
        }
        return true;
      }
      else {
        this.router.navigateByUrl('/admin/dashboard');
        return false;
      }
    } else {
      this.auth.redirectUrl = state.url; //borrar
      this.router.navigateByUrl('/login');
      return false;
    }
  }

  
  openDialogChangePasswordFirstTime() {
    this.matDialog.closeAll();
    this.matDialog.open(UsersEditPasswordFirsttimeComponent, {
      panelClass: 'dialogs-main',
      disableClose: true,
      data: { 
        id_user: this.userS.getUser().id
      },
    });
  }
  
}
