<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>Recolecciones Pendientes</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html">
              <mat-icon>trolley</mat-icon>
            </a></li>
          <li class="breadcrumb-item">Recolecciones</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="overflow-auto w-100">
            <div style="display: flex; align-items: flex-end; justify-content: space-between;">
              <div>
                <h1>Recolecciones</h1>
              </div>
              <button mat-raised-button color="primary" class="mt-2 mb-4" [routerLink]="'form'">
                Pedir Recolección <mat-icon class="force-center">add_circle</mat-icon>
              </button>
            </div>
          </div>
          <div class="container-fluid" *ngIf="loading; else content">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <ng-template #content>
            <ng-container *ngIf="pickups.length === 0; then noTable else pickupsTable"></ng-container>
          </ng-template>
          <ng-template #noTable>
            <div class="note-block">
              <p class="note"> No se encontraron recolecciones pendientes. Puedes pedir una recolección desde <a class="discrete-a" [routerLink]="['../shipments']">Mis Envíos</a> o <a class="discrete-a" [routerLink]="'form'">Crear una nueva</a>. </p>
            </div>
          </ng-template>
          <ng-template #pickupsTable>
            <div class="mat-elevation-z8 table-responsive">
              <table mat-table [dataSource]="pickups" class="w-100" matSort>
                <ng-container matColumnDef="id">
                  <th mat-header-cell class="wt-head" *matHeaderCellDef>
                    <div class="wh-text">No. Confirmación</div>
                    <div class="wh-icon">
                      <mat-icon matTooltip="No. Confirmación" [matTooltipPosition]="'above'">tag</mat-icon>
                    </div>
                  </th>
                  <td mat-cell class="wt-cell" *matCellDef="let x">
                    <div><b>{{ x.confirmationID }}</b></div>
                    <div>{{ x.carrier }}</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th mat-header-cell class="wt-head" *matHeaderCellDef>
                    <div class="wh-text">Dirección</div>
                    <div class="wh-icon">
                      <mat-icon matTooltip="Dirección" [matTooltipPosition]="'above'">location_pin</mat-icon>
                    </div>
                  </th>
                  <td mat-cell class="wt-cell" style="width: 30%;" *matCellDef="let x">{{ x.address }}</td>
                </ng-container>
                <ng-container matColumnDef="date">
                  <th mat-header-cell class="wt-head" *matHeaderCellDef>
                    <div class="wh-text">Fecha de Recolección</div>
                    <div class="wh-icon">
                      <mat-icon matTooltip="Fecha de Recolección" [matTooltipPosition]="'above'">calendar_month</mat-icon>
                    </div>
                  </th>
                  <td mat-cell class="wt-cell" *matCellDef="let x">
                    <div [style]="collected(x.date, x.endTime)">{{ x.textDate }}</div>
                    <div>{{ toTime(x.startTime) }} - {{ toTime(x.endTime) }}</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="content">
                  <th mat-header-cell class="wt-head" *matHeaderCellDef>
                    <div class="wh-text">Contenido</div>
                    <div class="wh-icon">
                      <mat-icon matTooltip="Contenido" [matTooltipPosition]="'above'">inventory</mat-icon>
                    </div>
                  </th>
                  <td mat-cell class="wt-cell" *matCellDef="let x">
                    <div>{{ x.boxes }} caja{{ x.boxes !== 1 ? 's' : '' }}</div>
                    <div>{{ x.weight }} kg</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="fee">
                  <th mat-header-cell class="wt-head" *matHeaderCellDef>
                    <div class="wh-text">Tarifa</div>
                    <div class="wh-icon">
                      <mat-icon matTooltip="Tarifa" [matTooltipPosition]="'above'">monetization_on</mat-icon>
                    </div>
                  </th>
                  <td mat-cell class="wt-cell" *matCellDef="let x">${{ x.fee }} MXN</td>
                </ng-container>
                <ng-container matColumnDef="user">
                  <th mat-header-cell class="wt-head" *matHeaderCellDef>
                    <div class="wh-text">Usuario</div>
                    <div class="wh-icon">
                      <mat-icon matTooltip="Usuario" [matTooltipPosition]="'above'">person_pin</mat-icon>
                    </div>
                  </th>
                  <td mat-cell class="wt-cell" *matCellDef="let x">
                    <div><b>{{ x.user }}</b></div>
                    <div>{{ x.textCDate }}</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell class="text-center wt-head" *matHeaderCellDef>
                    <div class="wh-text">Acciones</div>
                    <div class="wh-icon">
                      <mat-icon matTooltip="Acciones" [matTooltipPosition]="'above'">apps</mat-icon>
                    </div>
                  </th>
                  <td mat-cell class="wt-cell" *matCellDef="let row" class="text-center">
                    <button mat-icon-button matTooltip="Ver Manifiesto" [matTooltipPosition]="'above'" color="primary" (click)="showManifest(row.id)">
                      <mat-icon>assignment</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>
              </table>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>