<div class="container-fluid">
    <div class="row">
        <div class="col-xl-7 bg-login"></div>
        <div class="col-xl-5 p-0 bg-login-form">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo text-start" href="index.html">
                            <img class="img-fluid logo-login" src="./../../../assets/images/logo_buho_wing.png">
                        </a>
                    </div>
                    <div class="login-main">
                        <form *ngIf="isLogin" (ngSubmit)="login(f)" #f="ngForm" autocomplete="off">
                        <!-- <form *ngIf="!isVerificationMode" (ngSubmit)="login(f)" #f="ngForm" autocomplete="off"> -->
                            <h1>Hola somos Búho Wing 👋</h1>
                            <h4 class="text-muted">Por favor inicia sesion en tu cuenta</h4>
                            <div>
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Usuario</mat-label>
                                    <input matInput placeholder="Usuario" [(ngModel)]="user.email" name="user" required>
                                    <mat-icon matSuffix>account_circle</mat-icon>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Contraseña</mat-label>
                                    <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" [(ngModel)]="user.pass" name="pass" required>
                                    <mat-icon matSuffix (click)="hide = !hide" role="button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-checkbox [(ngModel)]="rememberme" name="rememberme">
                                    Recordarme
                                </mat-checkbox>
                            </div>
                            <div>
                                <button mat-raised-button color="primary" class="w-100 mt-2" type="submit">Iniciar sesión</button>
                            </div>
                        </form>
                        <div *ngIf="isVerificationMode" autocomplete="off">
                            <h1>Introduzca su código de confirmación</h1>
                            <h4 class="text-muted">Hemos enviado un código de verificación a su {{ verificationMethodSelected === 'email' ? 'correo electrónico' : 'teléfono' }}. Ingresa el código en el campo a continuación:</h4>
                            <div>
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Codigo</mat-label>
                                    <input maxlength="6" matInput placeholder="Codigo" [(ngModel)]="otp" name="otp" required>
                                    <mat-icon matSuffix>lock</mat-icon>
                                </mat-form-field>
                            </div>
                            <div *ngIf="isVerificationMode" class="text-start">
                                <div class="justify-content-start mb-2">
                                    <ng-container *ngIf="timeRemaining > 0; else resendCode">
                                        <p class="underline d-block">¿No recibiste el código?</p>
                                        <p class="text-muted">Podrás solicitar un nuevo código en: {{ timeRemaining }} segundos</p>
                                      </ng-container>
                                      <ng-template #resendCode>
                                        <p class="underline d-block" role="button" (click)="resendOtp()" ><u>Reenviar código</u></p>
                                      </ng-template>
                                </div>
                                <button [disabled]="otp.length < 6" mat-raised-button color="primary" class="w-100 mb-2" (click)="verifyOtp()">Verificar</button>
                            </div>
                        </div>
                        <div *ngIf="chooseVerificationMethod" autocomplete="off">
                            <h1>Verificación de dos pasos</h1>
                            <h4 class="text-muted">Te enviaremos un código de verificación al destino de tu elección. Por favor, selecciona el método preferido para recibir el código:</h4>
                            <div>
                                <button mat-raised-button color="primary" class="w-100 mt-2" (click)="handleSubmitCode('email')">Correo<mat-icon class="ms-2 fs-4">mail</mat-icon></button>
                                <button mat-raised-button color="primary" class="w-100 mt-2" (click)="handleSubmitCode('sms')">SMS<mat-icon class="ms-2 fs-4">sms</mat-icon></button>
                            </div>
                        </div>

                        <div *ngIf="isLogin" class="mt-2 text-center">
                            <a role="button" (click)="openDialog()">Olvidé mi contraseña</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>