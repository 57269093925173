<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>{{data && data!.alias ? 'Reenviar favorito: ' + data.alias : 'Crear envío'}}</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>local_shipping</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Mis envíos</li>
                    <li class="breadcrumb-item">Crear envío</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Ingresa la siguiente información para generar una nueva guía.</h5>
                </div>
                <div class="card-body">
                    <mat-stepper orientation="vertical" #stepper [linear]="false">
                        
                        <ng-template matStepperIcon="edit">
                            <mat-icon>done</mat-icon>
                          </ng-template>

                        <mat-step [stepControl]="formOriginAddress" errorMessage="Verificar datos de origen." >
                            <ng-template matStepLabel>
                                <h2 class="m-0 font-gray">Dirección de origen 
                                <mat-icon class="align-middle">location_on</mat-icon> 
                                <!-- <mat-icon *ngIf="formOriginAddress.valid" class="text-success align-middle m-3">check_circle</mat-icon> -->
                            </h2>
                            </ng-template>
                            <form [formGroup]="formOriginAddress">
                                <div class="row">
                                    <div class="col-sm-6 align-middle">
                                        <small class="text-muted">Campos son obligatorios *</small>
                                    </div>
                                    <div class="col-sm-6 text-end mb-2">
                                        <button mat-stroked-button type="button" color="primary" (click)="openDialogSelectAddress(isOrigin=1)">Direcciones guardadas <mat-icon>import_contacts</mat-icon></button>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Nombre completo</mat-label>
                                            <input matInput formControlName="name">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Empresa</mat-label>
                                            <input matInput formControlName="company">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input matInput formControlName="email">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-{{userObj.international_shipping?'3':'6'}}">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Teléfono</mat-label>
                                            <input matInput formControlName="phone">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="userObj.international_shipping" class="col-sm-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>País </mat-label>
                                            <mat-select formControlName="country" (selectionChange)="onCheckZipCodes($event, 'origin')">
                                                <mat-select-trigger>
                                                    <img *ngIf="formOriginAddress.get('country')?.value" src="https://buho.nyc3.cdn.digitaloceanspaces.com/buho-wing/flags/{{ formOriginAddress.get('country')?.value | lowercase }}.svg" alt="{{formOriginAddress.get('country')?.value}}" class="flag"> 
                                                    {{ selectedCountryOrigin?.country }}
                                                </mat-select-trigger>
                                                <mat-option *ngFor="let option of originIntCountries" [value]="option.countryCode">
                                                    <img src="https://buho.nyc3.cdn.digitaloceanspaces.com/buho-wing/flags/{{ option.countryCode | lowercase }}.svg" alt="{{option.country}}" class="flag"> {{option.country}} 
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div *ngIf="withZipCode">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Calle</mat-label>
                                                <input matInput formControlName="street">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Número</mat-label>
                                                <input matInput formControlName="number">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Código postal</mat-label>
                                                <input (keyup)="getSuburbs($event,'origin'); emptyFieldsPostalCode('origin')" matInput formControlName="postalCode" #opc>
                                                <mat-hint class="text-orange" *ngIf="zipCodeError && formOriginAddress.get('country')?.value == 'MX'">
                                                    Código postal <strong>inválido</strong>
                                                </mat-hint> 
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label *ngIf="formOriginAddress.get('country')?.value == 'CL'; else colonia">Comuna</mat-label>
                                                <ng-template #colonia><mat-label>Colonia</mat-label></ng-template>
                                                <input type="text" matInput formControlName="suburb" [matAutocomplete]="auto">
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of originFilteredOptions" [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                            </mat-form-field>
                                            <!-- <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Colonia</mat-label>
                                                <mat-select formControlName="suburb">
                                                    <mat-option *ngFor="let colonia of origin.neighborhoods" [value]="colonia">{{colonia}}</mat-option>
                                                </mat-select>
                                            </mat-form-field> -->
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Ciudad</mat-label>
                                                <input matInput formControlName="city">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Estado</mat-label>
                                                <input matInput formControlName="state" >
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!withZipCode">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Estado</mat-label>
                                                <input (keyup)="emptyFieldsNoPostalCode('origin')" type="text" matInput formControlName="state" [matAutocomplete]="autoStateOrigin" (input)="filterStates($event, 'origin')">
                                                <mat-autocomplete #autoStateOrigin="matAutocomplete">
                                                    <mat-option *ngFor="let option of filOriginIntStates" [value]="option.state"  (onSelectionChange)="onStateSelectionChange(option.countryCode, option.state, 'origin'); emptyFieldsOnChange('origin')">
                                                        {{option.state}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Ciudad</mat-label>
                                                <input (keyup)="emptyFieldsNoPostalCode('origin')" type="text" matInput formControlName="city" [matAutocomplete]="autoCityOrigin" (input)="filterCities($event, 'origin')">
                                                <mat-autocomplete #autoCityOrigin="matAutocomplete">
                                                    <mat-option *ngFor="let option of filOriginIntCities" [value]="option.city" (onSelectionChange)="onCitySelectionChange(option.city, 'origin'); emptyFieldsOnChangeSuburb('origin')">
                                                        {{option.city}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Colonia</mat-label>
                                                <input type="text" matInput formControlName="suburb" [matAutocomplete]="autoOrigin">
                                                    <mat-autocomplete #autoOrigin="matAutocomplete">
                                                        <mat-option *ngFor="let option of originIntSuburb" [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-9">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Calle</mat-label>
                                                <input matInput formControlName="street">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Número</mat-label>
                                                <input matInput formControlName="number">
                                            </mat-form-field>
                                        </div>
                                        <!-- <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Código postal</mat-label>
                                                <input (keyup)="getSuburbs($event,'origin')" matInput formControlName="postalCode">
                                                <mat-hint class="text-orange" *ngIf="zipCodeError">
                                                    Codigo postal <strong>invalido</strong>
                                                </mat-hint> 
                                            </mat-form-field>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>RFC</mat-label>
                                            <input matInput formControlName="rfc">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Referencia</mat-label>
                                            <input matInput formControlName="reference">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-6">
                                        <button mat-flat-button matStepperNext class="text-white" form="formOriginAddress" type="submit" color="accent">Siguiente <mat-icon>navigate_next</mat-icon></button>
                                    </div>
                                    <div class="col-sm-6 text-end">
                                        <!-- <button mat-stroked-button (click)="saveAddressData('origin')" type="button" color="primary">Guardar esta dirección <mat-icon>save_as</mat-icon></button> -->
                                        <button [disabled]="!formOriginAddress.valid" mat-stroked-button (click)="openDialogSaveAddress(formOriginAddress,'origin')" type="button" color="primary">Guardar esta dirección <mat-icon>save_as</mat-icon></button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="formDestinationAddress" errorMessage="Verificar datos de destino.">
                            <ng-template matStepLabel>
                                <h2 class="m-0 font-gray">Dirección de destino 
                                    <mat-icon class="align-middle">person_pin_circle</mat-icon>
                                    <!-- <mat-icon *ngIf="formDestinationAddress.valid" class="text-success align-middle m-3">check_circle</mat-icon>     -->
                                </h2>
                            </ng-template>
                            <form [formGroup]="formDestinationAddress">
                                <div class="row">
                                    <div class="col-sm-6 align-middle">
                                        <small class="text-muted">Campos son obligatorios *</small>
                                    </div>
                                    <div class="col-sm-6 text-end mb-2">
                                        <button mat-stroked-button type="button" color="primary" (click)="openDialogSelectAddress(isOrigin=0)">Direcciones guardadas <mat-icon>import_contacts</mat-icon></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Nombre completo</mat-label>
                                            <input matInput formControlName="name">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Empresa</mat-label>
                                            <input matInput formControlName="company">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input matInput formControlName="email">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-{{userObj.international_shipping?'3':'6'}}">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Teléfono</mat-label>
                                            <input matInput formControlName="phone">
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="userObj.international_shipping" class="col-sm-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>País </mat-label>
                                            <mat-select formControlName="country" (selectionChange)="onCheckZipCodes($event, 'destination')">
                                                <mat-select-trigger>
                                                    <img *ngIf="formDestinationAddress.get('country')?.value" src="https://buho.nyc3.cdn.digitaloceanspaces.com/buho-wing/flags/{{ formDestinationAddress.get('country')?.value | lowercase }}.svg" alt="{{formDestinationAddress.get('country')?.value}}" class="flag"> 
                                                    {{ selectedCountryDestination?.country }}
                                                </mat-select-trigger>
                                                <mat-option *ngFor="let option of destinationIntCountries" [value]="option.countryCode">
                                                    <img src="https://buho.nyc3.cdn.digitaloceanspaces.com/buho-wing/flags/{{ option.countryCode | lowercase }}.svg" alt="{{option.country}}" class="flag"> {{option.country}} 
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div *ngIf="withZipCodeDestination">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Calle</mat-label>
                                                <input matInput formControlName="street">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Número</mat-label>
                                                <input matInput formControlName="number">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Código postal</mat-label>
                                                <input (keyup)="getSuburbs($event,'destination'); emptyFieldsPostalCode('destination')" matInput formControlName="postalCode" #dpc>
                                                <mat-hint class="text-orange" *ngIf="zipCodeErrorDestination && formDestinationAddress.get('country')?.value == 'MX'">
                                                    Código postal <strong>inválido</strong>
                                                </mat-hint> 
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label *ngIf="formDestinationAddress.get('country')?.value == 'CL'; else colonia">Comuna</mat-label>
                                                <ng-template #colonia><mat-label>Colonia</mat-label></ng-template>
                                                <input type="text" matInput formControlName="suburb" [matAutocomplete]="autoDestination">
                                                    <mat-autocomplete #autoDestination="matAutocomplete">
                                                        <mat-option *ngFor="let option of destinationFilteredOptions" [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                            </mat-form-field>
                                            <!-- <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Colonia</mat-label>
                                                <mat-select formControlName="suburb">
                                                <mat-option  *ngFor="let colonia of destination.neighborhoods" [value]="colonia">{{colonia}}</mat-option>
                                                </mat-select>
                                            </mat-form-field> -->
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Ciudad</mat-label>
                                                <input  matInput formControlName="city">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Estado</mat-label>
                                                <input matInput formControlName="state">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!withZipCodeDestination">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Estado</mat-label>
                                                <input (keyup)="emptyFieldsNoPostalCode('destination')" type="text" matInput formControlName="state" [matAutocomplete]="autoStateDestination" (input)="filterStates($event, 'destination')">
                                                <mat-autocomplete #autoStateDestination="matAutocomplete">
                                                    <mat-option *ngFor="let option of filDestinationIntStates" [value]="option.state"  (onSelectionChange)="onStateSelectionChange(option.countryCode, option.state, 'destination'); emptyFieldsOnChange('destination')">
                                                        {{option.state}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Ciudad</mat-label>
                                                <input (keyup)="emptyFieldsNoPostalCode('destination')" type="text" matInput formControlName="city" [matAutocomplete]="autoCityDestination" (input)="filterCities($event, 'destination')">
                                                <mat-autocomplete #autoCityDestination="matAutocomplete">
                                                    <mat-option *ngFor="let option of filDestinationIntCities" [value]="option.city" (onSelectionChange)="onCitySelectionChange(option.city, 'destination'); emptyFieldsOnChangeSuburb('destination')">
                                                        {{option.city}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Colonia</mat-label>
                                                <input type="text" matInput formControlName="suburb" [matAutocomplete]="autoIntDestination">
                                                    <mat-autocomplete #autoIntDestination="matAutocomplete">
                                                        <mat-option *ngFor="let option of destinationIntSuburb" [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-9">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Calle</mat-label>
                                                <input matInput formControlName="street">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Número</mat-label>
                                                <input matInput formControlName="number">
                                            </mat-form-field>
                                        </div>
                                        <!-- <div class="col-sm-3">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Código postal</mat-label>
                                                <input (keyup)="getSuburbs($event,'destination')" matInput formControlName="postalCode">
                                                <mat-hint class="text-orange" *ngIf="zipCodeError">
                                                    Codigo postal <strong>invalido</strong>
                                                </mat-hint> 
                                            </mat-form-field>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>RFC</mat-label>
                                            <input matInput formControlName="reference">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Referencia</mat-label>
                                            <input matInput formControlName="reference">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-6">
                                        <button mat-flat-button matStepperNext class="text-white" form="formDestinationAddress" type="submit" color="accent">Siguiente <mat-icon>navigate_next</mat-icon></button>
                                    </div>
                                    <div class="col-sm-6 text-end">
                                        <button [disabled]="!formDestinationAddress.valid" (click)="openDialogSaveAddress(formDestinationAddress,'destination')" mat-stroked-button type="button" color="primary">Guardar esta dirección <mat-icon>save_as</mat-icon></button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="formShipment" errorMessage="Verificar datos del envío.">
                            
                            <ng-template matStepLabel>
                                <h2 class="m-0 font-gray">Paquete 
                                    <mat-icon class="align-middle">view_in_ar</mat-icon>
                                    <!-- <mat-icon *ngIf="formShipment.valid" class="text-success align-middle m-3">check_circle</mat-icon>                                         -->
                                </h2>
                            </ng-template>
                            <form [formGroup]="formShipment">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Número de orden</mat-label>
                                            <input matInput formControlName="shipmentId">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div formArrayName="packages" *ngFor="let package of packages.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-12 col-sm-4">
                                                <h3>
                                                    Tipo de paquete:
                                                    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="shipmentType" name="shipmentType" class="ms-2" value="box">
                                                        <mat-button-toggle value="box"><mat-icon>inventory_2</mat-icon> Caja</mat-button-toggle>
                                                        <mat-button-toggle value="envelope"><mat-icon>mail</mat-icon> Sobre</mat-button-toggle>
                                                        <mat-button-toggle value="tarima"><mat-icon>pallet</mat-icon> Tarima</mat-button-toggle>
                                                    </mat-button-toggle-group>
                                                </h3>
                                            </div>
                                        </div>
                                        <div *ngIf="group.value">
                                            <div  class="col-12 col-sm-12">
                                                <small class="text-orange">
                                                    {{ group.value == 'envelope' ? 
                                                        'La mayoría de las paqueterías no permiten exceder las siguientes dimensiones de "sobres": Largo: 35 cm. Ancho: 24 cm. Alto: 1.5 cm. Peso: 2 kg.' :
                                                        group.value == 'box'     ? 
                                                        'La mayoría de las paqueterías no permiten exceder las siguientes dimensiones de "cajas": Largo: 300 cm. Ancho: 100 cm. Alto: 100 cm. Peso: 100 kg.':
                                                        group.value == 'tarima'  ? 
                                                        '' :
                                                        null
                                                    }}
                                                </small>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Largo (cm)</mat-label>
                                                    <input matInput formControlName="length" type="number">
                                                </mat-form-field>                                        
                                            </div>
                                            <div class="col-sm-3">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Ancho (cm)</mat-label>
                                                    <input matInput formControlName="width" type="number">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-3">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Alto (cm)</mat-label>
                                                    <input matInput formControlName="height" type="number">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-3">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Peso (kg)</mat-label>
                                                    <input matInput formControlName="weight" type="number">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Contenido</mat-label>
                                                    <textarea matInput formControlName="content"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="formShipment.value.packages.length > 1" class="col-12 text-end">
                                        <button (click)="removePackage(i)" mat-stroked-button type="button" color="warn">Remover paquete <mat-icon>remove</mat-icon></button>
                                    </div>
                                </div>
                            </form>
                            <div class="row">
                                <div class="col-sm-6">
                                    <button (click)="getRates()" matStepperNext mat-flat-button class="text-white" form="formShipment" type="submit" color="accent">Siguiente <mat-icon>navigate_next</mat-icon></button>
                                </div>
                                <div class="col-sm-6 text-end mt-2">
                                    <button [disabled]="!formShipment.valid" (click)="addPackage()" mat-stroked-button type="button" color="primary">Agregar otro paquete <mat-icon>add</mat-icon></button>
                                    <!-- <button [hidden]="!clickedAddPkg" (click)="test()" mat-stroked-button type="button" color="primary">Probar<mat-icon>add</mat-icon></button> -->
                                </div>
                            </div>
                        </mat-step>
                        <!-- <mat-step>
                            <ng-template matStepLabel>
                                <h2 class="m-0 font-gray">Descarga tu guía <mat-icon class="align-middle">download</mat-icon></h2>
                            </ng-template>
                            <div class="row">
                                <div class="row mt-2">
                                    <div class="col-sm-12">
                                        <h3>
                                            <span *ngIf="base64">{{labelName}}.pdf</span>
                                            <button [disabled]="!base64" (click)="onClickDownloadPdf()"  mat-flat-button class="ms-3 text-white" color="accent">Descarga tu guía <mat-icon>download</mat-icon></button>
                                            <button [disabled]="!base64" (click)="print()" mat-stroked-button class="ms-3" color="accent">Imprime tu guía <mat-icon>print</mat-icon></button>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </mat-step> -->
                        <mat-step>
                            <ng-template  matStepLabel  >
                                <h2 class="m-0 font-gray" >Selecciona tu paquetería <mat-icon class="align-middle">local_shipping</mat-icon></h2>
                            </ng-template> 
                            <div *ngIf="formShipment.valid && formOriginAddress.valid && formDestinationAddress.valid && !typeOfCarriers && typeOfCarriersError == false" class="mt-5 d-flex justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div *ngIf="typeOfCarriersError">
                                <div class="mt-5 d-flex justify-content-center align-items-center">
                                    <p class="align-middle"><mat-icon class="text-danger mt-2 align-middle">error</mat-icon></p>
                                    <p class="align-middle">&nbsp;No se encontraron paqueterias para este envío</p>
                                </div>
                            </div>
                            <!-- <div *ngFor="let carrier of typeOfCarriers; index as i" [ngClass]="{ 'preselected' : i === 0 && foundQQ }">
                                <div *ngIf="i !== 0 || !foundQQ; else selectedQuote" class="container mt-4">
                                    <div *ngIf="typeOfCarriers && carrier.isZoneExt == true; else noExtArea" class="row align-items-center justify-content-start">
                                        <div class="col-sm-2 col-6 ">
                                            <img class="w-75 p-md-3" src="../../../assets/images/carriers/{{imgFormat(carrier.carrier)}}-sm.jpg" alt="{{imgFormat(carrier.carrier)}}">
                                        </div>
                                        <div class="col-6 col-md-4 mt-1 p-3 p-md-0">
                                            <p class="align-middle">{{carrier.service}}</p>
                                            <span class="badge bg-info">Zona extendida</span>
                                        </div>
                                        <div class="col text-end">
                                            <div class="row align-items-center justify-content-center">
                                                <div class="col">
                                                    <p class="fs-5">{{calculateTotalPrice(carrier.total_price | currency)}}</p>
                                                    <span class="badge bg-info">+ $200</span>
                                                </div>
                                                <div class="col-md-4 col-12">
                                                    <button class="text-white mx-auto w-100"  color="accent" mat-flat-button="button" (click)="openDialogCheckout(stepper, carrier)" [disabled]="!formOriginAddress.valid||!formDestinationAddress.valid||!formShipment.valid||clicked||!carrier.enough">Seleccionar<mat-icon>navigate_next</mat-icon></button>
                                                </div>
                                                <div class="col-md-1 col-1">
                                                    <mat-icon *ngIf="!carrier.enough" class="text-danger" matTooltip="Crédito insuficiente para comprar" [matTooltipPosition]="'above'">warning</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #noExtArea>
                                        <div *ngIf="typeOfCarriers"
                                            class="row align-items-center justify-content-start">
                                            <div class="col-sm-2 col-6 ">
                                                <img class="w-75 p-md-3" src="../../../assets/images/carriers/{{imgFormat(carrier.carrier)}}-sm.jpg"
                                                    alt="{{imgFormat(carrier.carrier)}}">
                                            </div>
                                            <div class="col-6 col-md-4 mt-1 p-3 p-md-0">
                                                <p class="align-middle">{{carrier.service}}</p>
                                            </div>
                                            <div class="col text-end">
                                                <div class="row align-items-center justify-content-center">
                                                    <div class="col">
                                                        <p class="fs-5">{{carrier.total_price | currency}}</p>
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        <button class="text-white mx-auto w-100" color="accent" mat-flat-button="button"
                                                            (click)="openDialogCheckout(stepper, carrier)"
                                                            [disabled]="!formOriginAddress.valid||!formDestinationAddress.valid||!formShipment.valid||clicked||!carrier.enough">Seleccionar<mat-icon>navigate_next</mat-icon></button>
                                                    </div>
                                                    <div class="col-md-1 col-1">
                                                        <mat-icon *ngIf="!carrier.enough" class="text-danger" matTooltip="Crédito insuficiente para comprar"
                                                            [matTooltipPosition]="'above'">warning</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                                <ng-template #selectedQuote>
                                    <div class="preselected-note">
                                        <p class="mb-2">* Paquetería seleccionada en cotización rápida:</p>
                                    </div>
                                    <div *ngIf="typeOfCarriers && carrier.isZoneExt == true; else noExtArea" class="row align-items-center justify-content-start">
                                        <div class="col-sm-2 col-6 ">
                                            <img class="w-75 p-md-3" src="../../../assets/images/carriers/{{imgFormat(carrier.carrier)}}-sm.jpg" alt="{{imgFormat(carrier.carrier)}}">
                                        </div>
                                        <div class="col-6 col-md-4 mt-1 p-3 p-md-0">
                                            <p class="align-middle">{{carrier.service}}</p>
                                            <span class="badge bg-info">Zona extendida</span>
                                        </div>
                                        <div class="col text-end">
                                            <div class="row align-items-center justify-content-center">
                                                <div class="col">
                                                    <p class="fs-5">{{calculateTotalPrice(carrier.total_price | currency)}}</p>
                                                    <span class="badge bg-info">+ $200</span>
                                                </div>
                                                <div class="col-md-4 col-12">
                                                    <button class="text-white mx-auto w-100"  color="accent" mat-flat-button="button" (click)="openDialogCheckout(stepper, carrier)" [disabled]="!formOriginAddress.valid||!formDestinationAddress.valid||!formShipment.valid||clicked||!carrier.enough">Seleccionar<mat-icon>navigate_next</mat-icon></button>
                                                </div>
                                                <div class="col-md-1 col-1">
                                                    <mat-icon *ngIf="!carrier.enough" class="text-danger" matTooltip="Crédito insuficiente para comprar" [matTooltipPosition]="'above'">warning</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #noExtArea>
                                        <div *ngIf="typeOfCarriers"
                                            class="row align-items-center justify-content-start">
                                            <div class="col-sm-2 col-6 ">
                                                <img class="w-75 p-md-3" src="../../../assets/images/carriers/{{imgFormat(carrier.carrier)}}-sm.jpg"
                                                    alt="{{imgFormat(carrier.carrier)}}">
                                            </div>
                                            <div class="col-6 col-md-4 mt-1 p-3 p-md-0">
                                                <p class="align-middle">{{carrier.service}}</p>
                                            </div>
                                            <div class="col text-end">
                                                <div class="row align-items-center justify-content-center">
                                                    <div class="col">
                                                        <p class="fs-5">{{carrier.total_price | currency}}</p>
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        <button class="text-white mx-auto w-100" color="accent" mat-flat-button="button"
                                                            (click)="openDialogCheckout(stepper, carrier)"
                                                            [disabled]="!formOriginAddress.valid||!formDestinationAddress.valid||!formShipment.valid||clicked||!carrier.enough">Seleccionar<mat-icon>navigate_next</mat-icon></button>
                                                    </div>
                                                    <div class="col-md-1 col-1">
                                                        <mat-icon *ngIf="!carrier.enough" class="text-danger" matTooltip="Crédito insuficiente para comprar"
                                                            [matTooltipPosition]="'above'">warning</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-template>
                                <mat-divider class="mt-3 md-3"></mat-divider>   
                            </div> -->
                            <div *ngFor="let x of typeOfCarriers; index as i">
                                <div *ngIf="i === 0 && foundQQ">
                                    <p class="mt-2 ps-note">* Paquetería seleccionada en cotización rápida:</p>
                                </div>
                                <div *ngIf="typeOfCarriers" class="quotes-grid" [ngClass]="{ 'preselected' : i === 0 && foundQQ }">
                                    <div class="quotes-grid-a">
                                        <img class="w-75" src="../../../assets/images/carriers/{{ imgFormat(x.carrier) }}-sm.jpg"
                                                    alt="{{ imgFormat(x.carrier) }}">
                                    </div>
                                    <div class="quotes-grid-b">
                                        <p>{{ x.service }}</p>
                                        <span *ngIf="typeOfCarriers && x.isZoneExt == true" class="badge bg-info">Zona extendida</span>
                                    </div>
                                    <div class="quotes-grid-c">
                                        <div>
                                            <mat-icon *ngIf="!x.enough" class="text-danger" matTooltip="Crédito insuficiente para comprar"
                                                [matTooltipPosition]="'above'">warning</mat-icon>
                                        </div>
                                        <p>{{ x.total_price | currency }}</p>
                                        <span *ngIf="typeOfCarriers && x.isZoneExt == true" class="badge bg-info">+ $200</span>
                                    </div>
                                    <div class="quotes-grid-d">
                                        <button class="text-white mx-auto w-100" color="accent" mat-flat-button="button" (click)="openDialogCheckout(stepper, x)"
                                            [disabled]="!formOriginAddress.valid || !formDestinationAddress.valid || !formShipment.valid || clicked || !x.enough">
                                            Seleccionar<mat-icon>navigate_next</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>  
                        </mat-step>
                    </mat-stepper>
                </div>
            </div>
        </div>
    </div>
</div>