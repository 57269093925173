<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Consultar ordenes no exitosas</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>plagiarism</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Consultar ordenes</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="formSearch">
                        <div class="row">
                            <h4>Selecciona fechas o ingresa el número de orden y da click en buscar para mostrar la información.</h4>
                            <div class="col-sm-4">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Fechas</mat-label>
                                    <mat-select formControlName="selFechas" (selectionChange)="onchangeFechas($event)" (mousedown)="borrarOrderNumber()">
                                        <mat-option value="1">Hoy</mat-option>
                                        <mat-option value="2">Este mes</mat-option>
                                        <mat-option value="3">Elegir fechas</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Fecha inicio</mat-label>
                                    <input formControlName="selFechaI" (ngModelChange)="onchangedateI($event)" matInput [matDatepicker]="picker" [min]="minDateI" [max]="maxDateI" placeholder="DD / MM / YYYY" >
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker [disabled]="dateIDis"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Fecha fin</mat-label>
                                    <input formControlName="selFechaF"  (ngModelChange)="onchangedateF($event)" matInput [matDatepicker]="pickerf" [min]="minDateF" [max]="maxDateF" placeholder="DD / MM / YYYY" >
                                    <mat-datepicker-toggle matSuffix [for]="pickerf"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerf [disabled]="dateIDis"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Número de orden</mat-label>
                                    <input matInput formControlName="txtOrderNumber">
                                    <mat-error *ngIf="formSearch.get('txtOrderNumber')?.hasError('required')">El campo es obligatorio</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <button [disabled]="formSearch.invalid" mat-stroked-button class="btn btn-primary mt-2 me-2 mb-1" matTooltip="Filtrar resultados" [matTooltipShowDelay]="1000" (click)="searchFilter()"><mat-icon>search</mat-icon><span class="btn-no-text"> Buscar</span></button>
                            </div>
                        </div>
                    </form>
                    
                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="primary" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    <div [hidden]="preLoadHidden">
                        <div class="overflow-auto w-100">
                            <mat-form-field>
                                <mat-label>
                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                            </mat-form-field>
                        </div>
                        <div class="mat-elevation-z8 table-responsive">
                            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="last_created_at" matSortDirection="desc" matSortDisableClear class="w-100">
                    
                                <ng-container matColumnDef="last_created_at">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                                    <td class="col-2 pe-2 break-word" mat-cell *matCellDef="let row" matTooltip="Fecha de última modificación" [matTooltipShowDelay]="1000" [matTooltipPosition]="'above'"> 
                                        {{row.last_created_at | date:'dd/MM/yyyy HH:mm' }}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>

                                <ng-container matColumnDef="company_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Compañía </th>
                                    <td class="col-3 break-word align-text-top pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> 
                                        <p>{{row.company_name}}</p>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="order_number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de orden </th>
                                    <td class="col-3 break-word align-text-top pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> 
                                        <p>{{row.order_number}}</p>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>

                                <ng-container matColumnDef="records">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Intentos </th>
                                    <td class="col-1 break-word align-text-top pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> 
                                        <p>{{row.records}}</p>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                    <td mat-cell *matCellDef="let row" class="text-center" >
                                        <button mat-icon-button matTooltip="Detalles" [matTooltipPosition]="'above'" color="primary" (click)="getDetails(row)">
                                            <mat-icon class="me-2">info</mat-icon> Ver detalles
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                
                    
                                <tr class="mat-row" *matNoDataRow>
                                    <td *ngIf="dataList.length == 0" class="mat-cell" colspan="6">No hay errores para mostrar</td>
                                    <td *ngIf="dataList.length > 1" class="mat-cell" colspan="6">No existen errores que coincidan con "{{input.value}}"</td>
                                </tr>
                            </table>                                    
                            <mat-paginator [pageSizeOptions]="getPageSizeOptions()" ></mat-paginator>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>