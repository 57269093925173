<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Reporte ejecutivo</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>request_quote</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Reporte - Ejecutivo</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <h4>Selecciona fechas y da click en buscar para mostrar la información</h4>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fechas</mat-label>
                                <mat-select [formControl]="selFechas" (selectionChange)="onchangeFechas($event)">
                                    <mat-option value="1">Hoy</mat-option>
                                    <mat-option value="2">Este mes</mat-option>
                                    <mat-option value="3">Elegir fechas</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha inicio</mat-label>
                                <input name="pickerI" (ngModelChange)="onchangedateI($event)" matInput [matDatepicker]="picker" [min]="minDateI" [max]="maxDateI" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayI">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha fin</mat-label>
                                <input name="pickerF" (ngModelChange)="onchangedateF($event)" matInput [matDatepicker]="pickerf" [min]="minDateF" [max]="maxDateF" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayF">
                                <mat-datepicker-toggle matSuffix [for]="pickerf"></mat-datepicker-toggle>
                                <mat-datepicker #pickerf [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4" [hidden]="chartTipo.series.length==0">
                          <mat-form-field class="w-100" appearance="outline" >
                            <mat-label>Tipo de envío</mat-label>
                            <mat-select [formControl]="selTipoEnvio" (selectionChange)="setGraphFilter($event)">
                                <mat-option value="0">Todos</mat-option>
                                <mat-option value="nat" [hidden]="chartTipo.series[0]<=0">Nacional</mat-option>
                                <mat-option value="int" [hidden]="chartTipo.series[1]<=0">Internacional</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-4" [hidden]="selCurrencyList.length<=1 || this.okCurrency.length<1">
                          <mat-form-field class="w-100" appearance="outline" >
                              <mat-label>Moneda</mat-label>
                              <mat-select [formControl]="selCurrency" (selectionChange)="setGraphFilter($event)">
                                  <mat-option value="0">Todos</mat-option>
                                  <mat-option *ngFor="let item of selCurrencyList" [value]="item.currency">{{item.currency}}</mat-option>
                              </mat-select>
                          </mat-form-field>
                        </div>
                        <!-- <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Compañías</mat-label>
                                <mat-select [formControl]="selCompanies" multiple>
                                    <mat-option *ngFor="let item of selCompaniesList" [value]="item.id">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4" [hidden]="true">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Usuarios</mat-label>
                                <mat-select [formControl]="selUsers" multiple>
                                    <mat-option *ngFor="let item of selUsersList" [value]="item.id">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                         -->
                        <div class="col-sm-4">
                            <button mat-stroked-button class="btn btn-primary mt-2 me-2 mb-1" matTooltip="Filtrar resultados" [matTooltipShowDelay]="1000" (click)="searchFilter()"><mat-icon>search</mat-icon><span class="btn-no-text"> Buscar</span></button>
                            <!-- <button (click)="exporter.exportTable('xlsx', {fileName:'Billing Data' })" mat-stroked-button class="btn btn-outline-success mt-2 me-2 mb-1" matTooltip="Exportar a Excel" [matTooltipShowDelay]="1000" [hidden]="preLoadHidden">
                                <mat-icon>download</mat-icon><span class="btn-no-text"> Descargar Excel </span>
                            </button> -->
                            
                        </div>
                    </div>
                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="primary" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    
                                     
                </div>
            </div>
        </div>

        <div class="row" >
          <h2 class="text-center" *ngIf="chartPieGoogleCli.dataTable.length" >Guías generadas</h2>
          <div class="col-sm-6" *ngIf="chartPieGoogleCli.dataTable.length" >
            <div class="card small-widget">
              <div class="card-body success">
                <mat-tab-group>
                  <mat-tab label="Gráfica"> 
                    <div class="googlechart">
                      <google-chart [data]="chartPieGoogleCli"  style="max-width: 100%; max-height: 100%;" ></google-chart>
                    </div>
                  </mat-tab>
                  <mat-tab label="Tabla"> 
                    <div *ngIf="chartPieGoogleCli.dataTable.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>{{ chartPieGoogleCli.dataTable[0][0] }}</th>
                            <th>{{ chartPieGoogleCli.dataTable[0][1] }}</th>
                            <th>%</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of chartPieGoogleCli.dataTable; let i = index">
                            <td *ngIf="i">{{ row[0] }}</td>
                            <td *ngIf="i">{{ row[1] | number}}</td>
                            <td *ngIf="i">{{ row[1]/sumClientData | percent:'1.2-2'}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ sumClientData | number}}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        
          <div class="col-sm-6" *ngIf="chartPieGooglePro.dataTable.length">
            <div class="card small-widget">
              <div class="card-body success">
                <mat-tab-group>
                  <mat-tab label="Gráfica"> 
                    <div class="googlechart">
                      <google-chart [data]="chartPieGooglePro" ></google-chart>
                    </div>
                  </mat-tab>
                  <mat-tab label="Tabla"> 
                    <div *ngIf="chartPieGooglePro.dataTable.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>{{ chartPieGooglePro.dataTable[0][0] }}</th>
                            <th>{{ chartPieGooglePro.dataTable[0][1] }}</th>
                            <th>%</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of chartPieGooglePro.dataTable; let i = index">
                            <td *ngIf="i">{{ row[0] }}</td>
                            <td *ngIf="i">{{ row[1] | number}}</td>
                            <td *ngIf="i">{{ row[1]/sumProviderData | percent:'1.2-2'}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ sumProviderData | number}}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>

          <h2 class="text-center" *ngIf="chartPieGoogleCliMon.dataTable.length" >Montos</h2>
          <div class="col-sm-12" *ngIf="chartPieGoogleCliMon.dataTable.length">
            <div class="card small-widget">
              <div class="card-body success">
                <mat-tab-group>
                  <mat-tab label="Gráfica"> 
                    <div class="googlechart">
                      <google-chart [data]="chartPieGoogleCliMon" ></google-chart>
                    </div>
                  </mat-tab>
                  <mat-tab label="Tabla"> 
                    <div *ngIf="chartPieGoogleCliMon.dataTable.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>{{ auxTableClientMonto[0][0] }}</th>
                            <th>{{ auxTableClientMonto[0][1] }}</th>
                            <th>{{ auxTableClientMonto[0][2] }}</th>
                            <th>{{ auxTableClientMonto[0][3] }}</th>
                            <th>{{ auxTableClientMonto[0][4] }}</th>
                            <th>{{ auxTableClientMonto[0][5] }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of auxTableClientMonto; let i = index">
                            <td *ngIf="i">{{ row[0] }}</td>
                            <td *ngIf="i">{{ '$ '+(row[1] | number)}}</td>
                            <td *ngIf="i">{{ '$ '+(row[2] | number)}}</td>
                            <td *ngIf="i">{{ '$ '+(row[3] | number)}}</td>
                            <td *ngIf="i">{{ row[4].toFixed(2) }} %</td>
                            <td *ngIf="i">{{ row[5].toFixed(2) }} %</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ '$ '+(sumAuxTableClientMonto[0] | number)}}</th>
                            <th>{{ '$ '+(sumAuxTableClientMonto[1] | number)}}</th>
                            <th>{{ '$ '+(sumAuxTableClientMonto[2] | number)}}</th>
                            <th>{{ sumAuxTableClientMonto[3] }} %</th>
                            <th>{{ sumAuxTableClientMonto[4] }} %</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        
          <div class="col-sm-12" *ngIf="chartPieGoogleProMon.dataTable.length">
            <div class="card small-widget">
              <div class="card-body success">
                <mat-tab-group>
                  <mat-tab label="Gráfica"> 
                    <div class="googlechart">
                      <google-chart [data]="chartPieGoogleProMon" ></google-chart>
                    </div>
                  </mat-tab>
                  <mat-tab label="Tabla"> 
                    <div *ngIf="chartPieGoogleProMon.dataTable.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>{{ auxTableProviderMonto[0][0] }}</th>
                            <th>{{ auxTableProviderMonto[0][1] }}</th>
                            <th>{{ auxTableProviderMonto[0][2] }}</th>
                            <th>{{ auxTableProviderMonto[0][3] }}</th>
                            <th>{{ auxTableProviderMonto[0][4] }}</th>
                            <th>{{ auxTableProviderMonto[0][5] }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of auxTableProviderMonto; let i = index">
                            <td *ngIf="i">{{ row[0] }}</td>
                            <td *ngIf="i">{{ '$ '+(row[1] | number)}}</td>
                            <td *ngIf="i">{{ '$ '+(row[2] | number)}}</td>
                            <td *ngIf="i">{{ '$ '+(row[3] | number)}}</td>
                            <td *ngIf="i">{{ row[4].toFixed(2) }} %</td>
                            <td *ngIf="i">{{ row[5].toFixed(2) }} %</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ '$ '+(sumAuxTableProviderMonto[0] | number)}}</th>
                            <th>{{ '$ '+(sumAuxTableProviderMonto[1] | number)}}</th>
                            <th>{{ '$ '+(sumAuxTableProviderMonto[2] | number)}}</th>
                            <th>{{ sumAuxTableProviderMonto[3] }} %</th>
                            <th>{{ sumAuxTableProviderMonto[4] }} %</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>

          <h2 class="text-center" *ngIf="auxClientKgsData.length" >KG más usado</h2>
          <div class="col-sm-12" *ngIf="auxClientKgsData.length">
            <div class="card small-widget">
              <div class="card-body success">

                <div class="row">
                  <div class="col-sm-6"> 
                    <div *ngIf="auxClientKgsData.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>KG</th>
                            <th>#</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of auxClientKgsData; let i = index" (click)="rowSelected(i, row['key'], 'client')" [class.table-active]="rowSelectedClientKgs == i">
                            <td >{{ row['key'] }}</td>
                            <td >{{ (row["total"] | number)}}</td>
                            <td > </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ (getTotalKGCli(auxClientKgsData) | number)}}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="col-sm-6" *ngIf="chartPieGoogleCliKgs.dataTable.length"> 
                    <google-chart [data]="chartPieGoogleCliKgs" ></google-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12" *ngIf="auxProviderKgsData.length">
            <div class="card small-widget">
              <div class="card-body success">

                <div class="row">
                  <div class="col-sm-6"> 
                    <div *ngIf="auxProviderKgsData.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>KG</th>
                            <th>#</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of auxProviderKgsData; let i = index" (click)="rowSelected(i, row['key'], 'provider')" [class.table-active]="rowSelectedProviderKgs == i">
                            <td >{{ row['key'] }}</td>
                            <td >{{ (row["total"] | number)}}</td>
                            <td > </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ (getTotalKGCli(auxProviderKgsData) | number)}}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="col-sm-6" *ngIf="chartPieGoogleProKgs.dataTable.length"> 
                    <google-chart [data]="chartPieGoogleProKgs" ></google-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 class="text-center" *ngIf="chartPieGoogleSegmento.dataTable.length" >Segmento más usado</h2>
          <div class="col-sm-6" *ngIf="chartPieGoogleSegmento.dataTable.length">
            <div class="card small-widget">
              <div class="card-body success">
                    <google-chart [data]="chartPieGoogleSegmento" ></google-chart>
                  
              </div>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="chartPieGoogleSegmento.dataTable.length">
            <div class="card small-widget">
              <div class="card-body success">
                <google-chart [data]="chartPieGoogleSegModerno" ></google-chart>
              </div>
            </div>
          </div>
          
          <h2 class="text-center" *ngIf="chartPieGoogleSegmento.dataTable.length" >Nacional / Internacional</h2>
          <div class="col-sm-12" *ngIf="chartPieGoogleNatInt.dataTable.length">
            <div class="card small-widget">
              <div class="card-body success">
                <mat-tab-group>
                  <mat-tab label="Gráfica"> 
                    <div class="googlechart">
                      <google-chart [data]="chartPieGoogleNatInt" ></google-chart>
                    </div>
                  </mat-tab>
                  <mat-tab label="Tabla"> 
                    <div *ngIf="chartPieGoogleNatInt.dataTable.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>{{ auxTableIntNatMonto[0][0] }}</th>
                            <th>{{ auxTableIntNatMonto[0][1] }}</th>
                            <th>{{ auxTableIntNatMonto[0][2] }}</th>
                            <th>{{ auxTableIntNatMonto[0][3] }}</th>
                            <th>{{ auxTableIntNatMonto[0][4] }}</th>
                            <th>{{ auxTableIntNatMonto[0][5] }}</th>
                            <th>{{ auxTableIntNatMonto[0][6] }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of auxTableIntNatMonto; let i = index">
                            <td *ngIf="i">{{ row[0] }}</td>
                            <td *ngIf="i">{{ row[1] | number}}</td>
                            <td *ngIf="i">{{ '$ '+(row[2] | number)}}</td>
                            <td *ngIf="i">{{ '$ '+(row[3] | number)}}</td>
                            <td *ngIf="i">{{ '$ '+(row[4] | number)}}</td>
                            <td *ngIf="i">{{ row[5].toFixed(2) }} %</td>
                            <td *ngIf="i">{{ row[6].toFixed(2) }} %</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ sumAuxTableIntNatMonto[0] | number }}</th>
                            <th>{{ '$ '+(sumAuxTableIntNatMonto[1] | number)}}</th>
                            <th>{{ '$ '+(sumAuxTableIntNatMonto[2] | number)}}</th>
                            <th>{{ '$ '+(sumAuxTableIntNatMonto[3] | number)}}</th>
                            <th>{{ sumAuxTableIntNatMonto[4] }} %</th>
                            <th>{{ sumAuxTableIntNatMonto[5] }} %</th>
                          </tr>
                        </tfoot>
                      </table>
                      <!-- <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>{{ chartPieGoogleNatInt.dataTable[0][0] }}</th>
                            <th>{{ chartPieGoogleNatInt.dataTable[0][1] }}</th>
                            <th>%</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of chartPieGoogleNatInt.dataTable; let i = index">
                            <td *ngIf="i">{{ row[0] }}</td>
                            <td *ngIf="i">{{ row[1] | number}}</td>
                            <td *ngIf="i">{{ row[1]/sumClientData | percent:'1.2-2'}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ sumClientData | number}}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table> -->
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>

          <h2 class="text-center" *ngIf="chartPieGoogleSegmento.dataTable.length" >Métodos de envío</h2>
          <div class="col-sm-12" *ngIf="auxMetEnvioCliData.length">
            <div class="card small-widget">
              <div class="card-body success">

                <div class="row">
                  <div class="col-sm-6"> 
                    <div *ngIf="auxMetEnvioCliData.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>Método de envío</th>
                            <th>#</th>
                            <th>$</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of auxMetEnvioCliData; let i = index" (click)="rowSelectedMetEnv(i, row['key'], 'client')" [class.table-active]="rowSelectedClientMetEnv == i">
                            <td >{{ row['key'] }}</td>
                            <td >{{ (row["total"] | number)}}</td>
                            <td >{{  '$ '+(row["total_price"] | number)}}</td>
                            <td > </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ (getTotalData(auxMetEnvioCliData, 'total') | number)}}</th>
                            <th>{{ '$ '+ (getTotalData(auxMetEnvioCliData, 'total_price') | number)}}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="col-sm-6" *ngIf="chartPieGoogleMetEnvioCli.dataTable.length"> 
                    <google-chart [data]="chartPieGoogleMetEnvioCli" ></google-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12" *ngIf="auxMetEnvioProData.length">
            <div class="card small-widget">
              <div class="card-body success">

                <div class="row">
                  <div class="col-sm-6"> 
                    <div *ngIf="auxMetEnvioProData.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th>Método de envío</th>
                            <th>#</th>
                            <th>$</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of auxMetEnvioProData; let i = index" (click)="rowSelectedMetEnv(i, row['key'], 'provider')" [class.table-active]="rowSelectedProviderMetEnv == i">
                            <td >{{ row['key'] }}</td>
                            <td >{{ (row["total"] | number)}}</td>
                            <td >{{  '$ '+(row["total_price"] | number)}}</td>
                            <td > </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th></th>
                            <th>{{ (getTotalData(auxMetEnvioCliData, 'total') | number)}}</th>
                            <th>{{ '$ '+ (getTotalData(auxMetEnvioCliData, 'total_price') | number)}}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="col-sm-6" *ngIf="chartPieGoogleMetEnvioPro.dataTable.length"> 
                    <google-chart [data]="chartPieGoogleMetEnvioPro" ></google-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <h2 class="text-center" *ngIf="auxLsProviderData.length" >Comparativa</h2>
          <div class="col-sm-12" *ngIf="auxLsProviderData.length">
            <div class="card small-widget">
              <div class="card-body success">

                <div class="row">
                  <div class="col-sm-4">
                    <mat-form-field class="w-100" appearance="outline" >
                        <mat-label>Datos</mat-label>
                        <mat-select [formControl]="selData" (selectionChange)="setPeriod($event)">
                          <mat-option *ngFor="let item of arrDataSel" [value]="item.value">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="w-100" appearance="outline" >
                        <mat-label>Periodicidad</mat-label>
                        <mat-select [formControl]="selPeriod" (selectionChange)="setPeriod($event)">
                          <mat-option *ngFor="let item of arrPeriodsSel" [value]="item.value">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="w-100" appearance="outline" >
                        <mat-label>Analizar</mat-label>
                        <mat-select [formControl]="selAnalize" (selectionChange)="setPeriod($event)">
                          <mat-option *ngFor="let item of arrAnalizeSel" [value]="item.value">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12" *ngIf="chartLineGooglePro.dataTable.length"> 
                    <google-chart [data]="chartLineGooglePro" (chartSelect)='chartLineGoogleProSelect($event)'></google-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>

          

        </div>




        <div class="col-sm-3" *ngIf="chartTipo.series.length">
          <div class="card small-widget">
              <div class="card-body success">
                  <span class="f-light">Guías</span>
                  <div class="d-flex   gap-1">
                      <div class="d-flex align-items-end flex-column">
                          <h1 class="mb-0">{{totalNatInt | number}}</h1>
                        </div>
                        <div style="margin-top: -10px;">
                          <!-- <h5 class="mb-0">{{sumNatInt.nat}}</h5> -->
                          <span class="f-12 f-w-500" [style.color]="arrayColorChart?.[0]"><i class="me-1" data-feather="trending-up"></i>Nat:{{sumNatInt.nat}}</span><br/>
                          <span class="f-12 f-w-500" [style.color]="arrayColorChart?.[1]"><i class="me-1" data-feather="trending-up"></i>Int:{{sumNatInt.int}}</span>
                          <div class="bg-gradient">
                              <mat-icon class="text-muted font-success">sell</mat-icon>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        
        <div class="col-sm-3" *ngIf="chartTipo.series.length">
          <div class="card small-widget">
              <div class="card-body success"><span class="f-light">Monto de compras</span>
                  <div class="d-flex align-items-end gap-1">
                      <h1 class="mb-0">$ {{(sumNatInt.totalPriceNat + sumNatInt.totalPriceInt)| number:'1.0-0' }}</h1>
                      <h5 class="mb-0">$ {{ (sumNatInt.totalPriceNat>sumNatInt.totalPriceInt ? sumNatInt.totalPriceNat : sumNatInt.totalPriceInt)| number:'1.0-0'  }}</h5>
                      <!-- <span class="f-12 f-w-500 font-primary "><i class="me-1" data-feather="trending-up"></i>+77%</span> -->
                  </div>
                  <div class="bg-gradient">
                      <mat-icon class="text-muted font-success">paid</mat-icon>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-sm-3" *ngIf="chartTipo.series.length">
          <div class="card small-widget">
              <div class="card-body success"><span class="f-light">KG más usado</span>
                  <div class="d-flex align-items-end gap-1">
                      <h1 class="mb-0">{{bestKg.kg | number}} KG</h1>
                      <h5 class="mb-0">Usado {{ bestKg.count| number:'1.0-0'  }} veces</h5>
                      <!-- <span class="f-12 f-w-500 font-primary "><i class="me-1" data-feather="trending-up"></i>+77%</span> -->
                  </div>
                  <div class="bg-gradient">
                      <mat-icon class="text-muted font-success">difference</mat-icon>
                  </div>
              </div>
          </div>
        </div>
        
        <div class="col-sm-3" *ngIf="chartTipo.series.length">
          <div class="card small-widget">
              <div class="card-body success"><span class="f-light">Segmento más usado</span>
                  <div class="d-flex align-items-end gap-1">
                      <h1 class="mb-0">Wing</h1>
                      <h5 class="mb-0">11564</h5>
                      <!-- <span class="f-12 f-w-500 font-primary "><i class="me-1" data-feather="trending-up"></i>+77%</span> -->
                  </div>
                  <div class="bg-gradient">
                      <mat-icon class="text-muted font-success">grading</mat-icon>
                  </div>
              </div>
          </div>
        </div>
        
        <div class="col-sm-4" *ngIf="chartTipo.series.length">
          <div class="card">
            <!-- <div class="card-header">
              <h5>Tipo</h5> 
            </div> -->
            <div class="card-body" >
              <div class="chart-container skill-chart">
                <div id="circlechart">
                  <apx-chart [chart]="chartTipo.chart" [plotOptions]="chartTipo.plotOptions" [series]="chartTipo.series" [labels]="chartTipo.labels" [legend]="chartTipo.legend" [colors]="chartTipo.colors" [responsive]="chartTipo.responsive"></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4" *ngIf="chartMetodoEnv.series.length">
          <div class="card">
            <div class="card-header">
              <h5>Métodos envío</h5>
            </div>
            <div class="card-body" >
              <div class="chart-container skill-chart">
                <div id="circlechart2">
                  <apx-chart [chart]="chartMetodoEnv.chart" [plotOptions]="chartMetodoEnv.plotOptions" [series]="chartMetodoEnv.series" [labels]="chartMetodoEnv.labels" [legend]="chartMetodoEnv.legend" [colors]="chartMetodoEnv.colors" [responsive]="chartMetodoEnv.responsive"></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4" *ngIf="chartProveedor.series.length">
          <div class="card">
              <div class="card-header">
                <h5>Proveedor</h5>
              </div>
              <div class="card-body" >
                <div class="chart-container skill-chart">
                  <div id="circlechart3">
                    <apx-chart [chart]="chartProveedor.chart" [plotOptions]="chartProveedor.plotOptions" [series]="chartProveedor.series" [labels]="chartProveedor.labels" [legend]="chartProveedor.legend" [colors]="chartProveedor.colors" [responsive]="chartProveedor.responsive"></apx-chart>
                  </div>
                </div>
              </div>
          </div>
        </div>
      



        <div class="col-sm-6" *ngIf="chartComparativePro.title.text!=''">
            <div class="card">
                <div class="card-header">
                  <h5>Volumen de compras </h5>
                </div>
                <div class="card-body" >
                  <div class="chart-container">
                    <div id="linechart">
                      <apx-chart [chart]="chartComparativePro.chart" [dataLabels]="chartComparativePro.dataLabels" [stroke]="chartComparativePro.stroke" [grid]="chartComparativePro.grid" [fill]="chartComparativePro.fill" [colors]="chartComparativePro.colors" [markers]="chartComparativePro.markers" [series]="chartComparativePro.series" [xaxis]="chartComparativePro.xaxis" [yaxis]="chartComparativePro.yaxis" [title]="chartComparativePro.title" [legend]="chartComparativePro.legend" [responsive]="chartComparativePro.responsive"></apx-chart>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6" *ngIf="chartComparativeProMon.title.text!=''">
            <div class="card">
                <div class="card-header">
                  <h5>Monto volumen de compras </h5>
                </div>
                <div class="card-body" >
                  <div class="chart-container">
                    <div id="linechart">
                      <apx-chart [chart]="chartComparativeProMon.chart" [dataLabels]="chartComparativeProMon.dataLabels" [stroke]="chartComparativeProMon.stroke" [grid]="chartComparativeProMon.grid" [fill]="chartComparativeProMon.fill" [colors]="chartComparativeProMon.colors" [markers]="chartComparativeProMon.markers" [series]="chartComparativeProMon.series" [xaxis]="chartComparativeProMon.xaxis" [yaxis]="chartComparativeProMon.yaxis" [title]="chartComparativeProMon.title" [legend]="chartComparativeProMon.legend" [responsive]="chartComparativeProMon.responsive"></apx-chart>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6" *ngIf="chartComparativeCli.title.text!=''">
            <div class="card">
                <div class="card-header">
                  <h5>Clientes </h5>
                </div>
                <div class="card-body" >
                  <div class="chart-container">
                    <div id="linechart">
                      <apx-chart [chart]="chartComparativeCli.chart" [dataLabels]="chartComparativeCli.dataLabels" [stroke]="chartComparativeCli.stroke" [grid]="chartComparativeCli.grid" [fill]="chartComparativeCli.fill" [colors]="chartComparativeCli.colors" [markers]="chartComparativeCli.markers" [series]="chartComparativeCli.series" [xaxis]="chartComparativeCli.xaxis" [yaxis]="chartComparativeCli.yaxis" [title]="chartComparativeCli.title" [legend]="chartComparativeCli.legend" [responsive]="chartComparativeCli.responsive"></apx-chart>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6" *ngIf="chartComparativePaq.title.text!=''">
            <div class="card">
                <div class="card-header">
                  <h5>Paqueterías </h5>
                </div>
                <div class="card-body" >
                  <div class="chart-container">
                    <div id="linechart">
                      <apx-chart [chart]="chartComparativePaq.chart" [dataLabels]="chartComparativePaq.dataLabels" [stroke]="chartComparativePaq.stroke" [grid]="chartComparativePaq.grid" [fill]="chartComparativePaq.fill" [colors]="chartComparativePaq.colors" [markers]="chartComparativePaq.markers" [series]="chartComparativePaq.series" [xaxis]="chartComparativePaq.xaxis" [yaxis]="chartComparativePaq.yaxis" [title]="chartComparativePaq.title" [legend]="chartComparativePaq.legend" [responsive]="chartComparativePaq.responsive"></apx-chart>
                    </div>
                  </div>
                </div>
            </div>
        </div>




        <div class="col-sm-6" *ngIf="chartCliente1.title.text!=''">
          <div class="card">
              <div class="card-header">
                  <div class="header-top">
                    <h5>Clientes</h5>
                    <!-- <div class="card-header-right-icon">
                      <div class="dropdown">
                        <button class="btn dropdown-toggle" id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown">Today</button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton"><a class="dropdown-item" href="#">Today</a><a class="dropdown-item" href="#">Tomorrow</a><a class="dropdown-item" href="#">Yesterday</a></div>
                      </div>
                    </div> -->
                  </div>
              </div>
              <div class="card-body">
                  <div class="chart-container chartCliente-chart">
                      <div id="chartCliente1" >
                        <apx-chart [chart]="chartCliente1.chart" [plotOptions]="chartCliente1.plotOptions" [colors]="chartCliente1.colors" [stroke]="chartCliente1.stroke" [fill]="chartCliente1.fill" [series]="chartCliente1.series" [title]="chartCliente1.title" [subtitle]="chartCliente1.subtitle" [tooltip]="chartCliente1.tooltip" [xaxis]="chartCliente1.xaxis" [yaxis]="chartCliente1.yaxis" [responsive]="chartCliente1.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente2" *ngIf="chartCliente2.title.text!=''">
                        <apx-chart [chart]="chartCliente2.chart" [plotOptions]="chartCliente2.plotOptions" [colors]="chartCliente2.colors" [stroke]="chartCliente2.stroke" [fill]="chartCliente2.fill" [series]="chartCliente2.series" [title]="chartCliente2.title" [subtitle]="chartCliente2.subtitle" [tooltip]="chartCliente2.tooltip" [xaxis]="chartCliente2.xaxis" [yaxis]="chartCliente2.yaxis" [responsive]="chartCliente2.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente3" *ngIf="chartCliente3.title.text!=''">
                        <apx-chart [chart]="chartCliente3.chart" [plotOptions]="chartCliente3.plotOptions" [colors]="chartCliente3.colors" [stroke]="chartCliente3.stroke" [fill]="chartCliente3.fill" [series]="chartCliente3.series" [title]="chartCliente3.title" [subtitle]="chartCliente3.subtitle" [tooltip]="chartCliente3.tooltip" [xaxis]="chartCliente3.xaxis" [yaxis]="chartCliente3.yaxis" [responsive]="chartCliente3.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente4" *ngIf="chartCliente4.title.text!=''">
                        <apx-chart [chart]="chartCliente4.chart" [plotOptions]="chartCliente4.plotOptions" [colors]="chartCliente4.colors" [stroke]="chartCliente4.stroke" [fill]="chartCliente4.fill" [series]="chartCliente4.series" [title]="chartCliente4.title" [subtitle]="chartCliente4.subtitle" [tooltip]="chartCliente4.tooltip" [xaxis]="chartCliente4.xaxis" [yaxis]="chartCliente4.yaxis" [responsive]="chartCliente4.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente5" *ngIf="chartCliente5.title.text!=''">
                        <apx-chart [chart]="chartCliente5.chart" [plotOptions]="chartCliente5.plotOptions" [colors]="chartCliente5.colors" [stroke]="chartCliente5.stroke" [fill]="chartCliente5.fill" [series]="chartCliente5.series" [title]="chartCliente5.title" [subtitle]="chartCliente5.subtitle" [tooltip]="chartCliente5.tooltip" [xaxis]="chartCliente5.xaxis" [yaxis]="chartCliente5.yaxis" [responsive]="chartCliente5.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente6" *ngIf="chartCliente6.title.text!=''">
                        <apx-chart [chart]="chartCliente6.chart" [plotOptions]="chartCliente6.plotOptions" [colors]="chartCliente6.colors" [stroke]="chartCliente6.stroke" [fill]="chartCliente6.fill" [series]="chartCliente6.series" [title]="chartCliente6.title" [subtitle]="chartCliente6.subtitle" [tooltip]="chartCliente6.tooltip" [xaxis]="chartCliente6.xaxis" [yaxis]="chartCliente6.yaxis" [responsive]="chartCliente6.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente7" *ngIf="chartCliente7.title.text!=''">
                        <apx-chart [chart]="chartCliente7.chart" [plotOptions]="chartCliente7.plotOptions" [colors]="chartCliente7.colors" [stroke]="chartCliente7.stroke" [fill]="chartCliente7.fill" [series]="chartCliente7.series" [title]="chartCliente7.title" [subtitle]="chartCliente7.subtitle" [tooltip]="chartCliente7.tooltip" [xaxis]="chartCliente7.xaxis" [yaxis]="chartCliente7.yaxis" [responsive]="chartCliente7.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente8" *ngIf="chartCliente8.title.text!=''">
                        <apx-chart [chart]="chartCliente8.chart" [plotOptions]="chartCliente8.plotOptions" [colors]="chartCliente8.colors" [stroke]="chartCliente8.stroke" [fill]="chartCliente8.fill" [series]="chartCliente8.series" [title]="chartCliente8.title" [subtitle]="chartCliente8.subtitle" [tooltip]="chartCliente8.tooltip" [xaxis]="chartCliente8.xaxis" [yaxis]="chartCliente8.yaxis" [responsive]="chartCliente8.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente9" *ngIf="chartCliente9.title.text!=''">
                        <apx-chart [chart]="chartCliente9.chart" [plotOptions]="chartCliente9.plotOptions" [colors]="chartCliente9.colors" [stroke]="chartCliente9.stroke" [fill]="chartCliente9.fill" [series]="chartCliente9.series" [title]="chartCliente9.title" [subtitle]="chartCliente9.subtitle" [tooltip]="chartCliente9.tooltip" [xaxis]="chartCliente9.xaxis" [yaxis]="chartCliente9.yaxis" [responsive]="chartCliente9.responsive"></apx-chart>
                      </div>
                      <div id="chartCliente10" *ngIf="chartCliente10.title.text!=''">
                        <apx-chart [chart]="chartCliente10.chart" [plotOptions]="chartCliente10.plotOptions" [colors]="chartCliente10.colors" [stroke]="chartCliente10.stroke" [fill]="chartCliente10.fill" [series]="chartCliente10.series" [title]="chartCliente10.title" [subtitle]="chartCliente10.subtitle" [tooltip]="chartCliente10.tooltip" [xaxis]="chartCliente10.xaxis" [yaxis]="chartCliente10.yaxis" [responsive]="chartCliente10.responsive"></apx-chart>
                      </div>
                      
                      
                    </div>
              </div>
          </div>

        </div>
        <div class="col-sm-6" *ngIf="chartProPaqueteria1.title.text!=''">
          <div class="card">
            <div class="card-header">
                    <div class="header-top">
                      <h5>Paqueterías</h5>
                      <!-- <div class="card-header-right-icon">
                        <div class="dropdown">
                          <button class="btn dropdown-toggle" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown">Today</button>
                          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton"><a class="dropdown-item" href="#">Today</a><a class="dropdown-item" href="#">Tomorrow</a><a class="dropdown-item" href="#">Yesterday</a></div>
                        </div>
                      </div> -->
                    </div>
            </div>
            <div class="card-body">
                <div class="chart-container chartProPaqueteria-chart">
                    <div id="chartProPaqueteria1" >
                      <apx-chart [chart]="chartProPaqueteria1.chart" [plotOptions]="chartProPaqueteria1.plotOptions" [colors]="chartProPaqueteria1.colors" [stroke]="chartProPaqueteria1.stroke" [fill]="chartProPaqueteria1.fill" [series]="chartProPaqueteria1.series" [title]="chartProPaqueteria1.title" [subtitle]="chartProPaqueteria1.subtitle" [tooltip]="chartProPaqueteria1.tooltip" [xaxis]="chartProPaqueteria1.xaxis" [yaxis]="chartProPaqueteria1.yaxis" [responsive]="chartProPaqueteria1.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria2" *ngIf="chartProPaqueteria2.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria2.chart" [plotOptions]="chartProPaqueteria2.plotOptions" [colors]="chartProPaqueteria2.colors" [stroke]="chartProPaqueteria2.stroke" [fill]="chartProPaqueteria2.fill" [series]="chartProPaqueteria2.series" [title]="chartProPaqueteria2.title" [subtitle]="chartProPaqueteria2.subtitle" [tooltip]="chartProPaqueteria2.tooltip" [xaxis]="chartProPaqueteria2.xaxis" [yaxis]="chartProPaqueteria2.yaxis" [responsive]="chartProPaqueteria2.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria3" *ngIf="chartProPaqueteria3.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria3.chart" [plotOptions]="chartProPaqueteria3.plotOptions" [colors]="chartProPaqueteria3.colors" [stroke]="chartProPaqueteria3.stroke" [fill]="chartProPaqueteria3.fill" [series]="chartProPaqueteria3.series" [title]="chartProPaqueteria3.title" [subtitle]="chartProPaqueteria3.subtitle" [tooltip]="chartProPaqueteria3.tooltip" [xaxis]="chartProPaqueteria3.xaxis" [yaxis]="chartProPaqueteria3.yaxis" [responsive]="chartProPaqueteria3.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria4" *ngIf="chartProPaqueteria4.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria4.chart" [plotOptions]="chartProPaqueteria4.plotOptions" [colors]="chartProPaqueteria4.colors" [stroke]="chartProPaqueteria4.stroke" [fill]="chartProPaqueteria4.fill" [series]="chartProPaqueteria4.series" [title]="chartProPaqueteria4.title" [subtitle]="chartProPaqueteria4.subtitle" [tooltip]="chartProPaqueteria4.tooltip" [xaxis]="chartProPaqueteria4.xaxis" [yaxis]="chartProPaqueteria4.yaxis" [responsive]="chartProPaqueteria4.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria5" *ngIf="chartProPaqueteria5.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria5.chart" [plotOptions]="chartProPaqueteria5.plotOptions" [colors]="chartProPaqueteria5.colors" [stroke]="chartProPaqueteria5.stroke" [fill]="chartProPaqueteria5.fill" [series]="chartProPaqueteria5.series" [title]="chartProPaqueteria5.title" [subtitle]="chartProPaqueteria5.subtitle" [tooltip]="chartProPaqueteria5.tooltip" [xaxis]="chartProPaqueteria5.xaxis" [yaxis]="chartProPaqueteria5.yaxis" [responsive]="chartProPaqueteria5.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria6" *ngIf="chartProPaqueteria6.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria6.chart" [plotOptions]="chartProPaqueteria6.plotOptions" [colors]="chartProPaqueteria6.colors" [stroke]="chartProPaqueteria6.stroke" [fill]="chartProPaqueteria6.fill" [series]="chartProPaqueteria6.series" [title]="chartProPaqueteria6.title" [subtitle]="chartProPaqueteria6.subtitle" [tooltip]="chartProPaqueteria6.tooltip" [xaxis]="chartProPaqueteria6.xaxis" [yaxis]="chartProPaqueteria6.yaxis" [responsive]="chartProPaqueteria6.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria7" *ngIf="chartProPaqueteria7.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria7.chart" [plotOptions]="chartProPaqueteria7.plotOptions" [colors]="chartProPaqueteria7.colors" [stroke]="chartProPaqueteria7.stroke" [fill]="chartProPaqueteria7.fill" [series]="chartProPaqueteria7.series" [title]="chartProPaqueteria7.title" [subtitle]="chartProPaqueteria7.subtitle" [tooltip]="chartProPaqueteria7.tooltip" [xaxis]="chartProPaqueteria7.xaxis" [yaxis]="chartProPaqueteria7.yaxis" [responsive]="chartProPaqueteria7.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria8" *ngIf="chartProPaqueteria8.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria8.chart" [plotOptions]="chartProPaqueteria8.plotOptions" [colors]="chartProPaqueteria8.colors" [stroke]="chartProPaqueteria8.stroke" [fill]="chartProPaqueteria8.fill" [series]="chartProPaqueteria8.series" [title]="chartProPaqueteria8.title" [subtitle]="chartProPaqueteria8.subtitle" [tooltip]="chartProPaqueteria8.tooltip" [xaxis]="chartProPaqueteria8.xaxis" [yaxis]="chartProPaqueteria8.yaxis" [responsive]="chartProPaqueteria8.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria9" *ngIf="chartProPaqueteria9.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria9.chart" [plotOptions]="chartProPaqueteria9.plotOptions" [colors]="chartProPaqueteria9.colors" [stroke]="chartProPaqueteria9.stroke" [fill]="chartProPaqueteria9.fill" [series]="chartProPaqueteria9.series" [title]="chartProPaqueteria9.title" [subtitle]="chartProPaqueteria9.subtitle" [tooltip]="chartProPaqueteria9.tooltip" [xaxis]="chartProPaqueteria9.xaxis" [yaxis]="chartProPaqueteria9.yaxis" [responsive]="chartProPaqueteria9.responsive"></apx-chart>
                    </div>
                    <div id="chartProPaqueteria10" *ngIf="chartProPaqueteria10.title.text!=''">
                      <apx-chart [chart]="chartProPaqueteria10.chart" [plotOptions]="chartProPaqueteria10.plotOptions" [colors]="chartProPaqueteria10.colors" [stroke]="chartProPaqueteria10.stroke" [fill]="chartProPaqueteria10.fill" [series]="chartProPaqueteria10.series" [title]="chartProPaqueteria10.title" [subtitle]="chartProPaqueteria10.subtitle" [tooltip]="chartProPaqueteria10.tooltip" [xaxis]="chartProPaqueteria10.xaxis" [yaxis]="chartProPaqueteria10.yaxis" [responsive]="chartProPaqueteria10.responsive"></apx-chart>
                    </div>
                    
                    
                  </div>
            </div>
          </div>

        </div>

    </div>
</div>