import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ApexAxisChartSeries } from 'ng-apexcharts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() totalShipments: any;
  @Input() actualMonth: any;

  chartOptions: Partial<any>;
  allSeries: ApexAxisChartSeries = [];
  selectedSeries: ApexAxisChartSeries = [];
  seriesVisibility: { [key: string]: boolean } = {};
  
  seriesColors: { [key: string]: string } = {
    'Envíos totales': '#9F19FF',
    'Entregados': '#51BB25',
    'Incidencias': '#F44336'
  };

  constructor() {
    this.chartOptions = {
      series: [],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Cantidad'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val.toLocaleString() + `${val > 1 ? ' Envíos' : ' Envío'}`
          }
        }
      }
    };
  }

  ngOnInit(): void {
    if (this.data) {
      this.processData(this.data, this.actualMonth);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.processData(changes['data'].currentValue, this.actualMonth);
    } else if (changes['actualMonth'] && changes['actualMonth'].currentValue) {
      this.processData(this.data, changes['actualMonth'].currentValue);
    }
  }

  processData(data: any, actualMonth: any) {
    const categories = this.getMonthName();
    let totalShip: (number | null)[] = new Array(7).fill(null);
    let delivery: (number | null)[] = new Array(7).fill(null);
    let incident: (number | null)[] = new Array(7).fill(null);

    data.forEach((item: any) => {
      const values = JSON.parse(item.values);
      const period = item.period - 2;
      totalShip[period] = values.totalShip;
      delivery[period] = values.delivery;
      incident[period] = values.incident;
    });

    totalShip[totalShip.length ] = actualMonth.total;
    delivery[delivery.length ] = actualMonth.successful;
    incident[incident.length ] = actualMonth.error;

    this.allSeries = [
      { name: 'Envíos totales', data: totalShip },
      { name: 'Entregados', data: delivery },
      { name: 'Incidencias', data: incident }
    ];

    this.allSeries.forEach(series => {
      this.seriesVisibility[series.name!] = true;
    });

    this.updateSelectedSeries();
    this.chartOptions.xaxis.categories = categories;
  }

  toggleSeries(seriesName: string, checked: boolean) {
    this.seriesVisibility[seriesName] = checked;
    this.updateSelectedSeries();
  }

  updateSelectedSeries() {
    this.selectedSeries = this.allSeries.filter(series => this.seriesVisibility[series.name!]);
    this.chartOptions.series = this.selectedSeries;
    this.chartOptions.colors = this.getSeriesColors();
  }

  getSeriesColors() {
    return this.selectedSeries.map(series => this.seriesColors[series.name!]);
  }

  getMonthName() {
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    const today = new Date();
    const currentMonth = today.getMonth();
    const months = [];
  
    for (let i = 0; i <= currentMonth; i++) {
      months.push(monthNames[i]);
    }
  
    return months;
}
}
