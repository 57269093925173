import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models/user.model';
import { ShopifyService } from 'src/app/services/shopify.service';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { catchError, map, of } from 'rxjs';


@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit {

  userObj!: UserModel;
  shop!: string;
  isLoading = true;
  hasStores: boolean= false;
  constructor(
    private userS: UsersService,
    private shopifyS: ShopifyService,
    private route: ActivatedRoute

  ) { 
    this.shopifyS.getStoreList().pipe(
      map((result: any) => {
        return result.length > 0;
      }),
      catchError((err: any) => {
        console.error(err);
        return of(false); 
      })
    ).subscribe((value: boolean) => {
      this.hasStores = value; 
      this.isLoading = false;
    });
  }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    // this.route.queryParamMap.subscribe((params: ParamMap) => {
    //   const shopParam = params.get('shop');
    //   shopParam ? this.shop = shopParam : this.shop = this.userObj.id_shop;
    // });
    // if (this.userObj.id_shop === null) { this.auth() }
  }

  // auth() {
  //   window.location.href = `http://localhost:4002/shopify/auth?shop=${this.shop}`;
  //   this.shopifyS.auth(this.shop).subscribe(
  //     (result : any) => {
  //       const expiry = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  //       localStorage.setItem('session', expiry.toString());
  //       this.isLoading = false;
  //     },
  //     (err : any) => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }
  //   );
  //   }
  

}
