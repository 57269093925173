import { Component, OnInit, ViewChild , ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as printJS from 'print-js';
import { CompanyModel } from 'src/app/models/company.model';
import { BillingDataModel, EquivalentCarSer, ServicesModeModel } from 'src/app/models/shipment.model';
import { CurrencyModel, UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import { pieChart1, lineChart, oRadialBar, oProgressBar, oGradient, MesNombre, finance, orderStatus2, aColorBase } from 'src/app/models/chart.model';
import { ChartSelectEvent } from 'ng2-google-charts';

@Component({
  selector: 'app-executive-report',
  templateUrl: './executive-report.component.html',
  styleUrls: ['./executive-report.component.scss']
})
export class ExecutiveReportComponent implements OnInit {

  trigoStrength = 3;
  primary_color = localStorage.getItem("primary_color") || "#7366ff";
  secondary_color = localStorage.getItem("secondary_color") || "#f73164";
 
  iteration = 11;
  arrayColorChart:any;
  coaasdasd=0;

  public chartPieGoogle = { ...pieChart1};
  public chartPieGoogleCli = { ...pieChart1};
  public chartPieGooglePro = { ...pieChart1};
  public chartPieGoogleCliMon = { ...pieChart1};
  public chartPieGoogleProMon = { ...pieChart1};
  public chartPieGoogleCliKgs = { ...pieChart1};
  public chartPieGoogleProKgs = { ...pieChart1};
  public chartPieGoogleSegmento = { ...pieChart1};
  public chartPieGoogleSegModerno = { ...pieChart1};
  public chartPieGoogleNatInt = { ...pieChart1};
  public chartPieGoogleMetEnvioCli = { ...pieChart1};
  public chartPieGoogleMetEnvioPro = { ...pieChart1};
  public chartLineGooglePro = { ...lineChart};
  public originalChartData:any[]=[];
  


  rowSelectedClientKgs: number = -1;
  auxClientKgsData:any[]=[];
  rowSelectedProviderKgs: number = -1;
  auxProviderKgsData:any[]=[];
  rowSelectedClientMetEnv: number = -1;
  auxMetEnvioCliData:any[]=[];
  rowSelectedProviderMetEnv: number = -1;
  auxMetEnvioProData:any[]=[];  

  sumClientData=0;
  sumClientMontoData=0;
  sumProviderData=0;
  sumProviderMontoData=0;
  sumKgsData=0;
  sumSegmentoData=0;
  sumSegModernoData=0;
  sumNatIntData=0;
  
  sameYear=true; // Cuando se utilizan diferentes años en el periodo

  auxTableClientMonto:any[]=[];
  sumAuxTableClientMonto:any=[];
  auxTableProviderMonto:any[]=[];
  sumAuxTableProviderMonto:any=[];
  auxTableIntNatMonto:any[]=[];
  sumAuxTableIntNatMonto:any=[];
  auxLsProviderData:any[]=[];
  auxLsClientData:any[]=[];
  auxLsCarrierData:any[]=[];
  auxLsSegmentData:any[]=[];

  public chartComparativePro = { ...orderStatus2};
  public chartComparativeProMon = { ...orderStatus2};
  public chartComparativeCli = { ...orderStatus2};
  public chartComparativePaq = { ...orderStatus2};
  
 
   public chartTipo = { ...oRadialBar };
   public chartProveedor = { ...oRadialBar };
   public chartMetodoEnv = { ...oRadialBar };
   
   public chartProPaqueteria1 = { ...oProgressBar};
   public chartProPaqueteria2 = { ...oProgressBar};
   public chartProPaqueteria3 = { ...oProgressBar};
   public chartProPaqueteria4 = { ...oProgressBar};
   public chartProPaqueteria5 = { ...oProgressBar};
   public chartProPaqueteria6 = { ...oProgressBar};
   public chartProPaqueteria7 = { ...oProgressBar};
   public chartProPaqueteria8 = { ...oProgressBar};
   public chartProPaqueteria9 = { ...oProgressBar};
   public chartProPaqueteria10 = { ...oProgressBar};

   public chartCliente1 = { ...oProgressBar};
   public chartCliente2 = { ...oProgressBar};
   public chartCliente3 = { ...oProgressBar};
   public chartCliente4 = { ...oProgressBar};
   public chartCliente5 = { ...oProgressBar};
   public chartCliente6 = { ...oProgressBar};
   public chartCliente7 = { ...oProgressBar};
   public chartCliente8 = { ...oProgressBar};
   public chartCliente9 = { ...oProgressBar};
   public chartCliente10 = { ...oProgressBar};


   public chartFinance = finance;

   public monthlySales = { ...oGradient};
   

   bestKg={kg:0 , count:0};  
   totalNatInt=0;
   sumNatInt={ nat: 0, int: 0, totalPriceNat:0, totalPriceInt:0 };
   groupCurrencyBillingData : any ;
   billingDataList =  new Array<BillingDataModel>();
   DataList =  new Array<BillingDataModel>();
   okCurrency: any;
   isLoading = true;
   base64!:string;
   servicesModeList =  new Array<ServicesModeModel>();
   equivalentCarSerList =  new Array<EquivalentCarSer>();
 
   //#region Filtros
   dateNow : Date = new Date();
   dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
     
   newDateTodayI : Date | null = null;//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDay());
   minDateI : Date =  new Date(2022, 0, 1);
   maxDateI : Date =  new Date();
   dateIDis=true;
   newDateTodayF : Date | null = null//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
   minDateF : Date =  new Date(2022, 0, 1);
   maxDateF : Date =  new Date();
   dateFDis=true;
 
   showFilter=false;
   selFechas = new FormControl('');  
   selCompanies = new FormControl('');  
   selCompaniesList:Array<CompanyModel>=[];
   selTipoEnvio = new FormControl('');

   selCurrency = new FormControl('');  
   selCurrencyList:Array<CurrencyModel>=[];
   selUsers = new FormControl('');  
   selUsersList:Array<UserModel>=[];
   
   selPeriod = new FormControl('');
   arrPeriodsSel=[{name:'Quartil', value:'quartil'},{name:'Mes', value:'mes'},{name:'Día', value:'dia'}];
   selAnalize = new FormControl('');
   arrAnalizeSel=[{name:'Guías', value:'guias'},{name:'Montos de compra', value:'montoscompra'},{name:'Montos de venta', value:'montosventa'},{name:'Utilidad', value:'utilidad'},{name:'Utilidad total', value:'utilidadtotal'},{name:'Margen', value:'margen'}];
   selData = new FormControl('');
   arrDataSel=[{name:'Proveedores', value:'proveedores'},{name:'Clientes', value:'clientes'},{name:'Paqueterías', value:'paquterias'},{name:'Segmentos', value:'segmentos'}];
 
   preLoadHidden = true;
   
   //#endregion
 
   
   showOptions = false;
    
   constructor(
     private userS: UsersService,
     private router: Router,
     private matDialog: MatDialog,
     private cdr: ChangeDetectorRef,
   ) { }
 
   ngOnInit(): void {
    this.arrayColorChart=aColorBase;
     this.showFilter=true;
     this.selFechas.setValue('2');
     this.selCurrency.setValue('0');
     this.selTipoEnvio.setValue('0');
     this.onchangeFechas(this.selFechas);
     //No cargar el listado de manera inicial
     //this.getBillingDataList(); 
     this.isLoading = false; //porque no se carga al principio los datos
     this.getServicesMode();
     this.getEquivalentCarSer();
 
     //this.getCompaniesList();
     //this.getCurrencyList();
     //this.getUsersList();
     //this.newDateToday.setDate(this.newDateToday.getDate() + 1);
   }
 
   getBillingDataList(date_i : string|null = null, date_f : string|null = null) {
     this.userS.getBillingDataListSf(date_i, date_f).subscribe(
       (result : any) => {
 
         this.groupCurrency(false, result);
         this.DataList = [ ...result ];
         
         for ( let i = 0; i < this.billingDataList.length; i++ ) {
           this.billingDataList[i].package_dimensions = JSON.parse(this.billingDataList[i].package_dimensions);
         }
         
         this.isLoading = false;
         this.preLoadHidden = false;
         this.getChartClientAndProvider();


        //  this.getCurrencyList();
        //  this.getNatInt();
        //  this.bestKpiData();
        //  this.getProviderChart();
        //  this.getComparativeChart()
        //  this.getVolumenChart();
       },
       (err : any) => {
         console.log(err);
         this.isLoading = false;
       }
     );
   }

   setGraphFilter(event:any) {
    if(this.selTipoEnvio.value=="0" && this.selCurrency.value=="0"){
      this.billingDataList = [... this.DataList];
    }else if(this.selTipoEnvio.value!="0" && this.selCurrency.value!="0"){
      this.billingDataList = this.DataList.reduce((acc:BillingDataModel[], item:BillingDataModel) => {
        if (item.currency == this.selCurrency.value && item.shipment_type == this.selTipoEnvio.value) {
          acc.push(item);
        }
        return acc;
      }, []);
    }else{
      if(this.selTipoEnvio.value!="0"){
        this.billingDataList = this.DataList.reduce((acc:BillingDataModel[], item:BillingDataModel) => {
          if (item.shipment_type == this.selTipoEnvio.value) {
            acc.push(item);
          }
          return acc;
        }, []);
      }
      if(this.selCurrency.value!="0"){
        this.billingDataList = this.DataList.reduce((acc:BillingDataModel[], item:BillingDataModel) => {
          if (item.currency == this.selCurrency.value) {
            acc.push(item);
          }
          return acc;
        }, []);
      }

    }
    
    this.getCurrencyList();
    this.getNatInt();
    this.getProviderChart();
    this.getComparativeChart()
    this.getVolumenChart();
     
  }
 
   groupCurrency(filter:boolean, data: Array<BillingDataModel> ){
     
     if(filter){
       //Si es tipo filtro significa que ya se trajeron los datos de la API y se agruparon anteriormente
       if(this.selCurrency.value == "0"){ //si son todas
         this.billingDataList = data;
       }else{
         //Ver si existe el agrupado de moneda, si no existe poner vacía la tabla
         if (!this.groupCurrencyBillingData[this.selCurrency.value]) {
           this.billingDataList = new Array<BillingDataModel>();
         }else{ //volver a llenar el mat-table
           const arrFil=this.groupCurrencyBillingData[this.selCurrency.value];
           this.billingDataList = arrFil;
           //console.log(this.billingDataList);
   
           // for ( let i = 0; i < this.billingDataList.length; i++ ) {
           //   this.billingDataList[i].package_dimensions = JSON.parse(this.billingDataList[i].package_dimensions);
           // }
          //  this.dataSource = new MatTableDataSource(this.billingDataList);        
          //  this.dataSource.paginator = this.paginator;
          //  this.dataSource.paginator.pageSize=this.billingDataList.length;
          //  this.dataSource.sort = this.sort;
           this.isLoading = false;
           this.preLoadHidden = false;
         }
       }
     }else{
       //Es de la consulta a la API, el resultado esta en data, aquí no afecta el filtro por moneda
       this.groupCurrencyBillingData = data.reduce((groupCurrency:{[key: string]: any[]} , item:BillingDataModel) => {
         //Si la moneda no existe en agrupado, se crea como array vacío
         if (!groupCurrency[item.currency]) {
           groupCurrency[item.currency] = [];
         }
         // El objeto se agrega al array en la moneda que pertenezca.
         groupCurrency[item.currency].push(item);
         return groupCurrency;
       }, {});
       this.okCurrency = Object.keys(this.groupCurrencyBillingData);
       if(this.okCurrency.length>1){
         Swal.fire({
           icon:  'warning',
           title: 'Diferentes tipos de monedas',
           text:  'En el reporte hay diferentes tipos de monedas, la sumatoria solo se hará con monedas del mismo tipo (puedes usar el filtro de moneda).'
         });
       }
       //this.selCurrency.setValue("0");
       this.billingDataList = data;
       //console.log(this.groupCurrencyBillingData, this.okCurrency.length, this.selCurrency.value);
     }
   }
   showSumFooter() {
     let vShow = false;
     if(this.okCurrency!=undefined){ 
       if(this.okCurrency.length==1){
         vShow = true;
       }else{
         if(this.selCurrency.value == "0"){ //si son todas
           vShow = false;
         }else{ //Tiene selecciona 1 tipo de moneda
           vShow = true
         }
       }
     }else{
       vShow = false;
     }
     return vShow;
   }
 
   dateToString(date:Date) {
     return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
   }
   
 
   searchFilter() {
     this.isLoading = true;
     let date_i:string|null, date_f:string|null, users:string|null, companies:string|null;
     let arrCom:Array<number>=this.selCompanies.value;
     let arrUse:Array<number>=this.selUsers.value;
     let currency:string;
 
     date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
     date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);
     companies=arrCom.length>0?arrCom.join(','):null;
     users=arrUse.length>0?arrUse.join(','):null;
     currency=this.selCurrency.value;
     const spDI = date_i?.split("-");
     const spDF = date_f?.split("-");
     console.log(spDI, spDF);
     if(spDI && spDF){
      if(spDI[0] != spDF[0]){
        this.sameYear=false;
      }
     }

     //console.log(companies, users);
     this.getBillingDataList(date_i, date_f);
   }
 
   getServicesMode() {
     this.userS.getListServicesMode().subscribe(
       (result : any) => {
         this.servicesModeList = result;
       },
       (err : any) => {
         console.log(err);
         this.isLoading = false;
       }
     );
   }

   getEquivalentCarSer() {
    this.userS.getListEquivalentCarSer().subscribe(
      (result : any) => {
        this.equivalentCarSerList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
 
   getCompaniesList() {
     this.userS.getCompanies().subscribe(
       (result : any) => {
         this.selCompaniesList = result;
       },
       (err : any) => {
         console.log(err);
         this.isLoading = false;
       }
     );
   }
   getCurrencyList() {
      const monedasUnicasSet = new Set(this.billingDataList.map(transaccion => transaccion.currency));
      this.selCurrencyList = Array.from(monedasUnicasSet).filter(m => m !== undefined && m !== '')
                                  .map(currencyCode => ({ currency: currencyCode } as CurrencyModel)) ;
      //console.log(this.selCurrencyList);
      //this.selCurrencyList = monedasUnicas;
      //  this.userS.getCurrencies().subscribe(
      //    (result : any) => {
      //      this.selCurrencyList = result;
      //    },
      //    (err : any) => {
      //      console.log(err);
      //      this.isLoading = false;
      //    }
      //  );
   }
   getUsersList() {
     this.userS.getUserListMin().subscribe(
       (result : any) => {
         this.selUsersList = result;
       },
       (err : any) => {
         console.log(err);
         this.isLoading = false;
       }
     );
   }
 

 
   searchFilterClean() {
     this.selCompanies.setValue([]);
     this.selUsers.setValue([]);
   }
 
 
   onchangedateI(e: Date): void {
     console.log('Inicio', e, 'Fin', this.newDateTodayF);
     if(this.newDateTodayF!=null && this.newDateTodayF!.getTime()<e.getTime()){
         this.newDateTodayF=null;
     }
   }
   onchangedateF(e: Date): void {
     console.log('Fin', e, 'Inicio', this.newDateTodayI);
     if(this.newDateTodayI!=null && this.newDateTodayI!.getTime()>e.getTime()){
       this.newDateTodayI=null;
     }
   }
 
   onchangeFechas(change:any) {
     //console.log(change);
     switch (change.value) {
       case '1'://Hoy
         this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
         this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
         this.dateIDis=true;
         this.dateFDis=true;
         break;
       case '2'://Este mes
         this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1);
         this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
         this.dateIDis=true;
         this.dateFDis=true;
         break;
       case '3'://Elegir fechas
         this.dateIDis=false;
         this.dateFDis=false;
         break;
       default:
         break;
     }
   }
 
   onchangeCurrency(change:any) {
     const auxArr= new Array<BillingDataModel>();
     this.groupCurrency(true,auxArr);
   }
   getPageSizeOptions(): number[]{
     if(this.billingDataList.length>10)
     return [10,50,100,this.billingDataList.length];
     else
     return [10,50,100];
   }
   
   getTotalService() {
     return (this.billingDataList.map(t => t.service_price).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
   }
 
   getTotalPrice() {
     return (this.billingDataList.map(t => t.total_price).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
   }
   
  //  toggleColumn(columnName: string, event: MatCheckboxChange) {
  //    //console.log(columnName);
  //    let columnHS=this.columnDefinitions.find( item=> item.def==columnName);
  //    if(columnHS){
  //      columnHS.hide=!event.checked;
  //    }
  //  }
 
  //  toggleOptions() {
  //    this.showOptions = !this.showOptions;
  //  }
 
   getAddress(data:string) {
     try {
       const address = JSON.parse(data);
       return `${address.name}, ${address.street}, ${address.number}, ${address.suburb}, ${address.postalCode}, ${address.city}`;
     } catch (error) {
       return ''; 
     }
   }
 
   getServiceMode(service:string, carrier:string, provider:string ) {
     try {
       let sService=service;
       const indexFound = this.servicesModeList.findIndex(r=> r.provider.toUpperCase() == provider.toUpperCase() 
                                                              && (r.carrier.toUpperCase().includes(carrier.toUpperCase()) || carrier.toUpperCase().includes(r.carrier.toUpperCase())) 
                                                              && (service.toUpperCase().includes(r.service.toUpperCase()) || r.service.toUpperCase().includes(service.toUpperCase())) );
       if(indexFound != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
         //sService=`${service}->${this.servicesModeList[indexFound].service_mode}`;
         sService=`${this.servicesModeList[indexFound].service_mode}`;
       }
       return sService;
     } catch (error) {
       return service; 
     }
   }



   getChartClientAndProvider(){
    this.coaasdasd=1;
    let auxClientData:any[]=[]; //Aquí se van a sumar los totales
    let auxClientMontoData:any[]=[]; //Aquí se van a sumar los totales
    let auxProviderData:any[]=[]; //Aquí se van a sumar los totales
    let auxProviderMontoData:any[]=[]; //Aquí se van a sumar los totales
    this.auxClientKgsData=[]; //Aquí se van a sumar los totales
    this.auxProviderKgsData=[]; //Aquí se van a sumar los totales
    let auxSegmentoData:any[]=[]; //Aquí se van a sumar los totales
    let auxSegModernoData:any[]=[]; //Aquí se van a sumar los totales
    let auxNatIntData:any[]=[]; //Aquí se van a sumar los totales
    this.auxMetEnvioCliData=[]; //Aquí se van a sumar los totales
    this.auxMetEnvioProData=[]; //Aquí se van a sumar los totales
    
    this.sumClientData=0;
    this.sumClientMontoData=0;
    this.sumProviderData=0;
    this.sumProviderMontoData=0;
    
    
    const groupedClient: { [key: string]: number } = {};
    const groupedClientMon: { [key: string]: number } = {};
    const groupedClientMonTable: { [key: string]: { [key: string]: number } } = {};
    const groupedProvider: { [key: string]: number } = {};
    const groupedProviderMon: { [key: string]: number } = {};
    const groupedProviderMonTable: { [key: string]: { [key: string]: number } } = {};
    const groupedCliKgs: { [key: string]: { [key: string]: number } } = {};
    const groupedProKgs: { [key: string]: { [key: string]: number } } = {};
    const groupedSegmento: { [key: string]: number } = {};
    const groupedNatInt: { [key: string]: number } = {};
    const groupedNatIntMonTable: { [key: string]: { [key: string]: number } } = {};
    const groupedMetEnvioCli: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    const groupedMetEnvioPro: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    
    const groupedProviderDate: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    const groupedClientDate: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    const groupedCarrierDate: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    const groupedSegmentDate: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    this.billingDataList.map( (item, i) =>{
        if(item){
          //KG groupedCliKgs y groupedProKgs
          const sPaq = JSON.stringify(item.package_dimensions);
          const paq = JSON.parse(sPaq);

          let cmkey = (item.company_name=='WMS'? item.ext_client_name : item.company_name); //Crear una key de cliente
          if (!groupedClient[cmkey]) {
            groupedClient[cmkey] = paq.length;
            this.sumClientData+= paq.length;
          } else {
            groupedClient[cmkey] += paq.length;
            this.sumClientData+= paq.length;
          }
          if (!groupedClientMon[cmkey]) {
            groupedClientMon[cmkey] = parseFloat(item.total_price);
            this.sumClientMontoData+= parseFloat(item.total_price);
          } else {
            groupedClientMon[cmkey] += parseFloat(item.total_price);
            this.sumClientMontoData+= parseFloat(item.total_price);
          }

          let prkey = this.equivalentProvider(item.provider);
          
          if (!groupedProvider[prkey]) {
            groupedProvider[prkey] = paq.length;
            this.sumProviderData+= paq.length;
          } else {
            groupedProvider[prkey] += paq.length;
            this.sumProviderData+= paq.length;
          }
          if (!groupedProviderMon[prkey]) {
            groupedProviderMon[prkey] = parseFloat(item.total_price);
            this.sumProviderMontoData+= parseFloat(item.total_price);
          } else {
            groupedProviderMon[prkey] += parseFloat(item.total_price);
            this.sumProviderMontoData+= parseFloat(item.total_price);
          }

          //Montos
          //#region Montos cliente y proveedor
          if (!groupedClientMonTable[cmkey]) {
            groupedClientMonTable[cmkey] = {};
            groupedClientMonTable[cmkey]['count'] = paq.length;
            groupedClientMonTable[cmkey]['service_price'] = (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedClientMonTable[cmkey]['total_price'] = (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }else{
            groupedClientMonTable[cmkey]['count']+= paq.length;
            groupedClientMonTable[cmkey]['service_price'] += (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedClientMonTable[cmkey]['total_price'] += (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }
          if (!groupedProviderMonTable[prkey]) {
            groupedProviderMonTable[prkey] = {};
            groupedProviderMonTable[prkey]['count'] = paq.length;
            groupedProviderMonTable[prkey]['service_price'] = (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedProviderMonTable[prkey]['total_price'] = (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }else{
            groupedProviderMonTable[prkey]['count']+= paq.length;
            groupedProviderMonTable[prkey]['service_price'] += (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedProviderMonTable[prkey]['total_price'] += (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }
          //#endregion
          
          //Segmento
          let sgmkey = item.segmento||''; //Crear una key de cliente
          switch (sgmkey) {
            case 'bulk':
              sgmkey='tradicional';
              break;
            case 'wing':
            case 'wms':
            case 'siteflow':
            case 'buho':
              break;
          }

          if (!groupedSegmento[sgmkey]) {
            groupedSegmento[sgmkey] = paq.length;
            this.sumSegmentoData+= paq.length;
          } else {
            groupedSegmento[sgmkey] += paq.length;
            this.sumSegmentoData+= paq.length;
          }

          //Nacional Internacional
          let snatint = item.shipment_type; //Crear una key de cliente
          // if (!groupedNatInt[snatint]) {
          //   groupedNatInt[snatint] = 1;
          // } else {
          //   groupedNatInt[snatint] ++;
          // }
          if (!groupedNatIntMonTable[snatint]) {
            groupedNatIntMonTable[snatint] = {};
            groupedNatIntMonTable[snatint]['count'] = paq.length;
            groupedNatIntMonTable[snatint]['service_price'] = (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedNatIntMonTable[snatint]['total_price'] = (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }else{
            groupedNatIntMonTable[snatint]['count']+= paq.length;
            groupedNatIntMonTable[snatint]['service_price'] += (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedNatIntMonTable[snatint]['total_price'] += (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }

          //Métodos de envío
          //#region Métodos de envío
          const mkey = `${this.getServiceMode(item.service, item.carrier, item.provider)}`; //Crear una key con el método del envío para agrupar
          // if(mkey=='Standard'){
          //   console.log(item.service, item.carrier, item.provider);
          // }
          if (!groupedMetEnvioCli[mkey]) {
            groupedMetEnvioCli[mkey] = {};
          }
          if (!groupedMetEnvioCli[mkey][cmkey]) {
            groupedMetEnvioCli[mkey][cmkey]={};
            groupedMetEnvioCli[mkey][cmkey]['count'] = paq.length;
            groupedMetEnvioCli[mkey][cmkey]['total_price'] = parseFloat(item.total_price);
            //groupedMetEnvioCli[mkey][cmkey] = 1;
          } else {
            groupedMetEnvioCli[mkey][cmkey]['count']+= paq.length;
            groupedMetEnvioCli[mkey][cmkey]['total_price'] += parseFloat(item.total_price);
            //groupedMetEnvioCli[mkey][cmkey]++;
          }
          if (!groupedMetEnvioPro[mkey]) {
            groupedMetEnvioPro[mkey] = {};
          }
          if (!groupedMetEnvioPro[mkey][prkey]) {
            groupedMetEnvioPro[mkey][prkey]={};
            groupedMetEnvioPro[mkey][prkey]['count'] = paq.length;
            groupedMetEnvioPro[mkey][prkey]['total_price'] = parseFloat(item.total_price);
            //groupedMetEnvioPro[mkey][prkey] = 1;
          } else {
            groupedMetEnvioPro[mkey][prkey]['count']+= paq.length;
            groupedMetEnvioPro[mkey][prkey]['total_price'] += parseFloat(item.total_price);
            //groupedMetEnvioPro[mkey][prkey]++;
          }
          //#endregion

          //#region KG groupedCliKgs y groupedProKgs
          //const sPaq = JSON.stringify(item.package_dimensions);
          //const paq = JSON.parse(sPaq);
          for (let index = 0; index < paq.length; index++) {
            const element = paq[index];
            const kgkey = `${element.weight}`; //Crear una key de provider para agrupar
            // if(parseInt(kgkey)>=412)
            //   console.log(item);
            if (!groupedCliKgs[kgkey]) {
              groupedCliKgs[kgkey] = {};
            }
            if (!groupedCliKgs[kgkey][cmkey]) {
              groupedCliKgs[kgkey][cmkey] = 1;
            } else {
              groupedCliKgs[kgkey][cmkey]++;
            }
            if (!groupedProKgs[kgkey]) {
              groupedProKgs[kgkey] = {};
            }
            if (!groupedProKgs[kgkey][prkey]) {
              groupedProKgs[kgkey][prkey] = 1;
            } else {
              groupedProKgs[kgkey][prkey]++;
            }
          }
          //#endregion

          //#region Fechas
          const datekey=item.created_at;

          const [datePartKey, timePartKey] = datekey.split('T');
          const [yearKey, monthKey, dayKey] = datePartKey.split('-').map(Number);
          const [hour, minute, second] = timePartKey.slice(0, -5).split(':').map(Number); // Elimina '.000Z' y divide
          const fechaAjustada = new Date(yearKey, monthKey - 1, dayKey, hour, minute, second);
          
          //const offsetHorario = fechaAjustada.getTimezoneOffset() * 60000;
          //fechaAjustada = new Date(fechaAjustada.getTime() - offsetHorario);

          const year = fechaAjustada.getFullYear();
          const month = String(fechaAjustada.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
          const day = String(fechaAjustada.getDate()).padStart(2, '0');
          const hours = String(fechaAjustada.getHours()).padStart(2, '0');
          const minutes = String(fechaAjustada.getMinutes()).padStart(2, '0');
          
          const formattedDateTime = `${year}-${month}-${day}`; // ${hours}:${minutes}`;
          //console.log(item.tracking_number, item.created_at, formattedDateTime);
          
          if (!groupedProviderDate[prkey]) {
            groupedProviderDate[prkey] = {};
          }
          if (!groupedProviderDate[prkey][formattedDateTime]) {
            groupedProviderDate[prkey][formattedDateTime]={};
            groupedProviderDate[prkey][formattedDateTime]['count'] = paq.length;
            groupedProviderDate[prkey][formattedDateTime]['service_price'] = (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedProviderDate[prkey][formattedDateTime]['total_price'] = (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          } else {
            groupedProviderDate[prkey][formattedDateTime]['count']+= paq.length;
            groupedProviderDate[prkey][formattedDateTime]['service_price'] += (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedProviderDate[prkey][formattedDateTime]['total_price'] += (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }

          if (!groupedClientDate[cmkey]) {
            groupedClientDate[cmkey] = {};
          }
          if (!groupedClientDate[cmkey][formattedDateTime]) {
            groupedClientDate[cmkey][formattedDateTime]={};
            groupedClientDate[cmkey][formattedDateTime]['count'] = paq.length;
            groupedClientDate[cmkey][formattedDateTime]['service_price'] = (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedClientDate[cmkey][formattedDateTime]['total_price'] = (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          } else {
            groupedClientDate[cmkey][formattedDateTime]['count']+= paq.length;
            groupedClientDate[cmkey][formattedDateTime]['service_price'] +=  (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0) ;
            groupedClientDate[cmkey][formattedDateTime]['total_price'] += (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }

          
          let carkey = `${item.carrier}`; //Crear una key de paquetería para agrupar
          const indexCarSer = this.equivalentCarSerList.findIndex(r=> r.carrier_dict.toUpperCase().includes(item.carrier.toUpperCase()) || item.carrier.toUpperCase().includes(r.carrier_dict.toUpperCase()) );
          if(indexCarSer != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
            let car_name=`${this.equivalentCarSerList[indexCarSer].carrier_cat}`;
            carkey = `${car_name}`;
          }
          if (!groupedCarrierDate[carkey]) {
            groupedCarrierDate[carkey] = {};
          }
          if (!groupedCarrierDate[carkey][formattedDateTime]) {
            groupedCarrierDate[carkey][formattedDateTime]={};
            groupedCarrierDate[carkey][formattedDateTime]['count'] = paq.length;
            groupedCarrierDate[carkey][formattedDateTime]['service_price'] = (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedCarrierDate[carkey][formattedDateTime]['total_price'] = (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          } else {
            groupedCarrierDate[carkey][formattedDateTime]['count']+= paq.length;
            groupedCarrierDate[carkey][formattedDateTime]['service_price'] +=  (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0) ;
            groupedCarrierDate[carkey][formattedDateTime]['total_price'] += (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }

          if (!groupedSegmentDate[sgmkey]) {
            groupedSegmentDate[sgmkey] = {};
          }
          if (!groupedSegmentDate[sgmkey][formattedDateTime]) {
            groupedSegmentDate[sgmkey][formattedDateTime]={};
            groupedSegmentDate[sgmkey][formattedDateTime]['count'] = paq.length;
            groupedSegmentDate[sgmkey][formattedDateTime]['service_price'] = (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0);
            groupedSegmentDate[sgmkey][formattedDateTime]['total_price'] = (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          } else {
            groupedSegmentDate[sgmkey][formattedDateTime]['count']+= paq.length;
            groupedSegmentDate[sgmkey][formattedDateTime]['service_price'] +=  (!isNaN(parseFloat(item.service_price))?parseFloat(item.service_price):0) ;
            groupedSegmentDate[sgmkey][formattedDateTime]['total_price'] += (!isNaN(parseFloat(item.total_price))?parseFloat(item.total_price):0);
          }
          //#endregion
          
        }
    });

    Object.entries(groupedCliKgs).forEach(([key, value]) => {
      let itemClientKgsData:any[]=[];
      let sumKgsCli=0;
      Object.entries(value).forEach(([key2, value2]) => {
        itemClientKgsData.push({ "key":key2, "count": value2}); 
        sumKgsCli+=value2;
      });
      itemClientKgsData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxClientKgsData.push({ "key":key, "items": itemClientKgsData, "total": sumKgsCli }); 
    });
    this.auxClientKgsData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });
    this.rowSelected(0, this.auxClientKgsData[0]['key'], 'client');
    //console.log('auxClientKgsData',this.auxClientKgsData);

    Object.entries(groupedProKgs).forEach(([key, value]) => {
      let itemProviderKgsData:any[]=[];
      let sumKgsPro=0;
      Object.entries(value).forEach(([key2, value2]) => {
        itemProviderKgsData.push({ "key":key2, "count": value2}); 
        sumKgsPro+=value2;
      });
      itemProviderKgsData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxProviderKgsData.push({ "key":key, "items": itemProviderKgsData, "total": sumKgsPro }); 
    });
    this.auxProviderKgsData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });
    this.rowSelected(0, this.auxProviderKgsData[0]['key'], 'provider');
    //console.log('auxProviderKgsData',this.auxProviderKgsData);
    
    //#region Clientes
    Object.entries(groupedClient).forEach(([key, value]) => {
      auxClientData.push({ "key":key, "count": value }); 
    });
    auxClientData.sort((a, b) => {
      return b.count - a.count;//descendente
      //return a.count - b.count; //ascendente (de menor a mayor)
    });
    this.chartPieGoogleCli.dataTable= [ ['Cliente', 'Guías'] ];
    auxClientData.map( (item, i) =>{
      this.chartPieGoogleCli.dataTable.push([item.key,item.count]);
    });
    this.chartPieGoogleCli.options={ ...this.chartPieGoogleCli.options, title: 'Clientes'};

    Object.entries(groupedClientMonTable).forEach(([key, value]) => { //groupedClientMon
      auxClientMontoData.push({ "key":key, "service_price": value.service_price, "total_price": value.total_price, "utilidad": value.total_price-value.service_price });
    });
    auxClientMontoData.sort((a, b) => {
      return b.total_price - a.total_price;//descendente
      //return a.total_price - b.total_price; //ascendente (de menor a mayor)
    });    
    this.chartPieGoogleCliMon.dataTable= [ ['Cliente', 'Monto'] ];
    this.auxTableClientMonto= [ ['Cliente', 'Monto de compra', 'Monto de venta', 'Utilidad', 'Utilidad Total', 'Margen'] ];
    let sumTableClientUtilidadTotal = auxClientMontoData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);

    auxClientMontoData.map( (item, i) =>{
      this.chartPieGoogleCliMon.dataTable.push([item.key, {'v':item.total_price, 'f': item.total_price.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) }]);
      this.auxTableClientMonto.push([item.key, item.service_price, item.total_price, item.utilidad, (item.utilidad/sumTableClientUtilidadTotal)*100, (item.utilidad/item.total_price)*100 ]);
    });
    this.chartPieGoogleCliMon.options={ ...this.chartPieGoogleCliMon.options, title: 'Clientes'};
        
    //Sumatoria de columnas
    const columnas = this.auxTableClientMonto[0].slice(1);
    console.log('columnas', columnas);
    this.sumAuxTableClientMonto = columnas.map((_: any, colIndex: number) => {
        return this.auxTableClientMonto.slice(1).reduce((acumulador, fila) => {
            return acumulador + parseFloat(fila[colIndex + 1]); // Sumar 1 para excluir la columna 0
        }, 0);
    });
    this.sumAuxTableClientMonto[3]=this.sumAuxTableClientMonto[3].toFixed(2);
    this.sumAuxTableClientMonto[4]=this.sumAuxTableClientMonto[4].toFixed(2);
    

    //Actualizar los gráficos
    let ccComponentCli = this.chartPieGoogleCli.component;
    if(ccComponentCli)
      ccComponentCli.draw();

    let ccComponentCliMon = this.chartPieGoogleCliMon.component;
    if(ccComponentCliMon)
      ccComponentCliMon.draw();
    //#endregion

    //#region Proveedores
    Object.entries(groupedProvider).forEach(([key, value]) => {
      auxProviderData.push({ "key":key, "count": value }); 
    });
    auxProviderData.sort((a, b) => {
      return b.count - a.count;//descendente
      //return a.count - b.count; //ascendente (de menor a mayor)
    });
    this.chartPieGooglePro.dataTable= [ ['Proveedor', 'Guías'] ];
    auxProviderData.map( (item, i) =>{
      this.chartPieGooglePro.dataTable.push([item.key,item.count]);
    });
    this.chartPieGooglePro.options={ ...this.chartPieGooglePro.options, title: 'Proveedores'};

    Object.entries(groupedProviderMonTable).forEach(([key, value]) => { //groupedProviderMon
      auxProviderMontoData.push({ "key":key, "service_price": value.service_price, "total_price": value.total_price, "utilidad": value.total_price-value.service_price });
    });
    auxProviderMontoData.sort((a, b) => {
      return b.total_price - a.total_price;//descendente
      //return a.total_price - b.total_price; //ascendente (de menor a mayor)
    });
    this.chartPieGoogleProMon.dataTable= [ ['Proveedor', 'Monto de venta'] ];
    this.auxTableProviderMonto= [ ['Cliente', 'Monto de compra', 'Monto de venta', 'Utilidad', 'Utilidad Total', 'Margen'] ];
    let sumTableProviderUtilidadTotal = auxClientMontoData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);

    auxProviderMontoData.map( (item, i) =>{
      this.chartPieGoogleProMon.dataTable.push([item.key, {'v':item.total_price, 'f': item.total_price.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) }]);
      this.auxTableProviderMonto.push([item.key, item.service_price, item.total_price, item.utilidad, (item.utilidad/sumTableProviderUtilidadTotal)*100, (item.utilidad/item.total_price)*100 ]);
    });
    this.chartPieGoogleProMon.options={ ...this.chartPieGoogleProMon.options, title: 'Proveedores'};

    //Sumatoria de columnas en la tabla
    const columnProviderTable = this.auxTableProviderMonto[0].slice(1);
    //console.log('columnProviderTable Provider', columnProviderTable);
    this.sumAuxTableProviderMonto = columnProviderTable.map((_: any, colIndex: number) => {
        return this.auxTableProviderMonto.slice(1).reduce((acumulador, fila) => {
            return acumulador + parseFloat(fila[colIndex + 1]); // Sumar 1 para excluir la columna 0
        }, 0);
    });
    this.sumAuxTableProviderMonto[3]=this.sumAuxTableProviderMonto[3].toFixed(2);
    this.sumAuxTableProviderMonto[4]=this.sumAuxTableProviderMonto[4].toFixed(2);
    //Sumatoria de columnas en la tabla

    //Actualizar el gráfico
    let ccComponentPro = this.chartPieGooglePro.component;
    if(ccComponentPro)
      ccComponentPro.draw();

    let ccComponentProMon = this.chartPieGoogleProMon.component;
    if(ccComponentProMon)
      ccComponentProMon.draw();
    //#endregion

    //#region segmento
    let sumTradicional=0;
    let sumModerno=0;
    Object.entries(groupedSegmento).forEach(([key, value]) => {
      switch (key) {
        case 'tradicional'://'bulk':
          sumTradicional+=value;
          break;
        case 'wing':
        case 'wms':
        case 'siteflow':
        case 'buho':
          auxSegModernoData.push({ "key":key, "count": value });
          sumModerno+=value;
          break;
      }
    });
    auxSegmentoData.push({ "key":'Tradicional', "count": sumTradicional });
    auxSegmentoData.push({ "key":'Moderno', "count": sumModerno });
    
    auxSegmentoData.sort((a, b) => {
      return b.count - a.count;//descendente
      //return a.count - b.count; //ascendente (de menor a mayor)
    });
    auxSegModernoData.sort((a, b) => {
      return b.count - a.count;//descendente
      //return a.count - b.count; //ascendente (de menor a mayor)
    });

    this.chartPieGoogleSegmento.dataTable= [ ['Segmento', 'Guías'] ];
    auxSegmentoData.map( (item, i) =>{
      this.chartPieGoogleSegmento.dataTable.push([item.key,item.count]);
    });
    this.chartPieGoogleSegmento.options={ ...this.chartPieGoogleSegmento.options, title: 'Segmento'};

    this.chartPieGoogleSegModerno.dataTable= [ ['Segmento', 'Guías'] ];
    auxSegModernoData.map( (item, i) =>{
      this.chartPieGoogleSegModerno.dataTable.push([item.key,item.count]);
    });
    this.chartPieGoogleSegModerno.options={ ...this.chartPieGoogleSegModerno.options, title: 'Moderno'};

    //Actualizar el gráfico
    let ccComponentSegmento = this.chartPieGoogleSegmento.component;
    if(ccComponentSegmento)
      ccComponentSegmento.draw();
    
    let ccComponentSegModerno = this.chartPieGoogleSegModerno.component;
    if(ccComponentSegModerno)
      ccComponentSegModerno.draw();


    //#endregion

    //#region Nacional Internacional
    Object.entries(groupedNatIntMonTable).forEach(([key, value]) => { //groupedNatInt
      //auxNatIntData.push({ "key":key, "count": value }); 
      auxNatIntData.push({ "key":key, "count": value.count, "service_price": value.service_price, "total_price": value.total_price, "utilidad": value.total_price-value.service_price });
    });
    auxNatIntData.sort((a, b) => {
      return b.count - a.count;//descendente
      //return a.count - b.count; //ascendente (de menor a mayor)
    });
    this.chartPieGoogleNatInt.dataTable= [ ['Tipo', 'Guías'] ];
    this.auxTableIntNatMonto= [ ['Tipo', 'Guías', 'Monto de compra', 'Monto de venta', 'Utilidad', 'Utilidad Total', 'Margen'] ];
    let sumTableNatIntUtilidadTotal = auxClientMontoData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);

    auxNatIntData.map( (item, i) =>{
      this.chartPieGoogleNatInt.dataTable.push([item.key,item.count]);
      this.auxTableIntNatMonto.push([item.key, item.count, item.service_price, item.total_price, item.utilidad, (item.utilidad/sumTableNatIntUtilidadTotal)*100, (item.utilidad/item.total_price)*100 ]);
    });
    this.chartPieGoogleNatInt.options={ ...this.chartPieGoogleNatInt.options, title: 'Tipo'};

    //Sumatoria de columnas en la tabla
    const columnNatIntTable = this.auxTableIntNatMonto[0].slice(1);
    //console.log('columnNatIntTable Provider', columnNatIntTable);
    this.sumAuxTableIntNatMonto = columnNatIntTable.map((_: any, colIndex: number) => {
        return this.auxTableIntNatMonto.slice(1).reduce((acumulador, fila) => {
            return acumulador + parseFloat(fila[colIndex + 1]); // Sumar 1 para excluir la columna 0
        }, 0);
    });
    this.sumAuxTableIntNatMonto[4]=this.sumAuxTableIntNatMonto[4].toFixed(2);
    this.sumAuxTableIntNatMonto[5]=this.sumAuxTableIntNatMonto[5].toFixed(2);
    //Sumatoria de columnas en la tabla
   
    //Actualizar los gráficos
    let ccComponentNatInt = this.chartPieGoogleNatInt.component;
    if(ccComponentNatInt)
      ccComponentNatInt.draw();
    //#endregion

    //#region Metodo de envío
    Object.entries(groupedMetEnvioCli).forEach(([key, value]) => {
      let itemClientMetEnvData:any[]=[];
      let sumMetEnvCli=0;
      let sumMetEnvCliMon=0;
      
      //console.log('----->',groupedMetEnvioCli);
      Object.entries(value).forEach(([key2, value2]) => {
        itemClientMetEnvData.push({ "key":key2, "count": value2.count, "total_price": value2.total_price}); 
        sumMetEnvCli+=value2.count;
        sumMetEnvCliMon+=value2.total_price;
      });
      itemClientMetEnvData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxMetEnvioCliData.push({ "key":key, "items": itemClientMetEnvData, "total": sumMetEnvCli, "total_price":sumMetEnvCliMon }); 
    });
    this.auxMetEnvioCliData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });
    this.rowSelectedMetEnv(0, this.auxMetEnvioCliData[0]['key'], 'client');
    //console.log('auxMetEnvioCliData',this.auxMetEnvioCliData);

    Object.entries(groupedMetEnvioPro).forEach(([key, value]) => {
      let itemProviderMetEnvData:any[]=[];
      let sumMetEnvPro=0;
      let sumMetEnvProMon=0;
      Object.entries(value).forEach(([key2, value2]) => {
        itemProviderMetEnvData.push({ "key":key2, "count": value2.count, "total_price": value2.total_price}); 
        sumMetEnvPro+=value2.count;
        sumMetEnvProMon+=value2.total_price;
        // itemProviderMetEnvData.push({ "key":key2, "count": value2}); 
        // sumMetEnvPro+=value2;
      });
      itemProviderMetEnvData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxMetEnvioProData.push({ "key":key, "items": itemProviderMetEnvData, "total": sumMetEnvPro, "total_price":sumMetEnvProMon }); 
    });
    this.auxMetEnvioProData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });
    this.rowSelectedMetEnv(0, this.auxMetEnvioProData[0]['key'], 'provider');
    //console.log('auxMetEnvioProData',this.auxMetEnvioProData);

    //#endregion

    //#region Gráfico de líneas
    this.auxLsClientData=[];
    this.auxLsProviderData=[];
    this.auxLsCarrierData=[];
    this.auxLsSegmentData=[];
    Object.entries(groupedClientDate).forEach(([key, value]) => {
      let itemClientDateData:any[]=[];
      let sumCliDate=0;
      let sumCliDatePri=0;
      let sumCliDateMon=0;
      
      //console.log('----->',groupedClientDate);
      Object.entries(value).forEach(([key2, value2]) => {
        itemClientDateData.push({ "key":key2, "count": value2.count, "service_price": value2.service_price, "total_price": value2.total_price, "utilidad": value2.total_price-value2.service_price}); 
        sumCliDate+=value2.count;
        sumCliDatePri+=value2.service_price;
        sumCliDateMon+=value2.total_price;
      });
      itemClientDateData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxLsClientData.push({ "key":key, "items": itemClientDateData, "total": sumCliDate, "service_price":sumCliDatePri, "total_price":sumCliDateMon, "utilidad": sumCliDateMon-sumCliDatePri }); 
    });
    this.auxLsClientData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });

    console.log(groupedProviderDate);
    
    Object.entries(groupedProviderDate).forEach(([key, value]) => {
      let itemProviderDateData:any[]=[];
      let sumProDate=0;
      let sumProDatePri=0;
      let sumProDateMon=0;
      
      //console.log('----->',groupedProviderDate);
      Object.entries(value).forEach(([key2, value2]) => {
        itemProviderDateData.push({ "key":key2, "count": value2.count, "service_price": value2.service_price, "total_price": value2.total_price, "utilidad": value2.total_price-value2.service_price}); 
        sumProDate+=value2.count;
        sumProDatePri+=value2.service_price;
        sumProDateMon+=value2.total_price;
      });
      itemProviderDateData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxLsProviderData.push({ "key":key, "items": itemProviderDateData, "total": sumProDate, "service_price":sumProDatePri, "total_price":sumProDateMon, "utilidad": sumProDateMon-sumProDatePri }); 
    });
    this.auxLsProviderData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });

    Object.entries(groupedCarrierDate).forEach(([key, value]) => {
      let itemDateData:any[]=[];
      let sumDataDate=0;
      let sumDataDatePri=0;
      let sumDataDateMon=0;
      
      //console.log('----->',groupedCarrierDate);
      Object.entries(value).forEach(([key2, value2]) => {
        itemDateData.push({ "key":key2, "count": value2.count, "service_price": value2.service_price, "total_price": value2.total_price, "utilidad": value2.total_price-value2.service_price}); 
        sumDataDate+=value2.count;
        sumDataDatePri+=value2.service_price;
        sumDataDateMon+=value2.total_price;
      });
      itemDateData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxLsCarrierData.push({ "key":key, "items": itemDateData, "total": sumDataDate, "service_price":sumDataDatePri, "total_price":sumDataDateMon, "utilidad": sumDataDateMon-sumDataDatePri }); 
    });
    this.auxLsCarrierData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });

    Object.entries(groupedSegmentDate).forEach(([key, value]) => {
      let itemDateData:any[]=[];
      let sumDataDate=0;
      let sumDataDatePri=0;
      let sumDataDateMon=0;
      
      //console.log('----->',groupedSegmentDate);
      Object.entries(value).forEach(([key2, value2]) => {
        itemDateData.push({ "key":key2, "count": value2.count, "service_price": value2.service_price, "total_price": value2.total_price, "utilidad": value2.total_price-value2.service_price}); 
        sumDataDate+=value2.count;
        sumDataDatePri+=value2.service_price;
        sumDataDateMon+=value2.total_price;
      });
      itemDateData.sort((a, b) => {
        return b.count - a.count;//descendente
        //return a.count - b.count; //ascendente (de menor a mayor)
      });
      this.auxLsSegmentData.push({ "key":key, "items": itemDateData, "total": sumDataDate, "service_price":sumDataDatePri, "total_price":sumDataDateMon, "utilidad": sumDataDateMon-sumDataDatePri }); 
    });
    this.auxLsSegmentData.sort((a, b) => {
      return b.total - a.total;//descendente
      //return a.total - b.total; //ascendente (de menor a mayor)
    });


    console.log(this.auxLsClientData);
    console.log(this.auxLsProviderData);
    console.log(this.auxLsCarrierData);
    console.log(this.auxLsSegmentData);
    
    this.selPeriod.setValue(this.arrPeriodsSel[0].value);
    this.selAnalize.setValue(this.arrAnalizeSel[0].value);
    this.selData.setValue(this.arrDataSel[0].value);
    this.setPeriod(null);

    //#endregion
    
    //console.log(auxClientData, auxProviderData);
  }

  equivalentProvider(provider:string){
    switch (provider) {
      case 'pxs':
      case 'pex':
        provider='pxs';
        break;
      default:
        break;
    }

    return provider;
  }

  providerGoogleLineChart(periodo:string, analizar:string){
    let nameFirstColum='Dates';
    //const groupedProDateGoogle: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    let dataAuxGoogleChart=[];
    let auxsumProviderData=0;
    const groupedProDateGoogle: { [key: string]: any } = {};
    //const groupedProNamesDateGoogle: { [key: string]: number } = {};
    let groupedProNamesDateGoogle: any[]=[];
    let utilidadTotal=0;

    if(analizar=='utilidadtotal')
      utilidadTotal = this.auxLsProviderData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);
    //console.log('utilidadTotal',utilidadTotal);
    
    this.auxLsProviderData.map( (item, i) =>{
      groupedProNamesDateGoogle.splice(i, 0, item.key); //Guarda en un array los nombres de las  paqueterías en el orden definido
      
      item.items.map( (itemInt: { utilidad: any; price: any;total_price: any;count: number; key: string | number; }, j: any) =>{
        let datekey = this.keyByPeriod( periodo, itemInt.key.toString() ); //Agrupar por fecha para tener todas las fecha existentes con datos
        this.analizeGroupedPeriod(analizar,datekey,groupedProDateGoogle,itemInt,utilidadTotal);
      });
    });
    console.log(groupedProDateGoogle, groupedProNamesDateGoogle);
    const auxFillGoogleProvider=[];
    const auxheadDateGoogle=[]; //Contendrá el número de guías en la fecha agrupada
    auxheadDateGoogle.push(nameFirstColum);
    groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
      auxheadDateGoogle.push(item);
    });
    auxFillGoogleProvider.push(auxheadDateGoogle);


    Object.entries(groupedProDateGoogle).forEach(([key, value]) => {
      const auxRowDate=[]; //Contendrá el número de guías o montosventa en la fecha agrupada
      if(periodo=='dia') {
        const [year, month, day] = key.split('-').map(Number);
        auxRowDate.push(new Date(year, month - 1, day));
      } else {
        auxRowDate.push(key);
      }

      groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
        const findProDate=this.auxLsProviderData.filter(x=>x.key==item);
        const iiitem=findProDate[0].items;
        const findData=iiitem.filter((x: { key: string; })=> this.keyByPeriod( periodo, x.key.toString() ) == key);
        auxRowDate.push(this.sumGroupedData(findData, analizar, utilidadTotal));
      });
      auxFillGoogleProvider.push(auxRowDate);
      //console.log(auxRowDate);
    });

    this.orderGroupedDataByPeriodo(auxFillGoogleProvider, periodo, nameFirstColum);
    
    console.log(auxFillGoogleProvider);

    this.chartLineGooglePro.dataTable= auxFillGoogleProvider;
    this.originalChartData = JSON.parse(JSON.stringify(auxFillGoogleProvider));

    this.chartLineGooglePro.options={ ...this.chartLineGooglePro.options, title: 'Proveedores',
                                      tooltip: {
                                        isHtml: true,
                                        //trigger: 'selection',
                                        format: {
                                          pattern: 'M/d/yy'
                                        },
                                        // textStyle: {
                                        //   color: '#333', // Color del texto
                                        //   fontSize: 12, // Tamaño de fuente
                                        //   fontName: 'Arial', // Tipo de fuente
                                        //   bold: false, // Negrita (true/false)
                                        //   italic: false // Itálica (true/false)
                                        // }
                                      },
                                      hAxis: {
                                        format: 'dd/M/yy',
                                        gridlines: {count: 15}
                                      },
                                      explorer: { 
                                        actions: ['dragToZoom', 'rightClickToReset'], 
                                        axis: 'horizontal', 
                                        keepInBounds: true, 
                                        maxZoomIn: 4.0 
                                      }
                                    };
    let ccDateComponentPro = this.chartLineGooglePro.component;
    if(ccDateComponentPro)
      ccDateComponentPro.draw();
    
  }

  clientGoogleLineChart(periodo:string, analizar:string){
    //TODO: Ordenar auxLsClientData de acuerdo a los datos seleccionados
    let nameFirstColum='Dates';
    let dataAuxGoogleChart=[];
    let auxsumClientData=0;
    const groupedProDateGoogle: { [key: string]: any } = {};
    //const groupedProNamesDateGoogle: { [key: string]: number } = {};
    let groupedProNamesDateGoogle: any[]=[];
    let utilidadTotal=0;

    if(analizar=='utilidadtotal')
      utilidadTotal = this.auxLsProviderData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);
    //console.log('utilidadTotal',utilidadTotal);
    
    this.auxLsClientData.map( (item, i) =>{
      groupedProNamesDateGoogle.splice(i, 0, item.key); //Guarda en un array los nombres de los clientes en el orden definido
      
      item.items.map( (itemInt: {total_price: any; count: number; key: string | number; }, j: any) =>{
        let datekey = this.keyByPeriod( periodo, itemInt.key.toString() ); //Agrupar por fecha para tener todas las fecha existentes con datos
        this.analizeGroupedPeriod(analizar,datekey,groupedProDateGoogle,itemInt,utilidadTotal);
      });
    });
    console.log(groupedProDateGoogle, groupedProNamesDateGoogle);
    const auxFillGoogleProvider=[];
    const auxheadDateGoogle=[]; //Contendrá el número de guías en la fecha agrupada
    auxheadDateGoogle.push(nameFirstColum);
    groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
      auxheadDateGoogle.push(item);
    });
    auxFillGoogleProvider.push(auxheadDateGoogle);


    Object.entries(groupedProDateGoogle).forEach(([key, value]) => {
      const auxRowDate=[]; //Contendrá el número de guías o montosventa en la fecha agrupada
      if(periodo=='dia') {
        const [year, month, day] = key.split('-').map(Number);
        auxRowDate.push(new Date(year, month - 1, day));
      } else {
        auxRowDate.push(key);
      }

      groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
        const findProDate=this.auxLsClientData.filter(x=>x.key==item);
        const iiitem=findProDate[0].items;
        const findData=iiitem.filter((x: { key: string; })=> this.keyByPeriod( periodo, x.key.toString() ) == key);
        auxRowDate.push(this.sumGroupedData(findData, analizar, utilidadTotal));
      });
      auxFillGoogleProvider.push(auxRowDate);
      //console.log(auxRowDate);
    });

    this.orderGroupedDataByPeriodo(auxFillGoogleProvider, periodo, nameFirstColum);
    console.log(auxFillGoogleProvider);

    this.chartLineGooglePro.dataTable= auxFillGoogleProvider;
    this.originalChartData = JSON.parse(JSON.stringify(auxFillGoogleProvider));

    this.chartLineGooglePro.options={ ...this.chartLineGooglePro.options, title: 'Clientes',
                                      tooltip: {
                                        isHtml: true,
                                        //trigger: 'selection',
                                        format: {
                                          pattern: 'M/d/yy'
                                        },
                                        // textStyle: {
                                        //   color: '#333', // Color del texto
                                        //   fontSize: 12, // Tamaño de fuente
                                        //   fontName: 'Arial', // Tipo de fuente
                                        //   bold: false, // Negrita (true/false)
                                        //   italic: false // Itálica (true/false)
                                        // }
                                      },
                                      hAxis: {
                                        format: 'dd/M/yy',
                                        gridlines: {count: 15}
                                      },
                                      explorer: { 
                                        actions: ['dragToZoom', 'rightClickToReset'], 
                                        axis: 'horizontal', 
                                        keepInBounds: true, 
                                        maxZoomIn: 4.0 
                                      }
                                    };
    let ccDateComponentPro = this.chartLineGooglePro.component;
    if(ccDateComponentPro)
      ccDateComponentPro.draw();
    
  }

  carrierGoogleLineChart(periodo:string, analizar:string){
    //TODO: Ordenar auxLsCarrierData de acuerdo a los datos seleccionados
    let nameFirstColum='Dates';
    let dataAuxGoogleChart=[];
    let auxsumClientData=0;
    const groupedProDateGoogle: { [key: string]: any } = {};
    //const groupedProNamesDateGoogle: { [key: string]: number } = {};
    let groupedProNamesDateGoogle: any[]=[];
    let utilidadTotal=0;

    if(analizar=='utilidadtotal')
      utilidadTotal = this.auxLsProviderData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);
    //console.log('utilidadTotal',utilidadTotal);
    
    this.auxLsCarrierData.map( (item, i) =>{
      groupedProNamesDateGoogle.splice(i, 0, item.key); //Guarda en un array los nombres de los clientes en el orden definido
      
      item.items.map( (itemInt: {total_price: any; count: number; key: string | number; }, j: any) =>{
        let datekey = this.keyByPeriod( periodo, itemInt.key.toString() ); //Agrupar por fecha para tener todas las fecha existentes con datos
        this.analizeGroupedPeriod(analizar,datekey,groupedProDateGoogle,itemInt,utilidadTotal);
      });
    });
    console.log(groupedProDateGoogle, groupedProNamesDateGoogle);
    const auxFillGoogleProvider=[];
    const auxheadDateGoogle=[]; //Contendrá el número de guías en la fecha agrupada
    auxheadDateGoogle.push(nameFirstColum);
    groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
      auxheadDateGoogle.push(item);
    });
    auxFillGoogleProvider.push(auxheadDateGoogle);


    Object.entries(groupedProDateGoogle).forEach(([key, value]) => {
      const auxRowDate=[]; //Contendrá el número de guías o montosventa en la fecha agrupada
      if(periodo=='dia') {
        const [year, month, day] = key.split('-').map(Number);
        auxRowDate.push(new Date(year, month - 1, day));
      } else {
        auxRowDate.push(key);
      }

      groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
        const findProDate=this.auxLsCarrierData.filter(x=>x.key==item);
        const iiitem=findProDate[0].items;
        const findData=iiitem.filter((x: { key: string; })=> this.keyByPeriod( periodo, x.key.toString() ) == key);
        auxRowDate.push(this.sumGroupedData(findData, analizar, utilidadTotal));
      });
      auxFillGoogleProvider.push(auxRowDate);
      //console.log(auxRowDate);
    });

    this.orderGroupedDataByPeriodo(auxFillGoogleProvider, periodo, nameFirstColum);
    console.log(auxFillGoogleProvider);

    this.chartLineGooglePro.dataTable= auxFillGoogleProvider;
    this.originalChartData = JSON.parse(JSON.stringify(auxFillGoogleProvider));

    this.chartLineGooglePro.options={ ...this.chartLineGooglePro.options, title: 'Paqueterías',
                                      tooltip: {
                                        isHtml: true,
                                        //trigger: 'selection',
                                        format: {
                                          pattern: 'M/d/yy'
                                        },
                                        // textStyle: {
                                        //   color: '#333', // Color del texto
                                        //   fontSize: 12, // Tamaño de fuente
                                        //   fontName: 'Arial', // Tipo de fuente
                                        //   bold: false, // Negrita (true/false)
                                        //   italic: false // Itálica (true/false)
                                        // }
                                      },
                                      hAxis: {
                                        format: 'dd/M/yy',
                                        gridlines: {count: 15}
                                      },
                                      explorer: { 
                                        actions: ['dragToZoom', 'rightClickToReset'], 
                                        axis: 'horizontal', 
                                        keepInBounds: true, 
                                        maxZoomIn: 4.0 
                                      }
                                    };
    let ccDateComponentPro = this.chartLineGooglePro.component;
    if(ccDateComponentPro)
      ccDateComponentPro.draw();
    
  }
  
  segmentGoogleLineChart(periodo:string, analizar:string){
    //TODO: Ordenar auxLsSegmentData de acuerdo a los datos seleccionados
    let nameFirstColum='Dates';
    let dataAuxGoogleChart=[];
    let auxsumClientData=0;
    const groupedProDateGoogle: { [key: string]: any } = {};
    //const groupedProNamesDateGoogle: { [key: string]: number } = {};
    let groupedProNamesDateGoogle: any[]=[];
    let utilidadTotal=0;

    if(analizar=='utilidadtotal')
      utilidadTotal = this.auxLsProviderData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);
    //console.log('utilidadTotal',utilidadTotal);
    
    this.auxLsSegmentData.map( (item, i) =>{
      groupedProNamesDateGoogle.splice(i, 0, item.key); //Guarda en un array los nombres de los clientes en el orden definido
      
      item.items.map( (itemInt: {total_price: any; count: number; key: string | number; }, j: any) =>{
        let datekey = this.keyByPeriod( periodo, itemInt.key.toString() ); //Agrupar por fecha para tener todas las fecha existentes con datos
        this.analizeGroupedPeriod(analizar,datekey,groupedProDateGoogle,itemInt,utilidadTotal);
      });
    });
    console.log(groupedProDateGoogle, groupedProNamesDateGoogle);
    const auxFillGoogleProvider=[];
    const auxheadDateGoogle=[]; //Contendrá el número de guías en la fecha agrupada
    auxheadDateGoogle.push(nameFirstColum);
    groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
      auxheadDateGoogle.push(item);
    });
    auxFillGoogleProvider.push(auxheadDateGoogle);


    Object.entries(groupedProDateGoogle).forEach(([key, value]) => {
      const auxRowDate=[]; //Contendrá el número de guías o montosventa en la fecha agrupada
      if(periodo=='dia') {
        const [year, month, day] = key.split('-').map(Number);
        auxRowDate.push(new Date(year, month - 1, day));
      } else {
        auxRowDate.push(key);
      }

      groupedProNamesDateGoogle.map( (item, i) =>{ //Recorrer los proveedores en orden
        const findProDate=this.auxLsSegmentData.filter(x=>x.key==item);
        const iiitem=findProDate[0].items;
        const findData=iiitem.filter((x: { key: string; })=> this.keyByPeriod( periodo, x.key.toString() ) == key);
        auxRowDate.push(this.sumGroupedData(findData, analizar, utilidadTotal));
      });
      auxFillGoogleProvider.push(auxRowDate);
      //console.log(auxRowDate);
    });

    this.orderGroupedDataByPeriodo(auxFillGoogleProvider, periodo, nameFirstColum);
    console.log(auxFillGoogleProvider);

    this.chartLineGooglePro.dataTable= auxFillGoogleProvider;
    this.originalChartData = JSON.parse(JSON.stringify(auxFillGoogleProvider));

    this.chartLineGooglePro.options={ ...this.chartLineGooglePro.options, title: 'Segmentos',
                                      tooltip: {
                                        isHtml: true,
                                        //trigger: 'selection',
                                        format: {
                                          pattern: 'M/d/yy'
                                        },
                                        // textStyle: {
                                        //   color: '#333', // Color del texto
                                        //   fontSize: 12, // Tamaño de fuente
                                        //   fontName: 'Arial', // Tipo de fuente
                                        //   bold: false, // Negrita (true/false)
                                        //   italic: false // Itálica (true/false)
                                        // }
                                      },
                                      hAxis: {
                                        format: 'dd/M/yy',
                                        gridlines: {count: 15}
                                      },
                                      explorer: { 
                                        actions: ['dragToZoom', 'rightClickToReset'], 
                                        axis: 'horizontal', 
                                        keepInBounds: true, 
                                        maxZoomIn: 4.0 
                                      }
                                    };
    let ccDateComponentPro = this.chartLineGooglePro.component;
    if(ccDateComponentPro)
      ccDateComponentPro.draw();
    
  }

  public chartLineGoogleProSelect(event: ChartSelectEvent) {
    // Encuentra el índice de la columna en los datos del gráfico
    const columnIndex = event.column;

    // Si la columna no se encuentra o el evento es diferente de 'select', no se hace nada
    if (!columnIndex || event.message !== 'select') return;

    // Texto para indicar leyenda oculta
    const hidden = '(Hidden) ';

    // Guarda el nombre original de la leyenda
    const originalLegendName = this.originalChartData[0][columnIndex];

    if (this.chartLineGooglePro.dataTable[0][columnIndex].includes(hidden)) {
      // Si la leyenda ya está oculta, se muestra de nuevo
      this.chartLineGooglePro.dataTable[0][columnIndex] = originalLegendName;
      for (let i = 1; i < this.chartLineGooglePro.dataTable.length; i++) {
        this.chartLineGooglePro.dataTable[i][columnIndex] = this.originalChartData[i][columnIndex];
      }
    }else{
      // Si la leyenda no está oculta, se oculta
      this.chartLineGooglePro.dataTable[0][columnIndex] = hidden + originalLegendName;
      for (let i = 1; i < this.chartLineGooglePro.dataTable.length; i++) {
        this.chartLineGooglePro.dataTable[i][columnIndex] = NaN;
      }
    }

    // Actualizar el gráfico con los nuevos datos
    let ccDateComponentPro = this.chartLineGooglePro.component;
    if (ccDateComponentPro)
      ccDateComponentPro.draw();
  }

  keyByPeriod(periodo:string, dateString:string){
    let datekey=dateString;
    const partes = dateString.toString().split("-"); //aaaa-mm-dd
    const anio = partes[0]; const mes = parseInt(partes[1]); const dia = partes[2];
    switch (periodo) {
      case "quartil":
        if (mes <= 3) {
          datekey = `Q1`; // Primer cuartil: enero, febrero, marzo
        } else if (mes <= 6) {
          datekey = `Q2`; // Segundo cuartil: abril, mayo, junio
        } else if (mes <= 9) {
          datekey = `Q3`; // Tercer cuartil: julio, agosto, septiembre
        } else {
          datekey = `Q4`; // Cuarto cuartil: octubre, noviembre, diciembre
        }
        if(!this.sameYear) //Si no es el mismo año agregar el año
          datekey=`${anio}-${datekey}`;

        break;
      case "mes":
        datekey=`${anio}-${mes}`;
        break;
      case "dia":
        //se mantiene el datakey;
        break;
    }
    return datekey;
  }

  analizeGroupedPeriod(analizar:string, datekey:any, groupedProDateGoogle:any, itemInt:any, utilidadTotal:number=0){
    switch (analizar) {
      case 'guias':
        if (!groupedProDateGoogle[datekey]) {
          groupedProDateGoogle[datekey]=itemInt.count;
        }
        else{
          groupedProDateGoogle[datekey]+=itemInt.count;
        }    
        break;
      case 'montosventa':
        if (!groupedProDateGoogle[datekey]) {
          groupedProDateGoogle[datekey]=itemInt.total_price;
        }
        else{
          groupedProDateGoogle[datekey]+=itemInt.total_price;
        }
        break;
      case 'montoscompra':
        if (!groupedProDateGoogle[datekey]) {
          groupedProDateGoogle[datekey]=itemInt.service_price;
        }
        else{
          groupedProDateGoogle[datekey]+=itemInt.service_price;
        }
        break;
      case 'utilidad':
        if (!groupedProDateGoogle[datekey]) {
          groupedProDateGoogle[datekey]=itemInt.utilidad;
        }
        else{
          groupedProDateGoogle[datekey]+=itemInt.utilidad;
        }
        break;
      case 'utilidadtotal':
          if (!groupedProDateGoogle[datekey]) {
            groupedProDateGoogle[datekey]=itemInt.utilidad/utilidadTotal;
          }
          else{
            groupedProDateGoogle[datekey]+=itemInt.utilidad/utilidadTotal;
          }
          break;
      case 'margen':
        if (!groupedProDateGoogle[datekey]) {
          groupedProDateGoogle[datekey]=itemInt.utilidad/itemInt.total_price;
        }
        else{
          groupedProDateGoogle[datekey]+=itemInt.utilidad/itemInt.total_price;
        }
        break;
    }
  }

  sumGroupedData(findData:any, analizar:string, utilidadTotal:number=0){
    let sum=null;
    let sub;
    let formattedPercent;
    if(findData && findData.length>0){//Ver si existen datos
      switch (analizar) {
        case 'guias':
          sum=findData.reduce( (suma: any, obj: { count: any; }) => suma + obj.count, 0);
          break;
        case 'montosventa':
          sub=findData.reduce( (suma: any, obj: { total_price: any; }) => suma + obj.total_price, 0);
          sum={'v':sub, 'f': sub.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) };
          break;
        case 'montoscompra':
          sub=findData.reduce( (suma: any, obj: { service_price: any; }) => suma + obj.service_price, 0);
          sum={'v':sub, 'f': sub.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) };
          break;
        case 'utilidad':
          sub=findData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);
          sum={'v':sub, 'f': sub.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) };
          break;
        case 'utilidadtotal':
          sub=findData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);
          sub=sub/utilidadTotal;
          formattedPercent = (sub * 100).toFixed(2) + '%';
          sum={'v':sub, 'f': formattedPercent };
            break;
        case 'margen':
          sub=findData.reduce( (suma: any, obj: { utilidad: any; }) => suma + obj.utilidad, 0);
          let margen_total_price=findData.reduce( (suma: any, obj: { total_price: any; }) => suma + obj.total_price, 0);
          sub=sub/margen_total_price;
          formattedPercent = (sub * 100).toFixed(2) + '%';
          sum={'v':sub, 'f': formattedPercent };
          break;
      }
    }
    return sum;
  }

  orderGroupedDataByPeriodo(auxFillGoogleProvider:any, periodo:string, nameFirstColum:string){
    switch (periodo) {
      case "quartil":
        auxFillGoogleProvider.sort((a: any[], b: any[]) => {
          // Ignorar la primera fila (encabezado)
          if (a[0] == nameFirstColum) return -1;
          if (b[0] == nameFirstColum) return 1;
          // Comparar los valores de la primera columna
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        });
        break;
      case "mes":
        auxFillGoogleProvider.sort((a: any[], b: any[]) => {
          // Ignorar la primera fila (encabezado)
          if (a[0] == nameFirstColum) return -1;
          if (b[0] == nameFirstColum) return 1;
          // Comparar los valores de la primera columna
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        });
        break;
      case "dia":
        auxFillGoogleProvider.sort((a: any[], b: any[]) => {
          // Convertir las fechas a objetos Date
          let dateA = new Date(a[0]);
          let dateB = new Date(b[0]);
          
          // Comparar las fechas
          if (dateA < dateB) {
              return -1;
          } else if (dateA > dateB) {
              return 1;
          } else {
              return 0;
          }
        });
        break;
    }
  }



  setPeriod(event:any) {
    switch (this.selData.value) {
      case 'proveedores':
        this.providerGoogleLineChart(this.selPeriod.value, this.selAnalize.value);
        break;
      case 'clientes':
        this.clientGoogleLineChart(this.selPeriod.value, this.selAnalize.value);
        break;
      case 'paquterias':
        this.carrierGoogleLineChart(this.selPeriod.value, this.selAnalize.value);
        break;
      case 'segmentos':
        this.segmentGoogleLineChart(this.selPeriod.value, this.selAnalize.value);
        break;
    }

  }











 
    bestKpiData(){
      console.log('bestKpiData');
      let auxKg: { [key: string]: number } = {}; //Agrupación por proveedores
      this.billingDataList.forEach((item) => {
        //#region KG más utilizado en el periodo
        const sPaq = JSON.stringify(item.package_dimensions);
        const paq = JSON.parse(sPaq);
        for (let index = 0; index < paq.length; index++) {
          const element = paq[index];
          const pkey = `${element.weight}`; //Crear una key de provider para agrupar
          // if(parseInt(pkey)>=412)
          //   console.log(item);
          if (!auxKg[pkey]) {
            auxKg[pkey] = 1;
          } else {
            auxKg[pkey]++;
          }
        }
        //#endregion

        //#region segmento más usado
        //#endregion

      });
      //#region KG más utilizado en el periodo
      console.log(auxKg);
      let sortedArrayC = Object.entries(auxKg).sort((a, b) => b[1] - a[1]); // Orden descendente, cambiar b[1] - a[1] a a[1] - b[1] para orden ascendente
      console.log('short',sortedArrayC);
      Object.keys(auxKg).forEach(key => delete auxKg[key]);
      sortedArrayC.forEach(([key, value]) => {
        auxKg[key] = value;
      });
      //console.log(auxKg);
      if(sortedArrayC && sortedArrayC.length>0){
        this.bestKg.kg = parseInt(sortedArrayC[0][0]);
        this.bestKg.count = sortedArrayC[0][1];
      }
      //#endregion
    }

    getNatInt(){
      this.chartTipo.series=[];
      this.totalNatInt=0;
      this.sumNatInt = this.billingDataList.reduce((acumulador, elemento) => {
          if(elemento.shipment_type=='nat'){
            acumulador.nat++;
            acumulador.totalPriceNat+= parseFloat(elemento.total_price);
          }
          else {
            acumulador.int++;
            acumulador.totalPriceInt+= parseFloat(elemento.total_price);
          }
          return acumulador;
      }, { nat: 0, int: 0, totalPriceNat:0, totalPriceInt:0 });
      //console.log(this.sumNatInt);
  
      let pNat=0, pInt=0;
      if(this.billingDataList.length>0){
        pNat=Math.round( (this.sumNatInt.nat*100)/this.billingDataList.length );
        pInt=Math.round( (this.sumNatInt.int*100)/this.billingDataList.length);
      }
      this.totalNatInt=this.sumNatInt.nat+this.sumNatInt.int;
      
      this.chartTipo.series=[pNat, pInt];
      this.chartTipo.labels=[`${this.sumNatInt.nat.toLocaleString('es-MX', { minimumFractionDigits: 0 })} Nat`, `${this.sumNatInt.int.toLocaleString('es-MX', { minimumFractionDigits: 0 })} Int`];
 
     //console.log(this.chartProveedor);
   }
 
  getProviderChart(){
     let aPro: { [key: string]: number } = {}; //Agrupación por proveedores
     let aPaq: { [key: string]: number } = {}; //Agrupación por paqueterías
     let aMEn: { [key: string]: number } = {}; //Agrupación por método de envío (terrestre, aéreo)
     let aCli: { [key: string]: number } = {}; //Agrupación por clientes


    this.billingDataList.forEach((item) => {
       const pkey = `${item.provider}`; //Crear una key de provider para agrupar
       if (!aPro[pkey]) {
         aPro[pkey] = 1;
       } else {
         aPro[pkey]++;
       }
 
       let pakey = `${item.carrier}`; //Crear una key de paquetería para agrupar
       const indexCarr = this.equivalentCarSerList.findIndex(r=> r.carrier_dict.toUpperCase().includes(item.carrier.toUpperCase()) || item.carrier.toUpperCase().includes(r.carrier_dict.toUpperCase()) );
       if(indexCarr != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
         //sService=`${service}->${this.servicesModeList[indexCarr].service_mode}`;
         let car_name=`${this.equivalentCarSerList[indexCarr].carrier_cat}`;
         pakey = `${car_name}`;
       }
       if (!aPaq[pakey]) {
         aPaq[pakey] = 1;
       } else {
         aPaq[pakey]++;
       }

      


      const mkey = `${this.getServiceMode(item.service, item.carrier, item.provider)}`; //Crear una key con el método del envío para agrupar
      // if(mkey=='nextday'){
      //   console.log(item.service, item.carrier, item.provider);
      // }
      if (!aMEn[mkey]) {
        aMEn[mkey] = 1;
      } else {
        aMEn[mkey]++;
      }

      const ckey = (item.company_name=='WMS'? item.ext_client_name : item.company_name); //Crear una key con el método del envío para agrupar
      if (!aCli[ckey]) {
        aCli[ckey] = 1;
      } else {
        aCli[ckey]++;
      }

    });

    this.chartProveedor.series = [];
    this.chartProveedor.labels = [];

    let sortedArrayProv = Object.entries(aPro).sort((a, b) => b[1] - a[1]); // Orden descendente, cambiar b[1] - a[1] a a[1] - b[1] para orden ascendente
    // sortedArrayProv = sortedArrayProv.slice(0, 5);
    Object.keys(aPro).forEach(key => delete aPro[key]);
    sortedArrayProv.forEach(([key, value]) => {
      aPro[key] = value;
    });

    for (let key in aPro) {
      if (Object.prototype.hasOwnProperty.call(aPro, key)) {
        //console.log(`Nombre de la propiedad: ${key}, Valor: ${aPro[key]}`);
        let per=0;
        if(this.billingDataList.length>0){
          per=Math.round( (aPro[key]*100)/this.billingDataList.length);

        }        
        this.chartProveedor.series.push(per);
        this.chartProveedor.labels.push(key);
       
      }
    }

    this.chartMetodoEnv.series = [];
    this.chartMetodoEnv.labels = [];
    
    let sortedArrayMet = Object.entries(aMEn).sort((a, b) => b[1] - a[1]); // Orden descendente, cambiar b[1] - a[1] a a[1] - b[1] para orden ascendente
    // sortedArrayMet = sortedArrayMet.slice(0, 2);
    Object.keys(aMEn).forEach(key => delete aMEn[key]);
    sortedArrayMet.forEach(([key, value]) => {
      aMEn[key] = value;
    });


    for (let key in aMEn) {
      if (Object.prototype.hasOwnProperty.call(aMEn, key)) {
        //console.log(`Nombre de la propiedad: ${key}, Valor: ${aMEn[key]}`);
        let per=0;
        if(this.billingDataList.length>0){
          per=Math.round( (aMEn[key]*100)/this.billingDataList.length);

        }        
        this.chartMetodoEnv.series.push(per);
        this.chartMetodoEnv.labels.push(key);
      }
    }


    this.chartProPaqueteria1.series = [];
    this.chartProPaqueteria1.colors = [aColorBase[0]];

    this.chartProPaqueteria1.plotOptions  = {
      ...this.chartProPaqueteria1.plotOptions,
      bar: {
          ...this.chartProPaqueteria1.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria1.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[0]], 
          },
      },
    };

    
    this.chartProPaqueteria1.title={ ...this.chartProPaqueteria1.title, text: ''};
    this.chartProPaqueteria1.subtitle={ ...this.chartProPaqueteria1.subtitle, text: ''};
    this.chartProPaqueteria2.series = [];
    this.chartProPaqueteria2.colors = [aColorBase[1]];
    this.chartProPaqueteria2.plotOptions  = {
      ...this.chartProPaqueteria2.plotOptions,
      bar: {
          ...this.chartProPaqueteria2.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria2.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[1]], 
          },
      },
    };
    //this.chartProPaqueteria2.plotOptions.bar.colors = {... this.chartProPaqueteria2.plotOptions.bar.colors, backgroundBarColors: [aColorBase[1]]};
    this.chartProPaqueteria2.plotOptions.bar.colors.backgroundBarColors = [aColorBase[1]];
    this.chartProPaqueteria2.title={ ...this.chartProPaqueteria2.title, text: ''};
    this.chartProPaqueteria2.subtitle={ ...this.chartProPaqueteria2.subtitle, text: ''};
    this.chartProPaqueteria3.series = [];
    this.chartProPaqueteria3.colors = [aColorBase[2]];
    this.chartProPaqueteria3.plotOptions  = {
      ...this.chartProPaqueteria3.plotOptions,
      bar: {
          ...this.chartProPaqueteria3.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria3.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[2]], 
          },
      },
    };

    this.chartProPaqueteria3.plotOptions.bar.colors.backgroundBarColors = [aColorBase[2]];
    this.chartProPaqueteria3.title={ ...this.chartProPaqueteria3.title, text: ''};
    this.chartProPaqueteria3.subtitle={ ...this.chartProPaqueteria3.subtitle, text: ''};
    this.chartProPaqueteria4.series = [];
    this.chartProPaqueteria4.colors = [aColorBase[3]];
    this.chartProPaqueteria4.plotOptions  = {
      ...this.chartProPaqueteria4.plotOptions,
      bar: {
          ...this.chartProPaqueteria4.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria4.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[3]], 
          },
      },
    };
    this.chartProPaqueteria4.title={ ...this.chartProPaqueteria4.title, text: ''};
    this.chartProPaqueteria4.subtitle={ ...this.chartProPaqueteria4.subtitle, text: ''};
    this.chartProPaqueteria5.series = [];
    this.chartProPaqueteria5.colors = [aColorBase[4]];
    this.chartProPaqueteria5.plotOptions  = {
      ...this.chartProPaqueteria5.plotOptions,
      bar: {
          ...this.chartProPaqueteria5.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria5.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[4]], 
          },
      },
    };
    this.chartProPaqueteria5.title={ ...this.chartProPaqueteria5.title, text: ''};
    this.chartProPaqueteria5.subtitle={ ...this.chartProPaqueteria5.subtitle, text: ''};
    this.chartProPaqueteria6.series = [];
    this.chartProPaqueteria6.colors = [aColorBase[0]];
    this.chartProPaqueteria6.plotOptions  = {
      ...this.chartProPaqueteria6.plotOptions,
      bar: {
          ...this.chartProPaqueteria6.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria6.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[0]], 
          },
      },
    };
    this.chartProPaqueteria6.title={ ...this.chartProPaqueteria6.title, text: ''};
    this.chartProPaqueteria6.subtitle={ ...this.chartProPaqueteria6.subtitle, text: ''};
    this.chartProPaqueteria7.series = [];
    this.chartProPaqueteria7.colors = [aColorBase[1]];
    this.chartProPaqueteria7.plotOptions  = {
      ...this.chartProPaqueteria7.plotOptions,
      bar: {
          ...this.chartProPaqueteria7.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria7.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[1]], 
          },
      },
    };
    this.chartProPaqueteria7.title={ ...this.chartProPaqueteria7.title, text: ''};
    this.chartProPaqueteria7.subtitle={ ...this.chartProPaqueteria7.subtitle, text: ''};
    this.chartProPaqueteria8.series = [];
    this.chartProPaqueteria8.colors = [aColorBase[2]];
    this.chartProPaqueteria8.plotOptions  = {
      ...this.chartProPaqueteria8.plotOptions,
      bar: {
          ...this.chartProPaqueteria8.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria8.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[2]], 
          },
      },
    };
    this.chartProPaqueteria8.title={ ...this.chartProPaqueteria8.title, text: ''};
    this.chartProPaqueteria8.subtitle={ ...this.chartProPaqueteria8.subtitle, text: ''};
    this.chartProPaqueteria9.series = [];
    this.chartProPaqueteria9.colors = [aColorBase[3]];
    this.chartProPaqueteria9.plotOptions  = {
      ...this.chartProPaqueteria9.plotOptions,
      bar: {
          ...this.chartProPaqueteria9.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria9.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[3]], 
          },
      },
    };
    this.chartProPaqueteria9.title={ ...this.chartProPaqueteria9.title, text: ''};
    this.chartProPaqueteria9.subtitle={ ...this.chartProPaqueteria9.subtitle, text: ''};
    this.chartProPaqueteria10.series = [];
    this.chartProPaqueteria10.colors = [aColorBase[4]];
    this.chartProPaqueteria10.plotOptions  = {
      ...this.chartProPaqueteria10.plotOptions,
      bar: {
          ...this.chartProPaqueteria10.plotOptions.bar,
          colors: {
              ...this.chartProPaqueteria10.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[4]], 
          },
      },
    };
    this.chartProPaqueteria10.title={ ...this.chartProPaqueteria10.title, text: ''};
    this.chartProPaqueteria10.subtitle={ ...this.chartProPaqueteria10.subtitle, text: ''};
    let contPaq=0;

    
    let sortedArray = Object.entries(aPaq).sort((a, b) => b[1] - a[1]); // Orden descendente, cambiar b[1] - a[1] a a[1] - b[1] para orden ascendente
    Object.keys(aPaq).forEach(key => delete aPaq[key]);
    sortedArray.forEach(([key, value]) => {
      aPaq[key] = value;
    });

    for (let key in aPaq) {
      if (Object.prototype.hasOwnProperty.call(aPaq, key)) {
        let per=0;
        if(this.billingDataList.length>0){
          per=Math.round( (aPaq[key]*100)/this.billingDataList.length);
        } 
        let ser= {
          name: key,
          data: [per],
        };
        contPaq++;
        switch (contPaq) {
          case 1:
            this.chartProPaqueteria1.series.push(ser);
            this.chartProPaqueteria1.title={ ...this.chartProPaqueteria1.title, text: `${key}`};
            this.chartProPaqueteria1.subtitle={ ...this.chartProPaqueteria1.subtitle, text: `${per} %`};
            
            break;
          case 2:
            this.chartProPaqueteria2.series.push(ser);
            this.chartProPaqueteria2.title={ ...this.chartProPaqueteria2.title, text: `${key}`};
            this.chartProPaqueteria2.subtitle={ ...this.chartProPaqueteria2.subtitle, text: `${per} %`};
            
            break;
          case 3:
            this.chartProPaqueteria3.series.push(ser);
            this.chartProPaqueteria3.title={ ...this.chartProPaqueteria3.title, text: `${key}`};
            this.chartProPaqueteria3.subtitle={ ...this.chartProPaqueteria3.subtitle, text: `${per} %`};
            
            break;
          case 4:
            this.chartProPaqueteria4.series.push(ser);
            this.chartProPaqueteria4.title={ ...this.chartProPaqueteria4.title, text: `${key}`};
            this.chartProPaqueteria4.subtitle={ ...this.chartProPaqueteria4.subtitle, text: `${per} %`};
            
            break;
          case 5:
            this.chartProPaqueteria5.series.push(ser);
            this.chartProPaqueteria5.title={ ...this.chartProPaqueteria5.title, text: `${key}`};
            this.chartProPaqueteria5.subtitle={ ...this.chartProPaqueteria5.subtitle, text: `${per} %`};
            
            break;
          case 6:
            this.chartProPaqueteria6.series.push(ser);
            this.chartProPaqueteria6.title={ ...this.chartProPaqueteria6.title, text: `${key}`};
            this.chartProPaqueteria6.subtitle={ ...this.chartProPaqueteria6.subtitle, text: `${per} %`};
            
            break;
          case 7:
            this.chartProPaqueteria7.series.push(ser);
            this.chartProPaqueteria7.title={ ...this.chartProPaqueteria7.title, text: `${key}`};
            this.chartProPaqueteria7.subtitle={ ...this.chartProPaqueteria7.subtitle, text: `${per} %`};
            
            break;
          case 8:
            this.chartProPaqueteria8.series.push(ser);
            this.chartProPaqueteria8.title={ ...this.chartProPaqueteria8.title, text: `${key}`};
            this.chartProPaqueteria8.subtitle={ ...this.chartProPaqueteria8.subtitle, text: `${per} %`};
            
            break;
          case 9:
            this.chartProPaqueteria9.series.push(ser);
            this.chartProPaqueteria9.title={ ...this.chartProPaqueteria9.title, text: `${key}`};
            this.chartProPaqueteria9.subtitle={ ...this.chartProPaqueteria9.subtitle, text: `${per} %`};
            
            break;
          case 10:
            this.chartProPaqueteria10.series.push(ser);
            this.chartProPaqueteria10.title={ ...this.chartProPaqueteria10.title, text: `${key}`};
            this.chartProPaqueteria10.subtitle={ ...this.chartProPaqueteria10.subtitle, text: `${per} %`};
            
            break;
        }
      }
    }


    this.chartCliente1.series = [];
    this.chartCliente1.colors = [aColorBase[0]];
    this.chartCliente1.plotOptions  = {
      ...this.chartCliente1.plotOptions,
      bar: {
          ...this.chartCliente1.plotOptions.bar,
          colors: {
              ...this.chartCliente1.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[0]], 
          },
      },
    };
    this.chartCliente1.title={ ...this.chartCliente1.title, text: ''};
    this.chartCliente1.subtitle={ ...this.chartCliente1.subtitle, text: ''};
    this.chartCliente2.series = [];
    this.chartCliente2.colors = [aColorBase[1]];
    this.chartCliente2.plotOptions  = {
      ...this.chartCliente2.plotOptions,
      bar: {
          ...this.chartCliente2.plotOptions.bar,
          colors: {
              ...this.chartCliente2.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[1]], 
          },
      },
    };
    this.chartCliente2.title={ ...this.chartCliente2.title, text: ''};
    this.chartCliente2.subtitle={ ...this.chartCliente2.subtitle, text: ''};
    this.chartCliente3.series = [];
    this.chartCliente3.colors = [aColorBase[2]];
    this.chartCliente3.plotOptions  = {
      ...this.chartCliente3.plotOptions,
      bar: {
          ...this.chartCliente3.plotOptions.bar,
          colors: {
              ...this.chartCliente3.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[2]], 
          },
      },
    };
    this.chartCliente3.title={ ...this.chartCliente3.title, text: ''};
    this.chartCliente3.subtitle={ ...this.chartCliente3.subtitle, text: ''};
    this.chartCliente4.series = [];
    this.chartCliente4.colors = [aColorBase[3]];
    this.chartCliente4.plotOptions  = {
      ...this.chartCliente4.plotOptions,
      bar: {
          ...this.chartCliente4.plotOptions.bar,
          colors: {
              ...this.chartCliente4.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[3]], 
          },
      },
    };
    this.chartCliente4.title={ ...this.chartCliente4.title, text: ''};
    this.chartCliente4.subtitle={ ...this.chartCliente4.subtitle, text: ''};
    this.chartCliente5.series = [];
    this.chartCliente5.colors = [aColorBase[4]];
    this.chartCliente5.plotOptions  = {
      ...this.chartCliente5.plotOptions,
      bar: {
          ...this.chartCliente5.plotOptions.bar,
          colors: {
              ...this.chartCliente5.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[4]], 
          },
      },
    };
    this.chartCliente5.title={ ...this.chartCliente5.title, text: ''};
    this.chartCliente5.subtitle={ ...this.chartCliente5.subtitle, text: ''};
    this.chartCliente6.series = [];
    this.chartCliente6.colors = [aColorBase[0]];
    this.chartCliente6.plotOptions  = {
      ...this.chartCliente6.plotOptions,
      bar: {
          ...this.chartCliente6.plotOptions.bar,
          colors: {
              ...this.chartCliente6.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[0]], 
          },
      },
    };
    this.chartCliente6.title={ ...this.chartCliente6.title, text: ''};
    this.chartCliente6.subtitle={ ...this.chartCliente6.subtitle, text: ''};
    this.chartCliente7.series = [];
    this.chartCliente7.colors = [aColorBase[1]];
    this.chartCliente7.plotOptions  = {
      ...this.chartCliente7.plotOptions,
      bar: {
          ...this.chartCliente7.plotOptions.bar,
          colors: {
              ...this.chartCliente7.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[1]], 
          },
      },
    };
    this.chartCliente7.title={ ...this.chartCliente7.title, text: ''};
    this.chartCliente7.subtitle={ ...this.chartCliente7.subtitle, text: ''};
    this.chartCliente8.series = [];
    this.chartCliente8.colors = [aColorBase[2]];
    this.chartCliente8.plotOptions  = {
      ...this.chartCliente8.plotOptions,
      bar: {
          ...this.chartCliente8.plotOptions.bar,
          colors: {
              ...this.chartCliente8.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[2]], 
          },
      },
    };
    this.chartCliente8.title={ ...this.chartCliente8.title, text: ''};
    this.chartCliente8.subtitle={ ...this.chartCliente8.subtitle, text: ''};
    this.chartCliente9.series = [];
    this.chartCliente9.colors = [aColorBase[3]];
    this.chartCliente9.plotOptions  = {
      ...this.chartCliente9.plotOptions,
      bar: {
          ...this.chartCliente9.plotOptions.bar,
          colors: {
              ...this.chartCliente9.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[3]], 
          },
      },
    };
    this.chartCliente9.title={ ...this.chartCliente9.title, text: ''};
    this.chartCliente9.subtitle={ ...this.chartCliente9.subtitle, text: ''};
    this.chartCliente10.series = [];
    this.chartCliente10.colors = [aColorBase[4]];
    this.chartCliente10.plotOptions  = {
      ...this.chartCliente10.plotOptions,
      bar: {
          ...this.chartCliente10.plotOptions.bar,
          colors: {
              ...this.chartCliente10.plotOptions.bar.colors,
              backgroundBarColors: [aColorBase[4]], 
          },
      },
    };
    this.chartCliente10.title={ ...this.chartCliente10.title, text: ''};
    this.chartCliente10.subtitle={ ...this.chartCliente10.subtitle, text: ''};
    let contCli=0;

    let sortedArrayC = Object.entries(aCli).sort((a, b) => b[1] - a[1]); // Orden descendente, cambiar b[1] - a[1] a a[1] - b[1] para orden ascendente
    Object.keys(aCli).forEach(key => delete aCli[key]);
    sortedArrayC.forEach(([key, value]) => {
      aCli[key] = value;
    });


    for (let key in aCli) {
      if (Object.prototype.hasOwnProperty.call(aCli, key)) {
        let per=0;
        if(this.billingDataList.length>0){
          per=Math.round( (aCli[key]*100)/this.billingDataList.length);
        } 
        let ser= {
          name: key,
          data: [per],
        };
        contCli++;
        switch (contCli) {
          case 1:
            this.chartCliente1.series.push(ser);
            this.chartCliente1.title={ ...this.chartCliente1.title, text: `${key}`};
            this.chartCliente1.subtitle={ ...this.chartCliente1.subtitle, text: `${per} %`};
            
            break;
          case 2:
            this.chartCliente2.series.push(ser);
            this.chartCliente2.title={ ...this.chartCliente2.title, text: `${key}`};
            this.chartCliente2.subtitle={ ...this.chartCliente2.subtitle, text: `${per} %`};
            
            break;
          case 3:
            this.chartCliente3.series.push(ser);
            this.chartCliente3.title={ ...this.chartCliente3.title, text: `${key}`};
            this.chartCliente3.subtitle={ ...this.chartCliente3.subtitle, text: `${per} %`};
            
            break;
          case 4:
            this.chartCliente4.series.push(ser);
            this.chartCliente4.title={ ...this.chartCliente4.title, text: `${key}`};
            this.chartCliente4.subtitle={ ...this.chartCliente4.subtitle, text: `${per} %`};
            
            break;
          case 5:
            this.chartCliente5.series.push(ser);
            this.chartCliente5.title={ ...this.chartCliente5.title, text: `${key}`};
            this.chartCliente5.subtitle={ ...this.chartCliente5.subtitle, text: `${per} %`};
            
            break;
          case 6:
            this.chartCliente6.series.push(ser);
            this.chartCliente6.title={ ...this.chartCliente6.title, text: `${key}`};
            this.chartCliente6.subtitle={ ...this.chartCliente6.subtitle, text: `${per} %`};
            
            break;
          case 7:
            this.chartCliente7.series.push(ser);
            this.chartCliente7.title={ ...this.chartCliente7.title, text: `${key}`};
            this.chartCliente7.subtitle={ ...this.chartCliente7.subtitle, text: `${per} %`};
            
            break;
          case 8:
            this.chartCliente8.series.push(ser);
            this.chartCliente8.title={ ...this.chartCliente8.title, text: `${key}`};
            this.chartCliente8.subtitle={ ...this.chartCliente8.subtitle, text: `${per} %`};
            
            break;
          case 9:
            this.chartCliente9.series.push(ser);
            this.chartCliente9.title={ ...this.chartCliente9.title, text: `${key}`};
            this.chartCliente9.subtitle={ ...this.chartCliente9.subtitle, text: `${per} %`};
            
            break;
          case 10:
            this.chartCliente10.series.push(ser);
            this.chartCliente10.title={ ...this.chartCliente10.title, text: `${key}`};
            this.chartCliente10.subtitle={ ...this.chartCliente10.subtitle, text: `${per} %`};
            
            break;
        }
      }
    }
    
    //console.log(aPro, aPaq, aMEn);
  }
  

  getComparativeChart(){
    let aPaq: { [key: string]: number } = {}; //Agrupación por paqueterías
    let aCli: { [key: string]: number } = {}; //Agrupación por clientes

    let aPaqMes: { [key: string]: { [key: string]: number } } = {}; //Agrupación por Paquetes por mes
    let aPaqNMes: { [key: string]: string } = {}; //Agrupación por Paquetes por mes, meses a utilizar
    let aCliMes: { [key: string]: { [key: string]: number } } = {}; //Agrupación por Clientes por mes
    let aCliNMes: { [key: string]: string } = {}; //Agrupación por Clientes por mes, meses a utilizar

    let date_i:string|null, date_f:string|null
    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);

    let differentYear=false;
    if((date_i && date_f) && date_i.split('-')[0]!=date_f.split('-')[0]){
      differentYear=true;
    }

    this.billingDataList.forEach((item) => {
      const pkey = `${item.provider}`; //Crear una key de provider para agrupar
      const anioItem = differentYear?((new Date(item.created_at).getFullYear()).toString().slice(-2)):'';

      let pakey = `${item.carrier}`; //Crear una key de paquetería para agrupar
      const indexCarr = this.equivalentCarSerList.findIndex(r=> r.carrier_dict.toUpperCase() == item.carrier.toUpperCase() );
      if(indexCarr != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
        //sService=`${service}->${this.servicesModeList[indexCarr].service_mode}`;
        let car_name=`${this.equivalentCarSerList[indexCarr].carrier_cat}`;
        pakey = `${car_name}`;
      }
      if (!aPaq[pakey]) {
        aPaq[pakey] = 1;
      } else {
        aPaq[pakey]++;
      }

      
      //#region Comparativa Paquetería
      //-*** 
      //const mesV = new Date(item.created_at).getMonth() ; // Los meses son del 0 a 11
      const mesV = `${new Date(item.created_at).getMonth()}${' '+anioItem}` ; // Los meses son del 0 a 11, se concatena el año para no mezclar meses de diferentes años
      const mesVO = new Date(item.created_at).getMonth() ; // Los meses son del 0 a 11

      let pmkey = `${item.carrier}`; //Crear una key de paquetería para agrupar
      const indexCarSer = this.equivalentCarSerList.findIndex(r=> r.carrier_dict.toUpperCase().includes(item.carrier.toUpperCase()) || item.carrier.toUpperCase().includes(r.carrier_dict.toUpperCase()) );
      if(indexCarSer != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
        //sService=`${service}->${this.servicesModeList[indexCarSer].service_mode}`;
        let car_name=`${this.equivalentCarSerList[indexCarSer].carrier_cat}`;
        pmkey = `${car_name}`;
      }

      //Si el mes no existe, inicializarlo en 0
      if (!aPaqNMes[mesV]) {
        aPaqNMes[mesV] = `${MesNombre[mesVO]}${' '+anioItem}`;//MesNombre[mesV];
      }

      if (!aPaqMes[pmkey]) {
        aPaqMes[pmkey] = {};
      }
      
      if (!aPaqMes[pmkey][mesV]) {
        aPaqMes[pmkey][mesV] = 1;
      } else {
        aPaqMes[pmkey][mesV]++;
      }
      //-*** 
      //#endregion Comparativa Paquetería

      //#region Comparativa Cliente
      //-*** 
      //const mesV = new Date(item.created_at).getMonth() ; // Los meses son del 0 a 11
      let cmkey = (item.company_name=='WMS'? item.ext_client_name : item.company_name); //Crear una key con el método del envío para agrupar

      //Si el mes no existe, inicializarlo en 0
      if (!aCliNMes[mesV]) {
        aCliNMes[mesV] = `${MesNombre[mesVO]}${' '+anioItem}`;//MesNombre[mesV];
      }

      if (!aCliMes[cmkey]) {
        aCliMes[cmkey] = {};
      }
      
      if (!aCliMes[cmkey][mesV]) {
        aCliMes[cmkey][mesV] = 1;
      } else {
        aCliMes[cmkey][mesV]++;
      }
      //-*** 
      //#endregion Comparativa Cliente
      

    });


    //#region Clientes
    //-----*****
    this.chartComparativeCli.xaxis.categories = [];
    let apMon: { name:string, data: any[] }[] = [];
    for (const key in aCliMes) {
      if (Object.prototype.hasOwnProperty.call(aCliMes, key)) {
        const innerObj = aCliMes[key];
        let aData=[];
        for (let key in aCliNMes) {
          //console.log(aCliNMes[key] , key, innerObj);
          if(innerObj[key])
            aData.push(innerObj[key]);
          else
            aData.push(null);
        }
        apMon.push({
          name: `${key}`,
          data: aData,
        });
      }
    }
    
    let auxCatMes=[];
    for (let key in aCliNMes) {
      auxCatMes.push(aCliNMes[key]);
    }

    apMon.sort((a, b) => {
      const sumA = a.data.reduce((acc, curr) => acc + curr, 0);
      const sumB = b.data.reduce((acc, curr) => acc + curr, 0); 
    
      return sumB - sumA; // Ordenar de mayor a menor por la suma de data
    });

    apMon = apMon.slice(0, 10);
    
    this.chartComparativeCli.series = apMon;
    this.chartComparativeCli.xaxis={ ...this.chartComparativeCli.xaxis, categories: auxCatMes};
    this.chartComparativeCli.title={ ...this.chartComparativeCli.title, text: 'Cantidad'};

    //console.log('cliMEs',aCliMes, apMon, aCliNMes);
    //#endregion  Clientes
   

    //#region Paqueterías
    //-----*****
    this.chartComparativePaq.xaxis.categories = [];
    let apMonPaq: { name:string, data: any[] }[] = [];
    for (const key in aPaqMes) {
      if (Object.prototype.hasOwnProperty.call(aPaqMes, key)) {
        const innerObj = aPaqMes[key];
        let aData=[];
        for (let key in aPaqNMes) {
          //console.log(aPaqNMes[key] , key, innerObj);
          if(innerObj[key])
            aData.push(innerObj[key]);
          else
            aData.push(null);
        }
        apMonPaq.push({
          name: `${key}`,
          data: aData,
        });
      }
    }
    
    // let auxCatMes=[];
    // for (let key in aPaqNMes) {
    //   auxCatMes.push(aPaqNMes[key]);
    // }

    apMonPaq.sort((a, b) => {
      const sumA = a.data.reduce((acc, curr) => acc + curr, 0);
      const sumB = b.data.reduce((acc, curr) => acc + curr, 0); 
    
      return sumB - sumA; // Ordenar de mayor a menor por la suma de data
    });

    apMonPaq = apMonPaq.slice(0, 10);
    
    this.chartComparativePaq.series = apMonPaq;
    this.chartComparativePaq.xaxis={ ...this.chartComparativePaq.xaxis, categories: auxCatMes};
    this.chartComparativePaq.title={ ...this.chartComparativePaq.title, text: 'Cantidad'};

    //console.log('cliPaq',aPaqMes, apMonPaq, aPaqNMes);
    //console.log('JSONPAQ', JSON.stringify(this.chartComparativePaq));
    //#endregion  Paqueterías

  }
  

  getVolumenChart(){
    let aMon: { [key: string]: number } = {}; //Agrupación por proveedores
    let aVol: { [key: string]: number } = {}; //Agrupación por paqueterías

    let aProMes: { [key: string]: { [key: string]: number } } = {}; //Agrupación por proveedores por mes
    let aProNMes: { [key: string]: string } = {}; //Agrupación por proveedores por mes, meses a utilizar
    let aProMesMon: { [key: string]: { [key: string]: number } } = {}; //Agrupación por proveedores por mes
    let aProNMesMon: { [key: string]: string } = {}; //Agrupación por proveedores por mes, meses a utilizar

    let date_i:string|null, date_f:string|null
    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);

    let differentYear=false;
    if((date_i && date_f) && date_i.split('-')[0]!=date_f.split('-')[0]){
      differentYear=true;
    }


    this.billingDataList.forEach((item) => {
      const mes = new Date(item.created_at).getMonth() + 1; // Sumar 1 porque los meses son del 0 a 11
      const anioItem = differentYear?((new Date(item.created_at).getFullYear()).toString().slice(-2)):'';


      //Si el mes no existe, inicializarlo en 0
      if (!aMon[mes]) {
        aMon[mes] = 0;
      }
      //Sumar el total_price del mes correspondiente
      aMon[mes] += parseFloat(item.total_price);

      //Conteo para el volumen
      if (!aVol[mes]) {
        aVol[mes] = 1;
      } else {
        aVol[mes]++;
      }



      //#region Volumen Cantidad
      //-*** 
      //const mesV = new Date(item.created_at).getMonth() ; // Los meses son del 0 a 11
      const mesV = `${new Date(item.created_at).getMonth()}${' '+anioItem}` ; // Los meses son del 0 a 11, se concatena el año para no mezclar meses de diferentes años
      const mesVO = new Date(item.created_at).getMonth() ; // Los meses son del 0 a 11
      let pmkey = `${item.provider}`;

      //Si el mes no existe, inicializarlo en 0
      if (!aProNMes[mesV]) {
        aProNMes[mesV] = `${MesNombre[mesVO]}${' '+anioItem}`;
      }

      if (!aProMes[pmkey]) {
        aProMes[pmkey] = {};
      }
      
      if (!aProMes[pmkey][mesV]) {
        aProMes[pmkey][mesV] = 1;
      } else {
        aProMes[pmkey][mesV]++;
      }
      //-*** 
      //#endregion Volumen Cantidad

      //#region Volumen Monto
      //-*** 

      //Si el mes no existe, inicializarlo en 0
      if (!aProNMesMon[mesV]) {
        aProNMesMon[mesV] = `${MesNombre[mesVO]}${' '+anioItem}`;
      }

      if (!aProMesMon[pmkey]) {
        aProMesMon[pmkey] = {};
      }
      
      if (!aProMesMon[pmkey][mesV]) {
        aProMesMon[pmkey][mesV] = 0;
      }
      aProMesMon[pmkey][mesV]+= parseFloat(item.total_price);
      
      //-*** 
      //#endregion Volumen Monto
    });


    //#region Volumen Cantidad
    //-----*****
    this.chartComparativePro.xaxis.categories = [];
    let apMon: { name:string, data: any[] }[] = [];
    for (const key in aProMes) {
      if (Object.prototype.hasOwnProperty.call(aProMes, key)) {
        const innerObj = aProMes[key];
        let aData=[];
        for (let key in aProNMes) {
          //console.log(aProNMes[key] , key, innerObj);
          if(innerObj[key])
            aData.push(innerObj[key]);
          else
            aData.push(null);
        }
        apMon.push({
          name: `${key}`,
          data: aData,
        });
      }
    }
    
    let auxCatMes=[];
    for (let key in aProNMes) {
      auxCatMes.push(aProNMes[key]);
    }

    apMon.sort((a, b) => {
      const sumA = a.data.reduce((acc, curr) => acc + curr, 0);
      const sumB = b.data.reduce((acc, curr) => acc + curr, 0); 
    
      return sumB - sumA; // Ordenar de mayor a menor por la suma de data
      // const nameA = a.name.toLowerCase(); 
      // const nameB = b.name.toLowerCase();
      // if (nameA < nameB) {
      //   return -1;
      // }
      // if (nameA > nameB) {
      //   return 1;
      // }
      // return 0;
    });

    this.chartComparativePro.series = apMon;
    this.chartComparativePro.xaxis={ ...this.chartComparativePro.xaxis, categories: auxCatMes};
    this.chartComparativePro.title={ ...this.chartComparativePro.title, text: 'Guías compradas'};

    //console.log('proMEs',aProMes, apMon, aProNMes);
    //#endregion  Volumen Cantidad

    //#region Volumen Monto
    //-----*****
    this.chartComparativeProMon.xaxis.categories = [];
    let apMonMon: { name:string, data: any[] }[] = [];
    for (const key in aProMesMon) {
      if (Object.prototype.hasOwnProperty.call(aProMesMon, key)) {
        const innerObj = aProMesMon[key];
        let aData=[];
        for (let key in aProNMesMon) {
          //console.log(aProNMesMon[key] , key, innerObj);
          if(innerObj[key])
            aData.push(innerObj[key]);
          else
            aData.push(null);
        }
        apMonMon.push({
          name: `${key}`,
          data: aData,
        });
      }
    }
    
    // let auxCatMes=[];
    // for (let key in aProNMesMon) {
    //   auxCatMes.push(aProNMesMon[key]);
    // }

    apMonMon.sort((a, b) => {
      const sumA = a.data.reduce((acc, curr) => acc + curr, 0);
      const sumB = b.data.reduce((acc, curr) => acc + curr, 0); 
    
      return sumB - sumA; // Ordenar de mayor a menor por la suma de data
      // const nameA = a.name.toLowerCase(); 
      // const nameB = b.name.toLowerCase();
      // if (nameA < nameB) {
      //   return -1;
      // }
      // if (nameA > nameB) {
      //   return 1;
      // }
      // return 0;
    });

    this.chartComparativeProMon.series = apMonMon;
    this.chartComparativeProMon.xaxis={ ...this.chartComparativeProMon.xaxis, categories: auxCatMes};
    this.chartComparativeProMon.title={ ...this.chartComparativeProMon.title, text: '($) Monto'};

    //console.log('proMEs',aProMesMon, apMonMon, aProNMesMon);
    //#endregion  Volumen Monto
  }

  getValues(obj: any) {
     console.log(obj);
  }
  getTotalKGCli(obj: any) {
    let total=0;
    obj.map( (item: { total: number; }) =>{
      total+=item.total;
    });
    return total;
  }
  getTotalData(obj: any, sum: string) {
    let total=0;
    obj.map( (item: { total: number;  total_price: number; }) =>{
      switch (sum) {
        case 'total':
          total+=item.total;
          break;
        case 'total_price':
          total+=item.total_price;
          break;
      }
    });
    return total;
  }
  rowSelected(index: number, vKey: string, client:string) {
    // chartPieGoogleCliKgs
    // chartPieGoogleProKgs
    if(client=='client'){
      const foundKg = this.auxClientKgsData.find(x=> x.key == vKey);
      if(foundKg){
        this.rowSelectedClientKgs = index;
        this.chartPieGoogleCliKgs.dataTable= [ ['Cliente', 'KG'] ];
        foundKg.items.map( (item: { key: any; count: any; }, i: any) =>{
          this.chartPieGoogleCliKgs.dataTable.push([item.key,item.count]);
        });
        //foundKg.total;
        this.chartPieGoogleCliKgs.options={ ...this.chartPieGoogleCliKgs.options, title:`Mezcla de ${foundKg.key} Kg por cliente`};

        //Actualizar los gráficos
        let ccComponent = this.chartPieGoogleCliKgs.component;
        if(ccComponent)
          ccComponent.draw();
      }
    }else{
      const foundKg = this.auxProviderKgsData.find(x=> x.key == vKey);
      if(foundKg){
        this.rowSelectedProviderKgs = index;
        this.chartPieGoogleProKgs.dataTable= [ ['Proveedor', 'KG'] ];
        foundKg.items.map( (item: { key: any; count: any; }, i: any) =>{
          this.chartPieGoogleProKgs.dataTable.push([item.key,item.count]);
        });
        //foundKg.total;
        this.chartPieGoogleProKgs.options={ ...this.chartPieGoogleProKgs.options, title:`Mezcla de ${foundKg.key} Kg por proveedor`};

        //Actualizar los gráficos
        let ccComponent = this.chartPieGoogleProKgs.component;
        if(ccComponent)
          ccComponent.draw();
      }
    }
  }

  rowSelectedMetEnv(index: number, vKey: string, client:string) {
    // chartPieGoogleCliKgs
    // chartPieGoogleProKgs
    if(client=='client'){
      const foundMetEnv = this.auxMetEnvioCliData.find(x=> x.key == vKey);
      if(foundMetEnv){
        this.rowSelectedClientMetEnv = index;
        this.chartPieGoogleMetEnvioCli.dataTable= [ ['Cliente', 'monto', 'count'] ];
        foundMetEnv.items.map( (item: { key: any; count: any; total_price:any;}, i: any) =>{
          this.chartPieGoogleMetEnvioCli.dataTable.push([item.key, {'v':item.total_price, 'f': item.total_price.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) }, item.count]);
        });
        //foundMetEnv.total;
        this.chartPieGoogleMetEnvioCli.options={ ...this.chartPieGoogleMetEnvioCli.options, title:`Clientes por: ${foundMetEnv.key} `};

        //Actualizar los gráficos
        let ccComponent = this.chartPieGoogleMetEnvioCli.component;
        if(ccComponent)
          ccComponent.draw();
      }
    }else{
      const foundMetEnv = this.auxMetEnvioProData.find(x=> x.key == vKey);
      if(foundMetEnv){
        this.rowSelectedProviderMetEnv = index;
        this.chartPieGoogleMetEnvioPro.dataTable= [ ['Proveedor', 'monto', 'count'] ];
        foundMetEnv.items.map( (item: { key: any; count: any; total_price:any; }, i: any) =>{
          this.chartPieGoogleMetEnvioPro.dataTable.push([item.key, {'v':item.total_price, 'f': item.total_price.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) }, item.count]);
        });
        //foundMetEnv.total;
        this.chartPieGoogleMetEnvioPro.options={ ...this.chartPieGoogleMetEnvioPro.options, title:`Proveedores por: ${foundMetEnv.key} `};

        //Actualizar los gráficos
        let ccComponent = this.chartPieGoogleMetEnvioPro.component;
        if(ccComponent)
          ccComponent.draw();
      }
    }
  }

  
  
 }