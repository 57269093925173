import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FavShipModel, packagesModel } from 'src/app/models/shipment.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import { ShipmentsLabelActionsComponent } from '../shipments-label-actions/shipments-label-actions.component';
import { EcommerceSetDimensionsComponent } from '../ecommerce-set-dimensions/ecommerce-set-dimensions.component';

@Component({
  selector: 'app-ecommerce-shipment-checkout',
  templateUrl: './ecommerce-shipment-checkout.component.html',
  styleUrls: ['./ecommerce-shipment-checkout.component.scss']
})
export class EcommerceShipmentCheckoutComponent implements OnInit {

  userObj!: UserModel;
  info!:any;
  base64!:string;
  base64Bol!:string;
  labelName!:string;
  packages!:[packagesModel];
  clicked = false;
  alias!: string;
  globalObject: any = {
    origin: '',
    packages: [],
    destination: '',
    selected_rate: ''
  };

  get favData():any { return this.userS.sharedData; } set favData(value: any) { this.userS.sharedData = value; }
  
  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
    private userS: UsersService,
    private routes: Router,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<EcommerceShipmentCheckoutComponent>,

  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.info = this.data;
    this.alias =  this.data.fav && this.data!.fav!.alias ? this.data.fav.alias : "";
    this.packages = this.data.data.packages;
    this.globalObject.origin = this.data.data.from_address;
    this.globalObject.packages = this.data.data.packages;
    this.globalObject.destination = this.data.data.to_address;
    this.globalObject.selected_rate = this.data.data.selected_rate;
  }

  goToFavorites(){
    this.routes.navigateByUrl("/admin/myfavorites");
  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }

  sendToForm(obj: FavShipModel) {
    this.favData = obj;
    this.favData.isEcommerce = true;
    // this.favData.rate_selected = obj.selected_rate;
    this.goToShipmentAdd();
  }

  test() {
    console.log(this.globalObject)
  }

  goToShipmentAdd() {
    this.routes.navigateByUrl('/admin/shipments/add');
  }

  getLabel() {
    Swal.fire({
      icon:  'info',
      title: 'Realizando pedido...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });   
    this.userS.getLabel(this.globalObject).subscribe(
      ( resp: any ) => {
        if ( resp.status.code == "success" ) {
            Swal.fire({
              icon:  'success',
              title: 'Gracias por su compra',
              html:  '¡Tu compra ha sido realizada con exito!',     
              allowEscapeKey:    false,
              allowOutsideClick: false,
              showConfirmButton: false,           
              timer: 1500,
              timerProgressBar: true,
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                this.base64 = resp.data.labels[0].payload;
                if(resp.data.documents)
                  this.base64Bol = resp.data.documents[0].payload;
                let labelName = 'Guia'+'_'+ new Date().toLocaleString().slice(0,10).split('/').join('_').split(',').join('').split(' ').join('');
                this.labelName = labelName;
                this.clicked = true;
                this.openDialogLabelActions();
              }
            });
          }
        },
      ( err:any ) => {
        if ( err.error!.status!.message.message == " Créditos insuficientes" || err.error!.status!.code == " Not enough credits ") {
          Swal.fire({
            icon:  'warning',
            title: 'Créditos insuficientes',
            text:  'No cuentas con los créditos necesarios para realizar esta compra.'
          });
        } else if ( err.error!.status!.message.message.includes("Error getting cache")) {
          Swal.fire({
            icon:  'warning',
            title: 'Buscar paqueterías',
            text:  'Por favor vuelve a realizar la búsqueda de paqueterías.'
          });
        }
        else {
          console.log( err );
          let msgError='Ocurrió un error al realizar la compra, por favor vuelva a intentarlo.';
          if(err.error!.status!.message!.custom)
            msgError=err.error!.status!.message!.custom.message
          Swal.fire({
            icon:  'error',
            title: 'Error en la compra',
            text:  msgError
          });
        }
      }
    );
  }

  openDialogLabelActions(){
    const dialogRef = this.matDialog.open( ShipmentsLabelActionsComponent, {
      width:'30rem',
      data: {
        data :{
         labelName : this.labelName,
         base64 :  this.base64,
         base64Bol :  this.base64Bol
        }
      },
      panelClass: 'dialogs-main',
    });
    dialogRef.afterClosed().subscribe( () => {
      this.dialogRef.close();
      this.routes.navigate(['/admin/shipments']);
    });
  }

  openDialogSetDimensions(){
    this.clicked = true;
    const dialogRef = this.matDialog.open( EcommerceSetDimensionsComponent, {
      panelClass: 'dialogs-sm',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.globalObject.packages[0].length = result.length;    
        this.globalObject.packages[0].width = result.width;    
        this.globalObject.packages[0].height = result.height; 
      }
    });
  }

}
