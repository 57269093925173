import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as printJS from 'print-js';
import Swal from 'sweetalert2';

import { BulkUploadAddComponent } from '../shared/dialogs/bulk-upload-add/bulk-upload-add.component';
import { environment } from 'src/environments/environment';
import { FavAddComponent } from '../shared/dialogs/fav-add/fav-add.component';
import { FavShipModel, ShipmentSaveModel } from 'src/app/models/shipment.model';
import { FavShowComponent } from '../shared/dialogs/fav-show/fav-show.component';
import { PickupsService } from 'src/app/services/pickups.service';
import { ShipmentsHelpComponent } from '../shared/dialogs/shipments-help/shipments-help.component';
import { ShipmentsPrintComponent } from '../shared/dialogs/shipments-print/shipments-print.component';
import { ShipmentsShowComponent } from '../shared/dialogs/shipments-show/shipments-show.component';
import { UsersService } from 'src/app/services/users.service';
import { ViewPickupEvidenceComponent } from '../shared/dialogs/view-pickup-evidence/view-pickup-evidence.component';


@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})
export class ShipmentsComponent implements OnInit {
  isClientUsr: boolean;
  isOperationsUsr: boolean;
  
  // shipmentList = new Array<ShipmentModel>();
  shipmentList =  new Array<ShipmentSaveModel>();
  isLoading = true;
  base64!:string;

  displayedColumns: string[] = ['tracking_number', 'carrier', 'destination', 'shipment', 'order_price', 'user_name', 'status','created_at','actions'];
  fsDisplayedColumns: string[] = ['alias', 'carrier', 'origin', 'destination', 'shipment',  'actions'];//['carrier','receiver', 'destination', 'sender','origin', 'shipment','actions'];
  dataSource!: MatTableDataSource<ShipmentSaveModel>;
  fsDataSource!: MatTableDataSource<FavShipModel>;
  estatusMapping:any = {
    'assigned': { label:'Recolectado', color: 'badge-orange', icon: 'forklift' },
    'order_created': {label:'Orden recibida', color: 'badge-purple', icon: 'assignment'},
    'order_completed': {label: 'Orden surtida', color: 'badge-dark', icon: 'assignment_turned_in'},
    'started': {label:'¡En ruta!', color: 'badge-warning', icon: 'local_shipping'},
    'successful': {label: 'Entregado', color: 'badge-success', icon: 'done_all'},
    'error': {label:'Error', color: 'badge-warning', icon: 'warning' },
    'pending': {label:'Pendiente', color: 'badge-warning', icon: 'warning' },
    'undelivered': {label:'No entregado', color: 'badge-danger', icon: 'warning' }
  };

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  get favData():any { return this.userS.sharedData; } set favData(value: any) { this.userS.sharedData = value; }
  get bulkUploadData():any { return this.userS.sharedBulkUpload; } set bulkUploadData(value: any) { this.userS.sharedBulkUpload = value; }
  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
    private pickupS: PickupsService
  ) { 
    this.isClientUsr = (this.userS.getUser().id_rol == environment.role_client_id);
    this.isOperationsUsr = (this.userS.getUser().id_rol == environment.role_operations_id);
  }

  ngOnInit(): void {
    this.getShipmentList();
    this.userS.sendCreditEvent();
  }

  getShipmentList() {
    this.userS.getShipmentList().subscribe(
      (result : any) => {
        this.shipmentList = result;
        this.shipmentList = this.shipmentList.map(shipment => {
          return {
            ...shipment,
            to_address: shipment.to_address + ', ' + shipment.dest_country
          };
        });
        for ( let i = 0; i < this.shipmentList.length; i++ ) {
          this.shipmentList[i].packages = JSON.parse(this.shipmentList[i].packages);
        }
        this.dataSource = new MatTableDataSource(this.shipmentList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  openDialogSaveFav(id:number) {
    
    const dialogRef = this.matDialog.open( FavAddComponent, {
        height: 'auto',
        maxHeight:'45rem',
        width:'80rem',
        data: { 
          shipmentIdSelected: id
        },
        panelClass: 'custom-dialog-container-scroll',
      });

      dialogRef.afterClosed().subscribe(result => {
        this.goToShipmentFav();
      });
  }

  cleanGoToShipmentAdd() {
    this.favData = null;
    this.goToShipmentAdd();
  }

  openBulkUpload() {
    const dialogRef = this.matDialog.open( BulkUploadAddComponent, {
      data: { 
        //shipmentIdSelected: id
      },
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        //console.log(resp);
        idBU = resp.id
      }
      if(idBU>0)
        this.goToBulkUploads(idBU);
    });
  }

  addFavtoService(obj: FavShipModel) {
    this.favData = obj;
    this.goToShipmentAdd();
  }

  showFav(obj: FavShipModel) {
    this.matDialog.open( FavShowComponent, {
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      data: obj,
      panelClass: 'custom-dialog-container-scroll',
    });
  }


  removeFavAddress(obj: FavShipModel) {
      Swal.fire({
        title: '¿Estás seguro que deseas eliminar el registro?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'info',
            title: 'Eliminando...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading(null);
            }
          });
          this.userS.deleteFavAddress(obj.id).subscribe(
            (result:any) => {
              if (result.message == "Success")                
              Swal.fire({
                icon: 'success',
                title: 'Eliminado',
                text: 'Favorito eliminado de manera correcta!'
              }).then(() => {
                this.goToShipmentFav();
              });
            },
            (err:any) => {
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
              });
            }
          );
        }
      }); 
  }

  goToShipmentAdd() {
    this.router.navigateByUrl('/admin/shipments/add');
  }

  goToShipmentFav() {
    this.router.navigateByUrl('/admin/myfavorites');
  }

  goToBulkUploads(id:number) {
    this.bulkUploadData = {"id":id};    
    this.router.navigateByUrl('/admin/bulk-upload');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  trackLabel(id:number) {
    const dialogRef = this.matDialog.open( ShipmentsShowComponent, {
      height: '40rem',
      width:'50rem',
      data: { 
      },
      panelClass: 'custom-dialog-container'
    });
  }

  viewLabel(id:number, isBol:boolean = false) {
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.findLabel(id).subscribe(
      ( resp: any ) => {
        if ( resp.data.status.code == "success" ) {
          const dialogRef = this.matDialog.open( ShipmentsPrintComponent, {
            height: '40rem',
            width:'80rem',
            data: { 
              data: isBol?resp.data.data.commercial_invoice : resp.data.data.payload
            },
            // panelClass: 'dialogs-main'
            panelClass: 'custom-dialog-container'
          });
          Swal.close();
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error',
          text:  'Ocurrió un error al descargar la guía, intenta de nuevo mas tarde.'
        });
      }
    );
  }

  
  requestPickup(shipment: any): void {
    shipment.from_address = JSON.parse(shipment.from_address); // Format address

    // Check if street and number are mixed:
    const match = shipment.from_address.street.match(/(.+)\b(\d+(?:\w*))(.+)*$/i);

    if (match) { // If they are, reformat
      shipment.from_address.street = match[1].trim();
      shipment.from_address.number = match[2].trim();
    }

    // Format data for sending
    const data = {
      carrier: shipment.carrier,
      city: shipment.from_address.city.toLowerCase().replace(/(?:^|\s)\w/g, (match: string) => match.toUpperCase()),
      company: shipment.from_address.company,
      createdAt: shipment.created_at,
      destination: shipment.to_address,
      email: shipment.from_address.email,
      id: shipment.id,
      name: shipment.from_address.name,
      neighborhood: shipment.from_address.suburb.toLowerCase().replace(/(?:^|\s)\w/g, (match: string) => match.toUpperCase()),
      number: shipment.from_address.number,
      package: {
        height: shipment.packages[0].height,
        length: shipment.packages[0].length,
        width: shipment.packages[0].width
      },
      phone: shipment.from_address.phone,
      postCode: shipment.from_address.postalCode,
      reference: shipment.from_address.reference,
      rfc: '',
      shipmentID: (shipment.order_id) ? shipment.order_id : null,
      state: shipment.from_address.state.toLowerCase().replace(/(?:^|\s)\w/g, (match: string) => match.toUpperCase()),
      street: shipment.from_address.street.toLowerCase().replace(/(?:^|\s)\w/g, (match: string) => match.toUpperCase()),
      trackingNumber: shipment.tracking_number,
      weight: shipment.packages[0].weight
    }

    this.pickupS.sendShipment(data);
    this.router.navigate(['admin/pickups/form']); 
  }

  validForPickup(carrier: string): boolean {
    const validCarriers = ['est', 'paq', 'pqe'];
    return !validCarriers.includes(carrier.substring(0, 3).toLowerCase()) || this.isClientUsr || this.isOperationsUsr;
  }
  
  locallyDelivered(carrier: string, status: string | null): boolean {
    return (carrier !== 'buho' || status !== 'successful');
  }

  showPickupEvidence(id: string) {
    console.log(id);
    this.matDialog.open(ViewPickupEvidenceComponent, {
      data: { id: id },
      panelClass: 'dialogs-main'
    });
  }


  printLabel(id:number, isBol:boolean = false) {
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.findLabel(id).subscribe(
      ( resp: any ) => {
        if ( resp.data.status.code == "success" ) {
          printJS({ printable: isBol? resp.data.data.commercial_invoice : resp.data.data.payload, type: "pdf", base64: true })
          Swal.close();
        }
      },
      ( err:any ) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error',
          text:  'Ocurrió un error al descargar la guía, intenta de nuevo mas tarde.'
        });
      }
    );
  }

  goToTracking(tracking_number: string) {
    window.open("https://tracking.buhologistics.com/?id="+tracking_number, "_blank");
  }

  helpShipment(obj:any) {
    const dialogRef = this.matDialog.open( ShipmentsHelpComponent, {
      data: {
        shipmentSel: obj
      },
      panelClass: 'dialogs-main'
    });
  }

  favCarrierName(obj:any) {
      if(obj!=null){
        return obj.carrier;
      }else{
        return '';
      }

  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }
}
