import { Component, OnInit } from '@angular/core';
import { UserLoginModel } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: UserLoginModel = new UserLoginModel();
  hide = true;
  rememberme = false;
  otp: string = '';
  isLogin: boolean = true;
  isVerificationMode: boolean = false;
  chooseVerificationMethod: boolean = false;
  verificationMethodSelected: string = '';
  timeRemaining: number = 60;
  timer: any;
  
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.auth.isAuthenticated())
      this.router.navigateByUrl('/admin/dashboard');

    if (localStorage.getItem('email')) {
      this.user.email = localStorage.getItem('email')!;
      this.rememberme = true;
    }
  }
  
  login(form: NgForm) {
    if (form.invalid) { return; }

    this.user.email = this.user.email.trim();

    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });

    this.auth.login(this.user).subscribe(resp => {
      Swal.close();
      if (this.rememberme) {
        localStorage.setItem('email', this.user.email);
      }
      if (resp.tkn) {
        this.auth.handleSuccessfulLogin();
      }
      if (resp.mfa?.sms === 1) {
        this.isLogin = false;
        this.chooseVerificationMethod = true;
      } else {
        this.isLogin = false;
        this.isVerificationMode = true;
        this.startTimer();
      }
    }, (err) => {
      console.log(err.error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al autenticar',
        text: 'Usuario o contraseña no válido'
      });
    });
  }

  handleSubmitCode(verificacionMethod: string) {
    this.verificationMethodSelected = verificacionMethod;
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });

    this.auth.SendCode(verificacionMethod, this.user).subscribe(resp => {
      Swal.close();
      this.isLogin = false;
      this.chooseVerificationMethod = false;
      this.isVerificationMode = true;
      this.startTimer();
    }, (err) => {
      console.log(err.error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al autenticar',
        text: 'Código de verificación incorrecto'
      });
    });
  }

  verifyOtp() {
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });

    this.auth.verifyOtp(this.otp, this.user).subscribe(resp => {
      Swal.close();
      this.auth.handleSuccessfulLogin();
    }, (err) => {
      console.log(err.error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al autenticar',
        text: 'Código de verificación incorrecto'
      });
    });
  }

  resendOtp() {
    this.auth.SendCode((this.verificationMethodSelected || 'email'), this.user).subscribe(resp => {
      this.timeRemaining = 60;
      this.startTimer();
    }, (err) => {
      console.log(err.error.message);
    });
  }

  openDialog() {
    Swal.fire({
      icon: 'warning',
      title: '¿Olvidó su contraseña?',
      text: 'Para recuperar su contraseña contacte a su agente de Búho o envíe un mensaje solicitando el restablecimiento de su contraseña al correo electrónico "hola@buhologistics.com"',
      heightAuto: false
    });
  }

  startTimer() {
    this.timer = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  formatTime(seconds: number): any {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds: string = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
