import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PickupsService } from 'src/app/services/pickups.service';
import { WarehousePickupReceipt } from 'src/app/models/shipment.model';

@Component({
  selector: 'app-view-pickup-evidence',
  templateUrl: './view-pickup-evidence.component.html',
  styleUrls: ['./view-pickup-evidence.component.scss']
})
export class ViewPickupEvidenceComponent implements OnInit {
  loading: boolean = true;
  receipt: WarehousePickupReceipt | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private pickups: PickupsService) { }

  ngOnInit(): void {
    this.setReceipt();
  }

  /* async Promise<void> setCheckout(0):
     Registers pickup to the DB. */
  async setReceipt(): Promise<void> {
    this.receipt = await this.pickups.getWarehousePickupReceipt(this.data.id) 
    this.loading = false;
  }
}
