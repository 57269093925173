import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { CompanyModel } from 'src/app/models/company.model';
import { StpOrderDetailComponent } from '../shared/dialogs/stp-order-detail/stp-order-detail.component';
import { StpOrderModel } from 'src/app/models/Stp.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-stp-orders',
  templateUrl: './stp-orders.component.html',
  styleUrls: ['./stp-orders.component.scss']
})
export class StpOrdersComponent implements OnInit {
  //groupCurrencyBillingData : any ;
  ordersDataList =  new Array<StpOrderModel>();
  okCurrency: any;
  isLoading = true;
  base64!:string;
  allTrue: boolean = false;
  //servicesModeList =  new Array<ServicesModeModel>();

  //#region Filtros
  dateNow : Date = new Date();
  dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
    
  newDateTodayI : Date | null = null;//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDay());
  minDateI : Date =  new Date(2022, 0, 1);
  maxDateI : Date =  new Date();
  dateIDis=true;
  newDateTodayF : Date | null = null//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
  minDateF : Date =  new Date(2022, 0, 1);
  maxDateF : Date =  new Date();
  dateFDis=true;

  showFilter=false;
  selFechas = new FormControl('');  
  selCompanies = new FormControl('');  
  selCompaniesList:Array<CompanyModel>=[];
  //selCurrency = new FormControl('');  
  //selCurrencyList:Array<CurrencyModel>=[];
  selUsers = new FormControl('');  
  selUsersList:Array<UserModel>=[];

  preLoadHidden = true;
  
  //#endregion

  
  //displayedColumns: string[] = ['tracking_number', 'carrier', 'shipment_id', 'order_number', 'company_name', 'user_name', 'shipment', 'created_at', 'currency', 'service_price', 'total_price'];
  columnDefinitions = [
    { def:'created_at', label: 'Creado', hide: false},
    { def:'id', label: 'ID', hide: true},
    { def:'record_id', label: 'IDEF', hide: true},
    { def:'transaction_date', label: 'Fecha operación', hide: false},
    { def:'tracking_key', label: 'Clave rastreo', hide: false},
    { def:'originator_institution', label: 'Clave rastreo devolución', hide: true},
    { def:'beneficiary_institution', label: 'Institución beneficiaria', hide: true},
    { def:'amount', label: 'Monto', hide: false},
    { def:'originator_name', label: 'Nombre ordenante', hide: false},
    { def:'type_originator_account', label: 'Tipo cuenta ordenante', hide: true},
    { def:'payer_account', label: 'Cuenta ordenante', hide: false},
    { def:'rfc_curp_originator', label: 'RFC CURP ordenante', hide: true},
    { def:'beneficiary_name', label: 'Nombre beneficiario', hide: true},
    { def:'type_beneficiary_account', label: 'Tipo cuenta beneficiario', hide: true},
    { def:'beneficiary_account', label: 'Cuenta beneficiario', hide: false},
    { def:'beneficiary_name2', label: 'Nombre beneficiario 2', hide: true},
    { def:'type_beneficiary_account2', label: 'Tipo cuenta beneficiario 2', hide: true},
    { def:'beneficiary_account2', label: 'Cuenta beneficiario 2', hide: true},
    { def:'rfc_curp_beneficiary', label: 'RFC CURP beneficiario', hide: true},
    { def:'concept_payment', label: 'Concepto pago', hide: false},
    { def:'reference_number', label: 'Referencia numérica', hide: false},
    { def:'company', label: 'Empresa', hide: true},
    { def:'payment_type', label: 'Tipo pago', hide: true},
    { def:'ts_settlement', label: 'tsLiquidación', hide: true},
    { def:'folio_codi', label: 'Folio Codi', hide: true},
    { def:'added_credit', label: 'Crédito agregado', hide: true},
    { def:'status', label: 'Estado', hide: true},
    { def:'conciliation', label: 'Conciliación', hide: true},
    { def:'conciliation_date', label: 'Fecha conciliación', hide: true},
    { def:'only_conciliation', label: 'Solo conciliación', hide: true},
    { def:'id_stp_clabe_company', label: 'ID STP compañía', hide: true},
    { def:'name_company', label: 'Compañía', hide: false},
    { def:'url_cep', label: 'Url CEP', hide: true},
    { def:'clabe', label: 'CLABE', hide: true},
    { def:'clabe_active', label: 'CLABE activa', hide: true},
    { def:'id_company', label: 'ID compañía', hide: true},
    { def:'actions', label: 'Acciones', hide: false},
  ]

  showOptions = false;

  
  getDisplayedColumns():string[] {
    return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
  }

  getDisplayedLabel(def:string):string {
    let cLabel=this.columnDefinitions.find(item=> item.def==def);
    return cLabel?.label||''
  }
  
  dataSource!: MatTableDataSource<StpOrderModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.showFilter=true;
    this.selFechas.setValue('2');
    //this.selCurrency.setValue('0');
    this.onchangeFechas(this.selFechas);
    //No cargar el listado de manera inicial
    //this.getBillingDataList(); 
    this.isLoading = false; //porque no se carga al principio los datos
    //this.getServicesMode();

    //this.getCompaniesList();
    //this.getCurrencyList();
    //this.getUsersList();
    //this.newDateToday.setDate(this.newDateToday.getDate() + 1);
  }

  getDataList(date_i : string|null = null, date_f : string|null = null, id_company : number|null = null) {
    this.userS.getStpListOrders(date_i, date_f, id_company).subscribe(
      (result : any) => {

        //this.groupCurrency(false, result);
        this.ordersDataList = result;
        
        // for ( let i = 0; i < this.ordersDataList.length; i++ ) {
        //   this.ordersDataList[i].package_dimensions = JSON.parse(this.ordersDataList[i].package_dimensions);
        // }
        this.dataSource = new MatTableDataSource(this.ordersDataList);        
        this.dataSource.paginator = this.paginator;
        //this.dataSource.paginator.pageSize=this.billingDataList.length;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.preLoadHidden = false;

        console.log(this.dataSource);
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }


  showSumFooter() {
    let vShow = false;
    if(this.okCurrency!=undefined){ 
      if(this.okCurrency.length==1){
        vShow = true;
      }else{
        //if(this.selCurrency.value == "0"){ //si son todas
        //  vShow = false;
        // }else{ //Tiene selecciona 1 tipo de moneda
           vShow = true
        // }
      }
    }else{
      vShow = false;
    }
    return vShow;
  }

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  searchFilter() {
    this.isLoading = true;
    let date_i:string|null, date_f:string|null, users:string|null, companies:string|null;
    let arrCom:Array<number>=this.selCompanies.value;
    let arrUse:Array<number>=this.selUsers.value;
    let currency:string;

    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);
    companies=arrCom.length>0?arrCom.join(','):null;
    users=arrUse.length>0?arrUse.join(','):null;
    //currency=this.selCurrency.value;
    console.log(companies, users);
    this.getDataList(date_i, date_f);
  }


  getCompaniesList() {
    this.userS.getCompanies().subscribe(
      (result : any) => {
        this.selCompaniesList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  
  getUsersList() {
    this.userS.getUserListMin().subscribe(
      (result : any) => {
        this.selUsersList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  searchFilterClean() {
    this.selCompanies.setValue([]);
    this.selUsers.setValue([]);
  }


  onchangedateI(e: Date): void {
    console.log('Inicio', e, 'Fin', this.newDateTodayF);
    if(this.newDateTodayF!=null && this.newDateTodayF!.getTime()<e.getTime()){
        this.newDateTodayF=null;
    }
  }
  onchangedateF(e: Date): void {
    console.log('Fin', e, 'Inicio', this.newDateTodayI);
    if(this.newDateTodayI!=null && this.newDateTodayI!.getTime()>e.getTime()){
      this.newDateTodayI=null;
    }
  }

  onchangeFechas(change:any) {
    //console.log(change);
    switch (change.value) {
      case '1'://Hoy
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '2'://Este mes
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1);
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '3'://Elegir fechas
        this.dateIDis=false;
        this.dateFDis=false;
        break;
      default:
        break;
    }
  }


  getPageSizeOptions(): number[]{
    if(this.ordersDataList.length>10)
    return [10,50,100,this.ordersDataList.length];
    else
    return [10,50,100];
  }
  
  getTotalService() {
    return (this.ordersDataList.map(t => t.amount).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
  }

  getTotalPrice() {
    return (this.ordersDataList.map(t => t.amount).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
  }

  toggleAll(event: MatCheckboxChange): void {
    const defaultcols = [
      'Acciones', 'Clave rastreo', 'Compañía', 'Concepto pago', 'Creado', 'Cuenta beneficiario',
      'Cuenta ordenante', 'Fecha operación', 'Monto', 'Nombre ordenante', 'Referencia numérica'
    ];
    
    this.columnDefinitions.forEach((x: any) => {
      x.hide = !(event.checked || defaultcols.includes(x.label));
    });

    this.allTrue = event.checked;
  }
  
  toggleColumn(columnName: string, event: MatCheckboxChange) {
    let toggleStatusCheck = true;
    
    let columnHS = this.columnDefinitions.find( item=> item.def==columnName);
    if (columnHS) columnHS.hide = !event.checked;

    this.columnDefinitions.forEach((x: any) => {
      if (x.hide === true) toggleStatusCheck = false;
    });

    this.allTrue = toggleStatusCheck;
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  getAddress(data:string) {
    try {
      const address = JSON.parse(data);
      return `${address.name}, ${address.street}, ${address.number}, ${address.suburb}, ${address.postalCode}, ${address.city}`;
    } catch (error) {
      return ''; 
    }
  }


  setNameXls() {
    let cdA=this.columnDefinitions.find( item=> item.def=='actions');
    if(cdA){
      cdA.hide=true;
    }
    let monthShort= `${this.dateNow.getMonth()+1}`; //this.dateNow.toLocaleString('default', { month: 'short' });
    let year=`${this.dateNow.getFullYear()}`;
    let sCli='';//this.selClient.value == "0"?'':' '+this.selClient.value;
    let sCur='';//this.selCurrency.value == "0"?'':' '+this.selCurrency.value;
    
    let res=`Reporte STP Transacciones ${year}-${monthShort}${sCli}${sCur}`;

    setTimeout(() => {
      let cdA=this.columnDefinitions.find( item=> item.def=='actions');
      if(cdA){
        cdA.hide=false;
      }
    }, 1000);

    return res;
  }

  statusName(status:string){
    let sStatus="";
    if(status){
      switch (status.toUpperCase()) {
        case 'D':
          sStatus='Devuelta';
          break;
        case 'LQ':
          sStatus='Liquidada';
          break;
        case 'CCE':
          sStatus='Confirmación enviada';
          break;
        case 'CXO':
          sStatus='Por enviar confirmación';
          break;
        case 'CCO':
          sStatus='Confirmada';
          break;
        case 'CCR':
          sStatus='Confirmación rechazada';
          break;
        default:
          break;
      } 
    }
    return sStatus;
  }
  
  onRowDoubleClick(event: any) {
    const rowData = event;
    //console.log('Datos del row seleccionado:', rowData);
    this.openDialogEdit(rowData);
  }

  openDialogEdit(row: any) {
    this.matDialog.open(StpOrderDetailComponent, {
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      data: {
        detail: row
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.searchFilter();
          //this.getData(this.data.id);
      }
    );
  }
}