import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UsersEditPasswordComponent } from '../users-edit-password/users-edit-password.component';
import { CompanyModel } from 'src/app/models/company.model';


@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {

  rolesList!: {
    id: number,
    rol: string
  }[];

  tipoCom!: {
    id: string,
    nombre: string
  }[];
  tipoComSel: string = "";

  formUser!: FormGroup;
  id_user!: number;
  isUserLogged!: boolean;
  permissionList : any = [];
  selected : any = [];
  providersList: any = [];
  carriersList: any = [];
  carriersListFiltered: any = [];
  carriersListTemp: any = [];
  companiesList! : Array<CompanyModel>;
  companyEmpty : CompanyModel = new CompanyModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<UsersEditComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.rolesList = environment.roles;
    this.tipoCom = [{"id": "porc", "nombre": "Porcentaje"},{"id": "cant", "nombre": "Cantidad"}];
    this.id_user = this.data.userSel.id;
    this.isUserLogged = this.id_user == this.userS.getUser().id;
    this.createFormUser();
    this.setUserForm();
  }

  createFormUser() {
    this.formUser = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/)]],
      id_rol: ['', [Validators.required]],
      carriers: ['', []],
      providers: ['', []],
      tipo_comision:['',[]],
      cant_comision:['',[]],
      id_company:[''],
      phone:[0],
      email2fa:[0],
      sms2fa:[0]
    });
  }

  // setBuhoConfig(id:number){
  //   this.getCompaniesList(environment.role_buho_id != id);
  // }

  // getCompaniesList(isClient : boolean) {
  //   this.companyEmpty.name = "Sin compañia";
  //   this.companiesList = [this.companyEmpty];
  //   if(isClient) {
  //     this.userS.getCompanies().subscribe(
  //       (result:any) => {
  //         if(result.length > 0) {
  //           this.companiesList = this.companiesList.concat(result);
  //         }
  //       },
  //       (err:any) => {
  //         console.log(err);
  //       }
  //     );
  //   }
  // }

  getCompaniesList() {
    this.companyEmpty.name = "Sin compañia";
    this.companiesList = [this.companyEmpty];
    this.userS.getCompanies().subscribe(
      (result: any) => {
        if (result.length > 0) {
          this.companiesList = this.companiesList.concat(result);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  unselect(obj : any): void {
    obj = undefined;
 }

  getConfigList() {
    this.userS.getConfigList().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.providersList = result.data.providers;
          this.carriersList = result.data.carriers;

          const prov = this.formUser.value.providers.map((p:any)=> p.id)

          this.carriersListFiltered = [];
          if(this.formUser.value.providers.length > 0){
            this.carriersListTemp = this.carriersList.filter((c:any) => prov.includes(c.id_provider)).map((c:any)=> ({ id : c.id , carrier: c.carrier}));
            this.carriersListTemp.forEach( (element:any) => {
              if(!this.carriersListFiltered.find((e:any)=>  e.carrier == element.carrier )){
                this.carriersListFiltered.push(element);
              }
            });
          }
          
        }
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getUserConfigList() {
    this.userS.getUserConfigList(this.id_user).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.formUser.controls["carriers"].setValue(result.data.carriers.map((elem: { id: any, active:any ; }) => (elem.active ? elem.id : undefined)));
          // this.formUser.controls["carriers"].setValue(result.data.carriers.map((elem: { id: any }) => ( elem.id)));
          this.formUser.controls["providers"].setValue(result.data.providers.map((elem: { id: any, active:any ; }) => (elem.active ? elem.id : undefined)));
          // this.formUser.controls["providers"].setValue(result.data.providers.map((elem: { id: any }) => (elem.id)));
        }
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getCarriers() {
    this.carriersListFiltered = [];
    if(this.formUser.value.providers.length > 0){
      this.carriersListTemp = this.carriersList.filter((c:any) => this.formUser.value.providers.includes(c.id_provider)).map((c:any)=> ({ id : c.id , carrier: c.carrier}));
      this.carriersListTemp.forEach( (element:any) => {
        if(!this.carriersListFiltered.find((e:any)=>  e.carrier == element.carrier )){
          this.carriersListFiltered.push(element);
        }
      });
    }
  }

  getPermissions(permissions : any){
    if(this.formUser.value.id_rol > 1) {
      this.userS.getPermissionList(this.formUser.value.id_rol).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.permissionList = result.data;
            this.selected = permissions.map((elem: { id: any; }) => elem.id);
          }
        },
        (err:any) => {
          console.log(err);
        }
      )
    }
    else this.permissionList = [];
  }

  setTipoCom() {
    this.tipoComSel = this.formUser.value.tipo_comision;
  }

  setUserForm() {
    const { active, email, id, role, rol_name, ...userFormInfo } = this.data.userSel;
    this.getConfigList();
    this.getUserConfigList();
    this.formUser.setValue(userFormInfo);
    // this.setBuhoConfig(userFormInfo.id_rol);
    this.getCompaniesList();
    this.tipoComSel = userFormInfo.tipo_comision;
  }

  ruSure() {
    if(this.formUser.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas editar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.formUser.value.comision = {tipo: this.formUser.value.tipo_comision, cantidad: this.formUser.value.cant_comision};
    const company = this.formUser.value.id_company;
    if( !this.formUser.get('email2fa')?.value ){ this.formUser.get('sms2fa')?.setValue(0) };
    this.formUser.value.id_company = company ? company : 0;
    this.userS.editUser(this.formUser.value, this.id_user).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Usuario guardado de manera correcta!'
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  openDialogPassword() {
    this.matDialog.open(UsersEditPasswordComponent, {
      data: { 
        id_user: this.id_user 
      },
      panelClass: 'dialogs-sm',
    });
  }

}
