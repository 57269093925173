<div class="modal-header">
    <h1 class="modal-title">Catálogo de costos</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h4>Agrega el Excel que contiene la información para hacer la comparativa. </h4>

    <form autocomplete="off" [formGroup]="formAdd" (ngSubmit)="ruSure()" class="mt-3" id="formAdd">
        <div class="row mt-2">
            <div class="row">
                <!-- <mat-label>Carga archivo de importación</mat-label> -->
                <div class="col-sm-8 ">
                    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload formControlName="file">
                    <div class="file-upload">                        
                        <button type="button" mat-flat-button color="primary" (click)="fileUpload.click()">
                            Adjuntar archivo <mat-icon>attach_file</mat-icon>
                        </button>
                        <span class="ms-3 h6">{{fileName || ""}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 ">
                    <mat-form-field class="w-100" appearance="outline" *ngIf="sheetNames.length>0">
                        <mat-label>Pestaña</mat-label>
                        <mat-select [(ngModel)]="selectedSheet" (selectionChange)="loadSheetData()" formControlName="pestania"> 
                          <mat-option *ngFor="let sheetName of sheetNames" [value]="sheetName">{{ sheetName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-8 d-flex ">
                    <div>
                        <span *ngIf="jsonData.length>0" [ngClass]="{'badge': true, 'bg-info': jsonData.length==0, 'bg-primary': jsonData.length>0 }">{{jsonData.length | number }} en total</span>
                    </div>
                </div>
            </div>
        </div>       
    </form>
    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
        <mat-progress-bar color="primary" mode="indeterminate">
        </mat-progress-bar>
    </div>

    <div *ngIf="dataAOA.length>0" class="table-responsive"  style="max-height: 300px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px;">
        
        <table class="table table-sm">
            <thead>
                <tr>
                  <th *ngFor="let header of getKeys(jsonData[0])">{{ header }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of jsonData.slice(0, 50); let i = index">
                  <td *ngFor="let cell of getValues(row)">{{ cell }}</td>
                </tr>
              </tbody>
        </table>
    </div>



</div>
<div class="modal-footer">
    <button mat-flat-button class="btn bg-success text-white" form="formAdd" type="submit" [disabled]="!formAdd.valid">Importar <mat-icon>publish</mat-icon></button>
</div>