<div class="modal-header">
    <h1 class="modal-title">Ayuda con Envío: {{data.shipmentSel.carrier}} - {{data.shipmentSel.tracking_number}}</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h4>¿Tienes algún problema con el envío? Describe el problema para que podamos ayudarte:</h4>
    <form autocomplete="off" [formGroup]="formHelp" (ngSubmit)="ruSure()"  class="mt-3" id="helpForm">
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Descripción de problema</mat-label>
                    <textarea matInput formControlName="description" rows="4"></textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button color="primary" form="helpForm" type="submit" [disabled]="!formHelp.valid">Enviar <mat-icon>send</mat-icon></button>
</div>