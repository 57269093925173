import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FavShipModel } from 'src/app/models/shipment.model';
import { UsersService } from 'src/app/services/users.service';
import { FavAddComponent } from '../shared/dialogs/fav-add/fav-add.component';
import { FavShowComponent } from '../shared/dialogs/fav-show/fav-show.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fav-shipments',
  templateUrl: './fav-shipments.component.html',
  styleUrls: ['./fav-shipments.component.scss']
})
export class FavShipmentsComponent implements OnInit {

  favShipList:Array<FavShipModel> = new Array<FavShipModel>();
  fsDisplayedColumns: string[] = ['alias', 'carrier', 'origin', 'destination', 'shipment',  'actions'];//['carrier','receiver', 'destination', 'sender','origin', 'shipment','actions'];
  fsDataSource!: MatTableDataSource<FavShipModel>;
  isLoading = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  get favData():any { return this.userS.sharedData; } set favData(value: any) { this.userS.sharedData = value; }
  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getFavShipmentList();
  }
  
  getFavShipmentList() {
    this.userS.getFavShipmentsList().subscribe(
      (result : any) => {
        this.favShipList = result;
        this.fsDataSource = new MatTableDataSource(this.favShipList);        
        this.fsDataSource.paginator = this.paginator;
        this.fsDataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  
  openDialogSaveFav(id:number) {
    
    const dialogRef = this.matDialog.open( FavAddComponent, {
        height: 'auto',
        maxHeight:'45rem',
        width:'80rem',
        data: { 
          shipmentIdSelected: id
        },
        panelClass: 'custom-dialog-container-scroll',
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getFavShipmentList();
      });
  }

  addFavtoService(obj: FavShipModel) {
    this.favData = obj;
    this.goToShipmentAdd();
  }

  showFav(obj: FavShipModel) {
    this.matDialog.open( FavShowComponent, {
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      data: obj,
      panelClass: 'custom-dialog-container-scroll',
    });
  }


  removeFavAddress(obj: FavShipModel) {
      Swal.fire({
        title: '¿Estás seguro que deseas eliminar el registro?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'info',
            title: 'Eliminando...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading(null);
            }
          });
          this.userS.deleteFavAddress(obj.id).subscribe(
            (result:any) => {
              if (result.message == "Success")                
              Swal.fire({
                icon: 'success',
                title: 'Eliminado',
                text: 'Favorito eliminado de manera correcta!'
              }).then(() => {
                this.getFavShipmentList();
              });
            },
            (err:any) => {
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
              });
            }
          );
        }
      }); 
  }

  goToShipmentAdd() {
    this.router.navigateByUrl('/admin/shipments/add');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.fsDataSource.filter = filterValue.trim().toLowerCase();
    if (this.fsDataSource.paginator) {
      this.fsDataSource.paginator.firstPage();
    }
  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }
}
