import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as printJS from 'print-js';
import { CompanyModel } from 'src/app/models/company.model';
import { BillingDataModel, ServicesModeModel } from 'src/app/models/shipment.model';
import { CurrencyModel, UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import { CarrierPricingXlsComponent } from '../shared/dialogs/carrier-pricing-xls/carrier-pricing-xls.component';
import { PriceComparisonNofoundComponent } from '../shared/dialogs/price-comparison-nofound/price-comparison-nofound.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-billing-data-siteflow',
  templateUrl: './billing-data-siteflow.component.html',
  styleUrls: ['./billing-data-siteflow.component.scss']
})
export class BillingDataSiteflowComponent implements OnInit {
  groupCurrencyBillingData : any ;
  billingDataList =  new Array<BillingDataModel>();
  okCurrency: any;
  isLoading = true;
  base64!:string;
  servicesModeList =  new Array<ServicesModeModel>();
  xlsData=new Array<BillingDataModel>();
  xlsDataNotFound=new Array<BillingDataModel>();
  xlsDataCost=new Array<BillingDataModel>();
  activeFilterXls = false;
  filterProperty = '';
  countCoincidencia = 0;
  formatoXls='';

  showExportColumn = false;

  //#region Filtros
  dateNow : Date = new Date();
  dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
    
  newDateTodayI : Date | null = null;//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDay());
  minDateI : Date =  new Date(2022, 0, 1);
  maxDateI : Date =  new Date();
  dateIDis=true;
  newDateTodayF : Date | null = null//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
  minDateF : Date =  new Date(2022, 0, 1);
  maxDateF : Date =  new Date();
  dateFDis=true;

  showFilter=false;
  selFechas = new FormControl('');  
  selCompanies = new FormControl('');  
  selCompaniesList:Array<CompanyModel>=[];
  selCurrency = new FormControl('');  
  selCurrencyXC :Array<string>=[];
  selClient = new FormControl('');  
  selClientXC :Array<string>=[];
  selCurrencyList:Array<CurrencyModel>=[];
  selUsers = new FormControl('');  
  selUsersList:Array<UserModel>=[];

  preLoadHidden = true;
  preUploadXlsdHidden = true;

  clientesPrecioEspecial:any;
  //#endregion

  
  //displayedColumns: string[] = ['tracking_number', 'carrier', 'shipment_id', 'order_number', 'company_name', 'user_name', 'shipment', 'created_at', 'currency', 'service_price', 'total_price'];
  columnDefinitions = [ //En el orden en el que se encuentra aquí es como se verán en la tabla
    { def:'created_at', label: 'Fecha de creación', hide: false},
    { def:'partner_id', label: 'PartnerId', hide: false},
    { def:'company_name', label: 'Cliente', hide: false},
    { def:'order_number', label: 'Order Id', hide: false},
    { def:'order_id', label: 'Referencia', hide: true},
    { def:'extra', label: 'Extra', hide: false},
    //{ def:'shipment_id', label: '# Orden', hide: true},
    { def:'provider', label: 'Proveedor', hide: true},
    { def:'carrier', label: 'Courier', hide: false},
    { def:'service', label: 'Método de envio', hide: false},
    { def:'extended_zone', label: 'Zona extendida', hide: false},
    { def:'shipment_type', label: 'Tipo', hide: false},
    { def:'content', label: 'Contenido', hide: true},
    { def:'kg', label: 'Kg enviado', hide: false},
    { def:'dimension', label: 'Volumen enviado', hide: false},

    { def:'xcKgText', label: 'Kg Comprados', hide: true},

    //{ def:'origin', label: 'Origen', hide: true},
    //{ def:'destination', label: 'Destino', hide: true},
    //{ def:'shipment', label: 'Envío', hide: true},
    { def:'service_price', label: 'Costo API', hide: true},
    { def:'total_price', label: 'Precio de venta total', hide: true},
    { def:'currency', label: 'Moneda interna', hide: true},
    
    { def:'xTotal_price', label: 'xls Costo', hide: true},
    
    
    
    { def:'xcModo', label: 'xcModo', hide: true},
    { def:'xcCosto', label: 'Costo interno', hide: true},
    { def:'xcCostoAdicional', label: 'Costos adicionales', hide: true},
    { def:'xcPrecioVenta', label: 'Precio Venta Buho', hide: true},
    { def:'other_charge_price', label: 'Precio de venta otros cargos', hide: false},
    { def:'xcPrecioVentaPacto', label: 'Precio Venta Externo', hide: true},
    { def:'xcMoneda', label: 'Moneda', hide: true},
    { def:'xcPaqueteria', label: 'xcPaquetería', hide: true},


    { def:'xCreated_at', label: 'xls creación', hide: true},
    { def:'xDestination_name', label: 'xls Destinatario', hide: true},    

    { def:'tracking_number', label: 'Guía', hide: false},
  ]
  showOptions = false;
  getDisplayedColumns():string[] {
    return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
  }

  getDisplayedLabel(def:string):string {
    let cLabel=this.columnDefinitions.find(item=> item.def==def);
    return cLabel?.label||''
  }
  
  dataSource!: MatTableDataSource<BillingDataModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.showFilter=true;
    this.selFechas.setValue('2');
    this.selCurrency.setValue('0');
    this.onchangeFechas(this.selFechas);
    //No cargar el listado de manera inicial
    //this.getBillingDataList(); 
    this.isLoading = false; //porque no se carga al principio los datos
    this.getServicesMode();

    //this.getCompaniesList();
    //this.getCurrencyList();
    //this.getUsersList();
    //this.newDateToday.setDate(this.newDateToday.getDate() + 1);
  }
  
  getBillingDataList(date_i : string|null = null, date_f : string|null = null) {
    this.userS.getBillingDataListSiteflow(date_i, date_f).subscribe(
      (result : any) => {

        //this.groupCurrency(false, result);
        this.billingDataList = result;
        this.formatoXls='';
        this.xlsData = new Array<BillingDataModel>();
        this.xlsDataNotFound = new Array<BillingDataModel>();
        
        for ( let i = 0; i < this.billingDataList.length; i++ ) {
          this.billingDataList[i].package_dimensions = JSON.parse(this.billingDataList[i].package_dimensions);
        }
        this.dataSource = new MatTableDataSource(this.billingDataList);        
        this.dataSource.paginator = this.paginator;
        //this.dataSource.paginator.pageSize=this.billingDataList.length;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.preLoadHidden = false;
        this.getClientList();
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  // groupCurrency(filter:boolean, data: Array<BillingDataModel> ){
  //   if(filter){
  //     //Si es tipo filtro significa que ya se trajeron los datos de la API y se agruparon anteriormente
  //     if(this.selCurrency.value == "0"){ //si son todas
  //       this.billingDataList = data;
  //     }else{
  //       //Ver si existe el agrupado de moneda, si no existe poner vacía la tabla
  //       if (!this.groupCurrencyBillingData[this.selCurrency.value]) {
  //         this.billingDataList = new Array<BillingDataModel>();
  //       }else{ //volver a llenar el mat-table
  //         const arrFil=this.groupCurrencyBillingData[this.selCurrency.value];
  //         this.billingDataList = arrFil;
  //         //console.log(this.billingDataList);
  
  //         // for ( let i = 0; i < this.billingDataList.length; i++ ) {
  //         //   this.billingDataList[i].package_dimensions = JSON.parse(this.billingDataList[i].package_dimensions);
  //         // }
  //         this.dataSource = new MatTableDataSource(this.billingDataList);        
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.paginator.pageSize=this.billingDataList.length;
  //         this.dataSource.sort = this.sort;
  //         this.isLoading = false;
  //         this.preLoadHidden = false;
  //       }
  //     }
  //   }else{
  //     //Es de la consulta a la API, el resultado esta en data, aquí no afecta el filtro por moneda
  //     this.groupCurrencyBillingData = data.reduce((groupCurrency:{[key: string]: any[]} , item:BillingDataModel) => {
  //       //Si la moneda no existe en agrupado, se crea como array vacío
  //       if (!groupCurrency[item.currency]) {
  //         groupCurrency[item.currency] = [];
  //       }
  //       // El objeto se agrega al array en la moneda que pertenezca.
  //       groupCurrency[item.currency].push(item);
  //       return groupCurrency;
  //     }, {});
  //     this.okCurrency = Object.keys(this.groupCurrencyBillingData);
  //     if(this.okCurrency.length>1){
  //       Swal.fire({
  //         icon:  'warning',
  //         title: 'Diferentes tipos de monedas',
  //         text:  'En el reporte hay diferentes tipos de monedas, la sumatoria solo se hará con monedas del mismo tipo (puedes usar el filtro de moneda).'
  //       });
  //     }
  //     //this.selCurrency.setValue("0");
  //     this.billingDataList = data;
  //     //console.log(this.groupCurrencyBillingData, this.okCurrency.length, this.selCurrency.value);
  //   }
  // }
  // showSumFooter() {
  //   let vShow = false;
  //   if(this.okCurrency!=undefined){ 
  //     if(this.okCurrency.length==1){
  //       vShow = true;
  //     }else{
  //       if(this.selCurrency.value == "0"){ //si son todas
  //         vShow = false;
  //       }else{ //Tiene selecciona 1 tipo de moneda
  //         vShow = true
  //       }
  //     }
  //   }else{
  //     vShow = false;
  //   }
  //   return vShow;
  // }

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  searchFilter() {
    this.isLoading = true;
    let date_i:string|null, date_f:string|null, users:string|null, companies:string|null;
    let arrCom:Array<number>=this.selCompanies.value;
    let arrUse:Array<number>=this.selUsers.value;
    let currency:string;

    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);
    companies=arrCom.length>0?arrCom.join(','):null;
    users=arrUse.length>0?arrUse.join(','):null;
    currency=this.selCurrency.value;
    console.log(companies, users);
    this.getBillingDataList(date_i, date_f);
  }

  getServicesMode() {
    this.userS.getListServicesMode().subscribe(
      (result : any) => {
        this.servicesModeList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  // getCompaniesList() {
  //   this.userS.getCompanies().subscribe(
  //     (result : any) => {
  //       this.selCompaniesList = result;
  //     },
  //     (err : any) => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }
  //   );
  // }
  // getCurrencyList() {
  //   this.userS.getCurrencies().subscribe(
  //     (result : any) => {
  //       this.selCurrencyList = result;
  //     },
  //     (err : any) => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }
  //   );
  // }
  // getUsersList() {
  //   this.userS.getUserListMin().subscribe(
  //     (result : any) => {
  //       this.selUsersList = result;
  //     },
  //     (err : any) => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }
  //   );
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  searchFilterClean() {
    this.selCompanies.setValue([]);
    this.selUsers.setValue([]);
  }


  onchangedateI(e: Date): void {
    console.log('Inicio', e, 'Fin', this.newDateTodayF);
    if(this.newDateTodayF!=null && this.newDateTodayF!.getTime()<e.getTime()){
        this.newDateTodayF=null;
    }
  }
  onchangedateF(e: Date): void {
    console.log('Fin', e, 'Inicio', this.newDateTodayI);
    if(this.newDateTodayI!=null && this.newDateTodayI!.getTime()>e.getTime()){
      this.newDateTodayI=null;
    }
  }

  onchangeFechas(change:any) {
    //console.log(change);
    switch (change.value) {
      case '1'://Hoy
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '2'://Este mes
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1);
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '3'://Elegir fechas
        this.dateIDis=false;
        this.dateFDis=false;
        break;
      default:
        break;
    }
  }

  // onchangeCurrency(change:any) {
  //   const auxArr= new Array<BillingDataModel>();
  //   this.groupCurrency(true,auxArr);
  // }
  getPageSizeOptions(): number[]{
    if(this.billingDataList.length>10)
    return [10,50,100,this.billingDataList.length];
    else
    return [10,50,100];
  }
  
  getTotalService() {
    return (this.billingDataList.map(t => t.service_price).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
  }

  getTotalPrice() {
    return (this.billingDataList.map(t => t.total_price).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
  }
  
  toggleColumn(columnName: string, event: MatCheckboxChange) {
    //console.log(columnName);
    let columnHS=this.columnDefinitions.find( item=> item.def==columnName);
    if(columnHS){
      columnHS.hide=!event.checked;
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  getAddress(data:string) {
    try {
      const address = JSON.parse(data);
      return `${address.name}, ${address.street}, ${address.number}, ${address.suburb}, ${address.postalCode}, ${address.city}`;
    } catch (error) {
      return ''; 
    }
  }

  openUploadExcel() {
    // this.billingDataList.forEach(elemento => {
    //   if (elemento.hasOwnProperty('xTotal_price')) {
    //     delete elemento.xTotal_price;
    //   }
    //   if (elemento.hasOwnProperty('xCreated_at')) {
    //     delete elemento.xCreated_at;
    //   }
    //   if (elemento.hasOwnProperty('xDestination_name')) {
    //     delete elemento.xDestination_name;
    //   }
    // });

    // const dialogRef = this.matDialog.open( PriceComparisonXlsComponent, {
    //   data: { 
    //     //shipmentIdSelected: id
    //   },
    //   height: 'auto',
    //   maxHeight:'45rem',
    //   width:'80rem',
    //   panelClass: 'custom-dialog-container-scroll',
    //   //panelClass: 'dialogs-main',
    // });

    // dialogRef.afterClosed().subscribe(resp => {
    //   let idBU=0;
    //   if (resp) {
    //     console.log(resp);
    //     idBU = resp.id;
    //     this.xlsData = resp.data;
    //     this.nameFormatoXls(resp.formato);
    //     this.comparativeBdXls();
    //     this.preUploadXlsdHidden=false;
    //     this.columnDefinitions.find
    //     let ctp=this.columnDefinitions.find( item=> item.def=='xTotal_price');
    //     if(ctp){
    //       ctp.hide=false;
    //     }
    //     // let cca=this.columnDefinitions.find( item=> item.def=='xCreated_at');
    //     // if(cca){
    //     //   cca.hide=false;
    //     // }
    //     let cdn=this.columnDefinitions.find( item=> item.def=='xDestination_name');
    //     if(cdn){
    //       cdn.hide=false;
    //     }
    //   }
    //   //if(idBU>0)
    //     //this.goToBulkUploads(idBU);
    // });
  }

  openUploadCost() {
    this.billingDataList.forEach(elemento => {
      if (elemento.hasOwnProperty('xcPartnerId')) {
        delete elemento.xcPartnerId;
      }
      if (elemento.hasOwnProperty('xcPaqueteria')) {
        delete elemento.xcPaqueteria;
      }
      if (elemento.hasOwnProperty('xcModo')) {
        delete elemento.xcModo;
      }
      if (elemento.hasOwnProperty('xcKg')) {
        delete elemento.xcKg;
      }
      if (elemento.hasOwnProperty('xcKgText')) {
        delete elemento.xcKgText;
      }
      if (elemento.hasOwnProperty('xcCosto')) {
        delete elemento.xcCosto;
      }
      if (elemento.hasOwnProperty('xcZona')) {
        delete elemento.xcZona;
      }
      if (elemento.hasOwnProperty('xcPrecioVenta')) {
        delete elemento.xcPrecioVenta;
      }
      if (elemento.hasOwnProperty('xcPrecioVentaPacto')) {
        delete elemento.xcPrecioVentaPacto;
      }
      if (elemento.hasOwnProperty('xcMoneda')) {
        delete elemento.xcMoneda;
      }
    });

    const dialogRef = this.matDialog.open( CarrierPricingXlsComponent, {
      data: { 
        //shipmentIdSelected: id
        clients:this.selClientXC
      },
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        console.log(resp);
        idBU = resp.id;
        this.clientesPrecioEspecial=resp.clientesEspeciales;
        this.xlsDataCost = resp.data;
        //this.nameFormatoXls(resp.formato);
        this.comparativeBDCost();
        this.preUploadXlsdHidden=false;
        //this.columnDefinitions.find;
        // let cco=this.columnDefinitions.find( item=> item.def=='xcCosto');
        // if(cco){
        //   cco.hide=false;
        // }
        // let ccoa=this.columnDefinitions.find( item=> item.def=='xcCostoAdicional');
        // if(ccoa){
        //   ccoa.hide=false;
        // }
        let cdn=this.columnDefinitions.find( item=> item.def=='xcKgText');
        if(cdn){
          cdn.hide=false;
        }
        // let cds=this.columnDefinitions.find( item=> item.def=='xcModo');
        // if(cds){
        //   cds.hide=false;
        // }

        // let xcp=this.columnDefinitions.find( item=> item.def=='xcPaqueteria');
        // if(xcp){
        //   xcp.hide=false;
        // }
        let xcpv=this.columnDefinitions.find( item=> item.def=='xcPrecioVenta');
        if(xcpv){
          xcpv.hide=false;
        }
        let xcpvp=this.columnDefinitions.find( item=> item.def=='xcPrecioVentaPacto');
        if(xcpvp){
          xcpvp.hide=false;
        }
        let xcmo=this.columnDefinitions.find( item=> item.def=='xcMoneda');
        if(xcmo){
          xcmo.hide=false;
        }

        this.getCurrencyList();
        this.getClientList();
      }
      //if(idBU>0)
        //this.goToBulkUploads(idBU);
    });
  }

  nameFormatoXls(formato:string) {
    let name='';
    switch (formato) {
      case 'pgo':        
        this.formatoXls = 'Pak2go';
        break;
      case 'afimex':
        this.formatoXls = 'Afimex';
        break;
      case 'eme':
        this.formatoXls = 'Enviame';
        break;
      case 'paquetexpress':
        this.formatoXls = 'Paquetexpress'
        break;
      default:
        this.formatoXls='';
        break;
      }
      return name;
  }

  trackingsNoFound() {
    const dialogRef = this.matDialog.open( PriceComparisonNofoundComponent, {
      data: this.xlsDataNotFound,
      height: 'auto',
      maxHeight:'50rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        console.log(resp);
      }
      //if(idBU>0)
        //this.goToBulkUploads(idBU);
    });
  }

  comparativeBdXls(){
    if(this.xlsData.length>0 && this.billingDataList.length>0){
      // this.billingDataList.forEach((item1) => {
      //   const item2 = this.xlsData.find((element: { xTracking_number: string; }) => element.xTracking_number === item1.tracking_number);
      //   if (item2) {
      //     item1.xTotal_price = item2.xTotal_price;
      //     item1.xCreated_at = item2.xCreated_at;
      //     item1.xDestination_name = item2.xDestination_name;
      //   }else{
      //     this.xlsDataNotFound.push();
      //   }
      // });

      this.xlsData.forEach((item1) => {
        const item2 = this.billingDataList.find((element) => element.tracking_number == item1.xTracking_number);
        if (item2) {
          item2.xTotal_price = item1.xTotal_price;
          item2.xCreated_at = item1.xCreated_at;
          item2.xDestination_name = item1.xDestination_name;
          this.countCoincidencia++;
        }else{
          this.xlsDataNotFound.push(item1);
        }
      });

      console.log(this.xlsDataNotFound);

      this.filterXTracking();
    }
  }

  comparativeBDCost(){
    this.countCoincidencia=0;
    this.isLoading = false;
    let conta=0;
    let contaFound=0;
    let contaNotFound=0;
    if(this.xlsDataCost.length>0 && this.billingDataList.length>0){
      this.billingDataList.forEach((item1) => {
        conta++;
        let bdCarrier=item1.carrier;
        switch (item1.carrier.toUpperCase()) {
          case 'FDXM':
            bdCarrier='FEDEX';
            break;
          case 'MX99':
            bdCarrier='99MINUTOS';
            break;        
          default:
            break;
        }
        //Sacar el peso
        let pac_dim=[];
        if (!Array.isArray(item1.package_dimensions)) {
          // No es un array
          pac_dim=JSON.parse(item1.package_dimensions);
        }else{
          pac_dim=item1.package_dimensions;
        }
        //Sacar el método o modo de envío
        let modoEnv=this.getServiceMode(item1.service, item1.carrier, item1.provider)

        // if(item1.service=='int_ground')
        //   console.log(item1.service, item1.carrier, item1.provider, modoEnv);

        let total_weight=pac_dim.length;
        if(total_weight>1){
          item1.xcCosto = 0;
          item1.xcPrecioVenta = 0;
          item1.xcPrecioVentaPacto = 0;
          item1.xcKgText ='';
        }
        let iterado=false;
        let encontrado=0;
        let no_encontrado=0;
        let dest_country=item1.dest_country.toUpperCase();
        const arrPaisPrice=['PR']; //Listado con los países que tienen un precio diferente
        //console.log(item1.dest_country.toUpperCase());
        //Recorrer el listado de paquetes de la guía
        pac_dim.forEach((ipackage: { length: number; width: number; height: number; weight:number;}) => {
          //filtrar por provider y paqutería        
          const cost = this.xlsDataCost.find((element) => (element.xcPaqueteria && bdCarrier.toUpperCase().includes(element.xcPaqueteria.toUpperCase()))
            && (item1.shipment_type.toLowerCase()=='nat' ? (element.xcModo && element.xcModo.toUpperCase() == modoEnv.toUpperCase()) : (  
                (element.xcModo && element.xcModo.toUpperCase().includes('INT')) && 
                //Para seleccionar los precios que son de un país específico
                (arrPaisPrice.includes(dest_country) ? (
                    element.xcCountry &&
                    dest_country==element.xcCountry.toUpperCase()
                  ):true
                )
              ) )
            && (element.xcKg && element.xcKg >= ipackage.weight )
            //verificar si el cliente tiene precios especiales o no
            && ((this.clientesPrecioEspecial && this.clientesPrecioEspecial.length>0)? 
                  (
                    this.clientesPrecioEspecial.includes(item1.company_name) ? //Ver si la compañía es alguno de los clientes con precio especial
                     element.xcClient?.toUpperCase()==item1.company_name.toUpperCase() //que el cliente especial sea igual a la compañía de la guía
                     : !element.xcClient //si la guía no es de un cliente especial tomar el registro si en xcClient no viene nada (para no tomar precios de un cliente)
                  ):(!element.xcClient)
              )
          );

          if(cost){
            item1.xcPartnerId = cost.xcPartnerId;
            item1.xcPaqueteria = cost.xcPaqueteria;
            item1.xcModo = cost.xcModo;
            item1.xcKg = cost.xcKg;
            if(total_weight>1){ //si es más de 1 paquete en la misma guía
              if(item1.xcKgText=='')
                item1.xcKgText = `${cost.xcKg} KG.`;
              else
                item1.xcKgText += ` ${cost.xcKg} KG.`;

              if(item1.xcCosto!=undefined && cost.xcCosto!=undefined)
                item1.xcCosto += cost.xcCosto;
              if(item1.xcPrecioVenta!=undefined && cost.xcPrecioVenta!=undefined)
                item1.xcPrecioVenta += cost.xcPrecioVenta;
              if(item1.xcPrecioVentaPacto!=undefined && cost.xcPrecioVentaPacto!=undefined)
                item1.xcPrecioVentaPacto += cost.xcPrecioVentaPacto;
            }else{
              item1.xcKgText = `${cost.xcKg} KG.`;
              item1.xcCosto = cost.xcCosto;
              item1.xcPrecioVenta = cost.xcPrecioVenta;
              item1.xcPrecioVentaPacto = cost.xcPrecioVentaPacto;
            }
            item1.xcMoneda = cost.xcMoneda;
            encontrado++;            
          }else{
            no_encontrado++;
          }
          iterado=true;
        });

        if(no_encontrado==0){
          contaFound++;
          this.countCoincidencia++;
        }else{
          this.xlsDataNotFound.push(item1);
          contaNotFound++;
          //console.log(item1);
        }

        // if(cost){
        //     item1.xcPartnerId = cost.xcPartnerId;
        //     item1.xcPaqueteria = cost.xcPaqueteria;
        //     item1.xcModo = cost.xcModo;
        //     item1.xcKg = cost.xcKg;
        //     item1.xcCosto = cost.xcCosto;
        //     item1.xcPrecioVenta = cost.xcPrecioVenta;
        //     item1.xcPrecioVentaPacto = cost.xcPrecioVentaPacto;
        //     item1.xcMoneda = cost.xcMoneda;
        //     contaFound++;
        //     this.countCoincidencia++;
        // }else{
        //   this.xlsDataNotFound.push(item1);
        //   contaNotFound++;
        //   console.log(item1);
        // }

        // const lsCost = this.xlsDataCost.filter(element => {
        //                     let mPro = item1.provider && element.xcProveedor ? item1.provider.toUpperCase() == element.xcProveedor.toUpperCase() : false;
        //                     let mCar = element.xcPaqueteria ? item1.carrier.toUpperCase().includes(element.xcPaqueteria.toUpperCase()):false;
        //                     //const mSer = item.service == "" || item.service.toUpperCase() == r.service.toUpperCase();
        //                     if(!mCar){
        //                       if(element.xcPaqueteria?.toUpperCase()=='FEDEX' && item1.carrier.toUpperCase().includes('FDXM')){
        //                         mCar=true;
        //                       }else if(element.xcPaqueteria?.toUpperCase()=='99MINUTOS' && item1.carrier.toUpperCase().includes('MX99')){
        //                         mCar=true;
        //                       }
        //                     }
        //                     // if(element.xcModo=='Aéreo'){
        //                     //   console.log(element);
        //                     // }
        //                     let mMod=element.xcModo ? item1.service.toUpperCase().includes(element.xcModo.toUpperCase()):false;
        //                     //Cumple el provider, carrier y service
        //                     return mPro && mCar && mMod; //&& mSer;
        //                 });
      //   if (lsCost && lsCost.length>0) {
      //     let pac_dim=[];
      //     if (!Array.isArray(item1.package_dimensions)) {
      //       // No es un array
      //       pac_dim=JSON.parse(item1.package_dimensions);
      //     }else{
      //       pac_dim=item1.package_dimensions;
      //     }
      //     let total_weight=0;
      //     //Recorrer el listado de paquetes de la guía
      //     pac_dim.forEach((item2: { length: number; width: number; height: number; }) => {
      //       const weightItem=(item2.length*item2.width*item2.height)/5000; //Sacar el peso volumétrico
      //       //filtrar por peso volumétrico del catálogo de costos
      //       let lsCostW = this.xlsDataCost.filter((element) => element.xcKg?element.xcKg>=weightItem:false);
      //       lsCostW.sort((a, b) => { //Ordernar los costos por peso del menor al mayor 
      //         if (a.xcKg !== undefined && b.xcKg !== undefined) {
      //           return a.xcKg - b.xcKg;
      //         }
      //         return 0; 
      //       });
      //       if(lsCostW && lsCostW.length>0){ //Si hay algúno de los pesos del catálogo tomar al primero porque sería el mismo o uno superior
      //         item1.xcPaqueteria = lsCostW[0].xcPaqueteria;
      //         item1.xcModo = lsCostW[0].xcModo;
      //         item1.xcKg = lsCostW[0].xcKg;
      //         item1.xcZona = lsCostW[0].xcZona;
      //         item1.xcProveedor = lsCostW[0].xcProveedor;
      //         item1.xcExtra = lsCostW[0].xcExtra;
      //         item1.xcCosto = lsCostW[0].xcCosto;
      //         total_weight+=lsCostW[0].xcCosto?lsCostW[0].xcCosto:0;
      //       }
      //       item1.xcCosto=total_weight;
      //     });
      //   }
      // });
      });
      this.filterXCTracking();
    }
    this.isLoading = false;
    console.log(conta, contaFound, contaNotFound);
  }

  getZoneFinal(service:string){
    const match=service.match(/Z(\d+)$/); //Busca al final de la cadena una Z seguida de dígitos
    //Si encontró retorna los dígitos si no devuelve nulo
    return match ? parseInt(match[1], 10) : null;
  }

  getServiceMode(service:string, carrier:string, provider:string ) {
    try {
      let sService=service;
      const indexFound = this.servicesModeList.findIndex(r=> r.provider.toUpperCase() == provider.toUpperCase() 
                                                          && (r.carrier.toUpperCase().includes(carrier.toUpperCase()) || carrier.toUpperCase().includes(r.carrier.toUpperCase())) 
                                                          && (service.toUpperCase().includes(r.service.toUpperCase()) || r.service.toUpperCase().includes(service.toUpperCase())) );

      // const indexFound = this.servicesModeList.findIndex(r=> r.provider.toUpperCase() == provider.toUpperCase() && r.carrier.toUpperCase() == carrier.toUpperCase() && service.toUpperCase().includes(r.service.toUpperCase()) );
      if(indexFound != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
        //sService=`${service}->${this.servicesModeList[indexFound].service_mode}`;
        sService=`${this.servicesModeList[indexFound].service_mode}`;
      }
      return sService;
    } catch (error) {
      return service; 
    }
  }

  filterXTracking() {
    if(this.activeFilterXls==false){
      //this.dataSource.filterPredicate = (data, filter) => data.xTotal_price > 0;
      this.dataSource.filterPredicate = (data, filter) => {
        return (data.xTotal_price!=null && data.xTotal_price > 0);
      };
      this.dataSource.filter = 'apply-filter'; // Cualquier valor aquí funcionará
    }else{
      this.dataSource.filterPredicate = (data, filter) => {
        return true;
      };
      this.dataSource.filter = '';
    }
    this.activeFilterXls = !this.activeFilterXls;
   
  }
  filterXCTracking() {
    if(this.activeFilterXls==false){
      //this.dataSource.filterPredicate = (data, filter) => data.xcCosto > 0;
      this.dataSource.filterPredicate = (data, filter) => {
        return (data.xcPrecioVentaPacto!=null && data.xcPrecioVentaPacto > 0);
      };
      this.dataSource.filter = 'apply-filter'; // Cualquier valor aquí funcionará
    }else{
      this.dataSource.filterPredicate = (data, filter) => {
        return true;
      };
      this.dataSource.filter = '';
    }
    this.activeFilterXls = !this.activeFilterXls;
   
  }

  getCurrencyList() {
    const monedasUnicasSet = new Set(this.xlsDataCost.map(transaccion => transaccion.xcMoneda));
    this.selCurrencyXC = Array.from(monedasUnicasSet).filter(m => m !== undefined && m !== '') as string[];
    console.log(this.selCurrencyXC);
    //this.selCurrencyList = monedasUnicas;
  }
  getClientList() {
    const clientesUnicosSet = new Set(this.billingDataList.map(transaccion => transaccion.company_name)); 
    this.selClientXC = Array.from(clientesUnicosSet).filter(m => m !== undefined) as string[];  
    console.log(this.selClientXC);
  }

  filterClientCurrency() {
    if(this.selCurrency.value == "0" && this.selClient.value == "0" ){ //si son todas
      this.dataSource.filterPredicate = (data, filter) => {
        return true;
      };
      this.dataSource.filter = '';
    }else{
      let sClient= this.selClient.value;
      let sCurrency= this.selCurrency.value;
      this.dataSource.filterPredicate = (data, filter) => {
        if(sCurrency=="0")
          return (data.company_name==sClient );
        else{
          if(sClient!='')
            return (data.company_name==sClient && data.xcMoneda == sCurrency );
          else
            return (data.xcMoneda == sCurrency );
        }
      };
      this.dataSource.filter = 'apply-filter'; // Cualquier valor aquí funcionará
    }

  }

  getStylePrecio(precio_venta:number, costo:number, property:string) {
    let res='';

    if(precio_venta > costo){
      if(property=='total_price' || property=='xcCosto')
        res='font-weight: bold; --bs-text-opacity: 1; color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;';
      else
        res='font-weight: bold;'; // color: gray;
    }
    return res;
  }

  showColumnsExport() {
    let cdn=this.columnDefinitions.find( item=> item.def=='total_price');
    if(cdn){
      cdn.hide=false;
    }
    let cdm=this.columnDefinitions.find( item=> item.def=='service_price');
    if(cdm){
      cdm.hide=false;
    }
  }
  setNameXls() {
    let monthShort=this.dateNow.toLocaleString('default', { month: 'short' });
    let year=` ${this.dateNow.getFullYear()}`;
    let sCli=this.selClient.value == "0"?'':' '+this.selClient.value;
    let sCur=this.selCurrency.value == "0"?'':' '+this.selCurrency.value;
    
    let res=`Billing Data Site flow ${monthShort}${year}${sCli}${sCur}`;

    return res;
  }

  removeUnitsWeight(text: string): string {
    const unidadesPesoRegEx = /(\d+(\.\d+)?)(\s+)?(KG|kg|Kg)?(\.)?/g;
    
    // Reemplazar vacío
    const textoSinUnidades = text.replace(unidadesPesoRegEx, '');
  
    return textoSinUnidades.trim();
  }

  sumWeight(row: any): number {
    let suma = 0;
    for (const item of row.package_dimensions) {
      suma += item.weight;
    }
    return suma;
  }
  


  exportToCSV(dataList: any[], fileName: string): void {
    const csvContent = this.convertArrayToCSV(dataList);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private convertArrayToCSV(dataList: any[]): string {
    const separator = ',';
    const header = Object.keys(dataList[0]).join(separator);
    const csvRows = dataList.map((data) => {
      const values = Object.values(data).map((value) => this.escapeCSVValue(value));
      return values.join(separator);
    });

    return [header, ...csvRows].join('\n');
  }

  private escapeCSVValue(value: any): string {
    if (typeof value === 'string') {
      return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
  
  }

  exportSummaryToCSV() {
    //Agrupar por cliente y moneda
    const groupedData = this.billingDataList.reduce((acc: Record<string, { cliente: string; moneda: string; registros: number; precio_api: number; precio_total: number; precio_venta_buho: number; precio_venta_externo: number }>, item) => {
      const key = `${item.company_name}|${item.xcMoneda}`; //Crear una key de liente y moneda para agrupar
    
      //Sumar los totales
      if (!acc[key]) {
        acc[key] = {
          cliente: item.company_name,
          moneda: item.xcMoneda??'',
          registros:1,
          precio_api: (item.service_price?parseFloat(item.service_price):0),
          precio_total: (item.total_price?parseFloat(item.total_price):0),
          precio_venta_buho: item.xcPrecioVenta ?? 0,
          precio_venta_externo: item.xcPrecioVentaPacto ?? 0,
        };
      } else {
        acc[key].registros++;
        acc[key].precio_api += (item.service_price?parseFloat(item.service_price):0);
        acc[key].precio_total += (item.total_price?parseFloat(item.total_price):0);
        acc[key].precio_venta_buho += item.xcPrecioVenta ?? 0;
        acc[key].precio_venta_externo += item.xcPrecioVentaPacto ?? 0;
      }
    
      return acc;
    }, {});
    
    //Obtener el json de agrupamiento que se va a exportar
    const result = Object.entries(groupedData).map(([key, value]) => value);
    //Poner a 2 decimales
    if(result && result.length>0){
      for (let index = 0; index < result.length; index++) {
        if(!result[index].moneda || result[index].moneda=='')
          result[index].cliente = `${result[index].cliente} (N/E)`; //Son los no encontrados en el tarifario, se pondrían de manera informativa en el resumen porque en el reporte no se muestran, para ver cuales son esta el botón de "Ver no encontrados"
        result[index].precio_api=parseFloat(result[index].precio_api.toFixed(2));
        result[index].precio_total=parseFloat(result[index].precio_total.toFixed(2));
        result[index].precio_venta_buho=parseFloat(result[index].precio_venta_buho.toFixed(2));
        result[index].precio_venta_externo=parseFloat(result[index].precio_venta_externo.toFixed(2));
      }
    }

    //console.log(result);

    let monthShort=this.dateNow.toLocaleString('default', { month: 'short' });
    let year=` ${this.dateNow.getFullYear()}`;
    
    let nameCsv=`Summary Site flow ${monthShort}${year}`;

    this.exportToCSV(result, nameCsv);
  }

  getInitialExtendedZone(data:number) {
    try {
      let cadena='';
      switch (data) {
        case 0://No se ha consultado
          cadena='';
          break;
        case 1://ZOna extendida
          cadena='E';
          break;
        case 2://ZOna Normal
          cadena='N';
          break;
        case 3://No es extendida ni normal
          cadena='';
          break;
        case 4://Error
          cadena='';
          break;
      }
      
      return cadena;
    } catch (error) {
      return ''; 
    }
  }
  
}