import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { IntCity, IntCountry, IntState, Geocluster, CarrierData, ShipmentSaveModel, CollectableShipment } from 'src/app/models/shipment.model';
import { PickupsCheckoutComponent } from '../../shared/dialogs/pickups-checkout/pickups-checkout.component';
import { PickupsManifestCreateComponent } from '../../shared/dialogs/pickups-manifest-create/pickups-manifest-create.component';
import { PickupsService } from 'src/app/services/pickups.service';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { ZipCodeDataModel } from 'src/app/models/address.model';


let SCDB: Array<CarrierData> = [];

@Component({
  selector: 'app-pickup-form',
  templateUrl: './pickup-form.component.html',
  styleUrls: ['./pickup-form.component.scss']
})
export class PickupFormComponent implements OnInit {
  private observer!: Subscription;

  carrierAbbrList: string[] = [];
  carrierList: string[] = [];
  collectableShipments: Array<CollectableShipment> = [];
  database!: Geocluster;
  error: string | null = null;
  fee: string = "0.00";
  importedShipment: CollectableShipment | null = null;
  lockCarrierSelect: boolean = false;
  manifestExist: boolean = false;
  manifestShipments: string[] = [];
  pckDatesNumValue: number[] = [];
  pckDatesRange: string[] = [];
  pckFinalRange: number[] = [];
  pckInitRange: number[] = [];
  pickupForm!: FormGroup;
  postcoded: boolean = true;
  requestLoading: boolean = false;
  sector!: Geocluster;
  shipmentsList: any[][] = [];
  subtotal: string = "0.00";
  total: string = "0.00";
  uid!: number;
  user!: UserModel;
  userPC!: ZipCodeDataModel;
  wUnit: string = "kg";
  

  constructor(private dialog: MatDialog, private formBuilder: FormBuilder, private pickupsService: PickupsService, private router: Router, private userService: UsersService) {}
    
  get data(): any { return this.userService.sharedData; }
  set data(value: any) { this.userService.sharedData = value; }

  ngOnInit(): void {
    // Get current user data
    this.user = this.userService.getUser();
    this.uid = this.user.id;
    // Create form
    this.createPickupRequest();
    this.startClusters(); // Initialize cluster-typed variables
    // Get dropdowns' deployable data
    this.getLocationData(null, 'states', [this.user.iso_country]);
    this.getCollectableShipments();
    this.getCarrierData(true);
    // Check if there's data to be imported from shipments component
    this.importedShipment = this.pickupsService.receiveShipment();
    if (this.importedShipment) this.throwShipmentToForm(-1);
    // Listen to changes in the shipment ID's field and fire throwShipmentToForm function when it does.
    this.observer = this.pickupForm.controls.sid.valueChanges.subscribe(value => {
      this.throwShipmentToForm(value);
    });
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  /* ------------------------------- main -------------------------------
     void createPickupRequest(0):
     Initial function to create forms. */
  createPickupRequest() {
    // Form group for creating a pickup request
    this.pickupForm = this.formBuilder.group({
      carrier: ['', [Validators.required]],
      city: [{ value:'', disabled: (this.user.iso_country == 'MX') }],
      company: ['', [Validators.minLength(3)]],
      country: 'MX',
      email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)] ],
      finalTime: ['', [Validators.required]],
      initTime: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern(/^[A-ZÀ-ÖØ-ÞĀ-Ž][a-zß-öø-ÿĀ-Ž]+(?: [A-Za-zÀ-ÖØ-öø-ÿĀ-Ž][a-zß-öø-ÿĀ-Ž]+)+$/)]],
      neighborhood: ['', [Validators.required]],
      number: ['', [Validators.required, Validators.maxLength(15)]],
      packages: ['', [Validators.required, Validators.min(1), Validators.pattern(/^[1-9][0-9]*$/)]],
      pckDate: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(10), Validators.maxLength(10)]],
      postCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      reference: [''],
      rfc: ['', [Validators.pattern(/^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/)]],
      sid: [-1],
      state: [{ value: '', disabled: (this.user.iso_country == 'MX') }],
      street: ['', [Validators.required, Validators.minLength(2)]],
      weight: ['', [Validators.required, Validators.min(0.0001), Validators.pattern(/^(?:[1-9][0-9]*|(?:[0-9]+\.[0-9]*[1-9]))$/)]]
    });
  }
  /* -------------------------------------------------------------------- */

  /* void changeWUnit()
     When triggered, changes the defined weight unit of the input weight. */
  changeWUnit(): void {
    switch (this.wUnit[0]) {
      case 'g': this.wUnit = "lb"; break;  // Grams to Pounds
      case 'k': this.wUnit = "g"; break;  // Kilograms to Grams
      case 'l': this.wUnit = "oz"; break;  // Pounds to Ounces
      case 'o': this.wUnit = "kg"; break;  // Ounces to Kilograms
      default:
        this.error = "Inner Logic (changeWUnit(0))";
      break;
    }
  }

  /* void clearFields(1):
     Clears fields, I guess.
     < withPostCode: Boolean if post code is available. */
  clearFields(withPostCode: boolean = true): void {
    if (withPostCode) { 
      this.pickupForm.controls['neighborhood'].reset();
      if (this.pickupForm.controls['postCode'].value == '') {
        this.pickupForm.controls['state'].reset();
        this.pickupForm.controls['city'].reset();
      }
    } else {
      if (this.pickupForm.controls['state'].value == '') {
        this.pickupForm.controls['city'].reset();
        this.pickupForm.controls['neighborhood'].reset();
      }
      if (this.pickupForm.controls['city'].value == '')
        this.pickupForm.controls['neighborhood'].reset();
    }
  }

  /* string extractShipmentID(1):
    Cleans the shipments' selection for manifest, and returns only the ID of
    the selected shipment for display, so that the textbox doesn't show all
    data contained in the shipments' "mat-options".
    < index: The selected index.
    > The shipment ID of the item in the selected index if valid. */
  extractShipmentID(index: any): string {
    return this.shipmentsList[index] ? this.shipmentsList[index][0] : '';
  }

  /* void filterSubDivision(2): 
     Filters all specified administrative subdivisions of a given entity
     after a change in the form.
     < event: The triggering event.
     < query: The expected subdivisions. */
  filterSubDivisions(event: Event, query: string): void {
    // The superentity (country > state > city) by which to filter data.
    const superEntity = (event.target as HTMLInputElement).value;
    switch (query[1]) {
      case 'e': // Neighborhoods
        this.sector.nbhoods = this.database.nbhoods!.filter((x: string) =>
          x.toLowerCase().includes(superEntity.toLowerCase())
        );
      break;
      case 'i': // Cities
        this.sector.cities = this.database.cities!.filter((x: IntCity) =>
          x.city.toLowerCase().includes(superEntity.toLowerCase())
        );
      break;
      case 't': // States
        this.sector.states = this.database.states!.filter((x: IntState) =>
          x.state.toLowerCase().includes(superEntity.toLowerCase())
        );
      break;
      default:
        this.error = "Inner Logic (filterSubDivisions(2))"
      break;
    }
  }

  /* void getLocationData(3?):
     Set specified location data for fields by event changes.
     < event: The triggering event.
     < query: Expected data to get.
     < arg: List of helping arguments if needed. */
  getLocationData(event: Event | null, query: string, arg: any[] | null = null): void {
    switch (query[1]) {
      case 'e': // Neighborhoods
        if (event != null) { // If the function was called from outside.
          const pc = (event!.target as HTMLInputElement).value.trim();
          if (this.pickupForm.get('country')?.value == 'MX') { // Conditional for future overbuilding
            if (pc.length != 5) return;
            this.userService.getPcData(pc).subscribe((x: any) => {
              if (x.message == "Not found") this.error = "PostCode";
              else {
                this.userPC = x.data;
                this.sector.nbhoods = x.data.neighborhoods;
                this.pickupForm.controls.city.setValue(x.data.city);
                this.pickupForm.controls.state.setValue(x.data.state);
              }
            });
          } else { // Conditional section for future overbuilding.
            this.userService.getIntPcData(pc, arg![0]).subscribe((x: any) => {
              this.userPC = x.data;
              (this.postcoded) ? this.sector.nbhoods = x.data.neighborhoods : this.data.nbhoods = x.data.neighborhoods;
              this.pickupForm.controls.city.setValue(x.data.city);
              this.pickupForm.controls.state.setValue(x.data.state);
            });
          }
        } else { // If the function was called from inside.
          this.userService.getIntSuburb(arg![0].toString(), this.pickupForm.get('country')?.value).subscribe((x: any) => {
            if (x.message == "Not found") this.error = 'Country';
            else this.database.nbhoods = x.data;
          });
        }
      break;
      case 'i': // Cities
        this.database.cities = [];
        this.sector.cities = [];
        this.userService.getIntCity(arg![1], arg![0]).subscribe((x: any) => {
          if (x.message == "Not found") this.error = "PostCode";
          else {
            this.database.cities = x.data;
            this.sector.cities = x.data;
          }
        });
      break;
      case 'o': // Countries
        this.userService.getAddressCountries().subscribe((x: any) => {
          if (x.message == "Not found") this.error = "Country";
          else this.database.countries = x.data;
        });
      break;
      case 't': // States
        this.userService.getIntStates(arg![0]).subscribe((x: any) => {
          if (x.message == "Not found") this.error = "Country";
          else {
            this.database.states = x.data;
            this.sector.states = x.data;
          }
        });
      break;
      default:
        this.error = "Inner Logic (getLocationData(3?))"
      break;
    }
  }

  /* void getCollectableShipments(0):
     Requests the shipments available for collection from the DB and
     format-fills deployable data. */
  getCollectableShipments(): void {
    this.pickupsService.getCollectableShipments().subscribe({
      next: (data: any) => { 
        this.collectableShipments = data;

        // Fill shipments' dropdown list
        this.shipmentsList = [];
        this.collectableShipments.forEach((x: CollectableShipment) => {
          const xaddress: string = `${x.address.street} ${x.address.number}, ${x.address.neighborhood}, ${x.address.postCode} ${x.address.city}, ${x.address.state}`,
                xdate: string = `${('0' + x.creationDate.getDate()).slice(-2)}-${('0' + (x.creationDate.getMonth() + 1)).slice(-2)}-${x.creationDate.getFullYear()}`;

          this.shipmentsList.push([x.trackingNumber, this.idCarrier(x.carrier), (xaddress.length > 60) ? xaddress.slice(0, 57) + "..." : xaddress, xdate]);
        });
      },
      error: (error) => { console.error('Error fetching collectable shipments: ', error); }
    });
  }

  /* void getCarrierData(0):
     Gets data about carriers' pickup schedule and
     sets it as options into the form.
     < init: Boolean to check if it is the initializing consult or another call.  */
  getCarrierData(init: boolean): void {
    // Initialize dropdown list
    if (init) {
      this.pickupsService.getCarrierData().subscribe(
        (result: any) => {
          SCDB = result.map((x: any) => {
            return { 
              ...x,
              name: x.carrier_name,
              abbr: x.key
            };
          });
          SCDB.sort((x, y) => { if (x.name < y.name) return -1; if (x.name > y.name) return 1; return 0; })
          for (const i in SCDB) {
            this.carrierList.push(SCDB[i].name);
            this.carrierAbbrList.push(SCDB[i].abbr);
          }
        },
        (error: any) => { console.log(error); }
      );
      for (const i in SCDB) {
        this.carrierList.push(SCDB[i].name);
        this.carrierAbbrList.push(SCDB[i].abbr);
      }
    // Update dropdown lists
    } else {
      const index: number = this.pickupForm.controls.carrier.value;
      // Clear dropdowns
      this.pckDatesNumValue = [];
      this.pckDatesRange = [];
      this.pckFinalRange = [];
      this.pckInitRange = [];
      if (index >= 0) {
        const avdays: number[] = [], today: Date = new Date();
        let j: number = 0, k: number = 0, pinDay: Date = new Date(), pinDOW: number = today.getDay();

        // Convert base-10 storage to binary data
        for (let i = 0; i < 7; i++)
          if ((SCDB[index].days & (1 << i)) !== 0)
            avdays.push(i);

        // Get available pickup dates
        while (j < SCDB[index].advance) {
          pinDay.setDate(pinDay.getDate() + 1);
          pinDOW = (pinDOW + 1) % 7;
          k++;

          if (avdays.includes(pinDOW)) {
            this.pckDatesRange.push(this.toSpanishDate(pinDay));
            this.pckDatesNumValue.push(k);
            j++;
        } }

        // Update initial pickup time dropdown.
        for (let i = SCDB[index].init_i; i <= SCDB[index].init_f; i++)
          this.pckInitRange.push(i);
        // Update final pickup time dropdown.
        for (let i = SCDB[index].final_i; i <= SCDB[index].final_f; i++)
          this.pckFinalRange.push(i);

        // If initial or final pickup time dropdowns have only one element, set as default
        if (this.pckInitRange.length === 1)
          this.pickupForm.controls.initTime.setValue(this.pckInitRange[0]);
        if (this.pckFinalRange.length === 1)
          this.pickupForm.controls.finalTime.setValue(this.pckFinalRange[0]);
      }
    }
  }

  /* void onChangeClear(1):
     When there is a change in one of the wider entities' fields, clears
     subdivisions' fields.
     < ignoreCityField: Boolean to ignore city field while resetting. */
  onChangeClear(ignoreCityField: boolean = false): void {
    if (!ignoreCityField) this.pickupForm.controls.city.reset();
    this.pickupForm.controls.neighborhood.reset();
  }

  /* void openManifestoDialog(0):
     Opens the Manifest Dialog. */
  openManifestDialog(): void {
    this.manifestExist = true;
    const dref = this.dialog.open(PickupsManifestCreateComponent, {
      data: {
        manifest: this.manifestShipments,
        orders: this.shipmentsList.filter(x => x[1] === this.carrierList[this.pickupForm.controls.carrier.value]),
        selectedDate: this.pickupForm.controls.pckDate.value || 0,
        selectedShipment: this.pickupForm.controls.sid.value + 1 || 0
      },
      panelClass: 'dialogs-lg'
    });

    // Get selected shipments for manifest generation.
    dref.afterClosed().subscribe(x => {
      if (x !== undefined) {
        let w = 0;
        this.manifestShipments = x;
        this.pickupForm.controls.packages.setValue(x.length);

        for (let i = 0; i < this.manifestShipments.length; i++)
          w += this.collectableShipments.find(x => x.trackingNumber === this.manifestShipments[i])?.weight || 0;
        this.pickupForm.controls.weight.setValue(w);
      }
    });
  }

  /* void replaceData(3?):
     Replaces selectable data for filters on selection change.
     < entity: The entity (state, city) changed.
     < current: The new value.
     < countryCode: The state's country code in case of relocation */
  replaceData(entity: string, current: string, countryCode: string | null = null): void {
    const lastValue = this.pickupForm.get(entity)?.value
    if (lastValue != current)
      this.getLocationData(null, (entity == 'state') ? 'cities' : 'neighborhoods', [current, countryCode]);
  }

  /* boolean requestIsReady(0):
     Enables 'send request' button if data is ready.
     > True if the conditions are met. */
  requestIsReady(): boolean {
    const controls: string[] = [
      "city", "email", "finalTime", "initTime", "name",
      "neighborhood", "number", "packages", "postCode",
      "pckDate", "carrier", "state", "street", "weight"
    ]

    return !(
      controls.every(i => {
        const x = this.pickupForm.get(i);
        return x && x.value !== null && x.value !== '';
    }));
  }

  /* void restartData(1):
     Restarts the form's data after a change in country. (Future overbuilding)
     < event: The triggering event. */
  restartData(event: any): void {
    this.sector.country = this.database.countries?.find((x: IntCountry) => x.countryCode === event.value) ?? null;
    // Reset fields
    this.pickupForm.controls['postCode'].reset();
    this.pickupForm.controls['neighborhood'].reset();
    this.pickupForm.controls['city'].reset();
    this.pickupForm.controls['state'].reset();

    if (this.sector.country != null)
      this.postcoded = (this.sector.country.hasPostalCode == 'true');

    if (!this.postcoded) this.getLocationData(null, 'states', [event.value]);
    this.toggleFieldsByPC(this.sector.country || null);
  }

  /* void startClusters(0):
     Initializes the code's clusters to avoid "undefined" on generation. */
  startClusters(): void {
    this.database = {
      cities: null, city: null, countries: null, country: null,
      nbhood: null, nbhoods: null, state: null, states: null
    };
    this.sector = {
      cities: null, city: null, countries: null, country: null,
      nbhood: null, nbhoods: null, state: null, states: null
    };
  }

  /* void throwShipmentToForm(1?):
     Throws the information into the corresponding fields in the form.
     < index: The index of the selected shipment. */
  throwShipmentToForm(index: number | undefined): void {
    if (index !== undefined && index !== -1) {
      const shipment: CollectableShipment = this.collectableShipments[index];

      const keys: string[] = ['city', 'email', 'number', 'phone', 'reference', 'rfc', 'state', 'street'];

      // Set post code and neighborhood before to avoid looping.
      this.pickupForm.controls.postCode.setValue(shipment.address.postCode);
      this.pickupForm.controls.neighborhood.setValue(shipment.address.neighborhood);

      // Set all remaining variables from origin address.
      for (const i of keys) {
        const ii = i as keyof CollectableShipment['address']; // Type assertion to overrule 'any' typing errors
        this.pickupForm.controls[i].setValue(shipment.address[ii]);
      }

      // Set name and company.
      this.pickupForm.controls.name.setValue(shipment.name);
      this.pickupForm.controls.company.setValue(shipment.company);

      // Set carrier data.
      this.pickupForm.controls.carrier.setValue(SCDB.findIndex(
        x => x.name.toLocaleLowerCase() === shipment.carrier.toLocaleLowerCase()
      ));
      this.getCarrierData(false);

      // Set packages and weight
      this.pickupForm.controls.packages.setValue(1);
      this.pickupForm.controls.weight.setValue(shipment.weight);

      // Update manifest list
      this.manifestShipments = [this.shipmentsList[index][0]];

      // Lock carrier selection
      this.lockCarrierSelect = true;
    } else if (index === -1) {
      // Update manifest list
      const x: CollectableShipment | null = this.importedShipment;

      if (x) { // If there's a value to be read.
        // Check if it is already in the list
        const csi: number = this.collectableShipments.findIndex(i => i.trackingNumber === x.trackingNumber); 
        
        if (csi !== -1) // If it is, do normal procedure.
          this.throwShipmentToForm(csi);
        else {
          const keys: string[] = ['city', 'email', 'number', 'phone', 'reference', 'rfc', 'state', 'street'];

          // Set post code and neighborhood apart due to name difference and before to avoid looping.
          this.pickupForm.controls.postCode.setValue(x.address.postCode);
          this.pickupForm.controls.neighborhood.setValue(x.address.neighborhood);

          // Set all remaining variables from origin address.
          for (const i of keys) {
            const ii = i as keyof CollectableShipment['address']; // Type assertion to overrule 'any' typing errors
            this.pickupForm.controls[i].setValue(x.address[ii]);
          }

          // Set name and company.
          this.pickupForm.controls.name.setValue(x.name);
          this.pickupForm.controls.company.setValue(x.company);

          // Set carrier data
          this.pickupForm.controls.carrier.setValue(SCDB.findIndex(
            y => y.name.toLocaleLowerCase() === x.carrier.toLocaleLowerCase()
          ));
          this.getCarrierData(false);

          // Set packages and weight
          this.pickupForm.controls.packages.setValue(1);
          this.pickupForm.controls.weight.setValue(x.weight);

          this.manifestShipments.push(x.trackingNumber);

          // Lock carrier selection
          this.lockCarrierSelect = true;
      }}
    } else {
      const keys: string[] = ['postCode', 'city', 'company', 'email', 'name', 'number', 'neighborhood',
        'packages', 'phone', 'reference', 'rfc', 'state', 'street', 'weight'];

      for (const i of keys) 
        this.pickupForm.controls[i].setValue('');

      // Update manifest list
      this.manifestShipments = [];
      this.manifestExist = false;

      // Unlock carrier selection
      this.lockCarrierSelect = false;
    }
  }

  /* void toggleFieldsByPC(1?):
     Toggles fields in the form based on country's post code availability.
     < country: The country in which the decision is based. */
  toggleFieldsByPC(country: IntCountry | null): void {
    const currCity = this.pickupForm.get('city'),
          currState = this.pickupForm.get('state');

    if (this.postcoded) {
      if (country && country.countryCode.toLocaleLowerCase() == 'mx') {
        currCity?.disable();
        currState?.disable();
      } else {
        currCity?.enable();
        currState?.enable();
      }
      this.pickupForm.controls.postCode.setValue('');
    } else {
      currCity?.enable();
      currState?.enable();
      this.pickupForm.controls.postCode.setValue('00000');
    }
  }

  /* boolean tooSoon(0):
     True if the value of final time for pickup selected is not
     at least four hours apart of the selected initial time.
     < x: The input number. */
  tooSoon(x: number): boolean {
    return this.pickupForm.controls.initTime.value && this.pickupForm.controls.initTime.value > (x - 4);
  }

  /* string toTime(1):
     Formats an integer to 12H time string for display.
     < x: The input number.
     > The formatted output. */
  toTime(x: number): string {
    return `${x % 12 === 0 ? 12 : x % 12}:00 ${x >= 12 ? 'PM' : 'AM'}`;
  }


  /* async Promise<void> sendPickupRequest(0):
     Sends the request to the specified carrier, date, times,
     and address. Then, opens the pickup-checkout dialog. */
  async sendPickupRequest(): Promise<void> {
    const dateHelper = new Date(), rawManifest: any[] = [];

    // Set loading animation
    this.requestLoading = true;

    // Set request date
    dateHelper.setDate(dateHelper.getDate() + this.pickupForm.controls.pckDate.value);
    dateHelper.setHours(0, 0, 0, 0);

    // Send pickup request
    const requestData = {
      address: {
        city: this.pickupForm.controls.city.value,
        neighborhood: this.pickupForm.controls.neighborhood.value,
        number: this.pickupForm.controls.number.value,
        postCode: this.pickupForm.controls.postCode.value,
        state: this.pickupForm.controls.state.value,
        street: this.pickupForm.controls.street.value
      },
      date: dateHelper,
      destination: [] as Array<any>,
      email: this.pickupForm.controls.email.value,
      finalTime: this.pickupForm.controls.finalTime.value,
      initTime: this.pickupForm.controls.initTime.value,
      name: this.pickupForm.controls.name.value,
      packages: this.pickupForm.controls.packages.value,
      phone: this.pickupForm.controls.phone.value,
      shipments: this.manifestShipments,
      weight: this.pickupForm.controls.weight.value
    };

    // Set additional data requested by Estafeta
    if (this.carrierList[this.pickupForm.controls.carrier.value] === "Estafeta") {
      let shipment: CollectableShipment | undefined;

      // Change type to array to receive multiple shipments' info
      requestData.packages = [];

      for (const n of this.manifestShipments) {
        // Find the shipment's info
        shipment = this.collectableShipments.find(x => x.trackingNumber === n);

        if (shipment) {
          // Push destination address
          requestData.destination.push({
            city: shipment.destination.city,
            neighborhood: shipment.destination.neighborhood,
            number: shipment.destination.number,
            postCode: shipment.destination.postCode,
            state: shipment.destination.state,
            street: shipment.destination.street
          });

          // Push package's data
          requestData.packages.push({ 
            height: shipment.package.height,
            length: shipment.package.length,
            weight: shipment.weight,
            width: shipment.package.width
          });
        } else {
          throw new Error(`Undefined or untraced shipment with tracking number: ${n}`)
        }
      }
    }

    const req: any = await this.pickupsService.requestPickup(requestData, this.carrierList[this.pickupForm.controls.carrier.value]);

    // Splice address
    const fulladdr = `${ this.pickupForm.controls.street.value } 
                      ${ this.pickupForm.controls.number.value }, 
                      ${ this.pickupForm.controls.neighborhood.value },
                      ${ this.pickupForm.controls.postCode.value } 
                      ${ this.pickupForm.controls.city.value }, 
                      ${ this.pickupForm.controls.state.value }`;

    // Prepare data for manifest
    for (const i of this.manifestShipments) {
      const j = this.collectableShipments.find(x => x.trackingNumber === i);
      rawManifest.push([0, j!.shipmentID || "#N/D", j!.carrier.toUpperCase(), j!.trackingNumber]);
    }

    // Stop loading animation
    this.requestLoading = false;

    // Open checkout dialog
    const coDialogRef = this.dialog.open(PickupsCheckoutComponent, {
      data: {
        address: fulladdr.replace(/\s\s+/g, ' '),
        carrier: this.carrierList[this.pickupForm.controls.carrier.value],
        confirmation: req.data,
        date: dateHelper,
        formattedDate: `${ this.pckDatesRange[this.pckDatesNumValue.indexOf(this.pickupForm.controls.pckDate.value)] }, ${ dateHelper.getFullYear() }`,
        fee: this.fee,
        finalTime: this.pickupForm.controls.finalTime.value,
        initTime: this.pickupForm.controls.initTime.value,
        manifest: rawManifest,
        packages: this.pickupForm.controls.packages.value,
        status: (req.status == 200),
        user: this.user.id,
        weight: this.pickupForm.controls.weight.value
      },
      panelClass: 'dialogs-main'
    });

    // If successful process, redirect to list
    if (req.status == 200)
      coDialogRef.afterClosed().subscribe(() => { this.router.navigate(['/admin/pickups']); });
  }


  /* string idCarriers(0):
     Renames carriers in imported shipments to fit carriers' name.
     < input: The carrier's name.
     > carrier: The identified carrier's name. */
  private idCarrier(input: string): string {
    if (input.substring(0,3) === 'DHL') return 'DHL';
    else if (input.toLowerCase() === 'paquetexpress') return 'Paquetexpress';
    else if (input.toLowerCase().substring(0,5) === 'fedex') return 'FedEx';
    else if (input.toLowerCase().substring(0,5) === 'estaf') return 'Estafeta';
    else return input;
  }

  /* string toSpanishDate(1):
   Formats a date element to Spanish long format for dropdown menus.
   < date: The input date.
   > The resulting formatted string. */
  private toSpanishDate(date: Date): string {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
          months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
                    'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    return `${days[date.getDay()]}, ${date.getDate()} de ${months[date.getMonth()]}`;
  }
}
