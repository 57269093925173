import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
type AOA = any[][];


@Component({
  selector: 'app-bulk-upload-add',
  templateUrl: './bulk-upload-add.component.html',
  styleUrls: ['./bulk-upload-add.component.scss']
})
export class BulkUploadAddComponent implements OnInit {
  dataAOA: AOA = [];
  jsonData : any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  //fileName: string = 'SheetJS.xlsx';
  
  id_user!: number;
  formAdd!: FormGroup;
  fileName : string = '';

  get bulkUploadData():any { return this.userS.sharedBulkUpload; } set bulkUploadData(value: any) { this.userS.sharedBulkUpload = value; }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<BulkUploadAddComponent>,
    private matDialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    //this.id_user = this.data.userSel.id;
    this.createFormUser();
  }


  createFormUser() {
    this.formAdd = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
      email_notification: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],
      file: ['', []],
      file_name: ['', []],
      rows: ['', []]
    });
  }


  ruSure() {
    if(this.formAdd.invalid) { return; }
    
    if(this.jsonData.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Error al guardar',
        text: 'No se encontró ningún dato para hacer la carga masiva, por favor sube una plantilla con datos.'
      });
    }else{
      Swal.fire({
        title: '¿Estás seguro que deseas realizar la carga masiva con el archivo seleccionado?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.saveForm();
        }
      });
    }

  }

  saveForm() {
    // this.dialogRef.close({ reload: false });
    // this.bulkUploadData = this.jsonData;
    // this.router.navigateByUrl('/admin/bulk-upload', { skipLocationChange: true });
    
    this.formAdd.get('rows')?.setValue(this.jsonData);
    this.formAdd.get('file_name')?.setValue(this.fileName);
    //console.log(this.formAdd.value);
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.userS.saveBulkUpload(this.formAdd.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Se han importado de manera correcta los datos del excel!'
          }).then(() => {            
            this.dialogRef.close({ reload: false, id: result.id });
            //this.dialogRef.close({ reload: false, credit: (this.formC.value.credit*1) + (this.formC.value.add_credit*1) });
          });
        }
      },
      (err:any) => {        
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al importar, por favor vuelve a intentarlo.'
          });
      }
    );
  }



  onFileSelected(event: any) {
    const archivo = event.target.files[0];
    
    const tipoPermitido = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']; // MIME para Excel
    const tamanioKb = 2048; // 1024=1 mb
    const tamanioMaximo = tamanioKb * 1024;
  
    if (!tipoPermitido.includes(archivo.type)) {
      // error: Tipo de archivo no válido
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El tipo de archivo no es válido.'
      });
      return;
    }
  
    if (archivo.size > tamanioMaximo) {
      // error: Tamaño de archivo excedido
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `El tamaño del archivo excede el límite de ${((tamanioMaximo/1024)/1024).toFixed(2)} Mb.`
      });
      return;
    }
  
    const file:File = event.target.files[0];
    if (file) {
      console.log(this.fileName = file.name);
      this.onFileChange(event);
      // const formData = new FormData();
      // formData.append("thumbnail", file);
      // const upload$ = this.http.post("/api/thumbnail-upload", formData);
      // upload$.subscribe();
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.dataAOA = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      
      //console.log(this.dataAOA);


      const headers: string[] = this.dataAOA[0];
      this.jsonData = [];
      this.jsonData = this.dataAOA.slice(1).map((row: any[], index: number) => {
        const obj: any = {};
        obj["ORDER"] = index + 1; //Columna "order" incremental
        headers.forEach((header: string, columnIndex: number) => {
          obj[header] = row[columnIndex] || "";
        });
        obj["STATUS"] = "loaded"; //Columna "status" por default "cargado"
    
        return obj;
      });
      //console.log(this.jsonData);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  abrirSeleccionArchivo() {
    const archivoInput = document.querySelector('.hidden-input') as HTMLInputElement;
    archivoInput.click();
  }
  
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  
  getValues(obj: any): any[] {
    return Object.values(obj);
  }


  
}
