<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>Cotización rápida</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a class="wing-icon" href="index.html">
            <svg viewBox="-2 -2 72 72" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" overflow="hidden">
              <defs><clipPath id="clip0">
                  <rect x="1432" y="894" width="69" height="69"/>
              </clipPath></defs>
              <g clip-path="url(#clip0)" transform="translate(-1432 -894)">
                <path d="M1469.95 918.15 1476.85 918.15 1476.85 921.6 1483.75 921.6 1483.75 928.5 1469.95 928.5 1469.95 931.95 1480.3 931.95C1481.28 931.95 1482.1 932.281 1482.76 932.942 1483.42 933.603 1483.75 934.422 1483.75 935.4L1483.75 945.75C1483.75 946.727 1483.42 947.547 1482.76 948.208 1482.1 948.869 1481.28 949.2 1480.3 949.2L1476.85 949.2 1476.85 952.65 1469.95 952.65 1469.95 949.2 1463.05 949.2 1463.05 942.3 1476.85 942.3 1476.85 938.85 1466.5 938.85C1465.53 938.85 1464.71 938.519 1464.05 937.858 1463.38 937.197 1463.05 936.377 1463.05 935.4L1463.05 925.05C1463.05 924.073 1463.38 923.253 1464.05 922.592 1464.71 921.931 1465.53 921.6 1466.5 921.6L1469.95 921.6ZM1451.71 894 1448.83 916.25 1462.61 916.209 1442.9 944.805 1445.77 922.555 1432 922.597ZM1456.96 894 1480.3 894 1501 914.7 1501 956.1C1501 957.997 1500.32 959.622 1498.97 960.973 1497.62 962.324 1496 963 1494.1 963L1452.7 963C1450.81 963 1449.18 962.324 1447.83 960.973 1446.48 959.622 1445.81 957.997 1445.81 956.1L1445.81 949.743 1452.7 939.733 1452.7 956.1 1494.1 956.1 1494.1 914.7 1476.85 914.7 1476.85 900.9 1456.07 900.9Z"
                     fill="#9F19FF" fill-rule="evenodd"/>
              </g>
            </svg>
          </a></li>
          <li class="breadcrumb-item">Cotización rápida</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <form [formGroup]="qqForm">
      <div class="card">
        <div class="card-body">
          <div class="row mt-3">
            <h4>Datos de envío:</h4>
          </div>
          <div class="row">
            <div class="col-md-5 col-sm-6 col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Código postal de origen</mat-label>
                <input matInput formControlName="originPC">
              </mat-form-field>
            </div>
            <div class="col-sm-2" id="littletruck">
              <img src="../../../assets/images/truck_speed.png" />
            </div>
            <div class="col-md-5 col-sm-6 col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Código postal de destino</mat-label>
                <input matInput formControlName="destPC">
              </mat-form-field>
            </div>
          </div>
          <hr style="border-top: dotted 2px;" />
          <div class="row">
            <h4>Paquete:</h4>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-6 col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Largo</mat-label>
                <input matInput formControlName="length">
                <div matSuffix class="forced-suffix">cm</div>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Ancho</mat-label>
                <input matInput formControlName="width">
                <div matSuffix class="forced-suffix">cm</div>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Altura</mat-label>
                <input matInput formControlName="height">
                <div matSuffix class="forced-suffix">cm</div>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Peso</mat-label>
                <input matInput formControlName="weight">
                <div matSuffix class="forced-suffix">kg</div>
              </mat-form-field>
            </div>
          </div>
          <div class="request-btncont">
            <button mat-flat-button class="request-btn mr-1" [disabled]="inProgress || invalidForm()" (click)="requestQQ()">
              <ng-container *ngIf="inProgress"><div class="request-spinner"><mat-spinner diameter="36"></mat-spinner></div></ng-container>
              <ng-container *ngIf="!inProgress">Cotizar</ng-container>
            </button>
          </div>
          <div class="row my-3" style="justify-content: center;" *ngIf="requestSuccessful">
            <div class="qq-table-container" id="hr-table" #hrtable>
              <table class="qq-table" [ngStyle]="{ 'justify-content': overflown ? 'flex-start' : 'center' }">
                <tbody>
                  <tr class="img-row"><td *ngFor="let x of quotesh.carriers, let i = index" (click)="select(i)" [ngClass]="{ 'selected' : selected(i) }">
                    <img src="../../../assets/images/carriers/{{ imgFormat(x) }}-sm.jpg" alt="{{ imgFormat(x) }}" />
                  </td></tr>
                  <tr><td *ngFor="let x of quotesh.servicetypes, let i = index" (click)="select(i)" [ngClass]="{ 'selected' : selected(i) }">
                    <p *ngIf="x && tooLong(x); else regularp" matTooltip="{{ x }}">{{ x }}</p>
                    <ng-template #regularp><p>{{ x }}</p></ng-template>
                  </td></tr>
                  <tr><td *ngFor="let x of quotesh.costs, let i = index" (click)="select(i)" [ngClass]="{ 'selected' : selected(i) }"><p><b>{{ x }}</b></p></td></tr>
                </tbody>
              </table>
            </div>
            <div class="qq-vtable-container" id="vr-table">
              <table class="qq-vtable">
                <tbody>
                  <tr *ngFor="let x of quotesv, let i = index" (click)="select(i)" [ngClass]="{ 'selected' : selected(i) }">
                    <td class="qq-vtable-col1">
                      <img src="../../../assets/images/carriers/{{ imgFormat(x.carrier) }}-sm.jpg" alt="{{ imgFormat(x.carrier) }}" />
                      <small *ngIf="x.servicetype">{{ x.servicetype }}</small>
                    </td>
                    <td class="qq-vtable-col2"><b>{{ x.cost }}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row my-3" style="justify-content: center;" *ngIf="requestGenerated && !requestSuccessful">
            <div class="note-block">
              <p class="note"> No se obtuvieron resultados para los valores utilizados. ¿Intentamos con otros valores? </p>
            </div>
          </div>
          <ng-container *ngIf="requestSuccessful && !invalidForm()">
            <div class="request-btncont mb-3">
              <button mat-flat-button class="request-btn ml-1" [disabled]="inProgress || invalidForm()" (click)="makeOfficial()">
                Crear envío
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</div>