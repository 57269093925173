<div>
  <div class="row justify-content-between me-1">
    <div class="col">
      <p class="text-muted">
        Envíos totales del año actual: <span class="font-success">{{totalShipments | number }}</span>
      </p>
    </div>
    <div class="col">
      <div class="row justify-content-end">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon mat-icon-button>tune</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <div
            *ngFor="let series of allSeries"
            (click)="$event.stopPropagation()"
          >
            <mat-checkbox
              color="primary"
              [checked]="seriesVisibility[series.name!]"
              (change)="toggleSeries(series.name!, $event.checked)"
            >
              {{ series.name }}
            </mat-checkbox>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [fill]="chartOptions.fill"
      [tooltip]="chartOptions.tooltip"
      [stroke]="chartOptions.stroke"
      [plotOptions]="chartOptions.plotOptions"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
    >
    </apx-chart>
  </div>
</div>
