<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Histórico de créditos </h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>request_quote</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Reporte - Histórico de créditos</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="false" class="row">
                        <h4>Selecciona fechas y da click en "Buscar" para mostrar la información</h4>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fechas</mat-label>
                                <mat-select [formControl]="selFechas" (selectionChange)="onchangeFechas($event)">
                                    <mat-option value="1">Hoy</mat-option>
                                    <mat-option value="2">Este mes</mat-option>
                                    <mat-option value="3">Elegir fechas</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha inicio</mat-label>
                                <input name="pickerI" (ngModelChange)="onchangedateI($event)" matInput [matDatepicker]="picker" [min]="minDateI" [max]="maxDateI" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayI">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha fin</mat-label>
                                <input name="pickerF" (ngModelChange)="onchangedateF($event)" matInput [matDatepicker]="pickerf" [min]="minDateF" [max]="maxDateF" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayF">
                                <mat-datepicker-toggle matSuffix [for]="pickerf"></mat-datepicker-toggle>
                                <mat-datepicker #pickerf [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': showOptions}"  aria-labelledby="customDropdownButton"> 
                                <div class="dropdown-content" style=" max-height: 300px; overflow-y: auto;">
                                    <h5 class="dropdown-header d-flex justify-content-between align-items-center" style="margin: 0px;">Columnas  
                                        <button  mat-icon-button (click)="toggleOptions()"  matTooltip="Cerrar" [matTooltipPosition]="'above'" color="link" class="">
                                            <mat-icon>close</mat-icon>
                                        </button> 
                                    </h5>
                                    <div class="dropdown-item">
                                      <p>Seleccione las columnas para mostrar.</p>
                                      <ul>
                                          <li *ngFor="let column of columnDefinitions">
                                              <mat-checkbox  [checked]="!column.hide" (change)="toggleColumn(column.def, $event)" class="d-flex align-items-center mb-2">
                                                  {{ column.label }}
                                              </mat-checkbox>
                                          </li>
                                      </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="primary" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    <div [hidden]="preLoadHidden">
                        <div class="overflow-auto w-100 mb-2" style="display: flex; justify-content: space-between;">
                            <div><mat-form-field>
                                <mat-label><mat-icon>search</mat-icon> Buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                            </mat-form-field></div>
                            <div>
                                <button mat-stroked-button class="btn btn-primary mt-2 me-2 mb-1 ch-updatebtn" matTooltip="Filtrar resultados" [matTooltipShowDelay]="1000" (click)="searchFilter()"><mat-icon class="ch-spinner">sync</mat-icon><span class="btn-no-text"> Actualizar tabla</span></button>
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive">
                            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear class="w-100">
                    
                                <ng-container matColumnDef="created_at">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('created_at')}} </th>
                                    <td class="col-2 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.created_at?row.created_at.slice(0,16).replace('T','  '):''}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
            
                                <ng-container matColumnDefidclabe="id_company">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="id_company">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_company')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_company}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="amount">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('amount')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.amount | currency}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="previous_credit">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('previous_credit')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.previous_credit}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="observations">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('observations')}} </th>
                                    <td class="col-3 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{(row.id_stp_collection?(row.reference_number+' - '+row.tracking_key):row.observations)}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="id_user_creator">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_user_creator')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_user_creator}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="id_stp_collection">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_stp_collection')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_stp_collection}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="company_name">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('company_name')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.company_name}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="transaction_date">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('transaction_date')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.transaction_date}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="tracking_key">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('tracking_key')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row">
                                        <ng-container *ngIf="row.url_cep && row.url_cep !== ''; else ctComment">
                                            <a href="{{row.url_cep}}" class="ch-tracking" matTooltip="Ir a Banxico" [matTooltipPosition]="'above'" target="_blank">
                                                {{ row.tracking_key }}
                                            </a>
                                        </ng-container>
                                        <ng-template #ctComment>
                                            <span style="color: lightgray">* {{ (row.id_stp_collection && row.reference_number && row.reference_number.toString().trim() !== '') ? row.tracking_key : row.observations }}</span>
                                        </ng-template>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="beneficiary_account">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('beneficiary_account')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.beneficiary_account}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="concept_payment">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('concept_payment')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.concept_payment}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="reference_number">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('reference_number')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.reference_number}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="added_credit">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('added_credit')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.added_credit}} 
                                        <a mat-icon-button >
                                            <mat-icon *ngIf="row.added_credit==1" class="text-success" mat-icon-button matTooltip="Crédito abonado a la compañía" [matTooltipPosition]="'above'">paid</mat-icon>
                                        </a>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="conciliation">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('conciliation')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.conciliation}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="url_cep">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('url_cep')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.url_cep}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="conciliation_date">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('conciliation_date')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.conciliation_date}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('status')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.status}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="status_group">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('status_group')}} </th>
                                    <td class="col-1" mat-cell *matCellDef="let row">
                                        <mat-icon [ngStyle]="{'color': icon(row.status_group, 'color')}"
                                                  [matTooltip]="icon(row.status_group, 'tooltip')" [matTooltipPosition]="'above'">
                                            {{icon(row.status_group)}}
                                    </mat-icon></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="email">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('email')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.email}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="nombre">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('nombre')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
            
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> {{getDisplayedLabel('actions')}} </th>
                                    <td class="col-sm-3 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row">
                                        <a mat-icon-button >
                                            <span *ngIf="row.status!=''" [ngClass]="{'badge': true , 'bg-warning': row.status!='CCO' && row.id_stp_collection<0, 'bg-dark': row.status!='CCO', 'bg-primary': row.status=='CCO' }" mat-icon-button matTooltip="{{statusName(row.status)}}" [matTooltipPosition]="'above'">{{row.status}}</span>
                                            <mat-icon *ngIf="row.conciliation==1" class="text-primary" mat-icon-button matTooltip="Conciliado" [matTooltipPosition]="'above'">receipt_long</mat-icon>
                                            <mat-icon *ngIf="row.added_credit==1 || !row.id_stp_collection" class="text-success" mat-icon-button matTooltip="Crédito abonado a la compañía" [matTooltipPosition]="'above'">paid</mat-icon>
                                        </a>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                
                    
                                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" ></tr>
                                <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()"></tr>
                    
                                <tr class="mat-row" *matNoDataRow>
                                    <td *ngIf="ordersDataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                    <td *ngIf="ordersDataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="getPageSizeOptions()" pageSize="100" ></mat-paginator>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>