export class ShipmentModel {
    name!: string;
    company!: string;
    email!: string;
    phone!: string;
    addressName!: string;
    street!: string;
    number!: string;
    suburb!: string;
    city!: string;
    state!: string;
    country!: string;
    postalCode!: string;
}

export class packagesModel {
    width!:number;
    heigth!:number;
    lenght!:number;
    weight!:number;
    content!:string;
    quantity!:number;
    shipmentType!:string;
}

export class globalObject {
    origin!:{
        city:string;
        state:string;
    };
    destination!:{
        email: string;
        city:string;
        state:string;
    };
    packages!:[packagesModel];
    shipmentId!: string;
    isEcommerce?:boolean = false;
    ecommerceOrderId?:string;
    selected_rate!:CarriersModel|string;
    orderNumber!:string;
}

export class ShipmentSaveModel {
    id_ship!:string;
    trackingNumber!:string;
    to_address!:string;
    dest_country!:string;
    packages!:string;
    carrier!:string;
    service!:string;
    shipmentType!:string;
    createdAt!:string;
    order_price!:string;
    status!:string;
}

export class CarriersModel { 
    carrier!:string;
    price!:number;
    total_price!:number;
    service!:string;
    currency!:number;
    id_rate!:string;
    source!:string;
    enough!:boolean;
    isZoneExt!:boolean;
}

export class FavShipModel {
    id!: string;
    to_address!: ShipmentModel;
    from_address!: ShipmentModel;
    packages!: [packagesModel];
    selected_rate!:CarriersModel;
    alias!: string;
}

export class BillingDataModel {
    id!:number;
    id_usuario!:number;
    user_name!:string;
    id_company!:number;
    company_name!:string;
    shipment_id!:number;
    shipment_type!:string;
    order_number!:string;
    carrier_found!:string;
    carrier_service!:string;
    service!:string;
    provider!:string;
    total_price!:string;
    service_price!:string;
    currency!:string;
    tracking_number!:string;
    weight!:string;
    weight_units!:string;
    package_dimensions!:string;
    created_at!:string;
    ext_client_name!:string;
    dest_country!:string;
    extended_zone!:string;
    
carrier!:string;
other_charge_price?:number;

xSource?:string;
xCarrier?:string;
xService?:string;
xWeight?:number;
xPrice?:number;
xTotal_price?:number;
xDestination_address?:string;
xDestination_name?:string;
xCreated_at?:string;
xTracking_number?:string;
xTracking_number_a?:string;
xContent?:string;
xMother_guide?:number;
xStatus?:string;

xcPartnerId?: string;
xcPaqueteria?: string;
xcModo?: string;
xcKg?: number;
xcKgText?: string;
xcZona?: string;
xcProveedor?: string;
xcExtra?: string;
xcCosto?: number;
xcPrecioVenta?: number;
xcPrecioVentaPacto?: number;
xcMoneda?: string;
xcCountry?: string;
xcClient?: string;
  segmento?: string;

xcPesoRegistrado?:number;
xcCostoCobrado?:number;
}

export class ServicesModeModel {
    id!:number;
    provider!:string;
    carrier!:string;
    service!:string;
    id_service_mode!:number;
    service_mode!:string;
}

export class EquivalentCarSer {
    services_equiv_det!:number;
    id_service_equiv!:number;
    equiv_name!:string;
    id_service!:number;
    service!:string;
    carrier_dict!:string;
    provider!:string;
    id_carrier!:number;
    carrier_cat!:string;
}

export class DashboardModel {
	total_shipments!:number;
	total_shipments_paid!:number;
	shipments_by_service!:any;
	shipments_average!:number;
	weight_average!:number;
    last_shipments!:any;
    total_favs!:number;

    constructor(){
        this.total_shipments = 0;
        this.total_shipments_paid = 0;
        this.shipments_average = 0;
        this.weight_average = 0;
        this.total_favs = 0;
    }
}

export class ShipmentUnsuccessfully {
	log_id!:number;
	order_number!:string;
	id_company!:number;
    company_name!:string;
	records!:number;
    last_created_at!:string;
}


export class LogOrderNumberModel {
	logger_id!:number;
	log_id!:number;
	info!:any;
	warnings!:any;
	errors!:any;
    created_at!:string;
}



export class IntCountry {
	//id!:number;
	country!:string;
	countryCode!:string;
	hasPostalCode!:string;
}

export class IntState {
	countryCode!:string;
	state!:string;
}

export class IntCity {
	//id!:string;
	city!:string;
}

export interface Pickup {
    address: string;
    boxes: number;
    carrier: string;
    confirmationID: string;
    creationDate: Date;
    date: Date;
    endTime: number;
    fee: number;
    id: number;
    manifestURL: string | null;
    startTime: number;
    textCDate: string;
    textDate: string;
    user: string;
    weight: number;
}

export interface WarehousePickupReceipt {
    email: string | null;
    ID: number;
    name: string;
    phone: string | null;
    photoURL: string;
    registryDate: Date;
    shipmentID: number;
    textRegDate: string;
    trackingNumber: string;
}

export interface CollectableShipment {
    address: {
        city: string;
        country: string;
        email: string;
        neighborhood: string;
        number: string;
        phone: string;
        postCode: string;
        reference: string;
        rfc: string;
        state: string;
        street: string;
    };
    carrier: string;
    company: string;
    creationDate: Date;
    destination: {
        city: string;
        country: string;
        neighborhood: string;
        number: string;
        phone: string;
        postCode: string;
        state: string;
        street: string;
    }
    id: number;
    name: string;
    shipmentID: string;
    package: {
        height: number;
        length: number;
        width: number;
    };
    trackingNumber: string;
    weight: number;
}

export interface CarrierData {
    abbr: string;
    advance: number;
    days: number;
    final_f: number;
    final_i: number;
    init_f: number;
    init_i: number;
    name: string;
}

export interface Geocluster {
    cities: IntCity[] | null;
    city: IntCity | null;
    countries: IntCountry[] | null;
    country: IntCountry | null;
    nbhood: string | null;
    nbhoods: string[] | null;
    state: IntState | null;
    states: IntState[] | null;
  }

 export interface dShipmentModel {
    id: number;
    order_id: string;
    tracking_number: string;
    to_address: string;
    carrier: string;
    service: string;
    created_at: string;
    status: string | null;
    delivery_date: string;
  }