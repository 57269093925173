import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BillingDataModel, ServicesModeModel } from 'src/app/models/shipment.model';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-price-comparison-nofound',
  templateUrl: './price-comparison-nofound.component.html',
  styleUrls: ['./price-comparison-nofound.component.scss']
})
export class PriceComparisonNofoundComponent implements OnInit {
  billingDataList =  new Array<BillingDataModel>();
  okCurrency: any;
  isLoading = true;
  base64!:string;
  servicesModeList =  new Array<ServicesModeModel>();
  
  dateNow : Date = new Date();

  showFilter=false;
  preLoadHidden = true;
  
 columnDefinitions = [
    { def:'xTracking_number', label: 'Tracking number', hide: false},
    { def:'xCreated_at', label: 'Creación', hide: false},
    { def:'xSource', label: 'Proveedor', hide: false},
    { def:'xCarrier', label: 'Paquetería', hide: false},
    { def:'xService', label: 'Servicio', hide: false},
    { def:'xWeight', label: 'Peso', hide: false},
    { def:'xContent', label: 'Contenido', hide: false},
    { def:'xDestination_address', label: 'Destino', hide: false},
    { def:'xPrice', label: 'Precio', hide: false},
    { def:'xTotal_price', label: 'Precio total', hide: false},
    { def:'xDestination_name', label: 'Destinatario', hide: false},
  ]
  showOptions = false;
  getDisplayedColumns():string[] {
    return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
  }

  getDisplayedLabel(def:string):string {
    let cLabel=this.columnDefinitions.find(item=> item.def==def);
    return cLabel?.label||''
  }
  
  dataSource!: MatTableDataSource<BillingDataModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.showFilter=true;
    this.isLoading = true; 
    console.log('not found trackings');
    this.getBillingDataList();
  }

  getBillingDataList() {
    console.log('getBillingDataList');

        this.billingDataList = this.data;
        this.dataSource = new MatTableDataSource(this.billingDataList);        
        this.dataSource.paginator = this.paginator;
        //this.dataSource.paginator.pageSize=this.billingDataList.length;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.preLoadHidden = false;
 
        //--GROUP BY de no encontrados----
        let combinacionesUnicas: { [clave: string]: BillingDataModel } = {};
        this.billingDataList.forEach(item => {
            let clave = `${item.xSource||item.provider}-${item.xCarrier||item.carrier}-${item.xService||item.service}`;
            // Almacenamos la combinación en el objeto si no existe ya
            if (!combinacionesUnicas[clave]) {
                combinacionesUnicas[clave] = item;
            }
        });
        let resultado = Object.values(combinacionesUnicas);
        
        console.log(resultado);
        resultado.forEach(item => {
            let clave = `${item.xSource||item.provider}-${item.xCarrier||item.carrier}-${item.xService||item.service}`;
            console.log(clave);
        });

  }

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  getServicesMode() {
    this.userS.getListServicesMode().subscribe(
      (result : any) => {
        this.servicesModeList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  
  

  getPageSizeOptions(): number[]{
    if(this.billingDataList.length>10)
    return [10,50,100,this.billingDataList.length];
    else
    return [10,50,100];
  }
  
  getTotalService() {
    return (this.billingDataList.map(t => t.service_price).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
  }

  getTotalPrice() {
    return (this.billingDataList.map(t => t.total_price).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
  }
  
  toggleColumn(columnName: string, event: MatCheckboxChange) {
    //console.log(columnName);
    let columnHS=this.columnDefinitions.find( item=> item.def==columnName);
    if(columnHS){
      columnHS.hide=!event.checked;
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  getAddress(data:string) {
    try {
      const address = JSON.parse(data);
      return `${address.name}, ${address.street}, ${address.number}, ${address.suburb}, ${address.postalCode}, ${address.city}`;
    } catch (error) {
      return ''; 
    }
  }

  setNameXls() {
    let pre= this.billingDataList.length>0?(this.billingDataList[0].xTracking_number?'Facturas ':'Costos '):'';//this.selClient.value == "0"?'':' '+this.selClient.value;
    let monthShort=this.dateNow.toLocaleString('default', { month: 'short' });
    let year=` ${this.dateNow.getFullYear()}`;
    let sCur=this.billingDataList.length;
    
    let res=`${pre}no encontrad${pre=='Facturas'?'as':'os'} ${sCur} ${monthShort}${year}`;

    return res;
  }
  

  
}
