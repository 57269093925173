<div class="modal-header">
    <h1 class="modal-title">{{ billingDataList.length>0?(billingDataList[0].xTracking_number?'Facturas':'Costos'):'' }} No encontrad{{ billingDataList.length>0?(billingDataList[0].xTracking_number?'as':'os'):'' }} ({{billingDataList.length}})
        <button (click)="exporter.exportTable('xlsx', {fileName: setNameXls() })" mat-stroked-button class="btn btn-outline-success mt-2 me-2 mb-1" matTooltip="Exportar a Excel" [matTooltipShowDelay]="1000" [hidden]="preLoadHidden">
            <mat-icon>download</mat-icon><span class="btn-no-text"> Descargar Excel </span>
        </button> 
    </h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h4>Guías del archivo de Excel que no encontraron coincidencia con nuestra base de datos. </h4>
    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
        <mat-progress-bar color="primary" mode="indeterminate">
        </mat-progress-bar>
    </div>
    
    <div [hidden]="preLoadHidden">
        <div class="overflow-auto w-100">
            <mat-form-field>
                <mat-label>
                    <mat-icon>search</mat-icon> Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
            </mat-form-field>
            <div class="float-end" >
                
            </div>
        </div>
        <div class="mat-elevation-z8 table-responsive">
            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="xCreated_at" matSortDirection="desc" matSortDisableClear class="w-100">
                <ng-container matColumnDef="xTracking_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xTracking_number')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row" matTooltip="Click para rastrear envío" [matTooltipShowDelay]="1000" [matTooltipPosition]="'above'"> <a class="a-ship fw-bold" target="_blank" href="https://tracking.buhologistics.com/?id={{row.xTracking_number||row.tracking_number}}">{{row.xTracking_number||row.tracking_number  }}</a></td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>
                <ng-container matColumnDef="xCreated_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xCreated_at')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row"> {{row.xCreated_at?(row.xCreated_at?row.xCreated_at.slice(0,16).replace('T','  '):''): (row.created_at?row.created_at.slice(0,16).replace('T','  '):'')}}</td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>
                <ng-container matColumnDef="xSource" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xSource')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xSource || row.provider }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="xCarrier" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xCarrier')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xCarrier || row.carrier }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="xService" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xService')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xService || row.service }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="xWeight" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xWeight')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xWeight  }} 
                        <span *ngFor="let package of row.package_dimensions; index as i"  >{{i==0?'':'. '}}{{ row.package_dimensions[i].weight}} kg</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="xContent" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xContent')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xContent }} 
                        <span *ngFor="let package of row.package_dimensions; index as i"  >{{i==0?'':'. '}}{{ row.package_dimensions[i].content }}</span>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="xDestination_address" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xDestination_address')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xDestination_address }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
				<ng-container matColumnDef="xPrice" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xPrice')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xPrice || row.service_price }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="xTotal_price" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xTotal_price')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{(row.xTotal_price || row.total_price) | currency}} </td>
                    <td mat-footer-cell *matFooterCellDef> <!-- <b *ngIf="showSumFooter()">{{getTotalPrice() | currency}}</b> --> </td>
                </ng-container>
                <ng-container matColumnDef="xDestination_name" >
                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('xDestination_name')}} </th>
                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{row.xDestination_name }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                
    
                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" ></tr>
                <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td *ngIf="billingDataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                    <td *ngIf="billingDataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="getPageSizeOptions()" pageSize="25" ></mat-paginator>
        </div>
    </div>

</div>
<!-- <div class="modal-footer">
    <button mat-flat-button class="btn bg-success text-white" form="formAdd" type="button" >Importar <mat-icon>publish</mat-icon></button>
</div> -->