<div class="modal-header ct-titlebox">
    <h1 class="modal-title ct-title">Recarga crédito</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h4 *ngIf="isAdminStrategic || cuenta_clabe" style="margin-top: 5px;">Puedes añadir crédito a {{company_name ? 'la compañía ' + company_name + ',' : 'tu compañía'}} {{ (cuenta_clabe == '') ? 'una vez que hayas asignado una CLABE' : 'utilizando la CLABE que aparece aquí.' }}
        <br>Crédito actual: <b>{{ userCredit | currency }} <small>{{ currency }}</small></b>
    </h4>
    <div *ngIf="isAdminStrategic || cuenta_clabe; else withoutClabe" class="row">
        <!-- Widget de Créditos (eliminado; información en L-7)
        <div class="col-md-6 col-9">
            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <div class="card small-widget">
                <div class="card-body primary"> <span class="f-light"> Créditos</span>
                    <div class="d-flex align-items-end gap-1">
                        <h1 class="mb-0">{{ userCredit | currency }} <small>{{ currency }}</small></h1>
                    </div>
                    <div class="bg-gradient">
                        <mat-icon class="text-muted font-primary">payments</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        -->
        <div class="clabe col-md-6 col-9">
            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <div *ngIf="!isLoading && cuenta_clabe!=''" class="card small-widget" (click)="copyClabe()" (mouseenter)="showInfo(true)" (mouseleave)="showInfo(false)">
                <div class="card-body primary"> <span class="f-light"> Cuenta CLABE </span>
                    <div class="d-flex align-items-end gap-1">
                        <h1 class="mb-0">{{ formatClabe(cuenta_clabe) }}</h1>
                    </div>
                    <div class="bg-gradient">
                        <mat-icon class="text-muted font-primary">content_copy</mat-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="!isLoading && cuenta_clabe==''" class="card small-widget" (click)="copyClabe()">
                <div class="card-body primary"> <span class="f-light"> No se ha encontrado una CLABE asignada </span>
                    <div class="d-flex align-items-end gap-1">
                        <button (click)="createClabe()" mat-flat-button class="btn bg-success text-white" type="button" [disabled]="cuenta_clabe!=''">Asignar CLABE</button>
                    </div>
                    <div class="bg-gradient">
                        <mat-icon class="text-muted font-primary">content_copy</mat-icon>
                    </div>
                </div>
            </div>
            <!-- Overlay-on-hover (transferido: L-67)
            <div class="ct-overlay" [ngClass]="{ 'visible': showBankInfo }">
                <p class="m-0">Banco: <span class="highlit">STP</span></p>
                <hr class="m-1">
                <p class="m-0">Titular: <span class="highlit">BUHO LOGISTICS SA DE CV</span></p>
            </div>
            -->
        </div>
        <div class="col-md-6 col-9">
            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <div class="card small-widget">
                <div class="card-body primary ct-bankcard">
                    <div class="ct-bankdata">
                        <p class="m-0">Banco: <span class="highlit">STP</span></p>
                        <hr class="my-1">
                        <p class="m-0">Titular: <span class="highlit">BUHO LOGISTICS SA DE CV</span></p>
                    </div>
                    <div class="bg-gradient">
                        <mat-icon class="text-muted font-primary">account_balance</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #withoutClabe>
        <div>
            
            <div class="card ">
                <div class="card-body primary"> <span class="f-light"> </span>
                    <div class="d-flex align-items-end gap-1">
                        <h4 class="mb-0">
                            Si desea agregar más créditos a su cuenta para poder seguir utilizando la plataforma Wing, por favor, contáctenos vía correo electrónico a "hola&#64;buhologistics.com" para asignar su cuenta CLABE de transferencias
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <h5 *ngIf="mensaje" class="text-center">{{mensaje}}</h5>
    <div class="row">
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-bar color="primary" mode="indeterminate">
            </mat-progress-bar>
        </div>
        <div [hidden]="preLoadHidden">
            <div class="overflow-auto w-100" style="display: flex; align-items: flex-end; justify-content: space-between;">
                <h2 class="mb-3">Últimos movimientos:</h2>
                <mat-form-field style="width: 300px;">
                    <mat-label>
                        <mat-icon>search</mat-icon> Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                </mat-form-field>
            </div>
            <div class="mat-elevation-z8 table-responsive ct-transtable">
                <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear class="w-100">
                    
                    <ng-container matColumnDef="created_at">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('created_at')}} </th>
                        <td class="col-2 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.created_at?row.created_at.slice(0,16).replace('T','  '):''}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <ng-container matColumnDefidclabe="id_company">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="id_company">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_company')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_company}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('amount')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.amount | currency}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="previous_credit">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('previous_credit')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.previous_credit}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="observations">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('observations')}} </th>
                        <td class="col-3 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{(row.id_stp_collection?(row.reference_number+' - '+row.tracking_key):row.observations)}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="id_user_creator">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_user_creator')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_user_creator}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="id_stp_collection">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('id_stp_collection')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.id_stp_collection}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="company_name">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('company_name')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.company_name}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="transaction_date">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('transaction_date')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.transaction_date}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="tracking_key">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('tracking_key')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row">
                            <ng-container *ngIf="row.url_cep && row.url_cep !== ''; else ctComment">
                                <a href="{{row.url_cep}}" class="ct-tracking" matTooltip="Ir a Banxico" [matTooltipPosition]="'above'" target="_blank">
                                    {{ row.tracking_key }}
                                </a>
                            </ng-container>
                            <ng-template #ctComment>
                                <span style="color: lightgray">* {{ (row.id_stp_collection && row.reference_number && row.reference_number.toString().trim() !== '') ? row.tracking_key : row.observations }}</span>
                            </ng-template>
                        </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="beneficiary_account">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('beneficiary_account')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.beneficiary_account}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="concept_payment">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('concept_payment')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.concept_payment}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="reference_number">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('reference_number')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.reference_number}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="added_credit">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('added_credit')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.added_credit}} 
                            <a mat-icon-button >
                                <mat-icon *ngIf="row.added_credit==1" class="text-success" mat-icon-button matTooltip="Crédito abonado a la compañía" [matTooltipPosition]="'above'">paid</mat-icon>
                            </a>
                        </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="conciliation">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('conciliation')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.conciliation}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="url_cep">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('url_cep')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.url_cep}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="conciliation_date">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('conciliation_date')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.conciliation_date}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('status')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.status}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="status_group">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('status_group')}} </th>
                        <td class="col-1" mat-cell *matCellDef="let row">
                            <mat-icon [ngStyle]="{'color': icon(row.status_group, 'color')}"
                                      [matTooltip]="icon(row.status_group, 'tooltip')" [matTooltipPosition]="'above'">
                                {{icon(row.status_group)}}
                        </mat-icon></td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('email')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.email}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="nombre">
                        <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('nombre')}} </th>
                        <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> {{getDisplayedLabel('actions')}} </th>
                        <td class="col-sm-3 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row">
                            <a mat-icon-button >
                                <span *ngIf="row.status!=''" [ngClass]="{'badge': true , 'bg-warning': row.status!='CCO' && row.id_stp_collection<0, 'bg-dark': row.status!='CCO' && row.id_stp_collection>0, 'bg-primary': row.status=='CCO' }" mat-icon-button matTooltip="{{statusName(row.status)}}" [matTooltipPosition]="'above'">{{row.status}}</span>
                                <mat-icon *ngIf="row.conciliation==1" class="text-primary" mat-icon-button matTooltip="Conciliado" [matTooltipPosition]="'above'">receipt_long</mat-icon>
                                <mat-icon *ngIf="row.added_credit==1 || !row.id_stp_collection" class="text-success" mat-icon-button matTooltip="Crédito abonado a la compañía" [matTooltipPosition]="'above'">paid</mat-icon>
                            </a>
                        </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    
        
                    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" ></tr>
                    <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                        <td *ngIf="billingDataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                        <td *ngIf="billingDataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                    </tr>
                </table>
                <!-- <mat-paginator [pageSizeOptions]="getPageSizeOptions()" pageSize="25" ></mat-paginator> -->
            </div>
        </div>
    </div>
    <div class="ct-button-array">
        <ng-container *ngIf="!isLoading && this.billingDataList.length > 0">
            <button mat-raised-button (click)="goToCreditHistory()" class="ct-btn-basic">Más movimientos</button>
        </ng-container>
        <button mat-raised-button (click)="closeDialog()" class="ct-btn-close">Cerrar</button>
    </div>
</div>