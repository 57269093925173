import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipments-show',
  templateUrl: './shipments-show.component.html',
  styleUrls: ['./shipments-show.component.scss']
})
export class ShipmentsShowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
