<div class="modal-header">
    <h1 class="modal-title">Ingresa las dimensiones</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <form autocomplete="off" [formGroup]="formMeasures" (ngSubmit)="save()" class="mt-3" id="measuresForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Largo (cm) </mat-label>
                    <input matInput formControlName="length">
                    <mat-icon matSuffix>straighten</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Ancho (cm) </mat-label>
                    <input matInput formControlName="width">
                    <mat-icon matSuffix>settings_ethernet</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Alto (cm) </mat-label>
                    <input matInput formControlName="height">
                    <mat-icon matSuffix>height</mat-icon>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button (click)="save()" type="submit" mat-flat-button class="btn bg-success text-white" form="passwordForm">Continuar <mat-icon>send</mat-icon></button>
</div>