import { Component, OnInit } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { ShopifyService } from 'src/app/services/shopify.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isClientUsr: boolean;
  isOperationsUsr: boolean;
  hasStores: boolean = false;
  isLoading: boolean = true; 

  constructor(
    private userS: UsersService,
    private shopifyS: ShopifyService,
  ) {
    this.isClientUsr = (this.userS.getUser().id_rol == environment.role_client_id);
    this.isOperationsUsr = (this.userS.getUser().id_rol == environment.role_operations_id);

    this.shopifyS.getStoreList().pipe(
      map((result: any) => {
        return result.length > 0;
      }),
      catchError((err: any) => {
        console.error(err);
        return of(false); 
      })
    ).subscribe((value: boolean) => {
      this.hasStores = value; 
      this.isLoading = false;
    });
  }

  ngOnInit(): void {
  }

  allowedCompanies(): boolean {
    const reqCompaniesShipmentId= [1, 34];
    return (reqCompaniesShipmentId.includes(this.userS.getUser().id_company));
  }
}
