import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stp-order-detail',
  templateUrl: './stp-order-detail.component.html',
  styleUrls: ['./stp-order-detail.component.scss']
})
export class StpOrderDetailComponent implements OnInit {
  
  dataStatus!:string;
  dataMessage!:string;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<StpOrderDetailComponent>,
    private matDialog: MatDialog
  ) { }


  ngOnInit(): void {
    this.fillData();
    this.dataStatus = this.data.detail.status;
    //this.dataMessage=this.data.detail.message;
  }

  statusName(status:string){
    let sStatus="";
    if(status){
      switch (status.toUpperCase()) {
        case 'D':
          sStatus='Devuelta';
          break;
        case 'LQ':
          sStatus='Liquidada';
          break;
        case 'CCE':
          sStatus='Confirmación enviada';
          break;
        case 'CXO':
          sStatus='Por enviar confirmación';
          break;
        case 'CCO':
          sStatus='Confirmada';
          break;
        case 'CCR':
          sStatus='Confirmación rechazada';
          break;
        default:
          break;
      } 
    }
    return sStatus;
  }

  tipoPago(clave:number){
    let tipoPago="";

    switch (clave){
      case 0 : 
        tipoPago = 'Devoluciones';
        break;
      case 1 : 
        tipoPago = 'Tercero a Tercero';
        break;
      case 5 : 
        tipoPago = 'Participante a Tercero';
        break;
      case 16 : 
        tipoPago = 'Devolución Extemporánea';
        break;
      case 17 : 
        tipoPago = 'Retorno';
        break;
      case 18 : 
        tipoPago = 'Retorno Extemporáneo';
        break;
      case 19 : 
        tipoPago = 'Cobros presenciales de una ocasión';
        break;
      case 20 : 
        tipoPago = 'Cobros no presenciales de una ocasión';
        break;
      case 21 : 
        tipoPago = 'Cobros no presenciales recurrentes';
        break;
      case 22 : 
        tipoPago = 'Cobros no presenciales recurrentes y no recurrentes a nombre de un tercero';
        break;
      case 23 : 
        tipoPago = 'Retorno especial';
        break;
      case 24 : 
        tipoPago = 'Retorno Extemporáneo especial';
        break;        
      default:
        break;
    } 
    return tipoPago;
  }


  private fillData(): void {
    Object.keys(this.data.detail).forEach((x: any) => {
      if (this.data.detail[x] === "null" || this.data.detail[x] === "")
        this.data.detail[x] = "N/A";
    });
  }
}
