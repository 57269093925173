import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardModel, ServicesModeModel, dShipmentModel } from 'src/app/models/shipment.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboardData =  new DashboardModel();
  userObj!: UserModel;
  isLoading = true;
  estatusMapping:any = {
    'assigned': { label:'Recolectado', color: 'badge-orange', icon: 'forklift' },
    'order_created': {label:'Orden recibida', color: 'badge-purple', icon: 'assignment'},
    'order_completed': {label: 'Orden surtida', color: 'badge-dark', icon: 'assignment_turned_in'},
    'started': {label:'¡En ruta!', color: 'badge-warning', icon: 'local_shipping'},
    'successful': {label: 'Entregado', color: 'badge-success', icon: 'done_all'},
    'error': {label:'Error', color: 'badge-warning', icon: 'warning' },
    'canceled': {label:'Cancelada', color: 'badge-warning', icon: 'warning' },
    'incident': {label:'Incidencia', color: 'badge-warning', icon: 'warning' },
    'pending': {label:'Pendiente', color: 'badge-warning', icon: 'warning' },
    'undelivered': {label:'No entregado', color: 'badge-danger', icon: 'warning' }
  };
  nDashboardData = {
    shipments: [] as dShipmentModel[],
    past_period: {} as any,
    avgDeliveryTime: [] as any[],
    successfulDelivery: [] as any[],
    carrierUsage: [] as any[],
    totalShipments: {} as any,
    monthlyShipments: {} as any
  };
  period:string = '1%20month';
  periodLabels: { [key: string]: [string, string] } = {
    '7%20day': ['Últimos 7 días', 'hace 7 dias'],
    '1%20month': ['Últimos 30 días', 'hace 1 mes'],
    '1%20year': ['Año actual', 'hace 1 año'],
  };
  isDropContainerVisible: boolean = false; 
  shipmentStatus:{ [key:string]:string } = {
    'assigned': '',
    'order_created': '',
    'order_completed': '',
    'started': '',
    'successful': '',
    'pending': '',
    'error': ''
  }
  barChartData:any;
  incidentShipments!: dShipmentModel[];
  pendingShipments!: dShipmentModel[];
  servicesModeList =  new Array<ServicesModeModel>();
  total_paid:any;
  avg_weight:any;
  general_avg_delivery_time:any;
  avg_delivery_time:any[]=[];
  success_rate:any[]=[];
  carrier_usage:any[]=[];

  constructor(
    private userS: UsersService,
    private dashboardS: DashboardService,
  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.getServicesMode();
    this.setPeriod(this.period);
  }

  getNDashboardData(period:string) {
    this.dashboardS.getDashboardData(period).subscribe(
      (result:any) => {
        this.nDashboardData = result;
        console.log(this.nDashboardData)
        this.total_paid = this.getTotalPaid(result.shipments);
        this.avg_weight = this.getAvgWeight(result.shipments);
        this.general_avg_delivery_time = this.getGeneralAvgDeliveryTime(result.shipments);
        this.avg_delivery_time = this.getAvgDeliveryTime(result.shipments);
        this.success_rate = this.getDeliverySuccessRate(result.shipments);
        this.carrier_usage = this.getCarrierUsagePercentage(result.shipments);
        this.getShipmentStatus(result.shipments);
        this.barChartData = result.barChart;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getTotalPaid (shipments:any) {
    let total = 0;
    for (let i = 0; i < shipments.length; i++) {
        total += shipments[i].total_price;
    }
    return total;
  }

  getAvgWeight (shipments:any) {
    let total = 0;
    for (let i = 0; i < shipments.length; i++) {
        total += parseInt(shipments[i].weight);
    }
    return total / shipments.length;
  }

  getGeneralAvgDeliveryTime(shipments: any[]) {
    let totalDeliveryTime = 0;
    let successfulShipmentsCount = 0;

    shipments.forEach(shipment => {
        if (shipment.status === 'successful' && shipment.delivery_date && shipment.created_at) {
            const createdAt = new Date(shipment.created_at);
            const deliveryDate = new Date(shipment.delivery_date);
            const timeDiff = deliveryDate.getTime() - createdAt.getTime();
            totalDeliveryTime += timeDiff;
            successfulShipmentsCount++;
        }
    });

    if (successfulShipmentsCount > 0) {
        const avgDeliveryTime = totalDeliveryTime / successfulShipmentsCount;
        const avgDeliveryTimeDays = avgDeliveryTime / (1000 * 60 * 60 * 24); 
        return avgDeliveryTimeDays;
    } else {
        return 0;
    }
  }


  getShipmentStatus (shipments:any) {
    this.shipmentStatus.assigned = shipments.filter((shipment:any) => shipment.status === 'assigned').length;
    this.shipmentStatus.order_created = shipments.filter((shipment: any) => shipment.status === 'order_created' || shipment.status === null).length;
    this.shipmentStatus.order_completed = shipments.filter((shipment:any) => shipment.status === 'order_completed').length;
    this.shipmentStatus.started = shipments.filter((shipment:any) => shipment.status === 'started').length;
    this.shipmentStatus.successful = shipments.filter((shipment:any) => shipment.status === 'successful').length;
    this.shipmentStatus.error = shipments.filter((shipment:any) => shipment.status === 'error' || shipment.status === 'incident' || shipment.status === 'canceled' ).length;
    this.shipmentStatus.pending = shipments.filter((shipment:any) => shipment.status === 'pending').length;
    this.incidentShipments = shipments.filter((service:any) =>
      service.status === "incident" || service.status === "canceled"
    );
    this.pendingShipments = shipments.filter((service:any) =>
      service.status != "successful"
    );
  }

  getAvgDeliveryTime(shipments: any[]): { carrier: string, service_mode: string, average_delivery_time: number, count: number }[] {
    const carrierServiceModeDeliveryTimes: { [key: string]: number[] } = {};

    shipments.forEach((shipment: any) => {
        if (shipment.status === 'successful' && shipment.delivery_date) {
            const createdAt = new Date(shipment.created_at).getTime();
            const deliveryDate = new Date(shipment.delivery_date).getTime();

            const timeDifference = (deliveryDate - createdAt) / (1000 * 60 * 60 * 24);

            const key = `${shipment.carrier}-${shipment.service_mode}`;

            if (!carrierServiceModeDeliveryTimes[key]) {
                carrierServiceModeDeliveryTimes[key] = [];
            }

            carrierServiceModeDeliveryTimes[key].push(timeDifference);
        }
    });

    const carrierServiceModeAverageTimes: { carrier: string, service_mode: string, average_delivery_time: number, count: number }[] = [];

    for (const key in carrierServiceModeDeliveryTimes) {
        const totalDeliveryTime = carrierServiceModeDeliveryTimes[key].reduce((total, time) => total + time, 0);
        const averageDeliveryTime = totalDeliveryTime / carrierServiceModeDeliveryTimes[key].length;

        const [carrier, service_mode] = key.split('-');

        carrierServiceModeAverageTimes.push({
            carrier,
            service_mode,
            average_delivery_time: averageDeliveryTime,
            count: carrierServiceModeDeliveryTimes[key].length
        });
    }

    carrierServiceModeAverageTimes.sort((a, b) => a.average_delivery_time - b.average_delivery_time);
    
    return carrierServiceModeAverageTimes;
  }

  getDeliverySuccessRate(shipments: any[]): { carrier: string, total_shipments: number, success_rate: number }[] {
    const carrierTotals: { [carrier: string]: number } = {};
    const carrierErrors: { [carrier: string]: number } = {};

    shipments.forEach((shipment: any) => {
        if (!carrierTotals[shipment.carrier]) {
            carrierTotals[shipment.carrier] = 0;
            carrierErrors[shipment.carrier] = 0;
        }

        carrierTotals[shipment.carrier]++;

        if (shipment.status === 'error' || shipment.status === 'incident') {
            carrierErrors[shipment.carrier]++;
        }
    });

    const carrierSuccessRates: { carrier: string, total_shipments: number, success_rate: number }[] = [];

    for (const carrier in carrierTotals) {
        const total = carrierTotals[carrier];
        const errors = carrierErrors[carrier];
        const successRate = ((total - errors) / total) * 100;

        carrierSuccessRates.push({
            carrier,
            total_shipments: total,
            success_rate: successRate
        });
    }

    carrierSuccessRates.sort((a, b) => b.total_shipments - a.total_shipments);

    return carrierSuccessRates;
  }

  getCarrierUsagePercentage(shipments: any[]): { carrier: string, percentage_of_total: number }[] {
    const totalShipments = shipments.length;
    const carrierCounts: { [carrier: string]: number } = {};

    shipments.forEach((shipment: any) => {
        if (!carrierCounts[shipment.carrier]) {
            carrierCounts[shipment.carrier] = 0;
        }
        carrierCounts[shipment.carrier]++;
    });

    const carrierUsagePercentages: { carrier: string, percentage_of_total: number }[] = [];

    for (const carrier in carrierCounts) {
        const count = carrierCounts[carrier];
        const percentage = (count / totalShipments) * 100;

        carrierUsagePercentages.push({
            carrier,
            percentage_of_total: percentage
        });
    }

    carrierUsagePercentages.sort((a, b) => b.percentage_of_total - a.percentage_of_total);

    return carrierUsagePercentages;
  }

  getPeriodLabel(period: string): string {
    return this.periodLabels[period][0] || period;
  }

  toggleDropContainer() {
    this.isDropContainerVisible = !this.isDropContainerVisible;
  }

  // setPeriod(period:string) {
  //   this.isLoading = true;
  //   this.period = period;
  //   this.isDropContainerVisible = false;
  //   this.getNDashboardData(this.period);
  // }
  setPeriod(period: string) {
    this.period = period;
    this.isLoading = true;
    let periodToFetch = period;

    if (period === '1%20year') {
        const currentMonth = new Date().getMonth() + 1
        periodToFetch = `${currentMonth}%20month`;
    }

    this.getNDashboardData(periodToFetch);
}

  getServicesMode() {
    this.userS.getListServicesMode().subscribe(
      (result : any) => {
        this.servicesModeList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  imgFormat(value:string) {
    if(!value) return;
    return value = value.replace(/\s+/g, '').toLowerCase();
  }

  getServiceMode(service:string, carrier:string ) {
    try {
      let sService=service;
      let sServiceMode;
      const indexFound = this.servicesModeList.findIndex(r=> (r.carrier.toUpperCase().includes(carrier.toUpperCase()) || carrier.toUpperCase().includes(r.carrier.toUpperCase())) 
                                                          && (service.toUpperCase().includes(r.service.toUpperCase()) || r.service.toUpperCase().includes(service.toUpperCase())) );
      if(indexFound != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
        //sService=`${service}->${this.servicesModeList[indexFound].service_mode}`;
        sService=`${this.servicesModeList[indexFound].service}`;
        sServiceMode=`${this.servicesModeList[indexFound].service_mode}`;
      }
      return `${(sService|| '') + " - " + (sServiceMode || '')  }`;
    } catch (error) {
      return service; 
    }
  }

}
