import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ecommerce-set-dimensions',
  templateUrl: './ecommerce-set-dimensions.component.html',
  styleUrls: ['./ecommerce-set-dimensions.component.scss']
})
export class EcommerceSetDimensionsComponent implements OnInit {

  formMeasures!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EcommerceSetDimensionsComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.createFormMeasures();
  }

  createFormMeasures() {
    this.formMeasures = this.fb.group({
      width: ['', [ Validators.required, Validators.minLength(1)]],
      height: ['', [Validators.required, Validators.minLength(1)]],
      length: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  save(): void {
    if (this.formMeasures.valid) {
      this.dialogRef.close(this.formMeasures.value);
    }
  }

}
