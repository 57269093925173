<div class="modal-header">
    <h1 class="modal-title">Nuevo compañía</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h4>Captura la información de la nueva compañía:</h4>
    <form autocomplete="off" [formGroup]="formCreate" (ngSubmit)="ruSure()" class="mt-3" id="formCreate">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline" (focusout)="onNameBlur()">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>apartment</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Clave de la compañía</mat-label>
                    <input matInput formControlName="key">
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Proveedores</mat-label>
                    <mat-select (selectionChange)="getCarriers();" formControlName="providers" multiple>
                        <mat-option *ngFor="let p of providersList" [value]="p.id">{{p.provider}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Paqueterías</mat-label>
                    <mat-select formControlName="carriers"  multiple>
                        <mat-option *ngFor="let c of carriersListFiltered" [value]="c.id">{{c.carrier}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>País</mat-label>
                    <mat-select formControlName="iso_country">
                        <mat-option *ngFor="let country of countriesList" [value]="country.iso_country">{{country.country}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline" (focusout)="onCreditBlur()">
                    <mat-label>Créditos</mat-label>
                    <input matInput formControlName="credit">
                    <mat-icon matSuffix>attach_money</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline" (focusout)="onCommissionTypeBlur()">
                    <mat-label>Selecciona el tipo de comisión</mat-label>
                    <mat-select formControlName="commission_type">
                        <mat-option *ngFor="let item of commissionTypesList" [value]="item.id">{{getLastWord(item.name)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Tipo de comisión</mat-label>
                    <mat-select (selectionChange)="setTipoCom($event);" formControlName="commission_tipo">
                        <mat-option *ngFor="let com of tipoCom" [value]="com.id">{{com.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Comisión</mat-label>
                    <input matInput formControlName="commission_cantidad">
                    <mat-icon matSuffix [hidden]="tipoComSel!='porc'">percent</mat-icon>
                    <mat-icon matSuffix [hidden]="tipoComSel!='cant'">attach_money</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div  *ngIf="false" class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Moneda</mat-label>
                    <input matInput formControlName="currency">
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>País</mat-label>
                    <input matInput formControlName="country">
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-2 mat-form-field-wrapper">
            <div class="col-sm-6">
                <div>Compras desde API</div>
                <div class="row mt-2">
                    <div class="col-sm-6  d-flex justify-content-start">
                        <mat-slide-toggle formControlName="opt_api_cre" matTooltip="¿Activar el uso de crédito de la compañía?" [matTooltipPosition]="'above'" color="primary" labelPosition="before"  style="margin-right: 10px;">Usar crédito</mat-slide-toggle>
                        <mat-slide-toggle formControlName="opt_api_com" matTooltip="¿Activar el uso de la comisión de la compañía?" [matTooltipPosition]="'above'" color="primary" labelPosition="before">Usar comisión</mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div>Compras desde Plataforma</div>
                <div class="row mt-2">
                    <div class="col-sm-6  d-flex justify-content-start">
                        <mat-slide-toggle formControlName="opt_pla_cre" matTooltip="¿Activar el uso de crédito de la compañía?" [matTooltipPosition]="'above'" color="primary" labelPosition="before"  style="margin-right: 10px;">Usar crédito</mat-slide-toggle>
                        <mat-slide-toggle formControlName="opt_pla_com" matTooltip="¿Activar el uso de la comisión de la compañía?" [matTooltipPosition]="'above'" color="primary" labelPosition="before">Usar comisión</mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-3">
                <div>Compra internacional</div>
                <div class="row mt-2">
                    <div class="col-sm-12  d-flex justify-content-start">
                        <mat-slide-toggle formControlName="international_shipping" matTooltip="Activar y desactivar compras internacionales" [matTooltipPosition]="'above'" color="primary" labelPosition="before"  style="margin-right: 10px;"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="formCreate.get('international_shipping')?.value">
                <div class="col-sm-6  d-flex justify-content-start">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Comisión internacional</mat-label>
                        <input matInput formControlName="commission_int_cantidad">
                        <mat-icon matSuffix [hidden]="tipoComSel!='porc'">percent</mat-icon>
                        <mat-icon matSuffix [hidden]="tipoComSel!='cant'">attach_money</mat-icon>
                    </mat-form-field>
                </div>
                
            </div>
        </div>

        
        <p class="mb-4"></p>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button (click)="resetForm()">Limpiar <mat-icon>delete_outline</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" form="formCreate" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>