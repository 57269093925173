import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-shipments-print',
  templateUrl: './shipments-print.component.html',
  styleUrls: ['./shipments-print.component.scss']
})
export class ShipmentsPrintComponent implements OnInit {

  Url!: SafeResourceUrl | string;
  link!:string;
  info!:any;

  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.link = "data:application/pdf;base64," + this.data.data;
    this.info = this.sanitizer.bypassSecurityTrustResourceUrl(this.link);
  }

}
