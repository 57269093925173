<div class="modal-header">
    <h1 class="modal-title">Registro: {{data.detail.order}}</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <!-- <h4>Edita la información del registro: <b>{{data.detail.order}}</b>  </h4> -->
    <p >
        <button mat-icon-button matTooltip="{{ statusChangeTip(dataStatus, dataMessage) }}" [matTooltipPosition]="'above'" color="{{statusChangeClass(dataStatus)}}" [ngClass]="{'processing-icon': dataStatus=='in_progress' }">
            <mat-icon>{{ statusChangeIcon(dataStatus) }}</mat-icon>
        </button>
        <span *ngIf="dataStatus=='error'"> {{dataMessage}}</span>
        <span *ngIf="dataStatus!='error'"> {{statusChangeTip(dataStatus, dataMessage)}}</span>
    </p>
    <form autocomplete="off" [formGroup]="formEdit" (ngSubmit)="ruSure()" class="" id="formEdit">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2" >
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_NAME</mat-label>
                    <input matInput formControlName="ORIG_NAME">
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_COMPANY</mat-label>
                    <input matInput formControlName="ORIG_COMPANY">
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_EMAIL</mat-label>
                    <input matInput formControlName="ORIG_EMAIL">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_PHONE</mat-label>
                    <input matInput formControlName="ORIG_PHONE">
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_STREET</mat-label>
                    <input matInput formControlName="ORIG_STREET">
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_NUMBER</mat-label>
                    <input matInput formControlName="ORIG_NUMBER">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_POSTALCODE</mat-label>
                    <input matInput formControlName="ORIG_POSTALCODE">
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_SUBURB</mat-label>
                    <input matInput formControlName="ORIG_SUBURB">
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_CITY</mat-label>
                    <input matInput formControlName="ORIG_CITY">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_STATE</mat-label>
                    <input matInput formControlName="ORIG_STATE">
                </mat-form-field>
            </div>
            <div class="col-sm-4" *ngIf="isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>ORIG_REFERENCE</mat-label>
                    <input matInput formControlName="ORIG_REFERENCE">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4" *ngIf="!isDifferentOrigin">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>WAREHOUSE_CODE</mat-label>
                    <input matInput formControlName="WAREHOUSE_CODE">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_NAME</mat-label>
                    <input matInput formControlName="DEST_NAME">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_COMPANY</mat-label>
                    <input matInput formControlName="DEST_COMPANY">
                </mat-form-field>
            </div>
            <input type="hidden" formControlName="id">
            <input type="hidden" formControlName="id_bulk_upload">
            <input type="hidden" formControlName="status_bud">
            <input type="hidden" formControlName="ORDER">
            <input type="hidden" formControlName="STATUS">
        
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_EMAIL</mat-label>
                    <input matInput formControlName="DEST_EMAIL">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_PHONE</mat-label>
                    <input matInput formControlName="DEST_PHONE">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_STREET</mat-label>
                    <input matInput formControlName="DEST_STREET">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_NUMBER</mat-label>
                    <input matInput formControlName="DEST_NUMBER">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_POSTALCODE</mat-label>
                    <input matInput formControlName="DEST_POSTALCODE">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_SUBURB</mat-label>
                    <input matInput formControlName="DEST_SUBURB">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_CITY</mat-label>
                    <input matInput formControlName="DEST_CITY">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_STATE</mat-label>
                    <input matInput formControlName="DEST_STATE">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>DEST_REFERENCE</mat-label>
                    <input matInput formControlName="DEST_REFERENCE">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>RATE_PROVIDER</mat-label>
                    <input matInput formControlName="RATE_PROVIDER">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>RATE_CARRIER</mat-label>
                    <input matInput formControlName="RATE_CARRIER">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>RATE_SERVICE</mat-label>
                    <input matInput formControlName="RATE_SERVICE">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>PKG_ORDER_NUMBER</mat-label>
                    <input matInput formControlName="PKG_ORDER_NUMBER">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>PKG_CONTENT</mat-label>
                    <input matInput formControlName="PKG_CONTENT">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>PKG_TYPE</mat-label>
                    <input matInput formControlName="PKG_TYPE">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>PKG_WEIGHT</mat-label>
                    <input matInput formControlName="PKG_WEIGHT">
                </mat-form-field>
            </div>
        
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>PKG_LENGTH</mat-label>
                    <input matInput formControlName="PKG_LENGTH">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>PKG_WIDTH</mat-label>
                    <input matInput formControlName="PKG_WIDTH">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>PKG_HEIGHT</mat-label>
                    <input matInput formControlName="PKG_HEIGHT">
                </mat-form-field>
            </div>
        </div>
        
        <p class="mb-4"></p>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="dataStatus!='in_progress' && dataStatus!='done'" mat-flat-button class="bg-success text-white" form="formEdit" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>