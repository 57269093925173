<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Histórico de consumo </h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'shipments'">
                            <mat-icon>request_quote</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Billing Data - Histórico de consumo</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <h4>Selecciona fechas y da click en buscar para mostrar la información</h4>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fechas</mat-label>
                                <mat-select [formControl]="selFechas" (selectionChange)="onchangeFechas($event)">
                                    <mat-option value="1">Hoy</mat-option>
                                    <mat-option value="2">Este mes</mat-option>
                                    <mat-option value="3">Elegir fechas</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha inicio</mat-label>
                                <input name="pickerI" (ngModelChange)="onchangedateI($event)" matInput [matDatepicker]="picker" [min]="minDateI" [max]="maxDateI" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayI">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Fecha fin</mat-label>
                                <input name="pickerF" (ngModelChange)="onchangedateF($event)" matInput [matDatepicker]="pickerf" [min]="minDateF" [max]="maxDateF" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTodayF">
                                <mat-datepicker-toggle matSuffix [for]="pickerf"></mat-datepicker-toggle>
                                <mat-datepicker #pickerf [disabled]="dateIDis"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Compañías</mat-label>
                                <mat-select [formControl]="selCompanies" multiple>
                                    <mat-option *ngFor="let item of selCompaniesList" [value]="item.id">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4" [hidden]="true">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Usuarios</mat-label>
                                <mat-select [formControl]="selUsers" multiple>
                                    <mat-option *ngFor="let item of selUsersList" [value]="item.id">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Moneda</mat-label>
                                <mat-select [formControl]="selCurrency" >
                                    <mat-option value="0">Todos</mat-option>
                                    <mat-option *ngFor="let item of selCurrencyList" [value]="item.currency">{{item.currency}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                        <div class="col-sm-4">
                            <button mat-stroked-button class="btn btn-primary mt-2 me-2 mb-1" matTooltip="Filtrar resultados" [matTooltipShowDelay]="1000" (click)="searchFilter()"><mat-icon>search</mat-icon><span class="btn-no-text"> Buscar</span></button>
                            <button (click)="exporter.exportTable('xlsx', {fileName:'Billing Data' })" mat-stroked-button class="btn btn-outline-success mt-2 me-2 mb-1" matTooltip="Exportar a Excel" [matTooltipShowDelay]="1000" [hidden]="preLoadHidden">
                                <mat-icon>download</mat-icon><span class="btn-no-text"> Descargar Excel </span>
                            </button>
                            <button  mat-stroked-button class="btn btn-outline-success mt-2 me-2 mb-1" id="customDropdownButton" (click)="toggleOptions()"  matTooltip="Columnas" [matTooltipPosition]="'above'" color="link" class="btn mt-2 me-2 mb-1" [hidden]="preLoadHidden">
                                <mat-icon>view_week</mat-icon> Columnas
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': showOptions}"  aria-labelledby="customDropdownButton"> 
                                <div class="dropdown-content" style=" max-height: 300px; overflow-y: auto;">
                                    <h5 class="dropdown-header d-flex justify-content-between align-items-center" style="margin: 0px;">Columnas  
                                        <button  mat-icon-button (click)="toggleOptions()"  matTooltip="Cerrar" [matTooltipPosition]="'above'" color="link" class="">
                                            <mat-icon>close</mat-icon>
                                        </button> 
                                    </h5>
                                    <div class="dropdown-item">
                                      <p>Seleccione las columnas para mostrar.</p>
                                      <ul>
                                          <li *ngFor="let column of columnDefinitions">
                                              <mat-checkbox  [checked]="!column.hide" (change)="toggleColumn(column.def, $event)" class="d-flex align-items-center mb-2">
                                                  {{ column.label }}
                                              </mat-checkbox>
                                          </li>
                                      </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="primary" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    
                    <div [hidden]="preLoadHidden">
                        <div class="overflow-auto w-100">
                            <mat-form-field>
                                <mat-label>
                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                            </mat-form-field>
                            <div class="float-end" >
                                
                            </div>
                        </div>
                        <div class="mat-elevation-z8 table-responsive">
                            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear class="w-100">
                                
                                <ng-container matColumnDef="created_at">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('created_at')}} </th>
                                    <td class="col-2 pe-2" mat-cell *matCellDef="let row"> {{row.created_at.slice(0,16).replace('T','  ')}}</td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="partner_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('partner_id')}} </th>
                                    <td class="col-1 pe-2" mat-cell *matCellDef="let row">
                                        {{row.partner_id?row.partner_id:row.company_name}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="company_name">
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('company_name')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{ row.company_name=='WMS'?row.ext_client_name : row.company_name}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="order_number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header > {{getDisplayedLabel('order_number')}} </th>
                                    <td class="col-1 pe-2" mat-cell *matCellDef="let row" > {{row.order_number}} </td>
                                    <td mat-footer-cell *matFooterCellDef > </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="shipment_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('shipment_id')}} </th>
                                    <td class="col-1 pe-2" mat-cell *matCellDef="let row" > {{row.shipment_id}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container> -->
                                <ng-container matColumnDef="provider">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('provider')}} </th>
                                    <td class="col-1 pe-2" mat-cell *matCellDef="let row">
                                        {{row.provider}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="carrier">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('carrier')}} </th>
                                    <td class="col-1 pe-2" mat-cell *matCellDef="let row">
                                        {{row.carrier}}
                                        <!-- <img class="w-50" src="../../../assets/images/carriers/{{row.carrier.toLowerCase()}}.jpg"> -->
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="service">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('service')}} </th>
                                    <td class="col-1 pe-2 long-word" mat-cell *matCellDef="let row">
                                        <span class="long-word" style="width: 50px;">
                                            {{getServiceMode(row.service, row.carrier, row.provider)}}
                                        </span>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="extended_zone">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('extended_zone')}} </th>
                                    <td class="col-1 pe-2 " mat-cell *matCellDef="let row">{{getInitialExtendedZone(row.extended_zone)}}</td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="shipment_type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('shipment_type')}} </th>
                                    <td class="col-1 pe-2 " mat-cell *matCellDef="let row">  {{row.shipment_type}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="content">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('content')}} </th>
                                    <td class="col-2 align-text-top pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> <div class="row" *ngFor="let package of row.package_dimensions; index as i"><p><span class="text-uppercase">{{package.content}}</span></p></div></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container> -->
                                <ng-container matColumnDef="reference">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('reference')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.reference }} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="content">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('content')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> <div class="row" *ngFor="let package of row.package_dimensions; index as i"><p>{{package.content}}</p></div></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="kg">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('kg')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> <div class="row" *ngFor="let package of row.package_dimensions; index as i"><p>{{package.weight | number: '1.0-0'}} KG.</p></div></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="dimension">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('dimension')}} </th>
                                    <td class="col-1 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> <div class="row" *ngFor="let package of row.package_dimensions; index as i"><p>{{package.length | number: '1.0-0' }}x{{package.width | number: '1.0-0' }}x{{package.height | number: '1.0-0' }}(cm) </p></div></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="origin">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('origin')}} </th>
                                    <td class="col-2 align-text-top pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> <div class="row"><p><span class="text-uppercase">{{getAddress(row.origin)}}</span></p></div></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="destination">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('destination')}} </th>
                                    <td class="col-2 align-text-top pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> <div class="row"><p><span class="text-uppercase">{{getAddress(row.destination)}}</span></p></div></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container> 
                                <ng-container matColumnDef="shipment">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('shipment')}} </th>
                                    <td class="col-3 align-text-top pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> <div class="row" *ngFor="let package of row.package_dimensions; index as i"><p><span class="text-uppercase">{{package.content}}</span><br>{{package.length | number: '1.0-0' }}x{{package.width | number: '1.0-0' }}x{{package.height | number: '1.0-0' }}(cm) - {{package.weight | number: '1.0-0'}} KG.</p></div></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container> -->
                                
                                <ng-container matColumnDef="service_price" >
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('service_price')}} </th>
                                    <td class="col-1 pe-2" mat-cell *matCellDef="let row">{{row.service_price | currency}} </td>
                                    <td mat-footer-cell *matFooterCellDef> <b *ngIf="showSumFooter()">{{getTotalService() | currency}}</b> </td>
                                </ng-container>
                                <ng-container matColumnDef="other_charge_price" >
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('other_charge_price')}} </th>
                                    <td class="col-1 pe-2" mat-cell *matCellDef="let row">{{(row.other_charge_price?row.other_charge_price:0) | currency}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                    <!-- <td mat-footer-cell *matFooterCellDef> <b *ngIf="showSumFooter()">{{getTotalService() | currency}}</b> </td> -->
                                </ng-container>
                                <ng-container matColumnDef="total_price" >
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('total_price')}} </th>
                                    <td class="col-2" mat-cell *matCellDef="let row">{{row.total_price | currency}} </td>
                                    <td mat-footer-cell *matFooterCellDef> <b *ngIf="showSumFooter()">{{getTotalPrice() | currency}}</b> </td>
                                </ng-container>
                                <ng-container matColumnDef="currency" >
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('currency')}} </th>
                                    <td class="" mat-cell *matCellDef="let row">{{row.currency}} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="tracking_number">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('tracking_number')}} </th>
                                    <td class="col-2 pe-2" mat-cell *matCellDef="let row" matTooltip="Click para rastrear envío" [matTooltipShowDelay]="1000" [matTooltipPosition]="'above'"> <a class="a-ship fw-bold" target="_blank" href="https://tracking.buhologistics.com/?id={{row.tracking_number}}">{{row.tracking_number}}</a></td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="origin" >
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('origin')}} </th>
                                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{ row.origin? row.origin.street :'' }} {{ row.origin? row.origin.number :'' }}, {{ row.origin? row.origin.suburb :'' }}, {{ row.origin? row.origin.city :'' }}, {{ row.origin? row.origin.state :'' }}, {{row.origin? row.origin.country :''}}, CP: {{ row.origin? row.origin.postalCode :'' }} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                <ng-container matColumnDef="destination" >
                                    <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{getDisplayedLabel('destination')}} </th>
                                    <td class="col-2 pe-2" mat-cell *matCellDef="let row">{{ row.destination? row.destination.street :'' }} {{ row.destination? row.destination.number :'' }}, {{ row.destination? row.destination.suburb :'' }}, {{ row.destination? row.destination.city :'' }}, {{ row.destination? row.destination.state :'' }}, {{row.destination? row.destination.country :''}}, CP: {{ row.destination? row.destination.postalCode :'' }} </td>
                                    <td mat-footer-cell *matFooterCellDef> </td>
                                </ng-container>
                                
                                
                                <!-- <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                    <td mat-cell *matCellDef="let row" class="text-center" >                                            
                                        <button mat-icon-button matTooltip="Imprimir guía" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="primary" (click)="printLabel(row.tracking_number)">
                                            <mat-icon>print</mat-icon>
                                        </button>
                                        
                                    </td>
                                </ng-container> -->
                    
                                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
                                <tr mat-footer-row *matFooterRowDef="getDisplayedColumns()"></tr>
                    
                                <tr class="mat-row" *matNoDataRow>
                                    <td *ngIf="billingDataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                    <td *ngIf="billingDataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                </tr>
                            </table>                                    
                            <!-- <mat-paginator [pageSizeOptions]="getPageSizeOptions()" ></mat-paginator> -->
                            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" pageSize="25"></mat-paginator>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>