<!-- select and over all row -->
<div class="modal-header pb-3">
    <h1 class="modal-title">Guardar dirección</h1>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="card card-absolute shadow shadow-showcase">
        <div class="card-header bg-primary">
          <h5 class="text-white">Datos de Dirección <mat-icon class="align-middle">pin_drop</mat-icon>
          </h5>
        </div>
        <div class="card-body">
            <mat-list role="list">
                <mat-list-item role="listitem"><strong class="pe-2">Nombre:</strong>{{ info.name }}</mat-list-item>
                <mat-list-item role="listitem"><strong class="pe-2">Empresa:</strong>{{ info.company }}</mat-list-item>
                <mat-list-item role="listitem"><strong class="pe-2">Email:</strong>{{ info.email }}</mat-list-item>
                <mat-list-item role="listitem"><strong class="pe-2">Teléfono:</strong>{{ info.phone }}</mat-list-item>
                <mat-list-item role="listitem"><strong class="pe-2">Dirección:</strong>{{ info.street }} {{ info.number }}, {{ info.suburb }}, {{ info.city }}, {{ info.state }}, {{info.country}}, CP: {{ info.postalCode }}</mat-list-item>
            </mat-list>
        </div>
    </div>
    <form [formGroup]="formSaveAddress">
        <mat-form-field class="w-100 pt-2" appearance="outline">
            <mat-label>Alias de dirección</mat-label>
            <mat-icon matSuffix>edit</mat-icon>
            <input formControlName="alias" (keyup)="addAddressName($event)" matInput>
        </mat-form-field>
    </form> 
</div>
<div class="modal-footer">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button [disabled]="!formSaveAddress.valid" (click)="saveAddressData()" mat-flat-button class="btn bg-success text-white" form="passwordForm" mat-dialog-close>Guardar <mat-icon>save</mat-icon></button>
</div>