<!-- <div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Configuración de Ecommerce</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon>storefront</mat-icon></a></li>
                    <li class="breadcrumb-item">Configuración de Ecommerce</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-9">
            <div class="card stores purchase-card discover">
                <div class="card-body empty">
                    <h1>Tus tiendas</h1>
                    <div class="d-flex justify-content-center mt-5" *ngIf="isLoading; else hasStores">
                        <mat-spinner></mat-spinner>
                    </div>

                    <ng-template #hasStores>
                        <div *ngIf="storeList.length === 0; else list"  class="row w-100 justify-content-center">
                            <img style="opacity: 20%; width: 25%;" src="assets/images/shop.png" alt="">
                            <p class="text-center f-light">Registra una tienda para comenzar</p>
                        </div>
                    </ng-template>

                    <ng-template #list>
                        <div class="table-responsive">
                            <table class="table">
                            <tbody>
                                <tr *ngFor="let store of storeList">
                                <td style="width: 15%;">
                                    <img class="mt-1" style="width: 100%;" src="assets/images/shopify.png" alt="">
                                </td>
                                <td>
                                    <div class="mt-4">
                                        <h1>{{store.nombre}}</h1>
                                        <span *ngIf="store.validated === 1; else noValid" class="badge badge-success">Validada</span>
                                        <ng-template #noValid>
                                            <span class="badge badge-secondary">Falta validación</span>
                                        </ng-template>                                    
                                        <a target="_blank" href="https://{{store.id_shop}}" class="d-block mt-2 ms-1 mb-1">{{store.id_shop}}</a>
                                        <p class="ms-1">Fecha de registro: {{formatDate(store.created_at)}}</p>
                                    </div>
                                </td>
                                <td align="right">
                                    <button (click)="openDialogShowToken(store.token)" class="btn btn-hover-effect btn-primary f-w-500 mt-5" href="#" target="_blank">Ver token</button>

                                    <div class="d-block pe-3 mt-1">
                                        <button  mat-icon-button matTooltip="Editar alias" [matTooltipPosition]="'above'" (click)="editStore(store)" color="primary">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Eliminar" [matTooltipPosition]="'above'" color="warn" (click)="deleteStore(store.id)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="col-3">
            <div class="row justify-content-center">
                <div class="col-xxl-3 col-xl-4 col-sm-6 box-col-6 wow zoomIn w-100">
                    <div class="card purchase-card discover">
                        <div class="row justify-content-center p-3">
                            <img class="img-fluid w-75" src="assets/images/shopify.png" />
                        </div>
                        <div class="card-body pt-3 row justify-content-center">
                            <h5 class="mb-1">Vincula tu tienda</h5>
                            <p class="f-light">Integra tu tienda de Shopify con Búho Wing</p>
                            <button (click)="openDialogGenerateToken()" class="btn btn-hover-effect btn-primary f-w-500" href="#" target="_blank">Comenzar ahora</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div> -->
<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Configuración de Ecommerce</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon>storefront</mat-icon></a></li>
                    <li class="breadcrumb-item">Configuración de Ecommerce</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card stores purchase-card discover">
                <div class="card-body empty">
                    <!-- <div class="d-flex justify-content-center mt-5">
                        <mat-spinner></mat-spinner>
                    </div> -->
                    <!-- <h3 class="text-center mt-4">Terminando configuración</h3> -->
                    <h1>Registra tu tienda</h1>
                    <form autocomplete="off" [formGroup]="registerForm" (ngSubmit)="ruSure()" class="mt-3" id="registerForm">
                        <!-- <small>Todos los campos son obligatorios *</small> -->
                        <div class="row mt-2">
                            <div class="col-sm-4">
                            <p class="f-light">Confirma el URL de tu tienda: <p>
                              <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Nombre de la tienda</mat-label>
                                <input matInput readonly formControlName="id_shop">
                                <!-- <span matSuffix>.myshopify.com</span> -->
                              </mat-form-field>
                            </div>
                          </div>
                    </form>
                    <button mat-flat-button class="bg-success text-white" form="registerForm" type="submit">Confirmar <mat-icon>save</mat-icon></button>
                </div>
            </div>
        </div>
</div>