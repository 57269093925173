import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { packagesModel } from 'src/app/models/shipment.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-shipments-checkout',
  templateUrl: './shipments-checkout.component.html',
  styleUrls: ['./shipments-checkout.component.scss']
})
export class ShipmentsCheckoutComponent implements OnInit {
  userObj!: UserModel;
  info!:any;
  base64!:string;
  labelName!:string;
  packages!:[packagesModel];
  clicked = false;
  alias!: string;
  
  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
    private userS: UsersService,
    private routes: Router


  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.info = this.data;
    this.packages = this.data.data.packages;
    this.alias =  this.data.fav && this.data!.fav!.alias ? this.data.fav.alias : "";
  }

  close(){
    return this.info.data
  }

  edit(index: number){
    return index
  }

  goToFavorites(){
    this.routes.navigateByUrl("/admin/myfavorites");
  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }

  substractExtendedZonePrice(number: any) {
    try{
      //Verificar si number es string
      if(typeof number === 'string'){

        //Extraer la primera letra y guardarla en una variable
        let firstLetter = number.charAt(0);

        //Si la primera letra es algún signo de moneda, eliminarlo
        if(firstLetter === '$' || firstLetter === '€' || firstLetter === '£'){
          number = number.slice(1);
        }
        number = parseFloat(number);

        let substract = number - 200.00;
        //Redondear a dos decimales y agregar signo de moneda
        let total = firstLetter + substract.toFixed(2);
  
        return total;

      } else {
        let substract = number - 200.00;
        //Redondear a dos decimales
        let total =  substract.toFixed(2);
  
        return total;
      }
    }catch(e){
      return 0;
    }
  }

  addExtendedZonePrice(number: any) {
    try{
      //Verificar si number es string
      if(typeof number === 'string'){

        //Extraer la primera letra y guardarla en una variable
        let firstLetter = number.charAt(0);

        //Si la primera letra es algún signo de moneda, eliminarlo
        if(firstLetter === '$' || firstLetter === '€' || firstLetter === '£'){
          number = number.slice(1);
        }
        number = parseFloat(number);

        let add = number + 200.00;
        //Redondear a dos decimales y agregar signo de moneda
        let total = firstLetter + add.toFixed(2);
  
        return total;

      } else {
        let add = number + 200.00;
        //Redondear a dos decimales
        let total =  add.toFixed(2);
  
        return total;
      }
    }catch(e){
      return 0;
    }
  }
}
