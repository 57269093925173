import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { Observable } from 'rxjs';

import { Pickup } from 'src/app/models/shipment.model'
import { PickupsService } from 'src/app/services/pickups.service';


@Component({
  selector: 'app-pickup-list',
  templateUrl: './pickup-list.component.html',
  styleUrls: ['./pickup-list.component.scss']
})
export class PickupListComponent implements OnInit {
  columns: string[] = ["id", "address", "date", "content", "fee", "user", "actions"];
  loading: boolean = true;
  pickups!: Pickup[];

  constructor(private pickupsService: PickupsService) { }

  ngOnInit(): void {
    this.getPickups();
  }

  /* string collected(1):
     If the pickup may have already been collected, sends a style change to the UI.
     < pickupDate: The date of the pickup appointment.
     < pickupET: The ending limit for the pickup appointment to happen.
     > The style clause or nothing. */
  collected(pickupDate: Date, pickupET: number): string {
    const today: Date = new Date();
    pickupDate.setHours(pickupET, 0, 0, 0);

    return (pickupDate < today) ? "color: #B7B7B7;" : "";
  }

  /* Array<Pickup> fetchPickups(0):
     Fetches the pending pickup appointments' information. */
  getPickups(): void {
    this.pickupsService.getPickups().subscribe({
      next: (data: any) => {
        this.pickups = data;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching pickups: ', error);
        this.loading = false;
    }});
  }

  /* void showManifest(1):
     Gets the manifest of the pickup appointment and shows it.
     < xID: The pickup appointment's ID. */
  showManifest(xID: number): void {
    const pickup: Pickup | undefined = this.pickups.find(x => x.id === xID); // Select pickup

    if (pickup == undefined)
      throw new Error(`Pickup with ID ${xID} does not exist or has not been retrieved.`);

    // If the manifest is already in the database, show the file.
    if (pickup.manifestURL !== null) {
      window.open(pickup.manifestURL, '_blank');
    } else {
      // If not, create it.
      let pme: Array<any>;

      this.pickupsService.getManifestElements(xID).subscribe({
        next: (data: any) => {
          pme = data;
          if (pme.length === 0)
            throw new Error(`Pickup with ID ${xID} has no known shipments attached to it or was not found in the database.`);

          this.pickupsService.generateManifest(pme);
      }});
    }
  }

  /* string toTime(1):
     Formats an integer to 12H time string for display.
     < x: The input number.
     > The formatted output. */
  toTime(x: number): string {
    return `${x % 12 === 0 ? 12 : x % 12} ${x >= 12 ? 'PM' : 'AM'}`;
  }
}
