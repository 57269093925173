import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StpCreditHistory, StpPendingOrder } from 'src/app/models/Stp.model';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-clabe-transfers',
  templateUrl: './clabe-transfers.component.html',
  styleUrls: ['./clabe-transfers.component.scss']
})
export class ClabeTransfersComponent implements OnInit {
[x: string]: any;
  //formC!: FormGroup;
  isAdminStrategic:boolean=false;
  idsAdmStr=[1,2];
  isLoading = true;
  id_company=0;
  userCredit=0;
  currency='';
  company_name='';
  cuenta_clabe='';
  mensaje='';
  showBankInfo: boolean = false;
  pendingsOrders: Array<StpPendingOrder>=[];

  userObj!: UserModel;
  billingDataList =  new Array<StpCreditHistory>();

  showFilter=false;
  preLoadHidden = true;

  columnDefinitions = [
    { def:'id', label: 'id', hide: true},
    { def:'id_company', label: 'id_company', hide: true},
    { def:'amount', label: 'Importe', hide: false},
    { def:'reference_number', label: 'Referencia', hide: false},
    { def:'tracking_key', label: 'No. Rastreo', hide: false},
    { def:'previous_credit', label: 'previous_credit', hide: true},
    { def:'observations', label: 'Información', hide: true},
    { def:'id_user_creator', label: 'id_user_creator', hide: true},
    { def:'id_stp_collection', label: 'id_stp_collection', hide: true},
    { def:'created_at', label: 'Creado', hide: false},
    { def:'company_name', label: 'company_name', hide: true},
    { def:'transaction_date', label: 'transaction_date', hide: true},
    { def:'beneficiary_account', label: 'beneficiary_account', hide: true},
    { def:'concept_payment', label: 'concept_payment', hide: true},
    { def:'added_credit', label: 'Agregado', hide: true},
    { def:'conciliation', label: 'Conciliado', hide: true},
    { def:'url_cep', label: 'url_cep', hide: true},
    { def:'conciliation_date', label: 'conciliation_date', hide: true},
    { def:'status', label: 'Estatus', hide: true},
    { def:'status_group', label: 'Estado', hide: false},
    { def:'email', label: 'email', hide: true},
    { def:'nombre', label: 'nombre', hide: true},
    { def:'actions', label: '', hide: true}
  ]
  showOptions = false;
  getDisplayedColumns():string[] {
    return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
  }

  getDisplayedLabel(def:string):string {
    let cLabel=this.columnDefinitions.find(item=> item.def==def);
    return cLabel?.label||''
  }
  
  dataSource!: MatTableDataSource<StpCreditHistory>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  get p_id_company():any { return this.userS.sharedId; } set p_id_company(value: any) { this.userS.sharedId = value; }

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<ClabeTransfersComponent>,
    private matDialog: MatDialog
  ) {
    this.isAdminStrategic = this.idsAdmStr.includes(this.userS.getUser().id_rol);
   }

  
  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.createForm();
    this.setUserForm();
    this.checkStpClabe();
    this.getBillingDataList();
  }

  getBillingDataList() {
    this.billingDataList = [];
    this.pendingsOrders = [];
    this.userS.getCreditHistorySummary(this.id_company).subscribe(
      (result : any) => {
        this.billingDataList = result;
        this.mergePendingsAndOrders();
        this.getPendingsOrders(this.id_company);
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
        this.getPendingsOrders(this.id_company);
      }
    );

        
  }

  getPendingsOrders(id_company:number) {
    this.pendingsOrders = [];
    this.userS.getPendingsOrders(id_company).subscribe(
      (result:any) => {
        this.pendingsOrders = result;
        this.mergePendingsAndOrders();
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  mergePendingsAndOrders(){
    let arrAux: Array<StpCreditHistory>=[];
    if(this.pendingsOrders && this.pendingsOrders.length>0){
      for (let index = 0; index < this.pendingsOrders.length; index++) {
        const element = this.pendingsOrders[index];
        let aux={
          id:0,
          id_company:element.id_company,
          amount: element.amount,
          previous_credit:0,
          observations: `Autorización pendiente`,
          id_user_creator:0,
          id_stp_collection:-1,
          created_at: element.created_at,
          company_name: '',
          transaction_date: element.transaction_date,
          tracking_key: element.tracking_key,
          beneficiary_account: element.beneficiary_account,
          concept_payment: element.concept_payment,
          reference_number: element.reference_number,
          added_credit: 0,
          conciliation: 0,
          url_cep: '',
          conciliation_date: element.created_at,
          status: 'AUTORIZACIÓN PENDIENTE',
          status_group: element.status_group,
          email: '',
          nombre: ''
        };
        arrAux.push(aux);
      }
      //this.billingDataList=arrAux;
      this.billingDataList=this.billingDataList.concat(arrAux);
      console.log("BDL: ", this.billingDataList);
    }

    this.dataSource = new MatTableDataSource(this.billingDataList);        
    this.dataSource.paginator = this.paginator;
    //this.dataSource.paginator.pageSize=this.billingDataList.length;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
    this.preLoadHidden = false;

    console.log(this.dataSource);
  }

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPageSizeOptions(): number[]{
    if(this.billingDataList.length>10)
    return [10,50,100,this.billingDataList.length];
    else
    return [10,50,100];
  }
  
  getTotalService() {
    return (this.billingDataList.map(t => t.amount).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
  }

  getTotalPrice() {
    return (this.billingDataList.map(t => t.amount).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
  }
  
  toggleColumn(columnName: string, event: MatCheckboxChange) {
    let columnHS=this.columnDefinitions.find( item=> item.def==columnName);
    if(columnHS){
      columnHS.hide=!event.checked;
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  showInfo(show: boolean | undefined): void {
    if (show !== undefined) this.showBankInfo = show;
    else this.showBankInfo = !this.showBankInfo;
  }


  createForm() {
    // this.formC = this.fb.group({
    //   id_company_credit: ['',[]],
    //   id_company: ['',[]],
    //   credit: ['',[]],
    //   observations: ['',[]],
    //   add_credit: ['', [ Validators.required]],
    // });
  }
  setUserForm() {
    this.company_name = this.data.company.name;
    this.id_company = this.data.company.id_company;
    this.userCredit = this.data.userCredit;
    this.currency = this.data.currency;
    this.pendingsOrders = this.data.pendingsOrders;
    //console.log(this.data);
    //const { active, email, id, role, rol_name, ...userFormInfo } = this.data.company;
    //this.formC.setValue(this.data);
  }
  checkStpClabe() {
    let json: any = [];
    this.userS.getCuentaClabe(this.id_company).subscribe(
      (result : any) => {
        if(result.message=="Success"){
          json=result.data;
          console.log(result.data);
          if(result.data.length>0){
            this.cuenta_clabe = result.data[0].clabe;
          }
          this.isLoading = false;
        }
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );

    return json;
  }

  formatClabe(clabe: String): String {
    return clabe.replace(/(\d{3})(\d{3})(\d{11})(\d{1})/, '$1 $2 $3 $4');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  icon(value: string, attribute: string = "icon"): string {
    if (attribute == 'icon') {
      if (value == 'credited') return 'check_circle';
      else if (value == 'not_credited') return 'cancel';
      else if (value == 'attention') return 'error';
    } else if (attribute == 'color') {
      if (value == 'credited') return '#09AE0C';
      else if (value == 'not_credited') return '#E00031';
      else if (value == 'attention') return '#CABD2B';
    } else if (attribute == 'tooltip') {
      if (value == 'credited') return 'Acreditado';
      else if (value == 'not_credited') return 'No acreditado';
      else if (value == 'attention') return 'Contáctanos para corroborar el estado de tu pago';
    }
    return '';
  }

  copyClabe(): void {
    if(this.cuenta_clabe && this.cuenta_clabe!=''){
      const textarea = document.createElement('textarea');
      textarea.value = this.cuenta_clabe;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
  
      this.mensaje = '¡CLABE copiada!';
      
      // Después de un tiempo, borra el mensaje
      setTimeout(() => {
        this.mensaje = '';
      }, 3000); // El mensaje se borrará después de 3 segundos
    }
  }

  createClabe() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.userS.createCuentaClabe(this.id_company).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          if(result.clabe){
            this.cuenta_clabe = result.clabe;
          }
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Se ha asignado la CLABE de manera correcta!'          
          }).then(() => {
            //console.log((this.formC.value.credit*1) + (this.formC.value.add_credit*1) );
          });
        }
      },
      (err:any) => {        
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
      }
    );
  }

  statusName(status:string){
    let sStatus="";
    if(status){
      switch (status.toUpperCase()) {
        case 'D':
          sStatus='Devuelta';
          break;
        case 'LQ':
          sStatus='Liquidada';
          break;
        case 'CCE':
          sStatus='Confirmación enviada';
          break;
        case 'CXO':
          sStatus='Por enviar confirma';
          break;
        case 'CCO':
          sStatus='Confirmada';
          break;
        case 'CCR':
          sStatus='Confirmación rech';
          break;
        default:
          break;
      } 
    }
    return sStatus;
  }

  goToCreditHistory() {
    this.p_id_company=this.id_company;
    this.dialogRef.close({ reload: false, hisory:true });
    this.router.navigateByUrl('/admin/credit-history');
  }
  

  ruSure() {
    //if(this.formC.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas añadir los créditos?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.save();
      }
    });
  }

  save() {
    // Swal.fire({
    //   icon: 'info',
    //   title: 'Guardando...',
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   showConfirmButton: false,
    //   didOpen: () => {
    //     Swal.showLoading(null);
    //   }
    // });
    // this.userS.addCreditCompany(this.formC.value.id_company_credit, this.formC.value.id_company, this.formC.value.add_credit, this.formC.value.observations).subscribe(
    //   (result:any) => {
    //     if(result.message == "Success") {
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Guardado',
    //         text: 'Compañía guardada de manera correcta!'          
    //       }).then(() => {
    //         //console.log((this.formC.value.credit*1) + (this.formC.value.add_credit*1) );
    //         this.dialogRef.close({ reload: false, credit: (this.formC.value.credit*1) + (this.formC.value.add_credit*1) });
    //       });
    //     }
    //   },
    //   (err:any) => {        
    //       console.log(err);
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Error al guardar',
    //         text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
    //       });
    //   }
    // );
  }

}
