<div class="header-wrapper row m-0">
    <div class="left-header horizontal-wrapper ps-0 col-sm-8">
        <ul class="horizontal-menu">
            <li class="level-menu outside ">
                <button mat-icon-button class="me-3 sidebar-menu-btn" (click)="menuToggle()"><mat-icon>menu</mat-icon></button>
            </li>
            <li class="level-menu outside">
                <button mat-stroked-button class="btn btn-primary" matTooltip="Genera un nuevo envío" [matTooltipShowDelay]="1000" (click)="goToShipmentAdd()"><mat-icon>local_shipping</mat-icon><span class="btn-no-text"> Crear envío</span></button>
            </li>
            <!-- <li class="level-menu outside">
                <button mat-stroked-button class="btn btn-outline-secondary ms-4" matTooltip="Contacta con equipo de soporte" [matTooltipShowDelay]="1000"><mat-icon>help</mat-icon><span class="btn-no-text"> Ayuda</span></button>
            </li> -->
        </ul>
    </div>
    <div class="nav-right col-8 col-sm-2 pull-right right-header p-0 col-sm-4">
        <ul class="nav-menus">
            <li>
                <button mat-stroked-button color="accent" (click)="openDialogAddCredit()">
                    <span *ngIf="pendingsOrders.length>0" [ngClass]="{'badge': true , 'bg-primary': true }" mat-icon-button matTooltip="{{tooltipPendings()}}" [matTooltipPosition]="'above'">{{pendingsOrders.length}}</span>
                    <mat-icon class="me-1 align-middle text-success">payments</mat-icon> <span class="h6 text-muted">{{userCredit | currency }} {{currency}}</span>
                </button>
            </li>
            <li class="profile-nav onhover-dropdown p-0 me-0">
                <div class="media profile-media"><img class="b-r-10" src="./../../../../assets/images/logo_bl_sm.png" alt="">
                    <div class="media-body"><span>{{userObj.name}}</span>
                        <p class="mb-0 font-roboto">{{roleName ? roleName : "admin"}}<i class="middle fa fa-angle-down"></i></p>
                    </div>
                </div>
                <ul class="profile-dropdown onhover-show-div">
                    
                    <li><a href="#" (click)="logOut()"><span><mat-icon class="align-middle me-2">logout</mat-icon>Salir</span></a></li>
                </ul>
            </li>
        </ul>
    </div>
</div>