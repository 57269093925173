<!-- select and over all row -->
<div class="modal-header pb-3">
    <h1 class="modal-title">Resumen{{(alias ? ": " + alias : "")}}</h1>
    <button tabindex="-1" type="button" class="btn-close" mat-dialog-close ></button>
    
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col">   
                <div class="card card-absolute shadow shadow-showcase">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">Origen <mat-icon class="align-middle">pin_drop</mat-icon>
                        </h5>
                    </div>
                    <div class="col-1 align-self-end position-absolute text-end pt-2 pe-3">
                        <!-- <button matTooltip="Editar" mat-icon-button  color="primary" [matTooltipPosition]="'above'" [mat-dialog-close]="edit(0)"><mat-icon>edit</mat-icon></button> -->
                    </div>
                    <div class="card-body">
                        <mat-list role="list">
                            <div class="container">
                                <div class="row">
                                    <div class="col">   
                                        <mat-list-item role="listitem"><strong class="pe-2">Nombre:</strong>{{data.data.from_address.name}}</mat-list-item>
                                    </div>
                                    <div class="col">   
                                        <mat-list-item role="listitem"><strong class="pe-2">Empresa:</strong>{{data.data.from_address.company}}</mat-list-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Email:</strong>{{data.data.from_address.email}}</mat-list-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Teléfono:</strong>{{data.data.from_address.phone}}</mat-list-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Dirección:</strong>{{ data.data.from_address.street }} {{ data.data.from_address.number }}, {{ data.data.from_address.suburb }}, {{ data.data.from_address.city }}, {{ data.data.from_address.state }}, {{data.data.from_address.country}}, CP: {{ data.data.from_address.postalCode }}</mat-list-item>
                                    </div>
                                </div>
                            </div>
                        </mat-list>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card card-absolute shadow shadow-showcase">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">Destino <mat-icon class="align-middle">person_pin_circle</mat-icon>
                        </h5>
                    </div>
                    <div class="col-1 align-self-end position-absolute text-end pt-2 pe-3">
                        <!-- <button matTooltip="Editar" mat-icon-button  color="primary" [matTooltipPosition]="'above'" [mat-dialog-close]="edit(1)"><mat-icon>edit</mat-icon></button> -->
                    </div>
                    <div class="card-body">
                        <mat-list role="list">
                            <div class="container">
                                <div class="row">
                                    <div class="col">   
                                        <mat-list-item role="listitem"><strong class="pe-2">Nombre:</strong>{{data.data.to_address.name}}</mat-list-item>
                                    </div>
                                    <div class="col">   
                                        <mat-list-item role="listitem"><strong class="pe-2">Empresa:</strong>{{data.data.to_address.company}}</mat-list-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Email:</strong>{{data.data.to_address.email}}</mat-list-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Teléfono:</strong>{{data.data.to_address.phone}}</mat-list-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Dirección:</strong>{{ data.data.to_address.street }} {{ data.data.to_address.number }}, {{ data.data.to_address.suburb }}, {{ data.data.to_address.city }}, {{ data.data.to_address.state }}, {{data.data.to_address.country}}, CP: {{ data.data.to_address.postalCode }}</mat-list-item>
                                    </div>
                                </div>
                            </div>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card card-absolute shadow shadow-showcase col">
                    <div class="card-header bg-primary">
                    <h5 class="text-white">Paqueteria  <mat-icon class="align-middle">view_in_ar</mat-icon>
                    </h5>
                    </div>
                    <div class="col-1 align-self-end position-absolute text-end pt-2 pe-3">
                        <!-- <button matTooltip="Editar" mat-icon-button  color="primary" [matTooltipPosition]="'above'" [mat-dialog-close]="edit(3)"><mat-icon>edit</mat-icon></button> -->
                    </div>
                    <div class="card-body">
                        <mat-list role="list">
                            <div class="container">
                                <div class="alert alert-light-warning" role="alert" *ngIf="data.fav&&(!data.fav.servicio||!data.fav.precio)" style="margin-bottom: 0.5rem;">
                                    <p class="text-orange">
                                        <mat-icon class="align-middle">warning</mat-icon>
                                        {{ data.fav.servicio ? 
                                            data.fav.precio ? 
                                                '' : 
                                            'El precio actual es diferente al envío anterior (' + (data.fav.total_price | currency) + ')':
                                            'Este servicio no esta disponible, por favor selecciona otro.'
                                        }}
                                    </p>
                                  </div>
                                <div class="alert alert-light-warning" role="alert" *ngIf="!info.data.selected_rate.enough" style="margin-bottom: 0.5rem;">
                                    <p class="text-orange">
                                        <mat-icon class="align-middle">warning</mat-icon>
                                        Créditos insuficientes
                                    </p>
                                </div>
                                <div class="row">
                                    <div class="col">   
                                        <mat-list-item role="listitem"><strong class="pe-2">Servicio:</strong>{{info.data.selected_rate.service}}</mat-list-item>
                                    </div>
                                    <div class="col">
                                        <mat-list-item role="listitem">
                                            <img src="../../../assets/images/carriers/{{imgFormat(info.data.selected_rate.carrier)}}-sm.jpg" alt="{{imgFormat(info.data.selected_rate.carrier)}}">
                                        </mat-list-item>
                                    </div>
                                </div>
                                <div class="row" *ngIf="info.data.selected_rate.isZoneExt == true; else noExtArea">
                                    <div class="col-12">   
                                        <!-- <mat-list-item role="listitem"><strong class="pe-2">Precio:</strong>{{substractExtendedZonePrice(info.data.selected_rate.total_price | currency)}}</mat-list-item> -->
                                        <!-- <mat-list-item role="listitem" style="margin-left: 48px; margin-top: -20px;">+ $200 Zona extendida</mat-list-item> -->
                                    </div>
                                    <div class="col">   
                                        <!-- <mat-list-item role="listitem"><strong class="pe-2">Precio con IVA:</strong>{{substractExtendedZonePrice(info.data.selected_rate.total_price_iva | currency)}}</mat-list-item> -->
                                        <mat-list-item role="listitem"><strong class="pe-2">Zona extendida:</strong>$200</mat-list-item>
                                        <mat-list-item role="listitem"><strong class="pe-2">Total:</strong>{{info.data.selected_rate.total_price_iva | currency}}</mat-list-item>
                                    </div>
                                </div>
                                <ng-template #noExtArea>
                                    <div class="row">
                                        <div class="col-12">   
                                            <mat-list-item role="listitem"><strong class="pe-2">Precio:</strong>{{info.data.selected_rate.total_price | currency}}</mat-list-item>
                                        </div>
                                        <div class="col">   
                                            <mat-list-item role="listitem"><strong class="pe-2">Precio con IVA:</strong>{{info.data.selected_rate.total_price_iva | currency}}</mat-list-item>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </mat-list>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card card-absolute shadow shadow-showcase col">
                    <div class="card-header bg-primary">
                      <h5 class="text-white">Paquetes  <mat-icon class="align-middle">view_in_ar</mat-icon>
                      </h5>
                    </div>
                    <div *ngIf=" (data.data.packages[0].length && data.data.packages[0].width && data.data.packages[0].height) != null && clicked" class="col-1 align-self-end position-absolute text-end pt-2 pe-3">
                        <button tabindex="-1" matTooltip="Editar" mat-icon-button  color="primary" [matTooltipPosition]="'above'" (click)="openDialogSetDimensions()"><mat-icon>edit</mat-icon></button>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">   
                                <mat-list-item role="listitem"><strong class="pe-2">Número de orden:</strong>{{data.data.shipmentId}}</mat-list-item>
                            </div>
                        </div>
                        <div *ngFor="let package of packages; index as i"  class="row align-items-center justify-content-center">
                            <mat-list role="list">
                                <b class="fs-6">Paquete {{i+1}}</b>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Tipo de paquete:</strong>{{ data.data.packages[i].shipmentType  == 'box' ? 'Caja' : data.data.packages[i].shipmentType == 'envelope' ? 'Sobre' : 'Tarima' }}</mat-list-item>
                                    </div>
                                    <div *ngIf="(data.data.packages[i].length && data.data.packages[i].width && data.data.packages[i].height) != null" class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Medidas:</strong>{{ data.data.packages[i].length}}x{{ data.data.packages[i].width}}x{{ data.data.packages[i].height}}, {{ data.data.packages[i].weight}} kg</mat-list-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-list-item role="listitem"><strong class="pe-2">Contenido:</strong>{{data.data.packages[i].content}}</mat-list-item>
                                    </div>
                                </div>
                                <mat-divider *ngIf="packages.length > 1" class="mt-3"></mat-divider>
                            </mat-list>
                        </div>
                    </div>
                </div>
            </div>

                

        </div>
    </div>

</div>
<div class="modal-footer" *ngIf="(data.fav==null || (data.fav != null && data.fav.servicio==true )) && info.data.selected_rate.enough">
    <button tabindex="-1" mat-stroked-button class="btn btn-outline-info me-3" (click)="sendToForm(data.data);" mat-dialog-close>Editar información <mat-icon>edit</mat-icon></button>
    <button tabindex="-1" *ngIf="(data.data.packages[0].length && data.data.packages[0].width && data.data.packages[0].height) === null; else getRate" (click)="openDialogSetDimensions()" mat-flat-button class="btn bg-success text-white me-3">Ingresar medidas <mat-icon>straighten</mat-icon></button>
    <ng-template #getRate>
        <button tabindex="-1" (click)="getLabel()"  mat-flat-button class="btn bg-success text-white me-3" form="passwordForm">Comprar guía <mat-icon>receipt</mat-icon></button>
    </ng-template>
</div>