import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as printJS from 'print-js';


@Component({
  selector: 'app-shipments-label-actions',
  templateUrl: './shipments-label-actions.component.html',
  styleUrls: ['./shipments-label-actions.component.scss']
})
export class ShipmentsLabelActionsComponent implements OnInit {

  labelName!:string;
  base64!:string;
  base64Bol!:string;

  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
  ) { }

  ngOnInit(): void {
    this.labelName = this.data.data.labelName;
    this.base64 = this.data.data.base64;
    this.base64Bol = this.data.data.base64Bol;
  }

  downloadPdf(base64String:string, fileName:string, isBol:boolean = false){
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${isBol?'Factura-':''}${fileName}.pdf`
    link.click();
  }

  onClickDownloadPdf(sbase64:string, isBol:boolean = false){
    let base64String = sbase64;
    this.downloadPdf(base64String, this.labelName, isBol);
  }

  print(sbase64:string) {
    printJS({ printable: sbase64, type: "pdf", base64: true})
  }

}
