import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { CurrencyModel, UserModel, UserModelMin, UserSaveModel } from '../models/user.model';
import { AddressModel } from '../models/address.model';
import { MainService } from './main.service';
import jwt_decode from 'jwt-decode';
import { FavShipModel, globalObject, ShipmentModel, BillingDataModel, ShipmentUnsuccessfully, ServicesModeModel, EquivalentCarSer } from '../models/shipment.model';
import { CommissionTypesModel, CompanyCompleteModel, CompanyModel, CompanyOptionsModel, CompanySaveModel, CountryModel } from '../models/company.model';
import { BulkUploadSaveModel } from '../models/bulk_upload.model';
import { StpCreditHistory, StpOrderModel, StpPendingOrder } from '../models/Stp.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private dataPipe: any | null = null;
  
  sharedData : FavShipModel = new FavShipModel;
  sharedOrderUnsuccessfully : ShipmentUnsuccessfully = new ShipmentUnsuccessfully;
  sharedBulkUpload : any = [];
  sharedId : number = 0;

  constructor(
    private main: MainService
  ) { }

  getUser() : UserModel {
    return jwt_decode(localStorage.getItem('token')!);
  }

  getUserList() {
    return this.main.postRequest({}, "/users/show").pipe(
      map((resp: any) => {
        let userList: Array<UserModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
              userList.push(e);
          });
        }     
        return userList;
      })
    );
  }

  getUserListMin() {
    return this.main.getRequest("/users/showmin").pipe(
      map((resp: any) => {
        let userList: Array<UserModelMin> = [];
        if(resp.message == "Success") {
          resp.data.forEach((e:any) => {
              userList.push(e);
          });
        }     
        return userList;
      })
    );
  }

  deleteUser(id: string) {
    return this.main.deleteRequest(`/users/delete/${ id }`);
  }

  changeStatusUser(id: number, active: number, idServ: number) {
    var userModel = new UserSaveModel();
    userModel.id_user = id;
    userModel.active = active;
    userModel.id_serv = idServ;
    return this.main.postRequest(userModel, "/users/edit/");
  }

  saveUser(userModel: UserSaveModel) {    
    const { passConfirm, id_user, active, ...rest } = userModel;
    return this.main.postRequest(rest, "/users/create");
  }

  editUser(userModel: UserSaveModel, id_user: number) {
    userModel.id_user = id_user;
    const { passConfirm, email, active, ...rest } = userModel;
    return this.main.postRequest(rest, "/users/edit");
  }

  getPermissionList(id_rol: number) {
    return this.main.getRequest("/users/permissions/" + id_rol);
  }

  saveAddress(address: AddressModel) {
    return this.main.postRequest(address, "/users/save-address/");
  }

  
  getAddressList( isOrigin:number ){
    return this.main.getRequest("/users/get-address-list/"+isOrigin).pipe(
      map((resp: any) => {
        let addressList: Array<AddressModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
            addressList.push(e);
          });
        }     
        return addressList;
      })
      );
    }
    
    getPcData(postalCode: String) {
      return this.main.getRequest("/shipment/pc-info/"+postalCode);
    }
    
    getAddressCountries() {
      return this.main.getRequest("/shipment/countries");
    }
    getIntPcData(id: String, countryCode:string) {
      return this.main.getRequest("/shipment/int-pc-info/"+id+'/'+countryCode);
    }
    getIntStates(id: String) {
      return this.main.getRequest("/shipment/int-states/"+id);
    }
    getIntCity(countryCode:string, state:string) {
      return this.main.getRequest("/shipment/int-city/"+state+'/'+countryCode);
    }
    getIntSuburb(city: String, countryCode:string) {
      return this.main.getRequest("/shipment/int-suburb/"+city+'/'+countryCode);
    }

    getLabel(globalObject:globalObject){
      return this.main.postRequest(globalObject,"/shipment/get-label");
    }

    getRates(globalObject:globalObject){
      return this.main.postRequest(globalObject,"/shipment/get-rates");
    }

    findLabel(trackingNumber:number){
      return this.main.postRequest({trackingNumber},"/shipment/findLabel");
    }

    getShipmentList() {
      return this.main.postRequest({}, "/shipment/show/tracking-guides").pipe(
        map((resp: any) => {
          let shipmentList: Array<ShipmentModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              shipmentList.push(e);
            });
          }     
          return shipmentList;
        })
      );
    }
    
    getBillingDataList(date_i : string|null = null, date_f : string|null = null, users : string|null = null, companies : string|null = null, currency : string|null = null) {
      let param={
        "date_i" : date_i,
        "date_f" : date_f,
        "users" : users,
        "companies" : companies,
        "currency": currency
      };
      return this.main.postRequest(param, "/shipment/show/list-billing-data").pipe(
        map((resp: any) => {
          let billingdataList: Array<BillingDataModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              billingdataList.push(e);
            });
          }     
          return billingdataList;
        })
      );
    }

    getBillingDataListSf(date_i : string|null = null, date_f : string|null = null) {
      let param={
        "date_i" : date_i,
        "date_f" : date_f
      };
      return this.main.postRequest(param, "/shipment/show/list-billing-data-sf").pipe(
        map((resp: any) => {
          let billingdataList: Array<BillingDataModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              billingdataList.push(e);
            });
          }     
          return billingdataList;
        })
      );
    }

    getBillingDataListSiteflow(date_i : string|null = null, date_f : string|null = null) {
      let param={
        "date_i" : date_i,
        "date_f" : date_f
      };
      return this.main.postRequest(param, "/shipment/show/list-billing-data-mwsf").pipe(
        map((resp: any) => {
          let billingdataList: Array<BillingDataModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              billingdataList.push(e);
            });
          }     
          return billingdataList;
        })
      );
    }

    getListServicesMode() {
      return this.main.getRequest( "/shipment/show/list-services-mode").pipe(
        map((resp: any) => {
          let ls: Array<ServicesModeModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              ls.push(e);
            });
          }     
          return ls;
        })
      );
    }

    getListEquivalentCarSer() {
      return this.main.getRequest( "/shipment/show/list-equivalent-carser").pipe(
        map((resp: any) => {
          let ls: Array<EquivalentCarSer> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              ls.push(e);
            });
          }     
          return ls;
        })
      );
    }

    getFavShipmentsList() {
      return this.main.getRequest("/shipment/get-fav-shipments").pipe(
        map((resp: any) => {
          let shipmentList: Array<FavShipModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              shipmentList.push(e);
            });
          }     
          return shipmentList;
        })
      );
    }

    getShipment(id: string) {
      return this.main.getRequest(`/shipment/show/${ id }`);
    }

    addFavAddress(obj:FavShipModel){
      return this.main.postRequest({obj},"/shipment/save-fav-shipment");
    }

    deleteFavAddress(id: string) {
      return this.main.deleteRequest(`/shipment/fav-shipment/delete/${ id }`);
    }

    deleteAddress(id: string) {
      return this.main.deleteRequest(`/users/address/delete/${ id }`);
    }

    getConfigList() {
      return this.main.getRequest("/users/get-config-list");
    }

    getUserConfigList(id_user:any) {
      return this.main.getRequest("/users/get-user-config-list/"+id_user);
    }

    getCompanyConfigList(id_company:any) {
      return this.main.getRequest("/company/get-config-list/"+id_company);
    }

    getCredit(id_user:any){
      return this.main.getRequest("/users/get-user-credit/"+id_user);
    }
    getCreditCompany(){
      return this.main.getRequest("/users/get-user-credit/");
    }
    getCurrentCreditCompany(id_company:number){
      return this.main.getRequest("/company/get-credit/"+id_company);
    }

    private subCredit = new Subject<any>();
    sendCreditEvent() {
      this.subCredit.next(null);
    }
    getCreditEvent(): Observable<any>{ 
      return this.subCredit.asObservable();
    }

    getCompanies() {
      return this.main.getRequest("/users/get-companies").pipe(
        map((resp: any) => {
          let list: Array<CompanyModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              list.push(e);
            });
          }     
          return list;
        })
      );
    }

    getCompaniesList() {
      return this.main.getRequest("/company/get-list").pipe(
        map((resp: any) => {
          let list: Array<CompanyCompleteModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              list.push(e);
            });
          }     
          return list;
        })
      );
    }

    getCommissionTypes() {
      return this.main.getRequest("/company/get-commission-types").pipe(
        map((resp: any) => {
          let list: Array<CommissionTypesModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              list.push(e);
            });
          }     
          return list;
        })
      );
    }

    getCountries() {
      return this.main.getRequest("/company/get-countries").pipe(
        map((resp: any) => {
          let list: Array<CountryModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              list.push(e);
            });
          }     
          return list;
        })
      );
    }

    getCompany(id:number){
      return this.main.getRequest("/company/"+id);
    }

    getPurchaseOptions(id:number) {
      return this.main.getRequest("/company/get-purchase-options/"+id).pipe(
        map((resp: any) => {
          let list: Array<CompanyOptionsModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              list.push(e);
            });
          }     
          return list;
        })
      );
    }

  
    saveCompany(model: CompanySaveModel) {
      model.active = 1;
      if (model.commission_cantidad == '' || model.commission_cantidad === null || model.commission_cantidad === undefined)
        model.commission_cantidad = "0";
      if (model.commission_int_cantidad == '' || model.commission_int_cantidad === null || model.commission_int_cantidad === undefined)
        model.commission_int_cantidad = "0";
      if (model.credit == '' || model.credit === null || model.credit === undefined)
        model.credit = "0";
      const { id_company, id_commission_company, id_company_credit, ...rest } = model;
      return this.main.postRequest(rest, "/company/create");
    }
    
    editCompany(model: CompanySaveModel) {
      if (model.commission_cantidad == '' || model.commission_cantidad === null || model.commission_cantidad === undefined)
        model.commission_cantidad = "0";
      if (model.commission_int_cantidad == '' || model.commission_int_cantidad === null || model.commission_int_cantidad === undefined)
        model.commission_int_cantidad = "0";
      if (model.credit == '' || model.credit === null || model.credit === undefined)
        model.credit = "0";
      return this.main.postRequest(model, "/company/edit");
    }

    addCreditCompany(id_company_credit:number, id_company:number, add_amount:number, observations:string) {
      var model = {
        'id_company_credit': id_company_credit,
        'id_company': id_company,
        'add_amount': add_amount,
        'observations': observations
      };
      return this.main.postRequest(model, "/company/add-credit");
    }

    changeStatusCompany(id: number, status:number) {
      var model = {
        'id': id,
        'active': status
      };
      return this.main.postRequest(model, "/company/status-change");
    }

    deleteCompany(id: string) {
      return this.main.deleteRequest(`/company/delete/${ id }`);
    }



    getCurrencies() {
      return this.main.getRequest("/users/get-currencies").pipe(
        map((resp: any) => {
          let list: Array<CurrencyModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              list.push(e);
            });
          }     
          return list;
        })
      );
    }
    
    sendHelpShipping(id:number, tracking_number:string, msg:string) {
      let param={
        "idshipping" : id,
        "msg" : msg,
        "tracking_number" : tracking_number
      };
      return this.main.postRequest(param, "/shipment/help-shipping");
    }

    getDashboard(date_i : string|null = null, date_f : string|null = null) {
      let param={
        "date_i" : date_i,
        "date_f" : date_f
      };
      return this.main.postRequest(param, "/shipment/show/dashboard-data");
    }

    getOrdersUnsuccessfully(date_i : string|null = null, date_f : string|null = null, order_number : string) {
      let param={
        "date_i" :  order_number==''?date_i:null,
        "date_f" :  order_number==''?date_f:null,
        "order_number" : order_number==''?null:order_number
      };
      return this.main.postRequest(param, "/shipment/show/orders-unsuccessfully");
    }

    getLogShiptmentOrder(order_number : string) {
      let param={
        "order_number" : order_number
      };
      return this.main.postRequest(param, "/shipment/show/log-order-number");
    }


    saveBulkUpload(model: BulkUploadSaveModel) {
      model.status = 'loaded';
      return this.main.postRequest(model, "/bulk-upload/create");
    }

    getBulkUploadDetail(id: number) {
      return this.main.getRequest("/bulk-upload/detail/"+id);
    }

    getBulkUploads() {
      return this.main.getRequest("/bulk-upload/get-list");
    }

    // getBulkUploadsLabels(globalObject:any){
    //   return this.main.postRequest(globalObject,"/bulk-upload/get-bu-labels");
    // }

    getBulkUploadsLabels(id_bulk_upload: number){
      return this.main.postRequest(globalObject,"/bulk-upload/get-bu-labels/"+id_bulk_upload);
    }

    getBulkUploadPdf(id_bulk_upload: number) {
      return this.main.getRequestBlob("/bulk-upload/pdf/"+id_bulk_upload);
    }

    editBulkUploadDetail(model: any) {
      return this.main.postRequest(model, "/bulk-upload/edit-detail");
    }


    getCuentaClabe(id_company: number) {
      return this.main.getRequest("/stp/clabes-by-company/"+id_company);
    }

    createCuentaClabe(id_company: number ) {
      const model= {
        id_company:id_company
      };
      return this.main.postRequest(model, "/stp/create-clabe");
    }

    getStpListOrders(date_i : string|null = null, date_f : string|null = null, id_company: number|null = null) {
      let param={
        "date_i" : date_i,
        "date_f" : date_f,
        "id_company" : id_company
      };
      return this.main.postRequest(param, "/stp/list-orders").pipe(
        map((resp: any) => {
          let lsModel: Array<StpOrderModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              lsModel.push(e);
            });
          }     
          return lsModel;
        })
      );
    }

    getCreditHistory(id:number) {
      return this.main.getRequest("/company/get-credit-history/"+id.toString()).pipe(
        map((resp: any) => {
          let lsModel: Array<StpCreditHistory> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              lsModel.push(e);
            });
          }     
          return lsModel;
        })
      );
    }
    getCreditHistorySummary(id:number) {
      return this.main.getRequest("/company/get-credit-history/summary/"+id.toString()).pipe(
        map((resp: any) => {
          let lsModel: Array<StpCreditHistory> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              lsModel.push(e);
            });
          }     
          return lsModel;
        })
      );
    }

    getPendingsOrders(id:number) {
      return this.main.getRequest("/stp/get-pending-orders/"+id.toString()).pipe(
        map((resp: any) => {
          let lsModel: Array<StpPendingOrder> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              lsModel.push(e);
            });
          }     
          return lsModel;
        })
      );
    }

    getInhouseShipmentList() {
      return this.main.postRequest({}, "/shipment/show/inhouse-tracking-guides").pipe(
        map((resp: any) => {
          let shipmentList: Array<ShipmentModel> = [];
          if(resp.message == "Success") {          
            resp.data.forEach((e:any) => {
              shipmentList.push(e);
            });
          }     
          return shipmentList;
        })
      );
    }

    /* any|null receiveNewShipData(0):
       Receives quick quote's data from the pipe and clears it.
       > data: The data to be received. */
    receiveNewShipData(): any | null {
      const data: any | null = this.dataPipe;
      this.dataPipe = null;
      return data;
    }

    /* void sendNewShipData(1):
       Adds quick quote's data to the pipe to be received by the
       Shipment Add component.
       < data: The data to be sent. */
    sendNewShipData(data: any): void {
      this.dataPipe = {
        destination: data.destination,
        origin: data.origin,
        package: {
          height: data.height,
          length: data.length,
          weight: data.weight,
          width: data.width
        },
        quote: data.quote
      } }
}
