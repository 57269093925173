import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {

  constructor() { }

  samePasswords( pass1: string, pass2: string ) {

    return ( formGroup: FormGroup ) => {
      const pass1Control = formGroup.controls[pass1];
      const pass2Control = formGroup.controls[pass2];
      if ( pass1Control.value === pass2Control.value ) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ noEqual: true });
      }

    }

  }

  validateWeight(control: AbstractControl): {[key: string]: any} | null {
    const typeControl = control.parent?.get('shipmentType');
    //console.log('validateWeight');
    if (typeControl && typeControl.value === 'box') {
      if (control.value && parseInt(control.value) > 100) {
        return { weightInvalid: true };
      }
    } else if(typeControl && typeControl.value === 'envelope'){
      if (control.value && parseInt(control.value) > 2) {
        return { weightInvalid: true };
      }
    }
    return null;
  }
}
