<div class="donut-chart">
    <apx-chart 
        [chart]="chartData.chart" 
        [plotOptions]="chartData.plotOptions" 
        [legend]="chartData.legend" 
        [series]="chartData.series" 
        [labels]="chartData.labels" 
        [colors]="chartData.colors"
        [tooltip]="chartData.tooltip"
        [responsive]="chartData.responsive">
    </apx-chart>
</div>
