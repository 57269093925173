import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
  selector: 'app-carrier-pricing-xls',
  templateUrl: './carrier-pricing-xls.component.html',
  styleUrls: ['./carrier-pricing-xls.component.scss']
})
export class CarrierPricingXlsComponent implements OnInit {
  wb:XLSX.WorkBook = XLSX.utils.book_new();
  sheetNames: string[] = [];
  selectedSheet: string='';

  dataAOA: AOA = [];
  jsonData : any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  //fileName: string = 'SheetJS.xlsx';
  
  //id_user!: number;
  formAdd!: FormGroup;
  fileName : string = '';

  selClient = new FormControl('');  
  selClientXC :Array<string>=[];

  isLoading=false;

  lsProvider=[
    {"key":"eia", "name":"ENVIA"},
    {"key":"pgo", "name":"PAK2GO"},
    {"key":"eme", "name":"ENVIAME"},
    {"key":"afx", "name":"AFIMEX"},
    {"key":"fdx", "name":"FEDEX"},
    {"key":"pxs", "name":"PAQUETEXPRESS"},
    {"key":"pxs", "name":"PAQUETE EXPRESS"}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<CarrierPricingXlsComponent>,
    private matDialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    //console.log(this.data);
    this.selClientXC = this.data.clients; 
    this.createFormUser();
  }

  createFormUser() {
    this.formAdd = this.fb.group({
      //name: ['', [ Validators.required, Validators.minLength(3)]],
      //email_notification: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],
      //formato: ['', [ Validators.required]],
      pestania: ['', []],
      file: ['', []],
      file_name: ['', []],
      rows: ['', []]
    });
  }


  ruSure() {
    if(this.formAdd.invalid) { return; }
    if(this.jsonData.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Error al importar',
        text: 'No se encontró ningún dato para hacer la comparación, por favor sube una plantilla con datos o selecciona la pestaña correcta.'
      });
    }else{
      Swal.fire({
        title: '¿Estás seguro que deseas realizar la comparación con los datos del archivo seleccionado?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.isLoading=true;
          this.saveForm();
        }
      });
    }

  }

  saveForm() {
    let aResponseJson: any=[];
    aResponseJson = this.mapPak2Go(this.jsonData);
    this.isLoading=true;
    this.dialogRef.close({ reload: false, id: 1, data: aResponseJson, formato: this.formAdd.value.formato, clientesEspeciales: this.selClient.value });
    // this.formAdd.get('rows')?.setValue(this.jsonData);
    // this.formAdd.get('file_name')?.setValue(this.fileName);

    //console.log(this.formAdd.value);
    // Swal.fire({
    //   icon: 'info',
    //   title: 'Guardando...',
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   showConfirmButton: false,
    //   didOpen: () => {
    //     Swal.showLoading(null);
    //   }
    // });
    // this.userS.saveBulkUpload(this.formAdd.value).subscribe(
    //   (result:any) => {
    //     if(result.message == "Success") {
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Guardado',
    //         text: 'Se han importado de manera correcta los datos del excel!'
    //       }).then(() => {            
    //         this.dialogRef.close({ reload: false, id: result.id });
    //         //this.dialogRef.close({ reload: false, credit: (this.formC.value.credit*1) + (this.formC.value.add_credit*1) });
    //       });
    //     }
    //   },
    //   (err:any) => {        
    //       console.log(err);
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Error al guardar',
    //         text: 'Ocurrió un error al importar, por favor vuelve a intentarlo.'
    //       });
    //   }
    // );
  }


  onFileSelected(event: any) {
    const archivo = event.target.files[0];
    
    const tipoPermitido = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']; // MIME para Excel
    const tamanioKb = 5120; // 1024=1 mb
    const tamanioMaximo = tamanioKb * 1024;
  
    if (!tipoPermitido.includes(archivo.type)) {
      // error: Tipo de archivo no válido
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'El tipo de archivo no es válido.'
      });
      return;
    }
  
    if (archivo.size > tamanioMaximo) {
      // error: Tamaño de archivo excedido
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `El tamaño del archivo excede el límite de ${((tamanioMaximo/1024)/1024).toFixed(2)} Mb.`
      });
      return;
    }
  
    const file:File = event.target.files[0];
    if (file) {
      console.log(this.fileName = file.name);
      this.onFileChange(event);
      // const formData = new FormData();
      // formData.append("thumbnail", file);
      // const upload$ = this.http.post("/api/thumbnail-upload", formData);
      // upload$.subscribe();
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      this.wb = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      this.sheetNames = this.wb.SheetNames; //para llenar el mat-select
      const wsname: string = this.wb.SheetNames[0];
      this.selectedSheet=wsname; //selecciona el primer elemento en el mat-select
      const ws: XLSX.WorkSheet = this.wb.Sheets[wsname];

      /* save data */
      this.dataAOA = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      
      //console.log(this.dataAOA);


      const headers: string[] = this.dataAOA[0];
      this.jsonData = [];
      this.jsonData = this.dataAOA.slice(1).map((row: any[], index: number) => {
        const obj: any = {};
        obj["ORDER"] = index + 1; //Columna "order" incremental
        headers.forEach((header: string, columnIndex: number) => {
          obj[header] = row[columnIndex] || "";
        });
    
        return obj;
      });
      //console.log(this.jsonData);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  loadSheetData() {
    /* grab sheet select */
    this.sheetNames = this.wb.SheetNames; //para llenar el mat-select
    const ws: XLSX.WorkSheet = this.wb.Sheets[this.selectedSheet];

    /* save data */
    this.dataAOA = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

    const headers: string[] = this.dataAOA[0];
    this.jsonData = [];
    this.jsonData = this.dataAOA.slice(1).map((row: any[], index: number) => {
      const obj: any = {};
      obj["ORDER"] = index + 1; //Columna "order" incremental
      headers.forEach((header: string, columnIndex: number) => {
        obj[header] = row[columnIndex] || "";
      });

      return obj;
    });
  }

  abrirSeleccionArchivo() {
    const archivoInput = document.querySelector('.hidden-input') as HTMLInputElement;
    archivoInput.click();
  }
  
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  
  getValues(obj: any): any[] {
    return Object.values(obj);
  }

  //#region mapeos
  mapPak2Go(received: any): any[] {
    const obj = received.map((item: { [x: string]: any; }) => ({
      xcPartnerId: (item?.['partnerId'] || '').toString(),
      xcPaqueteria : this.namePaqueteriaXls((item?.['Courier'] || '').toString()),
      xcModo : this.nameModeXls((item?.['Metodo de envio'] || '').toString()),
      xcKg : parseFloat((item?.['Kg'] || 0).toString().replace('kg', '').trim()),
      xcCosto : parseFloat((item?.['Costo'] || 0).toString().replace(/\$/g, '').trim()),
      xcPrecioVenta : parseFloat((item?.['Precio de Venta'] || 0).toString().replace(/\$/g, '').trim()),
      xcPrecioVentaPacto : parseFloat((item?.['Precio de Venta Medio Pliego'] || 0).toString().replace(/\$/g, '').trim()),
      xcMoneda: (item?.['Moneda'] || '').toString(),
      xcCountry: (item?.['País'] || '').toString(),
      xcClient: (item?.['Cliente'] || '').toString(),
    }));
    return obj;
  }

  serialDateToJSDate(serialDate: number): string {
    let formattedDate = '';
    if (!isNaN(serialDate)){ //Si es un número
      const adjustedSerialDate = (serialDate - 25569) * 86400 * 1000;
      let dateObj = new Date(adjustedSerialDate);

      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // +1 porque enero es 0
      const day = dateObj.getDate().toString().padStart(2, '0');
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');

      formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    }else{
      formattedDate = serialDate.toString();
    }
    return formattedDate;
  }

  cleanString(cadena:string) {
    //expresión regular para eliminar todos los caracteres que no sean letras o números
    return cadena.replace(/[^a-zA-Z0-9\-]/g, '');
  }

  namePaqueteriaXls(formato:string) {
    let name=formato;
    switch (formato) {
      case 'DHL':
        name='DHL';
        break;
      case 'ETA':
        name='ESTAFETA';
        break;
      case 'PEX':
        name='PAQUETEXPRESS';
        break;
      case 'FEDX':
        name='FEDEX';
        break;
      case 'RDPK':
        name='REDPACK';
        break;
      case 'SEGM':
        name='SEGMAIL';
        break;
      case 'FDX':
        name='FEDEX';
        break;
      case 'AFMX':
        name='AFIMEX';
        break;
      case '99M':
        name='99MINUTOS';
        break;
      case 'UPS':
        name='UPS';
        break;
      case 'JCPTE':
        name='JCPTE';
        break;
      case 'SGML':
        name='SEGMAIL';
        break;
      case 'RedP':
        name='REDPACK';
        break;
    }
    return name;
  }

  nameProviderXls(nombre:string) {
    const resultado = this.lsProvider.find(item => item.name.toUpperCase().includes(nombre.toUpperCase()));
    return resultado ? resultado.key : '';
  }

  nameModeXls(nombre:string) {
    let name=nombre;
    switch (nombre) {
      case 'A':
        name='Aéreo';
        break;
      case 'T':
        name='Terrestre';
        break;
    }
    return name;
  }

}
