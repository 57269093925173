<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Envíos masivos</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'bulk-uploads'">
                            <mat-icon>pallet</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Envíos masivos</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <ng-template mat-tab-label> Lista de cargas <mat-icon class="ms-1">list</mat-icon>
                </ng-template>
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <div class="float-end">
                            <button mat-stroked-button class="btn btn-outline-secondary" matTooltip="Genera un envío masivo" [matTooltipShowDelay]="1000" (click)="openBulkUpload()" style="margin-right: 10px;"><mat-icon>upload</mat-icon><span class="btn-no-text"> Carga masiva</span></button>
                        </div>
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Casa" #input>
                        </mat-form-field>
                    </div>
                    <!-- <div class="">
                        <b>Guías procesadas 20 de 100</b>
                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated  font-primary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%">75%</div>
                        </div>
                    </div> -->
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                                <td class="col-1 pe-4" mat-cell *matCellDef="let row"> {{row.id}}</td>
                            </ng-container>
                
                            <ng-container matColumnDef="name">
                                <th class="ps-4" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td class="col-2 ps-4 pe-2 text-justify pt-2 pb-2" mat-cell *matCellDef="let row"> {{row.name}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="file_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Archivo </th>
                                <td class="col-2" mat-cell matTooltip="Nombre del archivo utilizado para la carga masiva" *matCellDef="let row"> {{row.file_name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="email_notification" >
                                <th class="" mat-header-cell *matHeaderCellDef mat-sort-header>Email </th>
                                <td class="col-1 pe-4" mat-cell *matCellDef="let row">{{row.email_notification}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </th>
                                <td mat-cell *matCellDef="let row"> 
                                    <button  *ngIf="row.status=='done'" mat-icon-button matTooltip="Completado" [matTooltipPosition]="'above'" class="text-info">
                                        <mat-icon>done_all</mat-icon>
                                    </button>
                                    <button  *ngIf="row.status=='partial'" mat-icon-button matTooltip="Parcialmente compradas todas las guías" [matTooltipPosition]="'above'" class="text-muted">
                                        <mat-icon>done</mat-icon>
                                    </button>
                                    <button  *ngIf="row.status=='in_progress'" mat-icon-button matTooltip="En proceso" [matTooltipPosition]="'above'" color="accent" [ngClass]="{'processing-icon': row.status=='in_progress' }">
                                        <mat-icon>sync</mat-icon>
                                    </button>
                                    <button  *ngIf="row.status=='cancelled'" mat-icon-button matTooltip="Cancelado" [matTooltipPosition]="'above'" color="warn">
                                        <mat-icon>sync_disabled</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                
                            <ng-container matColumnDef="created_at">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                                <td mat-cell *matCellDef="let row"> {{row.created_at.slice(0,16).replace('T','  ')}}</td>
                            </ng-container>
                
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center" >                                            
                                    <button mat-icon-button matTooltip="Ver envíos" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="primary" (click)="viewDetail(row.id)">
                                        <mat-icon>preview</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Descargar todas las guías compradas" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="primary" (click)="downloadAllPdf(row.id)">
                                        <mat-icon>download</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Imprimir todas las guías compradas" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="primary" (click)="viewAllPdf(row.id)">
                                        <mat-icon>print</mat-icon>
                                    </button>
                                    <!-- <button mat-icon-button matTooltip="Imprimir guía" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="primary" (click)="printLabel(row.tracking_number)">
                                        <mat-icon>print</mat-icon>
                                    </button> -->
                                    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
                                        <mat-icon>more_vert</mat-icon>
                                    </button> -->
                                    <!-- <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="viewLabel(row.tracking_number)"><mat-icon class="me-2">preview</mat-icon>Ver Guía</button>
                                        <button mat-menu-item (click)="printLabel(row.tracking_number)"><mat-icon class="me-2">print</mat-icon>Imprimir Guía</button>
                                        <button mat-menu-item (click)="goToTracking(row.tracking_number)"><mat-icon class="me-2">local_shipping</mat-icon>Rastrear Envío</button>
                                        <button mat-menu-item (click)="openDialogSaveFav(row.id)"><mat-icon class="me-2">favorite_outline</mat-icon>Agregar a Favoritos</button>
                                        <button mat-menu-item (click)="helpShipment(row)"><mat-icon class="me-2">contact_support</mat-icon>Ayuda con Envío</button>
                                    </mat-menu> -->
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td *ngIf="dataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                <td *ngIf="dataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                            </tr>
                        </table>
                
                        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-bar color="primary" mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" pageSize="25"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>