import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { BulkUploadAddComponent } from '../shared/dialogs/bulk-upload-add/bulk-upload-add.component';
import Swal from 'sweetalert2';
import * as printJS from 'print-js';
import { environment } from 'src/environments/environment';
import { BulkUploadRowEditComponent } from '../shared/dialogs/bulk-upload-row-edit/bulk-upload-row-edit.component';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
  id_bulk_upload=0;
  bulk_upload_status='';
  bu_last_in_pogress='';
  bulk_upload_max=100;
  countShipment=0; //Cuenta cuantos elementos se tienen para comprar guías
  indexShipment=0; //Indica el último índice en el que se encuentra el listado enviando guías

  userObj!: UserModel;
  isLoading = true;
  
  dataList : any = [];
  //displayedColumns: string[] = ['actions','ORDER', 'tracking_number','WAREHOUSE_CODE','DEST_NAME','DEST_COMPANY','DEST_EMAIL','DEST_PHONE','DEST_STREET','DEST_NUMBER','DEST_POSTALCODE','DEST_SUBURB','DEST_CITY','DEST_STATE','DEST_REFERENCE', 'RATE_PROVIDER','RATE_CARRIER','RATE_SERVICE','PKG_ORDER_NUMBER','PKG_CONTENT','PKG_WEIGHT','PKG_LENGTH','PKG_WIDTH','PKG_HEIGHT'];
  columnDefinitions = [
      { def:'actions', label: 'Acciones', hide: false},
      { def:'ORDER', label: '#', hide: false},
      { def:'tracking_number', label: 'tracking_number', hide: false},
      
      { def:'WAREHOUSE_CODE', label: 'WAREHOUSE_CODE', hide: false},
      
      { def:'ORIG_NAME', label: 'ORIG_NAME', hide: true},
      { def:'ORIG_COMPANY', label: 'ORIG_COMPANY', hide: true},
      { def:'ORIG_EMAIL', label: 'ORIG_EMAIL', hide: true},
      { def:'ORIG_PHONE', label: 'ORIG_PHONE', hide: true},
      { def:'ORIG_STREET', label: 'ORIG_STREET', hide: true},
      { def:'ORIG_NUMBER', label: 'ORIG_NUMBER', hide: true},
      { def:'ORIG_POSTALCODE', label: 'ORIG_POSTALCODE', hide: true},
      { def:'ORIG_SUBURB', label: 'ORIG_SUBURB', hide: true},
      { def:'ORIG_CITY', label: 'ORIG_CITY', hide: true},
      { def:'ORIG_STATE', label: 'ORIG_STATE', hide: true},
      { def:'ORIG_REFERENCE', label: 'ORIG_REFERENCE', hide: true},
  
      { def:'DEST_NAME', label: 'DEST_NAME', hide: false},
      { def:'DEST_COMPANY', label: 'DEST_COMPANY', hide: false},
      { def:'DEST_EMAIL', label: 'DEST_EMAIL', hide: false},
      { def:'DEST_PHONE', label: 'DEST_PHONE', hide: false},
      { def:'DEST_STREET', label: 'DEST_STREET', hide: false},
      { def:'DEST_NUMBER', label: 'DEST_NUMBER', hide: false},
      { def:'DEST_POSTALCODE', label: 'DEST_POSTALCODE', hide: false},
      { def:'DEST_SUBURB', label: 'DEST_SUBURB', hide: false},
      { def:'DEST_CITY', label: 'DEST_CITY', hide: false},
      { def:'DEST_STATE', label: 'DEST_STATE', hide: false},
      { def:'DEST_REFERENCE', label: 'DEST_REFERENCE', hide: false},
      { def:'RATE_PROVIDER', label: 'RATE_PROVIDER', hide: false},
      { def:'RATE_CARRIER', label: 'RATE_CARRIER', hide: false},
      { def:'RATE_SERVICE', label: 'RATE_SERVICE', hide: false},
      { def:'PKG_ORDER_NUMBER', label: 'PKG_ORDER_NUMBER', hide: false},
      { def:'PKG_CONTENT', label: 'PKG_CONTENT', hide: false},
      { def:'PKG_TYPE', label: 'PKG_TYPE', hide: false},
      { def:'PKG_WEIGHT', label: 'PKG_WEIGHT', hide: false},
      { def:'PKG_LENGTH', label: 'PKG_LENGTH', hide: false},
      { def:'PKG_WIDTH', label: 'PKG_WIDTH', hide: false},
      { def:'PKG_HEIGHT', label: 'PKG_HEIGHT', hide: false},
    ]
  showOptions = false;
  getDisplayedColumns():string[] {
    return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
  }

  getDisplayedLabel(def:string):string {
    let cLabel=this.columnDefinitions.find(item=> item.def==def);
    return cLabel?.label||''
  }

  /**
   * Muestra/oculta los campos de dirección de origen
   * @param hide Por default false, muestra los campos
   */
  showDifferentOrigin(hide=false) {
    //Los campos de dirección de origen
    let columnsHS=this.columnDefinitions.filter( item=> item.def.includes('ORIG_'));
    if(columnsHS && columnsHS.length>0){
      columnsHS.map(cd=>cd.hide = hide);
    }

    //ocultar el  warehouse
    let columnHS=this.columnDefinitions.find( item=> item.def=='WAREHOUSE_CODE');
    if(columnHS){
      columnHS.hide=!hide;
    }
  }


  countDone: any=[];
  isSendAll=false;
  
  sTitulo:string ='';
  sFileName:string ='';
  sBtnMsjTotal:string='';

  showTrackingNumber = true; //Para mostrar la columna de tracking_number

  intervaloActualizacion: any; 
  intervaloPorcentaje: any; 
  percentProgress: number=0; 
  

  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  get data():any { return this.userS.sharedBulkUpload; } set data(value: any) { this.userS.sharedBulkUpload = value; }
  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    //console.log('BulkUploadComponent');
    //console.log(this.data);
    // if(this.data.id!=undefined)
    //   this.dataList=this.getData(this.data.id);
    // else
    //   this.dataList=this.data;

    // if(this.data.length==0)
    //   this.dataList=this.getData(this.data.id);

    if(this.data.id!=undefined && this.data.id>0){
      this.dataList=this.getData(this.data.id);
    }

    this.intervaloActualizacion = setInterval(() => {
      this.dataList=this.getData(this.data.id);
    }, (environment.update_interval*1000));    
  }

  ngOnDestroy() {
    clearInterval(this.intervaloActualizacion); // Limpia el intervalo al destruir el componente
    clearInterval(this.intervaloPorcentaje);
  }

  getData(id:number){
    let json: any = [];
    this.userS.getBulkUploadDetail(id).subscribe(
      (result : any) => {
        if(result.message=="Success"){
          json=result.data;
          this.dataList = result.data.bulk_upload_detail;
          this.dataSource = new MatTableDataSource(this.dataList);
          this.dataSource.paginator = this.paginator;
          //this.dataSource.paginator.pageSize=this.dataList.length;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
          this.sTitulo = result.data.bulk_upload[0]["name"];
          this.sFileName = result.data.bulk_upload[0]["file_name"];
          this.id_bulk_upload = result.data.bulk_upload[0]["id"];
          const lastStatus=this.bulk_upload_status;
          this.bulk_upload_status = result.data.bulk_upload[0]["status"];
          this.bu_last_in_pogress = result.data.bulk_upload[0]["last_in_progress"];
          this.bulk_upload_max = result.data.bulk_upload_max;

          console.log(this.dataList[0].hasOwnProperty('WAREHOUSE_CODE'));
          //Mostrar/ocultar las direcciones de origenes
          if(this.dataList && this.dataList.length > 0){
            if(!this.dataList[0].hasOwnProperty('WAREHOUSE_CODE') ){
              this.showDifferentOrigin();
            }
            else
              this.showDifferentOrigin(true);
          }
          else
            this.showDifferentOrigin(true);

          this.getTotal();

          if (this.bulk_upload_status=='loaded') {
            this.sendAll();
            console.log('this.sendAll() loaded');
          }
          if (this.bulk_upload_status=='in_progress') {
            this.statusInProgress();
            console.log('this.statusInProgress() in_progress');
          }else{
            this.isSendAll=false;
            clearInterval(this.intervaloPorcentaje); //Si es cualquier otro status detener el avance de la barra
          }
          if (this.bulk_upload_status=='done') {
            clearInterval(this.intervaloActualizacion); // Limpia el intervalo al destruir el componente
            clearInterval(this.intervaloPorcentaje);

            if(lastStatus!='' && lastStatus=='in_progress'){
              let msgShip='¡La compra masiva de guías ha sido realizada con exito!';
              if(this.countDone.length != this.dataList.length){
                msgShip='¡La compra masiva de guías ha concluido!, hubieron guías que no pudieron ser compradas, puedes intertar de nuevo';
              }
              Swal.fire({
                icon: 'success',
                title: 'Gracias por su compra',
                html: msgShip,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: `Confirmar`,
              }).then((result) => {      
                  this.isSendAll=false;
              });
            
            }
          }

          
        }
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );

    return json;
  }

  openBulkUpload() {
    const dialogRef = this.matDialog.open( BulkUploadAddComponent, {
      data: { 
        //shipmentIdSelected: id
      },
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.goToBulkUploads();
    });
  }

  statusChangeClass(status:string){
    let rClass="";
    switch (status) {
      case 'loaded':
        rClass='accent';
        break;
      case 'in_progress':
        rClass='basic';
        break;
      case 'done':
        rClass='primary';
        break;
      case 'cancel':
      case 'error':
        rClass='warn';
        break;
      default:
        break;
    }
    return rClass;
  }
  statusChangeIcon(status:string){
    let icon="";
    switch (status) {
      case 'loaded':
        icon='schedule_send';
        break;
      case 'in_progress':
        icon='sync';
        break;
      case 'done':
        icon='done';
        break;
      case 'cancel':
        icon='cancel';
        break;
      case 'error':
        icon='error';
        break;
      default:
        break;
    }
    return icon;
  }

  statusChangeTip(status:string, message:string){
    let icon="";
    if(message!='' && message!=null){
      icon=message;
    }
    else {
      switch (status) {
        case 'loaded':
          icon='Listo para comprar guía';
          break;
        case 'in_progress':
          icon='Comprando guía';
          break;
        case 'done':
          icon='Completado';
          break;
        case 'cancel':
          icon='Cancelado';
          break;
        case 'error':
          icon='Error al comprar';
          break;
        default:
          break;
      }
    }
        
    return icon;
  }

  ruSure() {
    // if(this.jsonData.length==0){
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error al guardar',
    //     text: 'No se encontró ningún dato para hacer la carga masiva, por favor sube una plantilla con datos.'
    //   });
    // }else{
      Swal.fire({
        title: '¿Estás seguro que deseas realizar la compra masiva de las guías?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.sendAll();
        }
      });
    //}
  }

  statusInProgress(): void {
    this.dataList.forEach((item: { status: string; }) => {
      if (item.status !== 'done') {
        item.status = 'in_progress';
      }
    });
    this.intervaloPorcentaje = setInterval(() => {
      this.showAdvance();
    }, (1500));
  }

  sendAll(): void {
    this.bulk_upload_status='in_progress';
    this.statusInProgress();

    const fechaActual = new Date();
    const offsetHorario = fechaActual.getTimezoneOffset() * 60000;
    const fechaIso = new Date(fechaActual.getTime() - offsetHorario).toISOString();
    this.bu_last_in_pogress = fechaIso;//new Date().toISOString(); //para iniciar el progress bar
    this.isSendAll=true;
    this.userS.getBulkUploadsLabels(this.id_bulk_upload).subscribe(
      ( resp: any ) => {
        console.log(resp);
          if ( resp.message == "Success" ) {
            Swal.fire({
              icon: 'success',
              title: 'Comprando guías',
              html: '¡La compra masiva de guías se ha iniciado!, cuando se concluya el proceso se notificará al correo indicado.',
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: `Confirmar`,
            }).then((result) => {      
                //this.isSendAll=false;
            });
          }
        },
      ( err:any ) => {
        this.isSendAll=false;
        if ( err.error!.status!.message == " Créditos insuficientes" || err.error!.status!.code == " Not enough credits ") {
          Swal.fire({
            icon:  'warning',
            title: 'Créditos insuficientes',
            text:  'No cuentas con los créditos necesarios para realizar esta compra.'
          });
        }
        else {
          console.log( err );
          Swal.fire({
            icon:  'error',
            title: 'Error en la compra',
            text:  'Ocurrió un error al realizar la compra, por favor vuelva a intentarlo.'
          });
        }
        //Recargar el listado si hubo algún error
        if(this.data.id!=undefined && this.data.id>0)
          this.dataList=this.getData(this.data.id);
      }
    );
    
  }

  sendAllInterval(): void {
    let sendInterval=[];
    this.isSendAll=true;
    let index = this.indexShipment;
    //let index2 = 0;
    const end = Math.min(index + this.bulk_upload_max, this.dataList.length);
    for (let i = index; i < end; i++) {
      if (sendInterval.length < this.bulk_upload_max) {
        if(this.dataList[i].status != 'done')
        {
          this.dataList[i].status = 'in_progress';
          sendInterval.push(this.dataList[i]);
          this.countShipment++;
        }
        this.indexShipment++;
      }
    }
    //Si no se tiene el intervalo completo continuar hasta llenarlo
    if(sendInterval.length<this.bulk_upload_max){
      for (let i = end; i < this.dataList.length; i++) {
        if (sendInterval.length<this.bulk_upload_max) {
          this.indexShipment++;
          if(this.dataList[i].status != 'done')
          {
            this.dataList[i].status = 'in_progress';
            sendInterval.push(this.dataList[i]);
            this.countShipment++;
          }
        }else{
          break;
        }
      }
    }

    // this.userS.getBulkUploadsLabels(sendInterval).subscribe(
    //   ( resp: any ) => {
    //     console.log(resp);
    //     this.updateStatus(resp.data);
    //       if ( resp.status.code == "success" ) {
    //         //Para enviar por bloques se necesita continuar apartir de dónde se envió anteriormente
    //         //Si el this.countShipment es igual o mayor a this.dataList.length ya se terminó de enviar, por lo tanto volver a poner en cero
    //         //Si no se ha llegado al total del listado se continua con el siguiente bloque
    //         if(this.indexShipment >= this.dataList.length){
    //           this.indexShipment = 0;
    //           this.countShipment = 0;

    //           let msgShip='¡La compra masiva de guías ha sido realizada con exito!';
    //           this.getTotal();
    //           if(this.countDone.length != this.dataList.length){
    //             msgShip='¡La compra masiva de guías ha concluido!, hubieron guías que no pudieron ser compradas, puedes intertar de nuevo';
    //           }

    //           Swal.fire({
    //             icon: 'success',
    //             title: 'Gracias por su compra',
    //             html: msgShip,
    //             showDenyButton: false,
    //             showCancelButton: false,
    //             confirmButtonText: `Confirmar`,
    //           }).then((result) => {      
    //               this.isSendAll=false;
    //           });
          
    //         }else{
    //           Swal.fire({
    //             icon:  'success',
    //             title: 'Tenga paciencia',
    //             html:  '¡Se ha realizado el bloque de compras!, continuaremos hasta finalizar',
    //             allowEscapeKey:    false,
    //             allowOutsideClick: false,
    //             showConfirmButton: false,
    //             timer: 1500,
    //             timerProgressBar: true,
    //           }).then((result) => {
    //             if (result.dismiss === Swal.DismissReason.timer) {
    //               //this.isSendAll=false; 
    //               console.log('siguiente');
    //               this.sendAll();  //recursivo
  
    //             }
    //           });
    //         }
    //       }
    //     },
    //   ( err:any ) => {
    //     this.isSendAll=true;
    //     if ( err.error!.status!.message == " Créditos insuficientes" || err.error!.status!.code == " Not enough credits ") {
    //       Swal.fire({
    //         icon:  'warning',
    //         title: 'Créditos insuficientes',
    //         text:  'No cuentas con los créditos necesarios para realizar esta compra.'
    //       });
    //     }
    //     else {
    //       console.log( err );
    //       Swal.fire({
    //         icon:  'error',
    //         title: 'Error en la compra',
    //         text:  'Ocurrió un error al realizar la compra, por favor vuelva a intentarlo.'
    //       });
    //     }
    //     //Recargar el listado si hubo algún error
    //     if(this.data.id!=undefined && this.data.id>0)
    //       this.dataList=this.getData(this.data.id);
    //   }
    // );
    
  }

  updateStatus(data:any) {
    data.forEach((item: { tracking_number:string, order_number: string; status: string; message: string; labels: any; billingData: any; }) => {
      let orderNumer = parseInt(item.order_number.slice(1).replace(/^0+/, "")); //quitar el primer 6 y los ceros de la izquierda;
      let status=item.status;

      let itemFound = this.dataList.find((itemBU: { id_shipment_user: number; }) => itemBU.id_shipment_user == orderNumer);
      //itemFound.tracking_number=tracking_number
      if (itemFound) {
        switch (status) {
          case 'success':
            itemFound.status = 'done';
            break;
          case 'error':
            itemFound.status = status;
            itemFound.message = item.message;
            break;
          default:
            itemFound.status = status;
            break;
        }
      }
    });
  }

  getStatus(): string {
    const statuses = [ 'done','cancel','error' ];
    const randomIndex = Math.floor(Math.random() * statuses.length);
    return statuses[randomIndex];
  }
  getTotal() {
    this.sBtnMsjTotal='Comprar guías ';
    if (this.bulk_upload_status=='in_progress')
      this.sBtnMsjTotal='Comprando guías ';

    if(this.dataList.length>0 ){
      this.countDone = this.dataList.filter((item: { status: string; }) => item.status == 'done');
      if(this.countDone.length==0){
        this.sBtnMsjTotal +=`(${this.dataList.length})`;
      }else if(this.dataList.length == this.countDone.length){
        this.sBtnMsjTotal +='';
      }else{
        this.sBtnMsjTotal +=`(${this.dataList.length-this.countDone.length} de ${this.dataList.length})`;
      }
    }
    //this.countDone = this.dataList.filter((item: { status: string; }) => item.status == 'done');
  }

  getTrackingNumber() {
    this.showTrackingNumber = this.dataList.every((item: { tracking_number: string; }) => !item.tracking_number);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPageSizeOptions(): number[]{
    if(this.dataList.length>10)
      return [10,50,100,this.dataList.length];
    else
      return [10,50,100];
  }

  downloadAllPdf(){
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.getBulkUploadPdf(this.id_bulk_upload).subscribe({
      next: (data: any) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const urlFile = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlFile;
        link.download = `${this.sTitulo}.pdf`;
        Swal.close();
        link.click();
      },
      error:  (err : any) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error al descargar',
          text:  'Ocurrió un error al descargar el PDF, por favor vuelva a intentarlo.'
        });
      }
    });

  }

  viewAllPdf(){
    Swal.fire({
      icon:  'info',
      title: 'Descargando guía...',
      allowEscapeKey:    false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading( null );
      }
    });
    this.userS.getBulkUploadPdf(this.id_bulk_upload).subscribe({
      next: (data: any) => {
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
        URL.revokeObjectURL(blobUrl);

        Swal.close();
      },
      error:  (err : any) => {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error al descargar',
          text:  'Ocurrió un error al descargar el PDF, por favor vuelva a intentarlo.'
        });
      }
    });

  }

  showAdvance() {
    if (this.bulk_upload_status=='in_progress') {
      const fecha = new Date(this.bu_last_in_pogress);
      const elapsedTime = Date.now() - fecha.getTime() - (6 * 60 * 60 * 1000); //restar las 6 horas de diferencia con la BD      
      if(this.dataList.length>0){
        const expectedTotalTime = (this.dataList.length-this.countDone.length) * (environment.bu_item_pro_time * 1000) + (Math.round(this.dataList.length/this.bulk_upload_max) * 1000); // 10000 ms = 15 segundos por item
        const percentage = (elapsedTime / expectedTotalTime) * 100;
        //console.log(elapsedTime, expectedTotalTime, percentage.toFixed(0), this.dataList.length, this.countDone.length);
        if(percentage>100)
        {
          this.percentProgress = 99;
        }else{
          this.percentProgress = Number(percentage.toFixed(0));
        }
      }
    }else{
      this.percentProgress =  0;
      clearInterval(this.intervaloPorcentaje);
    }
  }

  onRowDoubleClick(event: any) {
    const rowData = event;
    //console.log('Datos del row seleccionado:', rowData);
    this.openDialogEdit(rowData);
  }

  openDialogEdit(row: any) {
    this.matDialog.open(BulkUploadRowEditComponent, {
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      data: {
        detail: row
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getData(this.data.id);
      }
    );
  }

  boughtCheapest(row: any, col: string) {
    let cadena='';
    //const data_row = row.data_row;
    //TODO: ahorita solo el service sirve para el cheapest all
    //      Podría usarse con el carrier, porque se tiene el carrier como el mismo, entonces se puede filtrar igual que el de terrestre/aereo
    //      Ejemplo: Carrier: FEDEX y con eso obtengo del catálogo los carrier para las rules y el arreglo de carriers
    if(row.RATE_PROVIDER && row.RATE_PROVIDER.trim() != '' && row.RATE_PROVIDER.toLowerCase()=='any' )
    {  //Si solo viene el rate_service considerarlo como cheapestall
      switch (col) {
        case 'provider':
          if(row.provider)
            cadena=`|${row.provider}`;
          break;
        case 'carrier':
          if(row.carrier)
            cadena=`|${row.carrier}`;
          break;
        case 'service':
          if(row.service)
            cadena=`|${row.service}`;
          break;
        default:
          break;
      }
    }
    return cadena;
  }

}
