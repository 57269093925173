<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 mat-dialog-title class="m-0">{{ title }}</h1>
<hr />
<div *ngIf="data.status; else errorTemplate">
  <div class="row">
    <div class="col-md-4"><b>Dirección:</b></div>
    <div class="col-md-8">{{ data.address }}</div>
  </div>
  <div class="row">
    <div class="col-md-4"><b>Fecha de recolección:</b></div>
    <div class="col-md-8">{{ data.formattedDate }}</div>
  </div>
  <div class="row">
    <div class="col-md-4"><b>Hora inicial de recolección:</b></div>
    <div class="col-md-8">{{ toTime(data.initTime) }}</div>
  </div>
  <div class="row">
    <div class="col-md-4"><b>Hora final de recolección:</b></div>
    <div class="col-md-8">{{ toTime(data.finalTime) }}</div>
  </div>
  <div class="row">
    <div class="col-md-4"><b>Número de confirmación:</b></div>
    <div class="col-md-8">{{ data.confirmation }}</div>
  </div>
  <div class="lower-section">
    <div class="lwr-content">
      <p class="lwr-text">Puede descargar el contenido de su manifiesto aquí:</p>
      <button mat-raised-button class="lwr-button" [disabled]="manifestLoading" (click)="getManifest()">
        <ng-container *ngIf="manifestLoading"><div class="mnf-spinner"><mat-spinner diameter="32"></mat-spinner></div></ng-container>
        <ng-container *ngIf="!manifestLoading">Ver Manifiesto</ng-container>
      </button>
    </div>
  </div>
</div>
<ng-template #errorTemplate>
  <div class="lower-section">
    <div class="lwr-content">
      <p class="lwr-text">{{ data.confirmation }}</p>
    </div>
  </div>
</ng-template>
<hr />
<div class="checkout-footer">
  <button mat-raised-button class="lwr-button" (click)="closeDialog()">Cerrar</button>
</div>