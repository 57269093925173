import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddressAddComponent } from '../shared/dialogs/address-add/address-add.component';
import { AddressSelectComponent } from '../shared/dialogs/address-select/address-select.component';
import { UsersService } from 'src/app/services/users.service';
import { ZipCodeDataModel } from 'src/app/models/address.model';
import { CarriersModel, IntCity, IntCountry, IntState, globalObject, packagesModel } from 'src/app/models/shipment.model';
import Swal from 'sweetalert2';
// import { ShipmentsPrintComponent } from '../shared/dialogs/shipments-print/shipments-print.component';
import { ShipmentsCheckoutComponent } from '../shared/dialogs/shipments-checkout/shipments-checkout.component';
import * as printJS from 'print-js';
import { MatStepper } from '@angular/material/stepper';
import { ShipmentsLabelActionsComponent } from '../shared/dialogs/shipments-label-actions/shipments-label-actions.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Router } from '@angular/router';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { UserModel } from 'src/app/models/user.model';



@Component({
  selector: 'app-shipments-add',
  templateUrl: './shipments-add.component.html',
  styleUrls: ['./shipments-add.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
  ],
})

export class ShipmentsAddComponent implements OnInit {
  selectedCountryOrigin: any;
  selectedCountryDestination: any;
  userObj!: UserModel;
  id_user!: number;
  formOriginAddress! : FormGroup;
  formDestinationAddress! : FormGroup;
  formShipment! : FormGroup;
  origin!:ZipCodeDataModel;
  destination!:ZipCodeDataModel
  isOrigin!:number;
  zipCodeError!:boolean;
  zipCodeErrorDestination!:boolean;
  globalObject!:globalObject;
  base64!:string;
  base64Bol!:string;
  //stepper!: MatStepper;
  @ViewChild('stepper') stepper!: MatStepper;
  clicked = false;
  labelName!:string;
  originFilteredOptions!:Array<string>;
  destinationFilteredOptions!:Array<string>;
  typeOfCarriers!: Array<CarriersModel>;
  typeOfCarriersError:boolean = false;
  rateSelected!:CarriersModel;
  emailDefault="hola@buhologistics.com";
  orderNumber!:string;

  originIntCountries!:Array<IntCountry>;
  originIntStates!:Array<IntState>;
  filOriginIntStates!:Array<IntState>;
  originIntCities!:Array<IntCity>;
  filOriginIntCities!:Array<IntCity>;
  originIntSuburb!:Array<string>;
  filOriginIntSuburb!:Array<string>;
  destinationIntCountries!:Array<IntCountry>;
  destinationIntStates!:Array<IntState>;
  filDestinationIntStates!:Array<IntState>;
  destinationIntCities!:Array<IntCity>;
  filDestinationIntCities!:Array<IntCity>;
  destinationIntSuburb!:Array<string>;
  filDestinationIntSuburb!:Array<string>;
  
  countriesError!:boolean;

  withZipCode:boolean = true;
  withZipCodeDestination:boolean = true;

  // Necesarios para recibir cotización rápida
  @ViewChild('opc') originPostalCode!: ElementRef;
  @ViewChild('dpc') destPostalCode!: ElementRef;
  foundQQ: boolean = false;
  selectedQQ: any | null = null;

  //Es para indicar que compañías tendrán como requerido el campo de número de orden del paquete
  //Sam solicitó que sea solo netpay y sus distribuidores 24/07/2024
  reqCompaniesShipmentId= [34, 39, 40, 41, 42, 43, 44, 45, 46, 47];

    
  get data():any { return this.userS.sharedData; } set data(value: any) { this.userS.sharedData = value; }
  constructor(
    private userS: UsersService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private router: Router,
    private customValidator: CustomValidatorsService
  ) {}

  get packages(){
    return this.formShipment.get('packages') as FormArray;
  }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.createForms();
    this.id_user = this.userObj.id;//this.userS.getUser().id;
    this.zipCodeError = false;
    this.zipCodeErrorDestination = false;
    this.countriesError=false;
    this.getIntCountries('origin',true);
    this.setForm();
    if(this.packages.length == 0)
      this.addPackage();
  }

  ngAfterViewInit() {
    this.receiveQQData();
    //#region Agregar Eventos Click al mat-step-header
    let elements = document.querySelectorAll('mat-step-header');
    //console.log('elements: ', elements);
    if (elements) {
        elements.forEach((e, i) => {
            //console.log('e', i, ': ', e);
            switch (i) {
              case 3: // 3 - Selecciona tu paquetería
                e.addEventListener('click', () => this.getRates());
                break;
              default:
                break;
            }
        })
    }
    //#endregion
  }

  createForms() {
    this.formOriginAddress = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      company: ['', [ Validators.minLength(3)]],
      email: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],
      phone: ['', [ Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      street: ['', [ Validators.required, Validators.minLength(2)]],
      number: ['', [ Validators.required, Validators.maxLength(15)]],
      postalCode: ['', [ Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      suburb: ['', [ Validators.required]],
      city: [{value:'', disabled: (this.userObj.iso_country == 'MX') }],
      state: [{value:'', disabled: (this.userObj.iso_country == 'MX') }],
      rfc: [''],
      reference: [''],
      origin: 1,
      country: this.userObj?.iso_country || 'MX'
    });
    this.formOriginAddress.get('suburb')?.valueChanges.subscribe(response => {
      this.filterOriginData(response);
    });
    this.formOriginAddress.get('country')?.valueChanges.subscribe(country => {
      const phoneControl = this.formOriginAddress.get('phone');
      phoneControl?.clearValidators(); // Elimina las validaciones
      //TODO: Que se tenga por país el largo de los teléfonos para personalizar el campo de acuerdo al país
      if (country === 'CL') { // Si el país es Chile
        phoneControl?.setValidators([Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(11)]);
      } else {
        phoneControl?.setValidators([Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]);
      }
      phoneControl?.updateValueAndValidity();
    });
    
    this.formDestinationAddress = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3),  Validators.maxLength(60)]],
      company: ['', []],
      email: ['', [Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],
      phone: ['', [ Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      street: ['', [ Validators.required, Validators.minLength(2)]],
      number: ['', [ Validators.required, Validators.maxLength(15)]],
      postalCode: ['', [ Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      suburb: ['', [ Validators.required]],
      city: [{value: '', disabled: (this.userObj.iso_country == 'MX') }],
      state: [{value: '', disabled: (this.userObj.iso_country == 'MX') }],
      rfc: [''],
      reference: [''],
      destination: 1,
      country: this.userObj?.iso_country || 'MX'
    });
    this.formDestinationAddress.get('suburb')?.valueChanges.subscribe(response => {
      this.filterDestinationData(response);
    });
    this.formDestinationAddress.get('country')?.valueChanges.subscribe(country => {
      const phoneControl = this.formDestinationAddress.get('phone');
      phoneControl?.clearValidators(); // Elimina las validaciones
      //TODO: Que se tenga por país el largo de los teléfonos para personalizar el campo de acuerdo al país
      if (country === 'CL') { // Si el país es Chile
        phoneControl?.setValidators([Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(11)]);
      } else {
        phoneControl?.setValidators([Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]);
      }
      phoneControl?.updateValueAndValidity();
    });

    this.formShipment = this.fb.group({
      shipmentId: ['', [ Validators.maxLength(70)]],
      packages:this.fb.array([])
    });

    //#region Temporalmente se solicitó por Sam que el order number es requerido
    const shipIdControl = this.formShipment.get('shipmentId');
    if (this.reqCompaniesShipmentId.includes(this.userObj.id_company)) {
      shipIdControl?.setValidators([Validators.required, Validators.maxLength(70)]);
    } else {
      shipIdControl?.setValidators([Validators.maxLength(70)]);
    }
    shipIdControl?.updateValueAndValidity();
    //#endregion

  }


  openDialogSelectAddress( isOrigin:number ) {
    const dialogRef = this.matDialog.open( AddressSelectComponent, {
      data: { 
        origin: isOrigin
      },
      panelClass: 'dialogs-lg',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == undefined ){ return } 
      if( result[1] == 1 ) {
        const {id, id_usuario, addressName, destination, deleted, created_at, deleted_at, ...selected} = result[0]
        selected.rfc = 'test';
        this.formOriginAddress.setValue(selected);
        this.selectedCountryOrigin = this.originIntCountries.find(country => country.countryCode === selected.country);
        this.origin = selected;
      }
      else {
        const {id, id_usuario, addressName, origin, deleted, created_at, deleted_at, ...selected} = result[0]
        selected.rfc = 'test';
        this.formDestinationAddress.setValue(selected);
        this.selectedCountryDestination = this.destinationIntCountries.find(country => country.countryCode === selected.country);
        this.destination = selected;
      }

    });
  }

  openDialogSaveAddress( data:FormGroup, isOrigin:string ) {
    if( isOrigin=='origin' ) {
      if( this.formOriginAddress.invalid ) { return };
      this.matDialog.open( AddressAddComponent, {
        data: { 
          info:  data.value,
          city:this.formOriginAddress.controls.city.value||this.origin.city,
          state:this.formOriginAddress.controls.state.value||this.origin.state
        },
        panelClass: 'dialogs-main',
      });
    }
    else {
      this.matDialog.open( AddressAddComponent, {
        data: { 
          info:  data.value,
          city:this.formDestinationAddress.controls.city.value||this.destination.city,
          state:this.formDestinationAddress.controls.state.value||this.destination.state
        },
        panelClass: 'dialogs-main',
      });
    }
  }

  calculateTotalPrice(number: any) {
    try{
      //Verificar si number es string
      if(typeof number === 'string'){

        //Extraer la primera letra y guardarla en una variable
        let firstLetter = number.charAt(0);

        //Si la primera letra es algún signo de moneda, eliminarlo
        if(firstLetter === '$' || firstLetter === '€' || firstLetter === '£'){
          number = number.slice(1);
        }
        number = parseFloat(number);

        let substract = number - 200.00;
        //Redondear a dos decimales y agregar signo de moneda
        let total = firstLetter + substract.toFixed(2);
  
        return total;

      } else {
        let substract = number - 200.00;
        //Redondear a dos decimales
        let total =  substract.toFixed(2);
  
        return total;
      }
    }catch(e){
      return 0;
    }
  }

  getSuburbs( event: Event, isOrigin:string ) { 
    console.log(event);
    let coutryControl;
    if(isOrigin == "origin"){
      coutryControl = this.formOriginAddress.get('country');
    }else{
      coutryControl = this.formDestinationAddress.get('country');
    }

    //if(this.userObj.iso_country == 'MX'){
    if(coutryControl?.value=='MX'){
      const postalCode = ( event.target as HTMLInputElement ).value.trim();
      if( postalCode.length != 5 ){ return };
        this.userS.getPcData( postalCode ).subscribe(( val: any ) => {
          if(isOrigin == "origin"){
            { val.message == 'Not found' ? this.zipCodeError = true : this.zipCodeError = false };
            this.origin = val.data;
            this.originFilteredOptions = val.data.neighborhoods;
            this.formOriginAddress.controls.city.setValue(val.data.city);
            this.formOriginAddress.controls.state.setValue(val.data.state);
          }else{
            { val.message == 'Not found' ? this.zipCodeErrorDestination = true : this.zipCodeErrorDestination = false };
            this.destination = val.data;
            this.destinationFilteredOptions = val.data.neighborhoods;
            this.formDestinationAddress.controls.city.setValue(val.data.city);
            this.formDestinationAddress.controls.state.setValue(val.data.state);

            //console.log(this.destination);
          }
        });
    }else{ //Si es diferente a MX
      this.getIntSuburbs( event, coutryControl?.value, isOrigin );
    }
    //}
  }

  getIntSuburbs( event: Event, countryCode:string, isOrigin:string ) {     
    const postalCode = ( event.target as HTMLInputElement ).value.trim();
      this.userS.getIntPcData( postalCode, countryCode ).subscribe(( val: any ) => {
        //{ val.message == 'Not found' ? this.zipCodeError = true : this.zipCodeError = false };
        if(isOrigin == "origin"){
          this.origin = val.data;
          if(this.withZipCode) //Si usa zipcode sería el mismo campo que mx
            this.originFilteredOptions = val.data.neighborhoods;
          else
            this.originIntSuburb = val.data.neighborhoods;
          this.formOriginAddress.controls.city.setValue(val.data.city);
          this.formOriginAddress.controls.state.setValue(val.data.state);
        }else{
          this.destination = val.data;
          if(this.withZipCode) //Si usa zipcode sería el mismo campo que mx
            this.destinationFilteredOptions = val.data.neighborhoods;
          else
            this.destinationIntSuburb = val.data.neighborhoods;
          this.formDestinationAddress.controls.city.setValue(val.data.city);
          this.formDestinationAddress.controls.state.setValue(val.data.state);
        }
      });
  }

  getIntCountries(isOrigin:string , initial:boolean=false) {
      this.userS.getAddressCountries( ).subscribe(( val: any ) => {
        { val.message == 'Not found' ? this.countriesError = true : this.countriesError = false };
        if(isOrigin == "origin"){
          //this.origin = val.data;
          this.originIntCountries = val.data;
        }else{
          //this.destination = val.data;
          this.destinationIntCountries = val.data;
        }

        if(initial){
          this.destinationIntCountries = [...val.data];
          // console.log(this.originIntCountries, this.destinationIntCountries);
          this.formOriginAddress.controls.country.setValue(this.userObj?.iso_country || 'MX');
          this.selectedCountryOrigin = this.originIntCountries.find(country => country.countryCode === this.userObj?.iso_country) || 'México';
          this.formDestinationAddress.controls.country.setValue(this.userObj?.iso_country || 'MX');
          this.selectedCountryDestination = this.destinationIntCountries.find(country => country.countryCode === this.userObj?.iso_country) || 'México';
        }
      });

  }

  getIntStates(isOrigin:string, iso:string ) {
      this.userS.getIntStates(iso).subscribe(( val: any ) => {
        { val.message == 'Not found' ? this.countriesError = true : this.countriesError = false };
        if(isOrigin == "origin"){
          this.originIntStates = val.data;
          this.filOriginIntStates = val.data;
          //console.log(JSON.stringify(this.originIntStates));
        }else{
          this.destinationIntStates = val.data;
          this.filDestinationIntStates = val.data;
          //console.log(this.destination);
        }
      });
  }

  getIntCities(countryCode:string, state:string, isOrigin:string ) {
    if(isOrigin == "origin"){
      this.originIntCities = [];
      this.filOriginIntCities = [];
    }else{
      this.destinationIntCities = [];
      this.filDestinationIntCities = [];
      //console.log(this.destination);
    }
    this.userS.getIntCity(countryCode, state).subscribe(( val: any ) => {
      { val.message == 'Not found' ? this.countriesError = true : this.countriesError = false };
      if(isOrigin == "origin"){
        this.originIntCities = val.data;
        this.filOriginIntCities = val.data;
      }else{
        this.destinationIntCities = val.data;
        this.filDestinationIntCities = val.data;
        //console.log(this.destination);
      }
    });
  }

  getIntSuburb(city:string, isOrigin:string ) {
    let coutryControl;
    if(isOrigin == "origin"){
      coutryControl = this.formOriginAddress.get('country');
    }else{
      coutryControl = this.formDestinationAddress.get('country');
    }
    
    this.userS.getIntSuburb(city.toString(), coutryControl?.value).subscribe(( val: any ) => {
      { val.message == 'Not found' ? this.countriesError = true : this.countriesError = false };
      if(isOrigin == "origin"){
        this.originIntSuburb = val.data;
      }else{
        this.destinationIntSuburb = val.data;
        //console.log(this.destination);
      }
    });
  }

  filterOriginData(enteredData:any){
    try {
      this.originFilteredOptions = this.origin.neighborhoods.filter(item =>{
        return item.toLowerCase().indexOf(enteredData.toLowerCase()) > -1;
      });
    } catch (error) {
     return;
    }
  }

  filterDestinationData(enteredData:any){
    try {
      this.destinationFilteredOptions= this.destination.neighborhoods.filter(item =>{
        return item.toLowerCase().indexOf(enteredData.toLowerCase()) > -1;
      })
    } catch (error) {
      return;
    }
  }
  
  setForm() {
    if(this.data?.from_address) {
      const { full_address, addressName, ...originFormInfo } = this.data.from_address;
      this.formOriginAddress.setValue(originFormInfo);
      setTimeout(() => {
        this.stepper.next();
      }, 0);
    }
    if(this.data?.to_address) {
      const { full_address, ...destinationFormInfo } = this.data.to_address;
      this.formDestinationAddress.setValue(destinationFormInfo);
      setTimeout(() => {
        this.stepper.next();
      }, 0);
    }
    if(this.data?.packages){ 
      this.data.packages.forEach((e:packagesModel) => {
        const packageFormGroup = this.fb.group({
          shipmentType: ['', [ Validators.required]],
          length: ['', [ Validators.required, Validators.pattern("^[0-9]*$")]],
          width: ['', [ Validators.required, Validators.pattern("^[0-9]*$")]],
          height: ['', [ Validators.required, Validators.pattern("^[0-9]*$")]],
          weight: ['', [ Validators.required, Validators.pattern("^[0-9]*$")]],
          content: ['', [ Validators.required]],
        });
        const { quantity,...packFormInfo } = e;
        packageFormGroup.setValue(packFormInfo);
        this.packages.push(packageFormGroup);
      });   
      setTimeout(() => {
        this.stepper.next();
      }, 0);   
    }
    if(this.data?.rate_selected) {
      setTimeout(() => {
        this.stepper.next();
      }, 0);
      this.getRates();
    }
  }

  addPackage(){
    const packageFormGroup = this.fb.group({
      shipmentType: ['box', [ Validators.required]],
      length: ['', [ Validators.required, Validators.pattern("^[0-9]*$")]],
      width: ['', [ Validators.required, Validators.pattern("^[0-9]*$")] ],
      height: ['', [ Validators.required, Validators.pattern("^[0-9]*$")]],
      weight: ['', [ Validators.required, Validators.pattern("^[0-9]*$"), this.customValidator.validateWeight.bind(this)]],
      content: ['', [ Validators.required]],
    });
    this.packages.push(packageFormGroup);
  }

  removePackage(index:number){
    this.packages.removeAt(index)
  }

  getRates() {
    let arrUnd!:Array<CarriersModel>;
    this.typeOfCarriers = arrUnd;
    this.typeOfCarriersError=false;

    let originSuburb = this.formOriginAddress.controls.suburb.value;
    let destinationSuburb = this.formDestinationAddress.controls.suburb.value;

    // Remover acentos para el campo suburb en caso de que sea de Chile
    if (this.userObj.iso_country == 'CL' || (this.formOriginAddress.controls.country.value == 'CL' && this.formDestinationAddress.controls.country.value == 'CL')) {
      originSuburb = originSuburb.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      destinationSuburb = destinationSuburb.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } else if (this.formOriginAddress.controls.country.value == 'CL') {
      originSuburb = originSuburb.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } else if (this.formDestinationAddress.controls.country.value == 'CL') {
      destinationSuburb = destinationSuburb.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    this.globalObject = {
      origin: {
        ...this.formOriginAddress.value,
        city:this.formOriginAddress.controls.city.value||this.origin.city,
        state:this.formOriginAddress.controls.state.value||this.origin.state,
        suburb: originSuburb
      },
      destination: {
        ...this.formDestinationAddress.value,
        city:this.formDestinationAddress.controls.city.value||this.destination.city,
        state:this.formDestinationAddress.controls.state.value||this.destination.state,
        email:this.formDestinationAddress.controls.email.value||this.emailDefault,
        suburb: destinationSuburb
      },
      isEcommerce: false,
      packages: this.formShipment.value.packages,
      shipmentId: this.formShipment.value.shipmentId,
      selected_rate:'',
      orderNumber: this.orderNumber,
    };

    this.userS.getRates(this.globalObject).subscribe(
      ( resp: any ) => {
        if ( resp.status.code == "success" ) {
          this.typeOfCarriers = resp.data;
          if (this.selectedQQ !== null)
            this.searchSelectedQQ();
          this.orderNumber = resp.orderNumber;
          this.getRateFav();
        }
        else{
          this.typeOfCarriersError = true;
        }
      },
      ( err:any ) => {
        this.typeOfCarriersError = true;
      });
  }

  getRateFav() {
    let fav = {
      servicio: false,
      precio: false,
      total_price:0,
      alias: ""
    };
    if(this.data != null && this.data.rate_selected !=null && this.typeOfCarriers.length) {
      fav.alias = this.data.alias;
      const obj_rs : any = this.data.rate_selected;
      const r_obt : any = this.typeOfCarriers.find((e : any) => e.carrier == obj_rs.carrier && e.service == obj_rs.service);
      fav.total_price = obj_rs.total_price;
      fav.servicio = (!!r_obt);

      if(fav.servicio) {
        fav.precio = ((r_obt.total_price*1) == (obj_rs.total_price*1));
        this.openDialogCheckout(this.stepper, r_obt, fav);
      }
      else {
        this.openDialogCheckout(this.stepper, obj_rs, fav);
      }
      
    }
  }


  openDialogCheckout(stepper: MatStepper,carrier:CarriersModel, fav:any|null = null){
    this.globalObject.selected_rate = carrier;
    this.globalObject.orderNumber = this.orderNumber;
    if(this.data?.isEcommerce) {
      this.globalObject.isEcommerce = true;
      this.globalObject.ecommerceOrderId = this.data.ecommerceOrderId;
    } 
    if(this.globalObject.destination.email==this.emailDefault){
      this.globalObject.destination.email='';
    }
    const dialogRef = this.matDialog.open( ShipmentsCheckoutComponent, {
      height: 'auto',
      maxHeight:'50rem',
      width:'80rem',
      data: { 
        data: this.globalObject,
        fav: fav
      },
      panelClass: 'custom-dialog-container-scroll'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result>=0){
        this.move(stepper,result);
        return;
      }

      if(result == undefined || result == '' || result == null ){ return } 
      try {
        Swal.fire({
          icon:  'info',
          title: 'Realizando pedido...',
          allowEscapeKey:    false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading( null );
          }
        });
        this.userS.getLabel(result).subscribe(
          ( resp: any ) => {
            if ( resp.status.code == "success" ) {
                Swal.fire({
                  icon:  'success',
                  title: 'Gracias por su compra',
                  html:  '¡Tu compra ha sido realizada con exito!',     
                  allowEscapeKey:    false,
                  allowOutsideClick: false,
                  showConfirmButton: false,           
                  timer: 1500,
                  timerProgressBar: true,
                }).then((result) => {
                  if (result.dismiss === Swal.DismissReason.timer) {
                    this.base64 = resp.data.labels[0].payload;
                    if(resp.data.documents)
                      this.base64Bol = resp.data.documents[0].payload;
                    let labelName = 'Guia'+'_'+ new Date().toLocaleString().slice(0,10).split('/').join('_').split(',').join('').split(' ').join('');
                    this.labelName = labelName;
                    this.goForward(stepper);
                    this.clicked = true;
                    this.openDialogLabelActions();
                  }
                });
              }
            },
          ( err:any ) => {
            if ( err.error!.status!.message.message == " Créditos insuficientes" || err.error!.status!.code == " Not enough credits ") {
              Swal.fire({
                icon:  'warning',
                title: 'Créditos insuficientes',
                text:  'No cuentas con los créditos necesarios para realizar esta compra.'
              });
            } else if ( err.error!.status!.message.message.includes("Error getting cache")) {
              Swal.fire({
                icon:  'warning',
                title: 'Buscar paqueterías',
                text:  'Por favor vuelve a realizar la búsqueda de paqueterías.'
              });
            }
            else {
              console.log( err );
              let msgError='Ocurrió un error al realizar la compra, por favor vuelva a intentarlo.';
              if(err.error!.status!.message!.custom)
                msgError=err.error!.status!.message!.custom.message
              Swal.fire({
                icon:  'error',
                title: 'Error en la compra',
                text:  msgError
              });
            }
          }
        );
      } catch (err:any) {
        console.log( err );
        Swal.fire({
          icon:  'error',
          title: 'Error en la compra',
          text:  'Ocurrió un error al realizar la compra, por favor vuelva a intentarlo.'
        });
      }
      
    });
  }

  openDialogLabelActions(){
    const dialogRef = this.matDialog.open( ShipmentsLabelActionsComponent, {
      width:'30rem',
      data: {
        data :{
         labelName : this.labelName,
         base64 :  this.base64,
         base64Bol :  this.base64Bol
        }
      },
      panelClass: 'dialogs-main',
    });
    dialogRef.afterClosed().subscribe( () => {
      if(this.globalObject?.isEcommerce){
        this.router.navigate(['/admin/ecommerce']);
      }else{
        this.router.navigate(['/admin/shipments']);
      }
    });

  }

  downloadPdf(base64String:string, fileName:string){
      const source = `data:application/pdf;base64,${base64String}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `${fileName}.pdf`
      link.click();
  }
  
  onClickDownloadPdf(){
    let base64String = this.base64;
    this.downloadPdf(base64String,this.labelName);
  }

  print() {
    printJS({ printable: this.base64, type: "pdf", base64: true})
  }

  goForward(stepper: MatStepper){
    stepper.next();
  }

  test(){
    const dialogRef = this.matDialog.open( ShipmentsLabelActionsComponent, {
      width:'30rem',
      data: {
        data :{
         labelName : this.labelName,
         base64 :  this.base64
        }
      },
      panelClass: 'dialogs-main',
    });
  }

  move(stepper: MatStepper, index: number) {
    stepper.selectedIndex = index;
  }

  imgFormat(value:string) {
    return value = value.replace(/\s+/g, '').toLowerCase();
  }

  emptyFieldsPostalCode(isOrigin:string) {
    if(isOrigin == "origin"){
      if(this.formOriginAddress.controls['postalCode'].value == ''){
        this.formOriginAddress.controls['suburb'].reset();
        this.formOriginAddress.controls['city'].reset();
        this.formOriginAddress.controls['state'].reset();
      }else{
        this.formOriginAddress.controls['suburb'].reset();
      }
      
    }else{
      if(this.formDestinationAddress.controls['postalCode'].value == ''){
        this.formDestinationAddress.controls['suburb'].reset();
        this.formDestinationAddress.controls['city'].reset();
        this.formDestinationAddress.controls['state'].reset();
      }else{
        this.formDestinationAddress.controls['suburb'].reset();
      }
    }
  }

  emptyFieldsNoPostalCode(isOrigin:string) {
    if(isOrigin == "origin"){
      if(this.formOriginAddress.controls['state'].value == ''){
        this.formOriginAddress.controls['city'].reset();
        this.formOriginAddress.controls['suburb'].reset();
      }
      if(this.formOriginAddress.controls['city'].value == ''){
        this.formOriginAddress.controls['suburb'].reset();
      }
    }else{
      if(this.formDestinationAddress.controls['state'].value == ''){
        this.formDestinationAddress.controls['city'].reset();
        this.formDestinationAddress.controls['suburb'].reset();
      }
      if(this.formDestinationAddress.controls['city'].value == ''){
        this.formDestinationAddress.controls['suburb'].reset();
      }
    }
  }

  emptyFieldsOnChange(isOrigin:string) {
    if(isOrigin == "origin"){
      this.formOriginAddress.controls.city.setValue('');
      this.formOriginAddress.controls.suburb.setValue('');
    }else{
      this.formDestinationAddress.controls.city.setValue('');
      this.formDestinationAddress.controls.suburb.setValue('');
    }
  }

  emptyFieldsOnChangeSuburb(isOrigin:string) {
    if(isOrigin == "origin"){
      this.formOriginAddress.controls.suburb.setValue('');
    }else{
      this.formDestinationAddress.controls.suburb.setValue('');
    }
  }

  onCheckZipCodes(change:any, isOrigin:string) {
    if(isOrigin == "origin"){
      this.selectedCountryOrigin = this.originIntCountries.find(country => country.countryCode === change.value);
      let countrie = this.originIntCountries.filter(x=> x.countryCode == change.value);
      // Resetear los campos
      //this.formOriginAddress.controls['street'].reset();
      //this.formOriginAddress.controls['number'].reset();
      this.formOriginAddress.controls['postalCode'].reset();
      this.formOriginAddress.controls['suburb'].reset();
      this.formOriginAddress.controls['city'].reset();
      this.formOriginAddress.controls['state'].reset();
      if(countrie){
        this.withZipCode = countrie[0].hasPostalCode=='true'?true:false;
      }
      if(!this.withZipCode)
        this.getIntStates(isOrigin, change.value );
      this.toggleFieldsZipCode(isOrigin, (countrie?.[0] || null));
    }else{
      this.selectedCountryDestination = this.destinationIntCountries.find(country => country.countryCode === change.value);
      let countrie = this.destinationIntCountries.filter(x=> x.countryCode == change.value);
      // Resetear los campos
      //this.formDestinationAddress.controls['street'].reset();
      //this.formDestinationAddress.controls['number'].reset();
      this.formDestinationAddress.controls['postalCode'].reset();
      this.formDestinationAddress.controls['suburb'].reset();
      this.formDestinationAddress.controls['city'].reset();
      this.formDestinationAddress.controls['state'].reset();
      if(countrie){
        this.withZipCodeDestination = countrie[0].hasPostalCode=='true'?true:false;
      }
      if(!this.withZipCodeDestination)
        this.getIntStates(isOrigin, change.value );
      this.toggleFieldsZipCode(isOrigin, (countrie?.[0] || null));
    }
  }
  onStateSelectionChange(countryCode:string, state:string, isOrigin:string) {
    let oldValue='';
    if(isOrigin == "origin")
      oldValue=this.formOriginAddress.get('state')?.value
    else
      oldValue=this.formDestinationAddress.get('state')?.value

    if(oldValue != state){
      this.getIntCities(countryCode,state,isOrigin);
    }
  }
  onCitySelectionChange(city:string , isOrigin:string) {
    let oldValue='';
    if(isOrigin == "origin")
      oldValue=this.formOriginAddress.get('city')?.value
    else
      oldValue=this.formDestinationAddress.get('city')?.value

    if(oldValue != city){
      this.getIntSuburb(city,isOrigin);
    }
  }

  toggleFieldsZipCode(isOrigin:string, country:IntCountry|null) {
    if(isOrigin == "origin"){
      const cityControl = this.formOriginAddress.get('city');
      const stateControl = this.formOriginAddress.get('state');
      
      if (this.withZipCode) {
        if(country && country.countryCode.toLocaleLowerCase()=='mx'){
          cityControl?.disable();
          stateControl?.disable();
        }else{
          cityControl?.enable();
          stateControl?.enable();
        }
        // cityControl?.disable();
        // stateControl?.disable();
        this.formOriginAddress.controls.postalCode.setValue('');
      } else {
        cityControl?.enable();
        stateControl?.enable();
        this.formOriginAddress.controls.postalCode.setValue('00000');
      }
    }else{
      const cityControl = this.formDestinationAddress.get('city');
      const stateControl = this.formDestinationAddress.get('state');
  
      if (this.withZipCodeDestination) {
        if(country && country.countryCode.toLocaleLowerCase()=='mx'){
          cityControl?.disable();
          stateControl?.disable();
        }else{
          cityControl?.enable();
          stateControl?.enable();
        }
        // cityControl?.disable();
        // stateControl?.disable();
        this.formDestinationAddress.controls.postalCode.setValue('');
      } else {
        cityControl?.enable();
        stateControl?.enable();
        this.formDestinationAddress.controls.postalCode.setValue('00000');
      }
    }
  }

  setRequiredsFields(isOrigin:string, country: IntCountry) {
    let stateControl;
    let cityControl;
    let numberControl;
    let suburbControl;

    if(isOrigin == "origin"){
      stateControl=this.formOriginAddress.get('state');
      cityControl=this.formOriginAddress.get('city');
      numberControl=this.formOriginAddress.get('number');
      suburbControl=this.formOriginAddress.get('suburb');
    }else{
      stateControl=this.formDestinationAddress.get('state');
      cityControl=this.formDestinationAddress.get('city');
      numberControl=this.formDestinationAddress.get('number');
      suburbControl=this.formDestinationAddress.get('suburb');
    }
    
    //stateControl?.clearValidators(); // Elimina la validación de requerido
    //cityControl?.clearValidators(); // Elimina la validación de requerido
    numberControl?.clearValidators(); // Elimina la validación de requerido
    suburbControl?.clearValidators(); // Elimina la validación de requerido

    if(country.countryCode=='MX'){
      // cityControl?.setValidators([{value:'', disabled: (this.userObj.iso_country == 'MX') }]); 
      // stateControl?.setValidators([{value:'', disabled: (this.userObj.iso_country == 'MX') }]); 
      numberControl?.setValidators([Validators.required]); 
      suburbControl?.setValidators([Validators.required]); 
    }else{
      // if(country.requirednumber)
      //   numberControl?.setValidators([Validators.required]); 
      // if(country.requiredsuburb)
      //   suburbControl?.setValidators([Validators.required]); 
    }
    
    numberControl?.updateValueAndValidity();
    suburbControl?.updateValueAndValidity();
    console.log(isOrigin, 'updateValueAndValidity');
  }

  filterStates(event: Event, isOrigin:string): void {
        if(isOrigin=='origin'){
      const inputValue = (event.target as HTMLInputElement).value;
      this.filOriginIntStates = this.originIntStates.filter(option =>
        option.state.toLowerCase().includes(inputValue.toLowerCase())
      );
    }else{
      const inputValue = (event.target as HTMLInputElement).value;
      this.filDestinationIntStates = this.destinationIntStates.filter(option =>
        option.state.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  }
  filterCities(event: Event, isOrigin:string): void {
        if(isOrigin=='origin'){
      const inputValue = (event.target as HTMLInputElement).value;
      this.filOriginIntCities = this.originIntCities.filter(option =>
        option.city.toLowerCase().includes(inputValue.toLowerCase())
      );
    }else{
      const inputValue = (event.target as HTMLInputElement).value;
      this.filDestinationIntCities = this.destinationIntCities.filter(option =>
        option.city.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  }
  filterSuburbs(event: Event, isOrigin:string): void {
    if(isOrigin=='origin'){
      const inputValue = (event.target as HTMLInputElement).value;
      this.filOriginIntSuburb = this.originIntSuburb.filter(option =>
        option.toLowerCase().includes(inputValue.toLowerCase())
      );
    }else{
      const inputValue = (event.target as HTMLInputElement).value;
      this.filDestinationIntSuburb = this.destinationIntSuburb.filter(option =>
        option.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  }

  receiveQQData(): void {
    const data: any | null = this.userS.receiveNewShipData();
    let fakeEvent, packageData;
    
    if (data !== null) {
      // Set selected quick quote
      this.selectedQQ = data.quote;

      // Set origin and destination post codes
      this.formOriginAddress.controls.postalCode.setValue(data.origin);
      this.formDestinationAddress.controls.postalCode.setValue(data.destination);

      // Force keyup events for post codes
      fakeEvent = { target: this.originPostalCode.nativeElement as HTMLInputElement } as unknown as Event;
      this.getSuburbs(fakeEvent, 'origin');
      fakeEvent = { target: this.destPostalCode.nativeElement as HTMLInputElement } as unknown as Event;
      this.getSuburbs(fakeEvent, 'destination');

      // Set package data
      packageData = this.fb.group({
        content: ['', [ Validators.required]],
        height: [data.package.height, [ Validators.required, Validators.pattern("^[0-9]*$")]],
        length: [data.package.length, [ Validators.required, Validators.pattern("^[0-9]*$")]],
        shipmentType: ['box', [ Validators.required]],
        weight: [data.package.weight, [ Validators.required, Validators.pattern("^[0-9]*$"), this.customValidator.validateWeight.bind(this)]],
        width: [data.package.width, [ Validators.required, Validators.pattern("^[0-9]*$")] ]
      });

      this.packages.push(packageData);
      this.packages.removeAt(0);
  } }

  searchSelectedQQ(): void {
    const i = this.typeOfCarriers.findIndex(x =>
      x.carrier === this.selectedQQ.carrier &&
      x.price === this.selectedQQ.cost &&
      x.service === this.selectedQQ.servicetype
    );

    if (i !== -1) {
      this.foundQQ = true;
      const quote = this.typeOfCarriers.splice(i, 1)[0];
      this.typeOfCarriers.unshift(quote);
    } else this.foundQQ = false;
  }
}