<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>Pedir Recolección</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html">
              <mat-icon>trolley</mat-icon>
            </a></li>
          <li class="breadcrumb-item">Recolecciones</li>
          <li class="breadcrumb-item">Pedir Recolección</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="pickupForm">
        <div class="card">
          <div class="card-body">
            <h3 class="m-0 py-4 section-title">Guía
              <mat-icon class="align-middle">document_scanner</mat-icon>
            </h3>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Seleccionar Guía:</mat-label>
              <mat-select formControlName="sid">
                <mat-select-trigger>
                  {{ extractShipmentID(pickupForm.controls.sid.value) }}
                </mat-select-trigger>
                <mat-option></mat-option>
                <mat-option class="shipment-opt" *ngFor="let x of shipmentsList; let i = index" [value]="i">
                  <div class="shipment-opt-main">
                    <div class="shipment-opt-r1">
                      <span class="shipment-opt-id">{{ x[0] }}</span>
                      <span class="shipment-opt-gray">({{ x[1] }})</span>
                    </div>
                    <div class="shipment-opt-r2">
                      <span>{{ x[2] }}</span>
                      <span class="shipment-opt-gray">{{ x[3] }}</span>
                    </div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <hr style="border-top: dotted 2px;" />
            <h3 class="m-0 py-4 section-title">Dirección
              <mat-icon class="align-middle">location_on</mat-icon>
            </h3>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Nombre Completo</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Empresa</mat-label>
                  <input matInput formControlName="company">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Teléfono</mat-label>
                  <input matInput formControlName="phone">
                </mat-form-field>
              </div>
              <!-- <div *ngIf="user.international_shipping" class="col-md-3">
                <mat-form-field class="w-100 future-imp" appearance="outline">
                  <mat-label>País </mat-label>
                  <mat-select formControlName="country" (selectionChange)="restartData($event)">
                    <mat-select-trigger>
                      <img src="assets/countryFlags/mx.svg" alt="Mexico" class="flag"> México
                    </mat-select-trigger>
                    <mat-option value="MX">
                      <img src="assets/countryFlags/mx.svg" alt="Mexico" class="flag"> México
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
            </div>
            <div *ngIf="postcoded">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Calle</mat-label>
                    <input matInput formControlName="street">
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Número</mat-label>
                    <input matInput formControlName="number">
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Código Postal</mat-label>
                    <input matInput #postCode (keyup)="getLocationData($event,'neighborhoods'); clearFields()"
                      formControlName="postCode">
                    <mat-hint align="end">{{ postCode.value.length }} / 5</mat-hint>
                    <mat-hint class="text-orange"
                      *ngIf="error == 'PostCode' && pickupForm.get('country')?.value == 'MX'">
                      Código postal <strong>inválido</strong>
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Colonia</mat-label>
                    <input type="text" matInput formControlName="neighborhood" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let x of sector.nbhoods" [value]="x">
                        {{ x }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Ciudad</mat-label>
                    <input matInput formControlName="city">
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Estado</mat-label>
                    <input matInput formControlName="state">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div *ngIf="!postcoded">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Estado</mat-label>
                    <input (keyup)="clearFields(false)" type="text" matInput formControlName="state"
                      [matAutocomplete]="autoStateOrigin" (input)="filterSubDivisions($event, 'states')">
                    <mat-autocomplete #autoStateOrigin="matAutocomplete">
                      <mat-option *ngFor="let x of sector.states" [value]="x.state"
                        (onSelectionChange)="replaceData('state', x.state, x.countryCode); onChangeClear()">
                        {{ x.state }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Ciudad</mat-label>
                    <input (keyup)="clearFields(false)" type="text" matInput formControlName="city"
                      [matAutocomplete]="autoCityOrigin" (input)="filterSubDivisions($event, 'cities')">
                    <mat-autocomplete #autoCityOrigin="matAutocomplete">
                      <mat-option *ngFor="let x of sector.cities" [value]="x.city"
                        (onSelectionChange)="replaceData('city', x.city); onChangeClear(true)">
                        {{ x.city }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Colonia</mat-label>
                    <input type="text" matInput formControlName="neighborhood" [matAutocomplete]="autoOrigin">
                    <mat-autocomplete #autoOrigin="matAutocomplete">
                      <mat-option *ngFor="let x of data.nbhoods" [value]="x">
                        {{ x }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Calle</mat-label>
                    <input matInput formControlName="street">
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Número</mat-label>
                    <input matInput formControlName="number">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row pb-4">
              <div class="col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>RFC</mat-label>
                  <input matInput #rfc maxlength="13" formControlName="rfc">
                  <mat-hint align="end">{{ rfc.value.length }} / 13</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Referencia</mat-label>
                  <input matInput formControlName="reference">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h3 class="m-0 py-4 section-title">Paquetería 
              <mat-icon class="align-middle">local_shipping</mat-icon>
            </h3>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Paquetería:</mat-label>
                  <mat-select formControlName="carrier" [disabled]="lockCarrierSelect" (selectionChange)="getCarrierData(false)">
                    <mat-option value="-1"></mat-option>
                    <mat-option *ngFor="let x of carrierList; let i = index" [value]="i">
                      <img class="min-icon" src="assets/images/carriers/minicon_{{ carrierAbbrList[i] }}.png" />
                      &nbsp; {{ x }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Fecha de Recolección:</mat-label>
                  <mat-select formControlName="pckDate">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let x of pckDatesRange; let i = index" [value]="pckDatesNumValue[i]">
                      {{ x }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Hora Inicial de Recolección:</mat-label>
                  <mat-select formControlName="initTime" [disabled]="pckInitRange.length == 1">
                    <mat-option *ngFor="let x of pckInitRange" [value]="x">
                      {{ toTime(x) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Hora Final de Recolección:</mat-label>
                  <mat-select formControlName="finalTime" [disabled]="pckFinalRange.length == 1">
                    <mat-option *ngFor="let x of pckFinalRange" [value]="x" [disabled]="tooSoon(x)">
                      {{ toTime(x) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Cantidad de Paquetes:</mat-label>
                  <input matInput type="number" min="1" formControlName="packages">
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Peso Total:</mat-label>
                  <button matPrefix mat-icon-button disabled><mat-icon>scale</mat-icon></button>
                  <input matInput type="text" formControlName="weight">
                  <button matSuffix mat-button color="primary" class="forced-suffix" (click)="changeWUnit()">{{ wUnit }}</button>
                </mat-form-field>
              </div>
            </div>
            <div class="py-4">
              <button mat-flat-button class="inform-btn" (click)="openManifestDialog()">
                {{ manifestExist ? 'Editar Manifiesto' : 'Crear Manifiesto' }}
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body py-4">
            <h3 class="m-0 py-4 section-title">Costos de Recolección
              <mat-icon class="align-middle">receipt</mat-icon>
            </h3>
            <div class="checkout">
              <h4 class="check-sub">Tarifa de recolección</h4>
              <h4 class="check-sub">${{ fee }} MXN</h4>
            </div>
            <hr>
            <div class="checkout">
              <h3 class="check-total" color="primary">Total</h3>
              <h3 class="check-total" color="primary">${{ total }} MXN</h3>
            </div>
            <div class="checkout-btncont">
              <button mat-flat-button class="checkout-btn" [disabled]="requestIsReady() || requestLoading" (click)="sendPickupRequest()">
                <ng-container *ngIf="requestLoading"><div class="checkout-spinner"><mat-spinner diameter="36"></mat-spinner></div></ng-container>
                <ng-container *ngIf="!requestLoading">Solicitar recolección</ng-container>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
