import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CompanyModel } from 'src/app/models/company.model';
import { BillingDataModel, ServicesModeModel } from 'src/app/models/shipment.model';
import { CurrencyModel, UserModel } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import { PriceComparisonXlsComponent } from '../shared/dialogs/price-comparison-xls/price-comparison-xls.component';
import { PriceComparisonNofoundComponent } from '../shared/dialogs/price-comparison-nofound/price-comparison-nofound.component';
import { CostCatalogXlsComponent } from '../shared/dialogs/cost-catalog-xls/cost-catalog-xls.component';

@Component({
  selector: 'app-price-comparison',
  templateUrl: './price-comparison.component.html',
  styleUrls: ['./price-comparison.component.scss']
})
export class PriceComparisonComponent implements OnInit {
  billingDataList =  new Array<BillingDataModel>();
  okCurrency: any;
  isLoading = true;
  base64!:string;
  servicesModeList =  new Array<ServicesModeModel>();
  xlsData=new Array<BillingDataModel>();
  xlsDataNotFound=new Array<BillingDataModel>();
  xlsDataNotFoundCatalog=new Array<BillingDataModel>();
  xlsDataCost=new Array<BillingDataModel>();
  activeFilterXls = false;
  filterProperty = '';
  countCoincidencia = 0;
  formatoXls='';
  formatoCostXls='';

  costoZonaExtendida = 212.5;
  

  //#region Filtros
  dateNow : Date = new Date();
  dateNowMS : number = (new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate())).getTime();
    
  newDateTodayI : Date | null = null;//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDay());
  minDateI : Date =  new Date(2022, 0, 1);
  maxDateI : Date =  new Date();
  dateIDis=true;
  newDateTodayF : Date | null = null//new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
  minDateF : Date =  new Date(2022, 0, 1);
  maxDateF : Date =  new Date();
  dateFDis=true;

  showFilter=false;
  selFechas = new FormControl('');  
  selCompanies = new FormControl('');  
  selCompaniesList:Array<CompanyModel>=[];
  selCurrency = new FormControl('');  
  selCurrencyList:Array<CurrencyModel>=[];
  selUsers = new FormControl('');  
  selUsersList:Array<UserModel>=[];

  preLoadHidden = true;
  preUploadXlsdHidden = true;
  //#endregion
  
  //displayedColumns: string[] = ['tracking_number', 'carrier', 'shipment_id', 'order_number', 'company_name', 'user_name', 'shipment', 'created_at', 'currency', 'service_price', 'total_price'];
  columnDefinitions = [
    { def:'created_at', label: 'Fecha de creación', hide: false},
    { def:'partner_id', label: 'PartnerId', hide: false},
    { def:'company_name', label: 'Cliente', hide: false},
    { def:'order_number', label: 'Order Id', hide: false},
    //{ def:'shipment_id', label: '# Orden', hide: true},
    { def:'provider', label: 'Proveedor', hide: false},
    { def:'carrier', label: 'Courier', hide: false},
    { def:'service', label: 'Método de envio', hide: false},
    { def:'extended_zone', label: 'Zona extendida', hide: false},
    { def:'shipment_type', label: 'Tipo', hide: false},
    { def:'content', label: 'Contenido', hide: true},
    { def:'xcPesoRegistrado', label: 'Peso Estafeta', hide: true},
    { def:'kg', label: 'Kg enviado', hide: false},
    { def:'xcPesoDiferencia', label: 'Diferencia de peso', hide: true},

    { def:'dimension', label: 'Volumen enviado', hide: false},
    //{ def:'origin', label: 'Origen', hide: true},
    //{ def:'destination', label: 'Destino', hide: true},
    //{ def:'shipment', label: 'Envío', hide: true},
    { def:'service_price', label: 'Costo Respuesta api', hide: false},
    { def:'xcCostoCobrado', label: 'Costo Estafeta', hide: true},
    { def:'xcCostoDiferencia', label: 'Diferencia Buho VS Estafeta', hide: true},
    
    { def:'other_charge_price', label: 'Precio de venta otros cargos', hide: false},
    { def:'total_price', label: 'Precio de venta total', hide: false},
    { def:'currency', label: 'Moneda', hide: false},
    
    { def:'xTotal_price', label: 'Costo Proveedor', hide: true},
    { def:'xcCosto', label: 'Costo catálogo', hide: true},
    { def:'xTotal_dif', label: 'Diferencia', hide: true},

    { def:'xcKgText', label: 'Kg Comprados', hide: true},
    { def:'xcModo', label: 'Modo catálogo', hide: true},

    { def:'xCreated_at', label: 'xls creación', hide: true},
    { def:'xDestination_name', label: 'xls Destinatario', hide: true},    

    { def:'tracking_number', label: 'Guía', hide: false},

  ]
  showOptions = false;
  getDisplayedColumns():string[] {
    return this.columnDefinitions.filter(cd=>!cd.hide).map(cd=>cd.def);
  }

  getDisplayedLabel(def:string):string {
    let cLabel=this.columnDefinitions.find(item=> item.def==def);
    return cLabel?.label||''
  }
  
  dataSource!: MatTableDataSource<BillingDataModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.showFilter=true;
    this.selFechas.setValue('2');
    this.selCurrency.setValue('0');
    this.onchangeFechas(this.selFechas);
    //No cargar el listado de manera inicial
    //this.getBillingDataList(); 
    this.isLoading = false; //porque no se carga al principio los datos
    this.getServicesMode();
    // this.getCompaniesList();
    // this.getCurrencyList();
    // this.getUsersList();
    //this.newDateToday.setDate(this.newDateToday.getDate() + 1);
  }

  getBillingDataList(date_i : string|null = null, date_f : string|null = null) {
    console.log('getBillingDataList');
    this.userS.getBillingDataListSf(date_i, date_f).subscribe(
      (result : any) => {

        this.billingDataList = result;
        this.formatoXls='';
        this.formatoCostXls='';
        this.xlsData = new Array<BillingDataModel>();
        this.xlsDataNotFound = new Array<BillingDataModel>();
        this.xlsDataNotFoundCatalog = new Array<BillingDataModel>();
        
        for ( let i = 0; i < this.billingDataList.length; i++ ) {
          this.billingDataList[i].package_dimensions = JSON.parse(this.billingDataList[i].package_dimensions);
        }
        this.dataSource = new MatTableDataSource(this.billingDataList);        
        this.dataSource.paginator = this.paginator;
        //this.dataSource.paginator.pageSize=this.billingDataList.length;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.preLoadHidden = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  
  
  // showSumFooter() {
  //   let vShow = false;
  //   if(this.okCurrency!=undefined){ 
  //     if(this.okCurrency.length==1){
  //       vShow = true;
  //     }else{
  //       if(this.selCurrency.value == "0"){ //si son todas
  //         vShow = false;
  //       }else{ //Tiene selecciona 1 tipo de moneda
  //         vShow = true
  //       }
  //     }
  //   }else{
  //     vShow = false;
  //   }
  //   return vShow;
  // }

  dateToString(date:Date) {
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
  }
  

  searchFilter() {
    this.isLoading = true;
    let date_i:string|null, date_f:string|null, users:string|null, companies:string|null;
    let arrCom:Array<number>=this.selCompanies.value;
    let arrUse:Array<number>=this.selUsers.value;
    let currency:string;

    date_i = this.newDateTodayI==null?this.newDateTodayI:this.dateToString(this.newDateTodayI);
    date_f = this.newDateTodayF==null?this.newDateTodayF:this.dateToString(this.newDateTodayF);
    companies=arrCom.length>0?arrCom.join(','):null;
    users=arrUse.length>0?arrUse.join(','):null;
    currency=this.selCurrency.value;
    console.log(companies, users);
    this.getBillingDataList(date_i, date_f);
  }

  getServicesMode() {
    this.userS.getListServicesMode().subscribe(
      (result : any) => {
        this.servicesModeList = result;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }


  // getCompaniesList() {
  //   this.userS.getCompanies().subscribe(
  //     (result : any) => {
  //       this.selCompaniesList = result;
  //     },
  //     (err : any) => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }
  //   );
  // }
  // getCurrencyList() {
  //   this.userS.getCurrencies().subscribe(
  //     (result : any) => {
  //       this.selCurrencyList = result;
  //     },
  //     (err : any) => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }
  //   );
  // }
  // getUsersList() {
  //   this.userS.getUserListMin().subscribe(
  //     (result : any) => {
  //       this.selUsersList = result;
  //     },
  //     (err : any) => {
  //       console.log(err);
  //       this.isLoading = false;
  //     }
  //   );
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  
  searchFilterClean() {
    this.selCompanies.setValue([]);
    this.selUsers.setValue([]);
  }

  onchangedateI(e: Date): void {
    console.log('Inicio', e, 'Fin', this.newDateTodayF);
    if(this.newDateTodayF!=null && this.newDateTodayF!.getTime()<e.getTime()){
        this.newDateTodayF=null;
    }
  }
  onchangedateF(e: Date): void {
    console.log('Fin', e, 'Inicio', this.newDateTodayI);
    if(this.newDateTodayI!=null && this.newDateTodayI!.getTime()>e.getTime()){
      this.newDateTodayI=null;
    }
  }

  onchangeFechas(change:any) {
    //console.log(change);
    switch (change.value) {
      case '1'://Hoy
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '2'://Este mes
        this.newDateTodayI = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1);
        this.newDateTodayF = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());
        this.dateIDis=true;
        this.dateFDis=true;
        break;
      case '3'://Elegir fechas
        this.dateIDis=false;
        this.dateFDis=false;
        break;
      default:
        break;
    }
  }

  
  getPageSizeOptions(): number[]{
    if(this.billingDataList.length>10)
    return [10,50,100,this.billingDataList.length];
    else
    return [10,50,100];
  }
  
  getTotalService() {
    return (this.billingDataList.map(t => t.service_price).reduce((acc, value) => acc + Number(value), 0)).toFixed(2);
  }

  getTotalPrice() {
    return (this.billingDataList.map(t => t.total_price).reduce((acc, value) => acc + Number(value) , 0)).toFixed(2);
  }
  
  toggleColumn(columnName: string, event: MatCheckboxChange) {
    //console.log(columnName);
    let columnHS=this.columnDefinitions.find( item=> item.def==columnName);
    if(columnHS){
      columnHS.hide=!event.checked;
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  getAddress(data:string) {
    try {
      const address = JSON.parse(data);
      return `${address.name}, ${address.street}, ${address.number}, ${address.suburb}, ${address.postalCode}, ${address.city}`;
    } catch (error) {
      return ''; 
    }
  }

  openUploadExcel() {
    this.billingDataList.forEach(elemento => {
      if (elemento.hasOwnProperty('xTotal_price')) {
        delete elemento.xTotal_price;
      }
      if (elemento.hasOwnProperty('xCreated_at')) {
        delete elemento.xCreated_at;
      }
      if (elemento.hasOwnProperty('xDestination_name')) {
        delete elemento.xDestination_name;
      }
    });

    const dialogRef = this.matDialog.open( PriceComparisonXlsComponent, {
      data: { 
        //shipmentIdSelected: id
      },
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        console.log(resp);
        idBU = resp.id;
        this.xlsData = resp.data;
        this.nameFormatoXls(resp.formato);
        this.comparativeBdXls(resp.formato);
        this.preUploadXlsdHidden=false;
        this.columnDefinitions.find
        let ctp=this.columnDefinitions.find( item=> item.def=='xTotal_price');
        if(ctp){
          ctp.hide=false;
        }
        // let cca=this.columnDefinitions.find( item=> item.def=='xCreated_at');
        // if(cca){
        //   cca.hide=false;
        // }
        let cdn=this.columnDefinitions.find( item=> item.def=='xDestination_name');
        if(cdn){
          cdn.hide=false;
        }
        switch (resp.formato) {
          case 'estafeta':
            let xcPes=this.columnDefinitions.find( item=> item.def=='xcPesoRegistrado');
            if(xcPes){
              xcPes.hide=false;
            }
            let xcDif=this.columnDefinitions.find( item=> item.def=='xcPesoDiferencia');
            if(xcDif){
              xcDif.hide=false;
            }
            let xcCos=this.columnDefinitions.find( item=> item.def=='xcCostoCobrado');
            if(xcCos){
              xcCos.hide=false;
            }
            let xcCosDif=this.columnDefinitions.find( item=> item.def=='xcCostoDiferencia');
            if(xcCosDif){
              xcCosDif.hide=false;
            }

            break;
        
          default:
            break;
        }

      }
      //if(idBU>0)
        //this.goToBulkUploads(idBU);
    });
  }

  openUploadCost() {
    this.billingDataList.forEach(elemento => {
      if (elemento.hasOwnProperty('xcPaqueteria')) {
        delete elemento.xcPaqueteria;
      }
      if (elemento.hasOwnProperty('xcModo')) {
        delete elemento.xcModo;
      }
      if (elemento.hasOwnProperty('xcKgText')) {
        delete elemento.xcKgText;
      }
      if (elemento.hasOwnProperty('xcZona')) {
        delete elemento.xcZona;
      }
      if (elemento.hasOwnProperty('xcProveedor')) {
        delete elemento.xcProveedor;
      }
      if (elemento.hasOwnProperty('xcExtra')) {
        delete elemento.xcExtra;
      }
      if (elemento.hasOwnProperty('xcCosto')) {
        delete elemento.xcCosto;
      }
    });

    const dialogRef = this.matDialog.open( CostCatalogXlsComponent, {
      data: { 
        //shipmentIdSelected: id
      },
      height: 'auto',
      maxHeight:'45rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        console.log(resp);
        idBU = resp.id;
        this.xlsDataCost = resp.data;
        this.formatoCostXls=(resp.formato);
        this.comparativeBDCost();
        this.preUploadXlsdHidden=false;
        //this.columnDefinitions.find;
        let cco=this.columnDefinitions.find( item=> item.def=='xcCosto');
        if(cco){
          cco.hide=false;
        }
        let xtd=this.columnDefinitions.find( item=> item.def=='xTotal_dif');
        if(xtd){
          xtd.hide=false;
        }
        let cdn=this.columnDefinitions.find( item=> item.def=='xcKgText');
        if(cdn){
          cdn.hide=false;
        }
        let cds=this.columnDefinitions.find( item=> item.def=='xcModo');
        if(cds){
          cds.hide=false;
        }
      }
      //if(idBU>0)
        //this.goToBulkUploads(idBU);
    });
  }

  nameFormatoXls(formato:string) {
    let name='';
    switch (formato) {
      case 'pgo':        
        this.formatoXls = 'Pak2go';
        break;
      case 'afimex':
        this.formatoXls = 'Afimex';
        break;
      case 'eme':
        this.formatoXls = 'Enviame';
        break;
      case 'paquetexpress':
        this.formatoXls = 'Paquetexpress'
        break;
      case 'estafeta':
          this.formatoXls = 'Estafeta'
          break;
      default:
        this.formatoXls='';
        break;
      }
      return name;
  }

  trackingsNoFound(tipo:string) {
    const dialogRef = this.matDialog.open( PriceComparisonNofoundComponent, {
      data: tipo!='catalog'? this.xlsDataNotFound:this.xlsDataNotFoundCatalog,
      height: 'auto',
      maxHeight:'50rem',
      width:'80rem',
      panelClass: 'custom-dialog-container-scroll',
      //panelClass: 'dialogs-main',
    });

    dialogRef.afterClosed().subscribe(resp => {
      let idBU=0;
      if (resp) {
        console.log(resp);
      }
      //if(idBU>0)
        //this.goToBulkUploads(idBU);
    });
  }

  comparativeBdXls(format:string){
    console.log(format);
    if(this.xlsData.length>0 && this.billingDataList.length>0){
      // this.billingDataList.forEach((item1) => {
      //   const item2 = this.xlsData.find((element: { xTracking_number: string; }) => element.xTracking_number === item1.tracking_number);
      //   if (item2) {
      //     item1.xTotal_price = item2.xTotal_price;
      //     item1.xCreated_at = item2.xCreated_at;
      //     item1.xDestination_name = item2.xDestination_name;
      //   }else{
      //     this.xlsDataNotFound.push();
      //   }
      // });

      this.xlsData.forEach((item1) => {
        let item2 = this.billingDataList.find((element) => element.tracking_number == item1.xTracking_number 
                                                              || (format=='eme' && element.tracking_number == item1.xTracking_number_a )  //Para encontrar todas las guías de una guía madre de enviame
                                                                                                                                          //También aplica si no lo encontró, si es enviame y su estado es Devolución, porque va a checar en xTracking_number_a 
                                                              || (format=='afimex' && element.tracking_number == item1.xContent ) //a veces afimex devuelve completa la guía y otras no, la completa esta en xContent
                                                              ); 
                                                                                                                                            
        
        if (item2) {
          
          switch (format) {
            case 'pgo': //Solo cuando sea pack2go se va a agregar el other_charge_price que viene en el xPrice
              item2.other_charge_price = item1.xPrice;
              break;
            case 'estafeta': 
              item2.xcPesoRegistrado = item1.xcPesoRegistrado;
              item2.xcCostoCobrado = item1.xcCostoCobrado;
              break;
          
            default:
              break;
          }

          if(item2.xTotal_price) //Si ya tiene precio es porque es una guía madre
            item2.xMother_guide=1; //Si ya es una guía madre ya se contó 1 vez, TODO: la primera de las guías de la madre no tiene el bit xMother_guide en 1
          else
            item2.xMother_guide=0;

          item2.xTotal_price = (item2.xTotal_price||0) + (item1.xTotal_price||0); //Por si hay una guía madre, se le iría sumando al total cada guía del paquete
          item2.xCreated_at = item1.xCreated_at;
          item2.xDestination_name = item1.xDestination_name;

          if(item2.xMother_guide==0) //No contar a las guías madre, solo se cuenta la primera vez
            this.countCoincidencia++;

        }else{
          this.xlsDataNotFound.push(item1);
        }
      });

      console.log(this.xlsDataNotFound);

      this.filterXTracking();
    }
  }

  comparativeBDCost(){
    this.isLoading = false;
    let conta=0;
    let contaFound=0;
    let contaNotFound=0;
    let contaNotFoundBilling=0;
    if(this.xlsDataCost.length>0 && this.billingDataList.length>0){
      this.billingDataList.forEach((item1) => {
        //filtrar por provider y paqutería
        // const lsCost = this.xlsDataCost.filter((element) => item1.provider == element.xcProveedor && element.xcPaqueteria?item1.carrier.includes(element.xcPaqueteria):
        //                                                                                                               (element.xcPaqueteria=='FEDEX' && item1.carrier.includes('FDXM')?true:false));
        // if(item1.order_number=="4100001044" ){
        //   console.log(item1.order_number);
        // }

        // if(item1.tracking_number=='191174387414'){
        //   console.log(item1);
        // }

        // console.log('------------------------------------');
        // console.log(this.formatoXls);
        let arrProvPermitidos=this.getPermittedProvider();
        let continueComparative=false;
        continueComparative=arrProvPermitidos.includes(item1.provider);

        if(continueComparative){
          let bdCarrier=item1.carrier;
          switch (item1.carrier.toUpperCase()) {
            case 'FDXM':
              bdCarrier='FEDEX';
              break;
            case 'MX99':
              bdCarrier='99MINUTOS';
              break;
            case 'EST':
            case 'ESTS':
              bdCarrier='ESTAFETA';
              break;
            // case 'AFX':
            //   console.log('AFX', item1);
            //   break;
            // case 'AFIMEX':
            //   //console.log('AFIMEX',item1);
            //   break;
            case 'BIG SMART':
              bdCarrier='BSM';
              break;
            default:
              break;
          }
          //Sacar el peso
          let pac_dim=[];
          if (!Array.isArray(item1.package_dimensions)) {
            // No es un array
            pac_dim=JSON.parse(item1.package_dimensions);
          }else{
            pac_dim=item1.package_dimensions;
          }

          let modoEnv='';

          if(item1.carrier=="BIG SMART" )
            modoEnv=this.getServiceMode(item1.service, "BSM", item1.provider)
          else
            modoEnv=this.getServiceMode(item1.service, item1.carrier, item1.provider)

          if(item1.carrier.includes("est") ) // item1.carrier=="ESTAFETA S" || item1.carrier=="FDXM" || item1.carrier=="ESTS")
            console.log(modoEnv, item1.provider, item1.carrier, item1.service, bdCarrier);
          
          let total_weight=pac_dim.length;
          if(total_weight>1){
            item1.xcCosto = 0;
            // item1.xcPrecioVenta = 0;
            // item1.xcPrecioVentaPacto = 0;
            item1.xcKgText ='';
          }
          let iterado=false;
          let encontrado=0;
          let no_encontrado=0;
          let no_encontrado_factura=0;
  
  
          //Recorrer el listado de paquetes de la guía
          pac_dim.forEach((ipackage: { length: number; width: number; height: number; weight:number;}) => {
              let weightItem=(ipackage.length*ipackage.width*ipackage.height)/5000; //Sacar el peso volumétrico
              if(weightItem<ipackage.weight) // 09/11/2023 Sam dice que se debe tomar el que tenga mayor peso entre el volumétrico y el declarado en la guía
                weightItem=ipackage.weight;

            //filtrar por provider y paqutería        
            const cost = this.xlsDataCost.find((element) => (item1.provider && element.xcProveedor && (item1.provider.toUpperCase() == element.xcProveedor.toUpperCase()) )
                && (element.xcPaqueteria && bdCarrier.toUpperCase().includes(element.xcPaqueteria.toUpperCase()))
                && (item1.shipment_type.toLowerCase()=='nat' ? (element.xcModo && element.xcModo.toUpperCase() == modoEnv.toUpperCase()) : (element.xcModo && element.xcModo.toUpperCase().includes('INT')))
                && (element.xcKg && element.xcKg >= weightItem )
                && (bdCarrier.toUpperCase()=='DHL'?( element.xcZona && item1.service && this.getZoneCost(item1.service, element.xcZona) ) : true )
              );
    
            if(cost){
              //item1.xcPartnerId = cost.xcPartnerId;
              item1.xcPaqueteria = cost.xcPaqueteria;
              item1.xcModo = cost.xcModo;
              item1.xcKg = cost.xcKg;
              if(total_weight>1){ //si es más de 1 paquete en la misma guía
                if(item1.xcKgText=='')
                  item1.xcKgText = `${cost.xcKg} KG.`;
                else
                  item1.xcKgText += ` ${cost.xcKg} KG.`;
  
                if(item1.xcCosto!=undefined && cost.xcCosto!=undefined)
                  item1.xcCosto += cost.xcCosto;
                // if(item1.xcPrecioVenta!=undefined && cost.xcPrecioVenta!=undefined)
                //   item1.xcPrecioVenta += cost.xcPrecioVenta;
                // if(item1.xcPrecioVentaPacto!=undefined && cost.xcPrecioVentaPacto!=undefined)
                //   item1.xcPrecioVentaPacto += cost.xcPrecioVentaPacto;
              }else{
                item1.xcKgText = `${cost.xcKg} KG.`;
                item1.xcCosto = cost.xcCosto;
                // item1.xcPrecioVenta = cost.xcPrecioVenta;
                // item1.xcPrecioVentaPacto = cost.xcPrecioVentaPacto;
              }
              //item1.xcMoneda = cost.xcMoneda;
              encontrado++;            
            }else{
              no_encontrado++;
            }

            //Contar a los costos no encontrados pero con factura
            if(item1.xTotal_price && item1.xTotal_price>0){
              if(!cost)
                no_encontrado_factura++;
            }

            iterado=true;
          });
          
          //Contar a los costos no encontrados pero con factura, por eso se cambio la condición
          if(no_encontrado_factura==0){ //if(no_encontrado==0){
            contaFound++;
            this.countCoincidencia++;
          }else{
            this.xlsDataNotFoundCatalog.push(item1);
            contaNotFound++;
            //console.log(modoEnv,item1);
          }
        }else{
          //console.log('provider',item1.provider);
        }
        
      });
      //Se comento para que solo se muestren las que están en la factura en primera instancia
      //this.activeFilterXls=false;
      //this.filterXCTracking();
    }
    this.isLoading = false;
  }

  getPermittedProvider(){
    let arrProvPermitidos=[];
    switch (this.formatoXls) {
      case 'Pak2go':
        arrProvPermitidos.push('pgo');
        break;
      case 'Afimex':
        arrProvPermitidos.push('afx');
        break;
      case 'Enviame':
        arrProvPermitidos.push('eme');
        break;
      case 'Paquetexpress':
        arrProvPermitidos.push('pxs');
        break;
      case 'Estafeta':
        arrProvPermitidos.push('est');
          break;
      default:
        break;
    }
    return arrProvPermitidos;
  }

  getZoneFinal(service:string){
    const match=service.match(/Z(\d+)$/); //Busca al final de la cadena una Z seguida de dígitos
    //Si encontró retorna los dígitos si no devuelve nulo
    return match ? parseInt(match[1], 10) : null;
  }

  getZoneCost(service:string, zone:string ){
    let esZona=false;
    let dzone=this.getZoneFinal(service);
    if(dzone!=null){
      if (zone.includes('-')) {
        const [inicio, fin] = zone.split('-').map(Number);
        esZona = (dzone >= inicio && dzone <= fin);
      } else {
        esZona = (dzone == Number(zone));
      }
    }
    return esZona;
  }

  getServiceMode(service:string, carrier:string, provider:string ) {
    try {
      let sService=service;
      const indexFound = this.servicesModeList.findIndex(r=> r.provider.toUpperCase() == provider.toUpperCase() 
                                                          && (r.carrier.toUpperCase().includes(carrier.toUpperCase()) || carrier.toUpperCase().includes(r.carrier.toUpperCase())) 
                                                          && (service.toUpperCase().includes(r.service.toUpperCase()) || r.service.toUpperCase().includes(service.toUpperCase())) );

      // const indexFound = this.servicesModeList.findIndex(r=> r.provider.toUpperCase() == provider.toUpperCase() && r.carrier.toUpperCase() == carrier.toUpperCase() && service.toUpperCase().includes(r.service.toUpperCase()) );
      if(indexFound != -1) { //Si se encuentra guardar temporalmente en el auxCarSer para su posterior ordenamiento
        //sService=`${service}->${this.servicesModeList[indexFound].service_mode}`;
        sService=`${this.servicesModeList[indexFound].service_mode}`;
      }
      return sService;
    } catch (error) {
      return service; 
    }
  }

  // showSumFooter() {
  //   let vShow = false;
  //   if(this.okCurrency!=undefined){ 
  //     if(this.okCurrency.length==1){
  //       vShow = true;
  //     }else{
  //       if(this.selCurrency.value == "0"){ //si son todas
  //         vShow = false;
  //       }else{ //Tiene selecciona 1 tipo de moneda
  //         vShow = true
  //       }
  //     }
  //   }else{
  //     vShow = false;
  //   }
  //   return vShow;
  // }

  filterXTracking() {
    if(this.activeFilterXls==false){
      //this.dataSource.filterPredicate = (data, filter) => data.xTotal_price > 0;
      this.dataSource.filterPredicate = (data, filter) => {
        return (data.xTotal_price!=null && data.xTotal_price > 0);
      };
      this.dataSource.filter = 'apply-filter'; // Cualquier valor aquí funcionará
    }else{
      this.dataSource.filterPredicate = (data, filter) => {
        return true;
      };
      this.dataSource.filter = '';
    }
    this.activeFilterXls = !this.activeFilterXls;
   
  }
  filterXCTracking() {
    if(this.activeFilterXls==false){
      //this.dataSource.filterPredicate = (data, filter) => data.xcCosto > 0;
      let arrProvPermitidos=this.getPermittedProvider();

      this.dataSource.filterPredicate = (data, filter) => {
        return (data.xcCosto!=null && data.xcCosto > 0 && arrProvPermitidos.includes(data.provider) );
      };
      this.dataSource.filter = 'apply-filter'; // Cualquier valor aquí funcionará
    }else{
      this.dataSource.filterPredicate = (data, filter) => {
        return true;
      };
      this.dataSource.filter = '';
    }
    this.activeFilterXls = !this.activeFilterXls;
   
  }

  getStylePrecio(precio_venta:number, costo:number, property:string) {
    let res='';

    if(precio_venta > costo){
      if(property=='total_price' || property=='xcCosto' || property=='xTotal_dif')
        res='font-weight: bold; --bs-text-opacity: 1; color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;';
      else
        res='font-weight: bold;'; // color: gray;
    }
    return res;
  }

  setNameXls() {
    let monthShort=this.dateNow.toLocaleString('default', { month: 'short' });
    let year=` ${this.dateNow.getFullYear()}`;
    let sCli= this.formatoXls||'';//this.selClient.value == "0"?'':' '+this.selClient.value;
    let sCur='';//this.selCurrency.value == "0"?'':' '+this.selCurrency.value;
    
    let res=`Comparativa ${monthShort}${year}${sCli}${sCur}`;

    return res;
  }

  getInitialExtendedZone(data:number) {
    try {
      let cadena='';
      switch (data) {
        case 0://No se ha consultado
          cadena='';
          break;
        case 1://ZOna extendida
          cadena='E';
          break;
        case 2://ZOna Normal
          cadena='N';
          break;
        case 3://No es extendida ni normal
          cadena='';
          break;
        case 4://Error
          cadena='';
          break;
      }
      
      return cadena;
    } catch (error) {
      return ''; 
    }
  }

  sumWeight(row: any): number {
    let suma = 0;
    for (const item of row.package_dimensions) {
      suma += item.weight;
    }
    return suma;
  }

}
