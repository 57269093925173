import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import { CompaniesAddCreditComponent } from '../companies-add-credit/companies-add-credit.component';
import { CompanyOptionsModel, CountryModel } from 'src/app/models/company.model';

@Component({
  selector: 'app-companies-edit',
  templateUrl: './companies-edit.component.html',
  styleUrls: ['./companies-edit.component.scss']
})
export class CompaniesEditComponent implements OnInit {

  formEdit!: FormGroup;
  countriesList: any = [];
  selectedPais: any;
  companyOptionsList: any = [];
  commissionTypesList: any = [];
  tipoCom!: {
    id: string,
    nombre: string
  }[];
  tipoComSel: string = "";
  selected : any = [];

  providersList: any = [];
  carriersList: any = [];
  carriersListFiltered: any = [];
  carriersListTemp: any = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<CompaniesEditComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    //console.log(this.data);
    this.tipoCom = [{"id": "porc", "nombre": "Porcentaje"},{"id": "cant", "nombre": "Cantidad"}];
    this.getCompanyConfigList();
    this.getCommissionType();
    this.getCountries();
    this.getCompanyOptions(this.data.company.id_company);
    //this.getConfigList();
    this.createForm();
    this.setUserForm();
    this.selectISOCountry();
  }

  createForm() {
    this.formEdit = this.fb.group({
      id_company: ['', [ Validators.required]],
      id_commission_company: ['',[]],
      id_commission_int_company: ['',[]],
      id_company_credit: ['',[]],
      key: ['', [ Validators.required, Validators.maxLength(15)]],
      name: ['', [ Validators.required, Validators.minLength(3), Validators.maxLength(45)]],
      commission_type: ['',[Validators.required]],
      commission_name: ['',[]],
      country: ['', [ Validators.required, Validators.maxLength(100)]],
      iso_country: ['', [ Validators.required, Validators.maxLength(3)]],
      currency: ['', [ Validators.required, Validators.maxLength(3)]],
      active: ['',[]],
      commission_tipo: ['', []],
      commission_cantidad: ['',[]],
      credit: [{ value: '', disabled: true },[]],
      created_at: ['',[]],
      id_option_api: [0,[]],
      opt_api_cre: [false,[]],
      opt_api_com: [false,[]],
      id_option_pla: [0,[]],
      opt_pla_cre: [false,[]],
      opt_pla_com: [false,[]],
      international_shipping: [false,[]],
      commission_int_cantidad: ['',[]],
      carriers: ['', []],
      providers: ['', []],
      limit: ['', []]
    });
  }

  selectISOCountry(){
    console.log('selectISOCountry');
    this.formEdit.get('iso_country')?.valueChanges.subscribe(iso => {
      console.log('iso', iso);
      if (iso) {
        // Encuentra el país seleccionado en la lista de países
        this.selectedPais = this.countriesList.find((pais: CountryModel) => pais.iso_country === iso);
        console.log(this.selectedPais);
        // Actualiza el valor del campo currency en el formulario
        this.formEdit.get('currency')?.setValue(this.selectedPais.currency);
        this.formEdit.get('country')?.setValue(this.selectedPais.country);
      }
    });
  }

  getCommissionType() {
    this.userS.getCommissionTypes().subscribe(
      (result:any) => {
        this.commissionTypesList = result;
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getLastWord(words:string):string  {
    let aWords=words.split(' ');
    let lastWord=aWords[aWords.length-1];
    return `${lastWord.charAt(0).toUpperCase()}${lastWord.slice(1)}`;
  }

  getCountries() {
    this.userS.getCountries().subscribe(
      (result:any) => {
        this.countriesList = result;
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getCompanyOptions(id_company:number) {
    this.userS.getPurchaseOptions(id_company).subscribe(
      (result:any) => {
        this.companyOptionsList = result;
        //console.log(this.companyOptionsList);

        this.companyOptionsList.forEach((item: CompanyOptionsModel) => {
          if(item.type=='api'){
            this.formEdit.get('id_option_api')?.setValue(item.id);
            this.formEdit.get('opt_api_cre')?.setValue(item.credit);
            this.formEdit.get('opt_api_com')?.setValue(item.commission);
          }else{
            this.formEdit.get('id_option_pla')?.setValue(item.id);
            this.formEdit.get('opt_pla_cre')?.setValue(item.credit);
            this.formEdit.get('opt_pla_com')?.setValue(item.commission);
          }
        });
      },
      (err:any) => {
        this.data.company.id_option_api=0;
        this.data.company.opt_api_cre=false;
        this.data.company.opt_api_com=false;
        this.data.company.id_option_pla=0;
        this.data.company.opt_pla_cre=false;
        this.data.company.opt_pla_com=false;
        
        this.formEdit.get('id_option_api')?.setValue(this.data.company.id_option_api);
        this.formEdit.get('opt_api_cre')?.setValue(this.data.company.opt_api_cre);
        this.formEdit.get('opt_api_com')?.setValue(this.data.company.opt_api_com);      
        this.formEdit.get('id_option_pla')?.setValue(this.data.company.id_option_pla);
        this.formEdit.get('opt_pla_cre')?.setValue(this.data.company.opt_pla_cre);
        this.formEdit.get('opt_pla_com')?.setValue(this.data.company.opt_pla_com);

        console.log(err);
      }
    );
  }


  setTipoCom(e:any) {
    //console.log(e);
    this.tipoComSel = e.value;//this.formEdit.value.tipo_comision;
  }
  
  setUserForm() {
    //const { active, email, id, role, rol_name, ...userFormInfo } = this.data.company;
    this.data.company.id_option_api=0;
    this.data.company.opt_api_cre=false;
    this.data.company.opt_api_com=false;
    this.data.company.id_option_pla=0;
    this.data.company.opt_pla_cre=false;
    this.data.company.opt_pla_com=false;
    this.formEdit.setValue(this.data.company);
    this.tipoComSel = this.data.company.commission_tipo;
  }

  getConfigList() {
    this.userS.getConfigList().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.providersList = result.data.providers;
          this.carriersList = result.data.carriers;

          if(this.formEdit.value.providers==null)
            this.formEdit.value.providers=[];
          const prov = this.formEdit.value.providers.map((p:any)=> p)

          this.carriersListFiltered = [];
          if(this.formEdit.value.providers.length > 0){
            this.carriersListTemp = this.carriersList.filter((c:any) => prov.includes(c.id_provider)).map((c:any)=> ({ id : c.id , carrier: c.carrier}));
            this.carriersListTemp.forEach( (element:any) => {
              if(!this.carriersListFiltered.find((e:any)=>  e.carrier == element.carrier )){
                this.carriersListFiltered.push(element);
              }
            });
          }
          
        }
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getCompanyConfigList() {
    this.userS.getCompanyConfigList(this.data.company.id_company).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.formEdit.controls["carriers"].setValue(result.data.carriers.map((elem: { id: any, active:any ; }) => (elem.active ? elem.id : undefined)));
          // this.formEdit.controls["carriers"].setValue(result.data.carriers.map((elem: { id: any }) => ( elem.id)));
          this.formEdit.controls["providers"].setValue(result.data.providers.map((elem: { id: any, active:any ; }) => (elem.active ? elem.id : undefined)));
          // this.formEdit.controls["providers"].setValue(result.data.providers.map((elem: { id: any }) => (elem.id)));  

          this.getCarriers();
        }
      },
      (err:any) => {
        console.log(err);
      }
    )
  }

  getCarriers() {
    this.carriersListFiltered = [];
    if(this.formEdit.value.providers.length > 0){
      this.carriersListTemp = this.carriersList.filter((c:any) => this.formEdit.value.providers.includes(c.id_provider)).map((c:any)=> ({ id : c.id , carrier: c.carrier}));
      this.carriersListTemp.forEach( (element:any) => {
        if(!this.carriersListFiltered.find((e:any)=>  e.carrier == element.carrier )){
          this.carriersListFiltered.push(element);
        }
      });
    }
    this.getConfigList();
  }


  ruSure() {
    if(this.formEdit.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        //console.log(this.formEdit.value);
        this.save();
      }
    });
  }

  save() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading(null);
      }
    });
    this.userS.editCompany(this.formEdit.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Compañía guardada de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {        
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
      }
    );
  }

  openDialogAddCredit() {
    this.matDialog.open(CompaniesAddCreditComponent, {
      data: { 
        id_company_credit: this.data.company.id_company_credit,
        id_company: this.data.company.id_company,
        credit: this.data.company.credit,
        observations: '',
        add_credit:0
      },
      panelClass: 'dialogs-sm',
    }).afterClosed().subscribe(resp => {
      if (resp) {
        //console.log(resp);
        this.data.company.credit = resp.credit;
        this.formEdit.get('credit')?.setValue(resp.credit);
        this.onCreditBlur();
      }
    });
  }

  resetForm() {
    this.selected = [];
    this.formEdit.reset();
  }

  onCreditBlur() {
    let val = this.formEdit.get('credit')?.value;
    //console.log('val onCreditBlur: ',val);
    if (val!= '' && val >= 0) {
      this.formEdit.get('opt_api_cre')?.patchValue(true);
      this.formEdit.get('opt_pla_cre')?.patchValue(true);
    }
  }

}
