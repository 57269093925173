<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Detalle de la orden</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'/admin/shipment-orders'">
                            <mat-icon>plagiarism</mat-icon>
                        </a></li>
                    <li class="breadcrumb-item">Detalle de la orden</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid"  *ngIf="noDataReceived">
    <div class="row">
        <div class="container">
            <div class="col">
                <div class="card card-absolute shadow shadow-showcase">
                    <div class="card-body">
                        <div class="alert alert-light-warning" role="alert" style="margin-bottom: 0.5rem;">
                            <p class="text-orange">
                                <mat-icon class="align-middle">warning</mat-icon>
                                Regresa al <a [routerLink]="'/admin/shipment-orders'">listado</a> y selecciona una orden para poder ver los detalles.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid"  *ngIf="!noDataReceived">
    <div class="row">
        <div class="container" *ngIf="!preLoadHidden" >
            <div class="row">
                <div class="col">   
                    <div class="card card-absolute shadow shadow-showcase">
                        <div class="card-header bg-primary">
                            <h5 class="text-white">Origen <mat-icon class="align-middle">pin_drop</mat-icon>
                            </h5>
                        </div>
                        <div class="card-body">
                            <mat-list role="list">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">   
                                            <mat-list-item role="listitem"><strong class="pe-2">Nombre:</strong>{{dataShipment.body.origin.name}}</mat-list-item>
                                        </div>
                                        <div class="col">   
                                            <mat-list-item role="listitem"><strong class="pe-2">Empresa:</strong>{{dataShipment.body.origin.company}}</mat-list-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem"><strong class="pe-2">Email:</strong>{{dataShipment.body.origin.email}}</mat-list-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem"><strong class="pe-2">Teléfono:</strong>{{dataShipment.body.origin.phone}}</mat-list-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem" style="height: 50px;">
                                                <div>
                                                    <strong class="pe-2">Dirección:</strong>{{ dataShipment.body.origin.street }} {{ dataShipment.body.origin.number }}, <strong class="pe-2">Colonia:</strong>{{ dataShipment.body.origin.suburb }}, <strong class="pe-2">Ciudad:</strong>{{ dataShipment.body.origin.city }}, <strong class="pe-2">Estado:</strong>{{ dataShipment.body.origin.state }}, <strong class="pe-2">País:</strong>{{dataShipment.body.origin.country}}, <strong class="pe-2">CP:</strong>{{ dataShipment.body.origin.postalCode }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                    </div>
                                </div>
                            </mat-list>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card card-absolute shadow shadow-showcase">
                        <div class="card-header bg-primary">
                            <h5 class="text-white">Destino <mat-icon class="align-middle">person_pin_circle</mat-icon>
                            </h5>
                        </div>
                        <div class="card-body">
                            <mat-list role="list">
                                <div class="container">
                                    <div class="row">
                                        <div class="col">   
                                            <mat-list-item role="listitem"><strong class="pe-2">Nombre:</strong>{{dataShipment.body.destination.name}}</mat-list-item>
                                        </div>
                                        <div class="col">   
                                            <mat-list-item role="listitem"><strong class="pe-2">Empresa:</strong>{{dataShipment.body.destination.company}}</mat-list-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem"><strong class="pe-2">Email:</strong>{{dataShipment.body.destination.email}}</mat-list-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem"><strong class="pe-2">Teléfono:</strong>{{dataShipment.body.destination.phone}}</mat-list-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem" style="height: 50px;">
                                                <div>
                                                    <strong class="pe-2">Dirección:</strong>{{ dataShipment.body.destination.street }} {{ dataShipment.body.destination.number }}, <strong class="pe-2">Colonia:</strong>{{ dataShipment.body.destination.suburb }}, <strong class="pe-2">Ciudad:</strong>{{ dataShipment.body.destination.city }}, <strong class="pe-2">Estado:</strong>{{ dataShipment.body.destination.state }}, <strong class="pe-2">País:</strong>{{dataShipment.body.destination.country}}, <strong class="pe-2">CP:</strong>{{ dataShipment.body.destination.postalCode }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                    </div>
                                </div>
                            </mat-list>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row equal-height-row">
                <div class="col">
                    <div class="card card-absolute shadow shadow-showcase col">
                        <div class="card-header bg-primary">
                        <h5 class="text-white">Paqueteria  <mat-icon class="align-middle">view_in_ar</mat-icon>
                        </h5>
                        </div>
                        <div class="card-body">
                            <mat-list role="list">
                                <div class="container" *ngIf="dataShipment.body?.selected_rate">
                                    <div *ngIf="!(dataShipment.body?.selected_rate)" class="alert alert-light-warning" role="alert" style="margin-bottom: 0.5rem;">
                                        <p class="text-orange">
                                            <mat-icon class="align-middle">warning</mat-icon>
                                            No se pudo recuperar los datos de la tarifa seleccionada.
                                        </p>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem">
                                                <img src="../../../assets/images/carriers/{{imgFormat(dataShipment.body.selected_rate.carrier)}}-sm.jpg" alt="{{imgFormat(dataShipment.body.selected_rate.carrier)}}">
                                            </mat-list-item>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <mat-list-item role="listitem"><strong class="pe-2">Servicio:</strong>{{dataShipment.body.selected_rate.service}}</mat-list-item>
                                            </div>
                                            <div class="col">
                                                <mat-list-item role="listitem"><strong class="pe-2">Proveedor:</strong>{{dataShipment.body.selected_rate.source}}</mat-list-item>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col">   
                                            <mat-list-item role="listitem"><strong class="pe-2">Precio:</strong>{{dataShipment.body.selected_rate.total_price | currency}} {{ dataShipment.body.selected_rate.currency=='MXN'? '': dataShipment.body.selected_rate.currency }}</mat-list-item>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="container" *ngIf="!(dataShipment.body?.selected_rate)">
                                    <div class="row">
                                        <div *ngFor="let carrier of dataShipment.body.shipment.carrier; index as i" class="col">
                                            <mat-list-item role="listitem">
                                                <img src="../../../assets/images/carriers/{{imgFormat(carrier)}}-sm.jpg" alt="{{imgFormat(carrier)}}">
                                            </mat-list-item>
                                        </div>
                                        <div class="row">
                                            <div class="col" *ngIf="dataShipment.body?.shipment?.service">   
                                                <mat-list-item role="listitem"><strong class="pe-2">Servicio:</strong>{{dataShipment.body.shipment.service}}</mat-list-item>
                                            </div>
                                            <div class="col" *ngIf="dataShipment.body?.shipment?.provider && dataShipment.body?.shipment?.provider.length > 0">   
                                                <mat-list-item role="listitem">
                                                    <strong class="pe-2">Proveedor{{ dataShipment.body.shipment.provider.length>1?'es':'' }}:</strong><ng-container *ngFor="let provider of dataShipment.body.shipment.provider; index as i">{{i==0?'':', '}}{{provider}}</ng-container>
                                                </mat-list-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-list>
                        </div>
                    </div>
                </div>    
                <div class="col">
                    <div class="card card-absolute shadow shadow-showcase col">
                        <div class="card-header bg-primary">
                          <h5 class="text-white">Paquetes  <mat-icon class="align-middle">view_in_ar</mat-icon>
                          </h5>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col">   
                                    <mat-list-item role="listitem"><strong class="pe-2">Número de orden:</strong>{{dataShipment.order_number}}</mat-list-item>
                                </div>
                            </div>
                            <div *ngFor="let package of dataShipment.body.packages; index as i"  class="row align-items-center justify-content-center">
                                <mat-list role="list">
                                    <b class="fs-6">Paquete {{i+1}}</b>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem"><strong class="pe-2">Tipo de paquete:</strong>{{ package.shipmentType  == 'box' ? 'Caja' : package.shipmentType == 'envelope' ? 'Sobre' : 'Tarima' }}</mat-list-item>
                                        </div>
                                        <div class="col">
                                            <mat-list-item role="listitem"><strong class="pe-2">Medidas:</strong>{{ package.length}}x{{ package.width}}x{{ package.height}}, {{ package.weight}} kg</mat-list-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <mat-list-item role="listitem"><strong class="pe-2">Contenido:</strong>{{package.content}}</mat-list-item>
                                        </div>
                                    </div>
                                    <mat-divider *ngIf="dataShipment.body.packages.length > 1" class="mt-3"></mat-divider>
                                </mat-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="container" *ngIf="!preLoadHidden" >
            <div class="row equal-height-row">
                <div class="col-6">   
                    <div class="card card-absolute shadow shadow-showcase">
                        <div class="card-header bg-primary">
                            <h5 class="text-white">Seguimiento <mat-icon class="align-middle">rebase_edit</mat-icon>
                            </h5>
                        </div>
                        <div class="card-body ">
                            <ng-container *ngFor="let item of dataShipment.info; index as i">{{i>1?' > ':''}}{{i==0?'':formatItem(item)}}</ng-container>
                            <div class="align-text-top pe-2 text-justify pt-2 pb-2 "  *ngFor="let item of dataShipment.info_try; index as i">                                   
                                <strong>Proveedor:</strong> {{item.source}}, <strong>Transportador:</strong> {{item.carrier}}, <strong>Servicio:</strong> {{item.service}}, <strong>Precio:</strong> {{item.total_price}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card card-absolute shadow shadow-showcase">
                        <div class="card-header bg-primary">
                            <h5 class="text-white">Comentarios <mat-icon class="align-middle">notes</mat-icon>
                            </h5>
                        </div>
                        <div class="card-body ">
                            <ul *ngIf="dataShipment.warnings">
                                <li *ngFor="let item of dataShipment.warnings; index as i">{{ formatItem(item) }}</li>
                            </ul>
                            <div class="align-text-top pe-2 text-justify pt-2 pb-2"  *ngIf="dataShipment.warnings?.length == 0">
                                <p>N/A</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card card-absolute shadow shadow-showcase">
                        <div class="card-header bg-primary">
                            <h5 class="text-white">Errores <mat-icon class="align-middle">error</mat-icon>
                            </h5>
                        </div>
                        <div class="card-body">
                            <ul *ngIf="dataShipment.errors">
                                <li *ngFor="let item of dataShipment.errors; index as i">{{ formatItem(item) }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>


    <div class="row" *ngIf="isLoading">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">                    
                    <div style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="primary" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>