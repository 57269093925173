import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { map, Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private main: MainService

  ) { }

  getDashboardData( period : string ) {
    return this.main.getRequest(`/dashboard/get-dashboard-data?period=${period || '1%20month'}`).pipe(
      map((resp: any) => {
        if(resp.message == "Success") {
          return resp.data;
        }     
      })
    );
  }

}
