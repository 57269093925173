import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipments-help',
  templateUrl: './shipments-help.component.html',
  styleUrls: ['./shipments-help.component.scss']
})
export class ShipmentsHelpComponent implements OnInit {

  formHelp!: FormGroup;

  constructor(
    @Inject( MAT_DIALOG_DATA ) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<ShipmentsHelpComponent>
  ) { }

  ngOnInit(): void {
    this.createFormHelp();
  }

  createFormHelp() {
    this.formHelp = this.fb.group({
      description: ['', [ Validators.required, Validators.minLength(3)]],
    });
  }

  ruSure() {
    if(this.formHelp.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas solicitar ayuda para el envío: ' + this.data.shipmentSel.carrier + ' - ' + this.data.shipmentSel.tracking_number + '?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.sendHelp();
      }
    });
  }

  sendHelp() {
    //console.log(this.formHelp.value);
    this.userS.sendHelpShipping(this.data.shipmentSel.id, this.data.shipmentSel.tracking_number, this.formHelp.value.description ).subscribe(
      (result : any) => {
        //this.selCompaniesList = result;
        Swal.fire({
          icon: 'success',
          title: 'Enviado',
          text: 'Solicitud de ayuda enviada de manera correcta!'
        }).then(() => {
          this.dialogRef.close();
          //console.log(result);
        });

      },
      (err : any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al enviar',
          text: 'Ocurrió un error al enviar la solicitud de ayuda, por favor vuelva a intentarlo.'
        });
      }
    );
  }

}
