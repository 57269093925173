export class CompanyModel {
    id!: number;
    name!: string;
    active!: number;
    created_at!: string;
}


export class CompanyCompleteModel {
    id_company!:number;
    id_commission_company!:number;
    id_company_credit!:number;
    key!: string;
    name!: string;
    commission_type!: number;
    commission_name!: string;
    id_commission_compan!: string;
    commission_tipo!: string;
    commission_cantidad!: string;
    country!: string;
    iso_country!: string;
    currency!: string;
    active!: number;
    created_at!: string;
    credit!: number;
}

export class CompanySaveModel {
    id_company!:number;
    key!: string;
    name!: string;
    commission_type!: number;
    country!: string;
    iso_country!: string;
    currency!: string;
    active!: number;
    
    id_commission_company!:number;
    commission_tipo!:string;
    commission_cantidad!:string;
    commission_int_cantidad!:string;

    id_company_credit!:number;
    credit!:string;

    id_option_api!:number;
    opt_api_cre!:number;
    opt_api_com!:number;
    id_option_pla!:number;
    opt_pla_cre!:number;
    opt_pla_com!:number;
}

export class CommissionTypesModel {
    id!: number;
    name!: string;
}

export class CountryModel {
    id!: number;
    country!: string;
    iso_country!: string;
    currency!: string;
}

export class CompanyOptionsModel {
    id!: number;
    id_company!: number;
    type!: string;
    credit!: string;
    commission!: string;
}