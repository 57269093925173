<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 mat-dialog-title class="m-0">Crear Manifiesto</h1>
<p class="mb-3">Selecciona las guías para crear el manifiesto:</p>
<div mat-dialog-content>
  <form [formGroup]="billForm">
    <div class="row">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Órdenes</mat-label>
        <mat-select formControlName="selection" (selectionChange)="addToManifest()">
          <mat-option class="shipment-opt" *ngFor="let x of orders" [value]="x" [disabled]="bill.includes(x)">
            <div class="shipment-opt-main">
              <div class="shipment-opt-r1">
                <span class="shipment-opt-id">{{ x[0] }}</span>
                <span class="shipment-opt-gray">({{ x[1] }})</span>
              </div>
              <div class="shipment-opt-r2">
                <span>{{ x[2] }}</span>
                <span class="shipment-opt-gray">{{ x[3] }}</span>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <h2 class="mb-1 mt-3">Guías seleccionadas:</h2>
    </div>
    <div class="row">
      <div class="scrollable-box">
        <div *ngFor="let x of bill" class="selected-item">
          <div class="col-11">
            <b>{{ x[0] }}</b><br>
            {{ x[1] }}<br>
            {{ x[2] }}
          </div>
          <button mat-icon-button (click)="removeItem(x)"><mat-icon>highlight_remove</mat-icon></button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button mat-flat-button class="bg-success text-white px-3 py-1 my-3" form="billForm" type="submit" (click)="onSubmit()">Guardar &nbsp;&nbsp;<mat-icon>save</mat-icon></button>
</div>