<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>Compañías</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon>group</mat-icon></a></li>
                    <li class="breadcrumb-item">Lista de Compañías</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <div class="float-end">
                            <button mat-raised-button color="primary" (click)="openDialogCreate()" class="mt-2 mb-2">
                                Agregar nueva <mat-icon>person_add</mat-icon>
                            </button>
                        </div>
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. buho" #input>
                        </mat-form-field>
                    </div>

                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id_company}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="commission">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Comisión </th>
                                <td mat-cell *matCellDef="let row"> {{row.commission_name}}<span *ngIf="row.commission_cantidad!=null">{{row.commission_tipo=='porc'?': ':': $'}}{{row.commission_cantidad}}{{row.commission_tipo=='porc'?'%':''}}</span>
                                </td>
                            </ng-container>
                
                            <ng-container matColumnDef="country">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                                <td mat-cell *matCellDef="let row"> {{row.country}} </td>
                            </ng-container>

                            <ng-container matColumnDef="currency">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                                <td mat-cell *matCellDef="let row"> {{row.currency}} </td>
                            </ng-container>

                            <ng-container matColumnDef="credit">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Créditos </th>
                                <td mat-cell *matCellDef="let row"> {{row.credit | currency }} </td>
                            </ng-container>

                            <ng-container matColumnDef="international_shipping">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Envíos </th>
                                <td mat-cell *matCellDef="let row"> {{row.international_shipping?'Internacional':'Nacional'}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center">
                                    <button [disabled]="row.rol_name == 'admin'" mat-icon-button matTooltip="Configuración" [matTooltipPosition]="'above'" (click)="openDialogEdit(row)" color="primary">
                                        <mat-icon [hidden]="row.rol_name == 'admin'" >settings</mat-icon>
                                    </button>
                                    <button [disabled]="row.rol_name == 'admin'" mat-icon-button matTooltip="Movimientos" [matTooltipPosition]="'above'" (click)="openDialogClabe(row)" color="primary">
                                        <mat-icon [hidden]="row.rol_name == 'admin'" >account_balance_wallet</mat-icon>
                                    </button>
                                    <!-- <mat-slide-toggle [checked]="row.active == 1" (click)="activate(row.id, row.active, row.name)" matTooltip="¿Activo?" [matTooltipPosition]="'above'" color="primary"></mat-slide-toggle>
                                    <button mat-icon-button matTooltip="Eliminar" [hidden]="!isAdmin()" [matTooltipPosition]="'above'" color="warn" (click)="delete(row.id)">
                                        <mat-icon>delete</mat-icon>
                                    </button> -->
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td *ngIf="dataList.length == 0" class="mat-cell" colspan="6">No hay información para mostrar</td>
                                <td *ngIf="dataList.length > 1" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                            </tr>
                        </table>
                
                        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                            <mat-progress-spinner color="primary" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>