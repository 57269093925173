import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddressModel } from 'src/app/models/address.model';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.scss']
})
export class AddressSelectComponent implements OnInit {

  addressList = new Array<AddressModel>();
  isLoading = true;
  isOrigin! : number;
  addressSelected! : AddressModel;

  displayedColumns: string[] = ['addressName', 'name', 'email', 'address', 'actions'];
  dataSource!: MatTableDataSource<AddressModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    @Inject( MAT_DIALOG_DATA ) public data:any
  ) { }

  ngOnInit(): void {
    this.isOrigin = this.data.origin;
    this.getAddressList();
  }

  getAddressList(){
    this.userS.getAddressList(this.isOrigin).subscribe(
      (result : any) => {
        this.addressList = result;
        this.dataSource = new MatTableDataSource(this.addressList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteAddress(id:any) {
    Swal.fire({
      title: '¿Está seguro que desea eliminar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Eliminando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading(null);
          }
        });
        this.userS.deleteAddress(id).subscribe(
          (result:any) => {
            if (result.message == "Success")                
            Swal.fire({
              icon: 'success',
              title: 'Eliminado',
              text: 'Usuario eliminado de manera correcta!'
            }).then(() => {
              this.getAddressList();
            });
          },
          (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al eliminar',
              text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
            });
          }
        );
      }
    });
  }

  selectAddress(addressSelected:AddressModel) {
    this.addressSelected = addressSelected;
    return [this.addressSelected, this.isOrigin];
  }

}
