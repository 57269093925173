<div>
    <div class="logo-wrapper">
        <a [routerLink]="'dashboard'">
            <img class="img-fluid for-dark" src="./../../../../assets/images/logo_buho_wing_wh.png" alt="">
        </a>
    </div>
    <nav class="sidebar-main">
        <div id="sidebar-menu">
            <ul class="sidebar-links">
                <div class="simplebar-wrapper">
                    <div class="simplebar-height-auto-observer-wrapper">
                        <div class="simplebar-height-auto-observer"></div>
                    </div>
                    <div class="simplebar-mask">
                        <div class="simplebar-offset">
                            <div class="simplebar-content-wrapper">
                                <div class="simplebar-content">
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'dashboard'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">dashboard</mat-icon> Dashboard</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list sidebar-title-sec" [hidden]="isClientUsr">
                                        <span>Envíos</span>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'shipments'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">local_shipping</mat-icon> Mis Envíos</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'myfavorites'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">favorite</mat-icon> Mis Favoritos</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list" [hidden]="!allowedCompanies()">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'inhouse-shipments'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">inventory</mat-icon> Envíos Internos</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'bulk-uploads'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">pallet</mat-icon> Envíos Masivos</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title wing-icon" [routerLink]="'quick-quote'" [routerLinkActive]="'active'">
                                            <span>
                                                <svg viewBox="-2 -2 72 72" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" overflow="hidden">
                                                    <defs><clipPath id="clip0"><rect x="1432" y="894" width="69" height="69"/></clipPath></defs>
                                                    <g clip-path="url(#clip0)" transform="translate(-1432 -894)">
                                                        <path d="M1469.95 918.15 1476.85 918.15 1476.85 921.6 1483.75 921.6 1483.75 928.5 1469.95 928.5 1469.95 931.95 1480.3 931.95C1481.28 931.95 1482.1 932.281 1482.76 932.942 1483.42 933.603 1483.75 934.422 1483.75 935.4L1483.75 945.75C1483.75 946.727 1483.42 947.547 1482.76 948.208 1482.1 948.869 1481.28 949.2 1480.3 949.2L1476.85 949.2 1476.85 952.65 1469.95 952.65 1469.95 949.2 1463.05 949.2 1463.05 942.3 1476.85 942.3 1476.85 938.85 1466.5 938.85C1465.53 938.85 1464.71 938.519 1464.05 937.858 1463.38 937.197 1463.05 936.377 1463.05 935.4L1463.05 925.05C1463.05 924.073 1463.38 923.253 1464.05 922.592 1464.71 921.931 1465.53 921.6 1466.5 921.6L1469.95 921.6ZM1451.71 894 1448.83 916.25 1462.61 916.209 1442.9 944.805 1445.77 922.555 1432 922.597ZM1456.96 894 1480.3 894 1501 914.7 1501 956.1C1501 957.997 1500.32 959.622 1498.97 960.973 1497.62 962.324 1496 963 1494.1 963L1452.7 963C1450.81 963 1449.18 962.324 1447.83 960.973 1446.48 959.622 1445.81 957.997 1445.81 956.1L1445.81 949.743 1452.7 939.733 1452.7 956.1 1494.1 956.1 1494.1 914.7 1476.85 914.7 1476.85 900.9 1456.07 900.9Z"
                                                            fill-rule="evenodd"/>
                                                    </g>
                                                </svg> Cotización Rápida
                                            </span>
                                        </a>
                                    </li>

                                    <li class="sidebar-list sidebar-title-sec" [hidden]="isClientUsr || isOperationsUsr">
                                        <span>Recolecciones</span>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [hidden]="isClientUsr || isOperationsUsr" [routerLink]="'pickups'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">trolley</mat-icon> Recolecciones</span>
                                        </a>
                                    </li>

                                    <li class="sidebar-list sidebar-title-sec" [hidden]="isClientUsr || isOperationsUsr">
                                        <span>Configuración</span>
                                    </li>
                                    
                                    <li class="sidebar-list" >
                                        <a class="sidebar-link sidebar-title" [hidden]="isClientUsr || isOperationsUsr" [routerLink]="'companies'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">apartment</mat-icon> Compañías</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [hidden]="isClientUsr || isOperationsUsr" [routerLink]="'users'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">group</mat-icon> Usuarios</span>
                                        </a>
                                    </li>
                                    <!-- <li class="sidebar-list sidebar-title-sec"> -->
                                    <li [hidden]="isClientUsr" class="sidebar-list sidebar-title-sec">
                                        <span>Ecommerce</span>
                                    </li>
                                
                                    <li class="sidebar-list" [hidden]="!hasStores || isClientUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'ecommerce'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">list_alt</mat-icon> Ordenes</span>
                                        </a>
                                    </li>                                
                                    <li class="sidebar-list" [hidden]="hasStores || isClientUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'ecommerce'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">settings</mat-icon> Configuración</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list sidebar-title-sec" [hidden]="isClientUsr || isOperationsUsr">
                                        <span>Reportes</span>
                                    </li>
                                    <li class="sidebar-list" [hidden]="isClientUsr || isOperationsUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'executive-report'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">request_quote</mat-icon> Ejecutivo </span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list" [hidden]="isClientUsr || isOperationsUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'billing-data'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">request_quote</mat-icon> Estado de Cuenta</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list" [hidden]="isClientUsr || isOperationsUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'billing-data-sf'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">request_quote</mat-icon> Consumo</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list" [hidden]="isClientUsr || isOperationsUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'price-comparison'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">request_quote</mat-icon> Comparativa </span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list" [hidden]="isClientUsr || isOperationsUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'shipment-orders'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">plagiarism</mat-icon> Consultar Órdenes</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list" [hidden]="isClientUsr || isOperationsUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'billing-data-siteflow'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">request_quote</mat-icon> Siteflow </span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list" [hidden]="isClientUsr || isOperationsUsr">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'stp-orders'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">request_quote</mat-icon> STP </span>
                                        </a>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </nav>
</div>