    <div class="row justify-content-center">
        <mat-spinner
            class="ms-2 mt-5"
            diameter="100"
            *ngIf="isLoading; else content">
        </mat-spinner>  
    </div>
    <ng-template #content>
        <!-- <app-ecommerce-config *ngIf="isLoading || !hasStores; else ecommerceOrders"></app-ecommerce-config> -->
        <app-ecommerce-landing *ngIf="isLoading || !hasStores; else ecommerceOrders"></app-ecommerce-landing>
        <ng-template #ecommerceOrders>
            <app-ecommerce-orders></app-ecommerce-orders>
        </ng-template>
    </ng-template>
